import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { getLiveAndUpcomings, removeFromLiveAndUpcomings, updateFromLiveAndUpcomings, addLiveAndUpcomings, getLastResults, addLastResult, setCurrentGame } from "store/actions/game/game.actions";
import { getActiveJackpot, updateJackpotBonus, setWonJackpotBonuses } from 'store/actions/bonuses/jackpot.actions';
import { getGameRTPs } from "store/actions/auth/auth.actions";

import { GAME_STATUSES, GAME_TYPE } from "constants/game.constants";
import useForceUpdate from "hooks/useForceUpdate";
import useEvent from "hooks/useEvent";
import { CASHIER_API_INVOKE_EVENTS, CASHIER_API_LISTENER_EVENTS, JOB_API_INVOKE_EVENTS, JOB_API_LISTENER_EVENTS } from "constants/webSocket.contants";
import WebSocketService from "services/webSocket";
import { isNullish } from "utils/common";

/* Keno Page Wrapper Container Component - Wrapper */
const KenoWrapper = ({
	getLiveAndUpcomings,
	removeFromLiveAndUpcomings,
	updateFromLiveAndUpcomings,
	addLiveAndUpcomings,
	getLastResults,
	addLastResult,
	setCurrentGame,
	getGameRTPs,
	decodedData,
	getActiveJackpot,
	updateJackpotBonus,
	setWonJackpotBonuses
}) => {
	const token = useSelector(state => state.auth?.stream?.access?.wsToken ?? null)
	const [cashierForceUpdate, cashierForceUpdateState] = useForceUpdate()
	const [jobForceUpdate, jobForceUpdateState] = useForceUpdate()
	const game = decodedData?.kenoGame ?? null;
	const activeJackpotId = decodedData?.activeJackpotId ?? null;

	const onJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		updateJackpotBonus(d);
	})

	const onWonJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		setWonJackpotBonuses(d);
	})

	const onEvents = useEvent((data) => {
		const parsedData = JSON.parse(data);

		if (parsedData.gameType !== GAME_TYPE.KENO) {
			return;
		}

		if (parsedData.status === GAME_STATUSES.FINISHED) {
			removeFromLiveAndUpcomings(parsedData.id);

			if (parsedData.gameType === GAME_TYPE.KENO) {
				addLastResult(parsedData);
			}
		} else if (parsedData.status === GAME_STATUSES.STARTED || parsedData.status === GAME_STATUSES.CLOSE_FOR_BETTING || parsedData.status === GAME_STATUSES.PREAMBLE_STARTED) {
			updateFromLiveAndUpcomings(parsedData);
		} else if (parsedData.status === GAME_STATUSES.NEW) {
			addLiveAndUpcomings(parsedData);
		}
	})

	const handleCashierWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}
		
		wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.JACKPOTS);
		wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS);

		wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.JACKPOTS, onJackpots);
		wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS, onWonJackpots);

		cashierForceUpdate();
	})

	const handleJobWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}
		
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.EVENTS);

		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.EVENTS, onEvents);

		jobForceUpdate();
	})


	// Create connections
	const { cashierWS, jobWS } = useMemo(/** @return {WebSocketConnections} */() => {

		/** @type {WebSocketConnections} */
		const wsServices = {
			cashierWS: null,
			jobWS: null
		};

		if (!token) {
			return wsServices
		}

		const urlCashierWS = `${import.meta.env.SYSTEM_WS_URL_CASHIER}?accessToken=${token}`;
		const urlJobWS = `${import.meta.env.SYSTEM_WS_URL_JOBS}?accessToken=${token}`;

		wsServices.cashierWS = new WebSocketService(urlCashierWS, handleCashierWSEvent)
		wsServices.jobWS = new WebSocketService(urlJobWS, handleJobWSEvent)

		return wsServices

	}, [token])

	// start / stop connection by document visibility
	useEffect(() => {
		if (!token || isNullish(cashierWS) || isNullish(jobWS)) {
			return;
		}

		const visibilityChangeListener = () => {
			if (document.hidden) {
				return;
			}
			cashierWS.startConnection();
			jobWS.startConnection();
		};

		document.addEventListener("visibilitychange", visibilityChangeListener);

		return () => {
			cashierWS.stopConnection();
			jobWS.stopConnection();

			document.removeEventListener("visibilitychange", visibilityChangeListener);
		};
	}, [token, cashierWS, jobWS]);

	// Watch all jackpot updates
	useEffect(() => {
		if (!activeJackpotId || !cashierWS?.isConnected) {
			return
		}
		const invokeJackpotsMessage = `${CASHIER_API_LISTENER_EVENTS.JACKPOTS}_${activeJackpotId}`;
		cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.SUBSCRIBE, invokeJackpotsMessage);
		return () => {
			if (!cashierWS?.isConnected) {
				return;
			}
			cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeJackpotsMessage);
		};

	}, [activeJackpotId, cashierWS, cashierForceUpdateState]);

	// Watch all event updates
	useEffect(() => {
		if (!token || !jobWS.isConnected || !game) {
			return
		}
		const invokeMessage = `${JOB_API_LISTENER_EVENTS.EVENTS}_${game.type}_${game.id}`;
		jobWS.invoke(JOB_API_INVOKE_EVENTS.SUBSCRIBE, invokeMessage);
		return () => {
			if (!jobWS?.isConnected) {
				return;
			}
			jobWS.invoke(JOB_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeMessage);
		};

	}, [token, jobWS, jobForceUpdateState, game]);

	useEffect(() => {
		setCurrentGame(GAME_TYPE.KENO);
	}, []);

	/** Subscribe to signalR when session loaded,
	 * Load live and upcomings, rtps
	 * */
	useEffect(() => {
		getLiveAndUpcomings();
		getLastResults();
		if (game?.id) {
			getGameRTPs(game.id);
		}
	}, [game?.id]);

	useEffect(() => {
		if(activeJackpotId !== null) {
			getActiveJackpot(true);
		}
	}, [activeJackpotId]);

	return <Outlet context={{ decodedData }} />;
};

/** KenoWrapper propTypes
 * PropTypes
 */
KenoWrapper.propTypes = {
	/** Redux action to get live and upcoming matches */
	getLiveAndUpcomings: PropTypes.func,
	/** Redux action to remove match from live and upcomings matches */
	removeFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to update match in live and upcomings matches */
	updateFromLiveAndUpcomings: PropTypes.func,
	/** Redux action to add match to live and upcomings matches */
	addLiveAndUpcomings: PropTypes.func,
	/** Redux action to get last results */
	getLastResults: PropTypes.func,
	/** Redux action to add match to last results */
	addLastResult: PropTypes.func,
	/** Redux action to set current game */
	setCurrentGame: PropTypes.func,
	/** React property, decoded data taken from url */
	decodedData: PropTypes.object,
	/** Redux action to get game rtps */
	getGameRTPs: PropTypes.func,
	/** Redux action to get Active Jackpot Info */
	getActiveJackpot: PropTypes.func,
	/** Redux action to update jackpot info */
	updateJackpotBonus: PropTypes.func,
	/** Redux action, to set won Jackpots */
	setWonJackpotBonuses: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getLiveAndUpcomings: () => {
		dispatch(getLiveAndUpcomings(true));
	},
	removeFromLiveAndUpcomings: (id) => {
		dispatch(removeFromLiveAndUpcomings(id));
	},
	updateFromLiveAndUpcomings: (game) => {
		dispatch(updateFromLiveAndUpcomings(game));
	},
	addLiveAndUpcomings: (game) => {
		dispatch(addLiveAndUpcomings(game));
	},
	getLastResults: () => {
		dispatch(getLastResults(true));
	},
	addLastResult: (result) => {
		dispatch(addLastResult(result));
	},
	setCurrentGame: (type) => {
		dispatch(setCurrentGame(type));
	},
	getGameRTPs: (id) => {
		dispatch(getGameRTPs(id));
	},
	getActiveJackpot: (fromStream) => {
		dispatch(getActiveJackpot(fromStream));
	},
	updateJackpotBonus: data => {
		dispatch(updateJackpotBonus(data))
	},
	setWonJackpotBonuses: (data) => {
		dispatch(setWonJackpotBonuses(data));
	}
});

export default connect(null, mapDispatchToProps)(KenoWrapper);
