import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import JackpotWin from 'components/pages/jackpotLiveInfo/animations/jackpotWin';

import { useOutletContext } from "react-router";

import { JACKPOT_ANIMATIONS_TIMEOUTS } from "constants/bonus.constants";
import { removeWonJackpotBonus } from 'store/actions/bonuses/jackpot.actions';

const getWonJackpots = (state) => state.bonuses.jackpot.wonJackpots;

const JackpotAnimations = ({ getMainContainer, isLiveMonitor = false, disableAnimation = false }) => {
	const [animateableJackpot, setAnimatableJackpot] = useState(null);
	const [showCanfetti, setShowCanfetti] = useState(false);

	const wonJackpotsHistoryRef = useRef([]);

	const { decodedData } = useOutletContext();
	

	const dispatch = useDispatch()
	const wonJackpots = useSelector(getWonJackpots);

	useEffect(() => {
		if (disableAnimation || wonJackpots.length === 0) {
			return
		}
		const wonJackpot = wonJackpots.at(0);
		wonJackpotsHistoryRef.current.push(wonJackpot)
		setAnimatableJackpot(wonJackpot);
	}, [wonJackpots, disableAnimation]);

	useEffect(() => {
		return () => {
			wonJackpotsHistoryRef.current.forEach(historyWonJackpot => {
				dispatch(removeWonJackpotBonus(historyWonJackpot))
			})
		}
	}, [])

	return (
		<JackpotWin
			getMainContainer={getMainContainer}
			animateableJackpot={animateableJackpot}
			currencySymbol={decodedData?.currencySymbol}
			currencyDecimalCount={decodedData?.currencyDecimalCount ?? 0}
			setAnimationFinish={() => {
				setShowCanfetti(false);
				setAnimatableJackpot(null);
			}}
			cleanupCallback={(jackpot) => {
				wonJackpotsHistoryRef.current = wonJackpotsHistoryRef.current.filter(historyJackpot => {
					return historyJackpot._id !== jackpot._id;
				})
				dispatch(removeWonJackpotBonus(jackpot))
			}}
			setShowCanfetti={setShowCanfetti}
			isLiveMonitor={isLiveMonitor}
			disableAnimation={disableAnimation}
		/>
	)
};

export default JackpotAnimations;