export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";
export const REMOVE_ALERT_MESSAGE = "REMOVE_ALERT_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";

export const SET_PLAYER = "SET_PLAYER";
export const SET_SESSION = "SET_SESSION";
export const SET_SESSION_GAMES = "SET_SESSION_GAMES";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_SESSION_LOADED = "SET_SESSION_LOADED";
export const SET_SESSION_FAILED = "SET_SESSION_FAILED";
export const SET_SESSION_NO_GAME_FOUND = "SET_SESSION_NO_GAME_FOUND";
export const SET_SESSION_TEMPLATE_TYPE = "SET_SESSION_TEMPLATE_TYPE";
export const SET_TOKEN_EXPIRATION = "SET_TOKEN_EXPIRATION";
export const SET_LOGIN_BEFORE = "SET_LOGIN_BEFORE";
export const SET_LOGIN_AFTER = "SET_LOGIN_AFTER";
export const SET_BALANCE = "SET_BALANCE";
export const SET_LOGO_ID = "SET_LOGO_ID";
export const SET_BONUS_CONFIGS = "SET_BONUS_CONFIGS";
export const SET_MAINTENANCE_MODE = "SET_MAINTENANCE_MODE";
export const SET_STREAM_ACCESS = "SET_STREAM_ACCESS";
export const SET_TRANSLATIONS_LOADED = "SET_TRANSLATIONS_LOADED";
export const SET_STREAM_CONFIGURATION = "SET_STREAM_CONFIGURATION";
export const SET_GAME_RTPS = "SET_GAME_RTPS";

export const SET_LANGUAGES_BEFORE = "SET_LANGUAGES_BEFORE";
export const SET_LANGUAGES_AFTER = "SET_LANGUAGES_AFTER";
export const SET_LANGUAGES = "SET_LANGUAGES";

export const SET_ACTIVATE_PASSWORD_SETTINGS_BEFORE = "SET_ACTIVATE_PASSWORD_SETTINGS_BEFORE";
export const SET_ACTIVATE_PASSWORD_SETTINGS_AFTER = "SET_ACTIVATE_PASSWORD_SETTINGS_AFTER";
export const SET_ACTIVATION_PASSWORD_SETTINGS = "SET_ACTIVATION_PASSWORD_SETTINGS";

export const SET_ACTIVATE_BEFORE = "SET_ACTIVATE_BEFORE";
export const SET_ACTIVATE_AFTER = "SET_ACTIVATE_AFTER";

export const RESET_PASSWORD_ACTION_BEFORE = "RESET_PASSWORD_ACTION_BEFORE";
export const RESET_PASSWORD_ACTION_AFTER = "RESET_PASSWORD_ACTION_AFTER";

export const FORGOT_PASSWORD_ACTION_BEFORE = "FORGOT_PASSWORD_ACTION_BEFORE";
export const FORGOT_PASSWORD_ACTION_AFTER = "FORGOT_PASSWORD_ACTION_AFTER";

export const SET_CHANGE_PASSWORD_SETTINGS_BEFORE = "SET_CHANGE_PASSWORD_SETTINGS_BEFORE";
export const SET_CHANGE_PASSWORD_SETTINGS_AFTER = "SET_CHANGE_PASSWORD_SETTINGS_AFTER";
export const SET_CHANGE_PASSWORD_BEFORE = "SET_CHANGE_PASSWORD_BEFORE";
export const SET_CHANGE_PASSWORD_AFTER = "SET_CHANGE_PASSWORD_AFTER";
export const SET_CHANGE_PASSWORD_SETTINGS = "SET_CHANGE_PASSWORD_SETTINGS";

export const LIVE_AND_UPCOMINGS_BEFORE = "LIVE_AND_UPCOMINGS_BEFORE";
export const LIVE_AND_UPCOMINGS_AFTER = "LIVE_AND_UPCOMINGS_AFTER";
export const SET_LIVE_AND_UPCOMINGS = "SET_LIVE_AND_UPCOMINGS";
export const SET_LIVE_AND_UPCOMINGS_LIVE_MONITOR = "SET_LIVE_AND_UPCOMINGS_LIVE_MONITOR";
export const REMOVE_LIVE_AND_UPCOMINGS = "REMOVE_LIVE_AND_UPCOMINGS";
export const REMOVE_LIVE_AND_UPCOMINGS_LIVE_MONITOR = "REMOVE_LIVE_AND_UPCOMINGS_LIVE_MONITOR";
export const UPDATE_LIVE_AND_UPCOMINGS = "UPDATE_LIVE_AND_UPCOMINGS";
export const UPDATE_LIVE_AND_UPCOMINGS_LIVE_MONITOR = "UPDATE_LIVE_AND_UPCOMINGS_LIVE_MONITOR";
export const ADD_LIVE_AND_UPCOMINGS = "ADD_LIVE_AND_UPCOMINGS";
export const ADD_LIVE_AND_UPCOMINGS_LIVE_MONITOR = "ADD_LIVE_AND_UPCOMINGS_LIVE_MONITOR";

export const LAST_RESULTS_BEFORE = "LAST_RESULTS_BEFORE";
export const LAST_RESULTS_AFTER = "LAST_RESULTS_AFTER";
export const SET_LAST_RESULTS = "SET_LAST_RESULTS";
export const ADD_LAST_RESULT = "ADD_LAST_RESULT";

export const EVENT_BEFORE = "EVENT_BEFORE";
export const EVENT_AFTER = "EVENT_AFTER";
export const SET_EVENT = "SET_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_LIVE_MONITOR = "UPDATE_EVENT_LIVE_MONITOR";

export const SET_CURRENT_EVENT = "SET_CURRENT_EVENT";
export const SET_CURRENT_GAME = "SET_CURRENT_GAME";

export const SET_SEASON_MARKETS = "SET_SEASON_MARKETS";
export const SET_SEASON_STANDINGS = "SET_SEASON_STANDINGS";
export const SET_TEAMS_HEAD_TO_HEAD = "SET_TEAMS_HEAD_TO_HEAD";
export const ADD_TEAMS_HEAD_TO_HEAD = "ADD_TEAMS_HEAD_TO_HEAD";

export const SET_BETSLIP_MODE = "SET_BETSLIP_MODE";
export const ADD_BET = "ADD_BET";
export const REMOVE_BET = "REMOVE_BET";
export const REMOVE_LAST_BET = "REMOVE_LAST_BET";
export const UPDATE_EVENT_BETS = "UPDATE_EVENT_BETS";
export const UPDATE_BET_STAKE = "UPDATE_BET_STAKE";
export const CLEAR_BETS = "CLEAR_BETS";
export const SET_STAKE = "SET_STAKE";
export const PLACE_BET_BEFORE = "PLACE_BET_BEFORE";
export const PLACE_BET_AFTER = "PLACE_BET_AFTER";
export const SET_BETSLIP = "SET_BETSLIP";
export const SET_FORECAST = "SET_FORECAST";
export const CLEAR_FORECAST = "CLEAR_FORECAST";
export const SET_KENO_BALLS = "SET_KENO_BALLS";
export const CLEAR_KENO_BALLS = "CLEAR_KENO_BALLS";
export const SET_ON_FOCUS_STAKE = "SET_ON_FOCUS_STAKE";
export const SET_ON_BLUR_TIMEOUT_ID = "SET_ON_BLUR_STAKE";
export const ADD_LUCKY_SIX_BALLS = "ADD_LUCKY_SIX_BALLS";
export const REMOVE_LUCKY_SIX_BALL = "REMOVE_LUCKY_SIX_BALL";
export const CLEAR_LUCKY_SIX_BALLS = "CLEAR_LUCKY_SIX_BALLS";

export const SET_RESULTS = "SET_RESULTS";
export const RESULTS_BEFORE = "RESULTS_BEFORE";
export const RESULTS_AFTER = "RESULTS_AFTER";
export const SET_RESULTS_FILTERS = "SET_RESULTS_FILTERS";
export const RESET_RESULTS_FILTERS = "RESET_RESULTS_FILTERS";
export const RESULTS_TO_PRINT_BEFORE = "RESULTS_TO_PRINT_BEFORE";
export const RESULTS_TO_PRINT_AFTER = "RESULTS_TO_PRINT_AFTER";
export const SET_RESULTS_TO_PRINT = "SET_RESULTS_TO_PRINT";
export const RESET_RESULTS_TO_PRINT = "RESET_RESULTS_TO_PRINT";
export const SET_RESULTS_PRINTING = "SET_RESULTS_PRINTING";

export const ADD_PENDING = "ADD_PENDING";
export const UPDATE_PENDING = "UPDATE_PENDING";
export const UPDATE_PENDING_BET = "UPDATE_PENDING_BET";
export const SET_PENDINGS = "SET_PENDINGS";
export const REMOVE_PENDING = "REMOVE_PENDING";
export const PENDING_BEFORE = "PENDING_BEFORE";
export const PENDING_AFTER = "PENDING_AFTER";

export const SET_SETTLED = "SET_SETTLED";
export const SETTLED_BEFORE = "SETTLED_BEFORE";
export const SETTLED_AFTER = "SETTLED_AFTER";
export const SET_SETTLED_FILTERS = "SET_SETTLED_FILTERS";
export const RESET_SETTLED_FILTERS = "RESET_SETTLED_FILTERS";
export const UPDATE_SETTLED = "UPDATE_SETTLED";

export const SHOW_PAYMENT_METHOD = "SHOW_PAYMENT_METHOD";
export const SET_CONFIRMING_TRANSACTION = "SET_CONFIRMING_TRANSACTION";

export const UPDATE_TICKET_REPRINT_REQUEST = "UPDATE_TICKET_REPRINT_REQUEST";

export const TICKET_CONFIRMATION_BEFORE = "TICKET_CONFIRMATION_BEFORE";
export const TICKET_CONFIRMATION_AFTER = "TICKET_CONFIRMATION_AFTER";
export const SET_TICKET_CONFIRMATION = "SET_TICKET_CONFIRMATION";
export const CLOSE_TICKET_CONFIRMATION = "CLOSE_TICKET_CONFIRMATION";
export const VIEWING_TICKET_BEFORE = "VIEWING_TICKET_BEFORE";
export const VIEWING_TICKET_AFTER = "VIEWING_TICKET_AFTER";
export const SET_VIEWING_TICKET = "SET_VIEWING_TICKET";
export const UPDATE_VIEWING_TICKET = "UPDATE_VIEWING_TICKET";
export const UPDATE_VIEWING_TICKET_BET = "UPDATE_VIEWING_TICKET_BET";
export const SHOW_VIEWING_TICKET = "SHOW_VIEWING_TICKET";
export const HIDE_VIEWING_TICKET = "HIDE_VIEWING_TICKET";
export const SET_PRINTING_TICKET = "SET_PRINTING_TICKET";
export const REMOVE_PRINTING_TICKET = "REMOVE_PRINTING_TICKET";

export const SET_REPRINT_LAST_TICKET_SHOW = "SET_REPRINT_LAST_TICKET_SHOW";
export const SET_LAST_REPRINT_TICKET_BEFORE = "SET_LAST_REPRINT_TICKET_BEFORE";
export const SET_LAST_REPRINT_TICKET_AFTER = "SET_LAST_REPRINT_TICKET_AFTER";
export const SET_LAST_REPRINT_TICKET = "SET_LAST_REPRINT_TICKET";

export const REPORT_BEFORE = "REPORT_BEFORE";
export const REPORT_AFTER = "REPORT_AFTER";
export const SET_TURNOVER_REPORT = "SET_TURNOVER_REPORT";
export const CLEAR_TURNOVER_REPORT = "CLEAR_TURNOVER_REPORT";
export const SET_PRINTING_REPORT = "SET_PRINTING_REPORT";
export const SET_TURNOVER_TYPE = "SET_TURNOVER_TYPE";

export const QR_TICKET_BEFORE = "QR_TICKET_BEFORE";
export const QR_TICKET_AFTER = "QR_TICKET_AFTER";
export const SET_QR_TICKET = "SET_QR_TICKET";

export const BONUS_BEFORE = "BONUS_BEFORE";
export const BONUS_AFTER = "BONUS_AFTER";
export const SET_VIEW_BONUS = "SET_BONUS";
export const HIDE_VIEWING_BONUS = "HIDE_VIEWING_BONUS";
export const SET_USE_BONUS = "SET_USE_BONUS";
export const CLEAN_USE_BONUS = "CLEAN_USE_BONUS";

export const JACKPOT_BONUS_BEFORE = "JACKPOT_BONUS_BEFORE";
export const JACKPOT_BONUS_AFTER = "JACKPOT_BONUS_AFTER";
export const SET_JACKPOT_BONUS = "SET_JACKPOT_BONUS";
export const UPDATE_JACKPOT_BONUS = "UPDATE_JACKPOT_BONUS";
export const SET_WON_JACKPOT_BONUSES = "SET_WON_JACKPOT_BONUSES";
export const REMOVE_WON_JACKPOT_BONUS = "REMOVE_WON_JACKPOT_BONUS";

export const SET_SHOW_TEMPLATES = "SET_SHOW_TEMPLATES";
export const SAVE_TEMPLATE_BEFORE = "SAVE_TEMPLATE_BEFORE";
export const SAVE_TEMPLATE_AFTER = "SAVE_TEMPLATE_AFTER";

export const SET_SPIN_TO_WIN_HOVERED_NUMBERS = "SET_SPIN_TO_WIN_HOVERED_NUMBERS";

export const SET_LUCKY_SIX_STATISTICS = "SET_LUCKY_SIX_STATISTICS";
export const SET_SPIN_TO_WIN_STATISTICS = "SET_SPIN_TO_WIN_STATISTICS";
