import { REPORT_GAME_TYPES } from "./reports.constants";
import { SPIN_TO_WIN_MARKET_GROUPS } from "constants/market.constants.js";

export const GAME_TYPE = {
	FOOTBALL_SINGLE_MATCH: 1,
	ENGLISH_LEAGUE: 2,
	HORSE_RACING: 4,
	GREYHOUNDS_RACE: 8,
	AFRICAN_CUP: 16,
	KENO: 32,
	WORLD_CUP: 64,
	EUROPEAN_CUP: 128,
	CHAMPIONS_LEAGUE: 256,
	COPA_LIBERTADORES: 512,
	HORSE_STEEPLECHASING: 1024,
	PENALTY_SHOOTOUT: 2048,
	// TURKISH_LEAGUE: 4096
	LUCKY_SIX: 8192,
	SPIN_TO_WIN: 16384
};

export const GAME_TYPE_TEXT_KEYS = {
	[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: "virtualFootball",
	[GAME_TYPE.HORSE_RACING]: "horseRacing",
	[GAME_TYPE.GREYHOUNDS_RACE]: "greyhounds",
	[GAME_TYPE.ENGLISH_LEAGUE]: "englishLeague",
	[GAME_TYPE.KENO]: "keno",
	[GAME_TYPE.AFRICAN_CUP]: "africanCup",
	[GAME_TYPE.WORLD_CUP]: "footballWorldCup",
	[GAME_TYPE.EUROPEAN_CUP]: "footballEuropeanCup",
	[GAME_TYPE.CHAMPIONS_LEAGUE]: "footballChampionsLeague",
	[GAME_TYPE.COPA_LIBERTADORES]: "footballCopaLibertadores",
	[GAME_TYPE.HORSE_STEEPLECHASING]: "steeplechase",
	[GAME_TYPE.LUCKY_SIX]: "luckySix",
	[GAME_TYPE.SPIN_TO_WIN]: "spin2Win",
	[GAME_TYPE.PENALTY_SHOOTOUT]: "penaltyShootout"
	// [GAME_TYPE.TURKISH_LEAGUE]: "turkishLeague",
};

export const GAME_TYPE_TEXTS_BY_NAME = {
	footballSingleMatch: "common.virtualFootball",
	horseRaceFlat: "common.horseRacing",
	greyhoundRace: "common.greyhounds",
	footballEnglandLeague: "common.englishLeague",
	keno: "common.keno",
	footballAfricanCup: "common.africanCup",
	footballWorldCup: "common.footballWorldCup",
	footballEuropeanCup: "common.footballEuropeanCup",
	footballChampionsLeague: "common.footballChampionsLeague",
	footballCopaLibertadores: "common.footballCopaLibertadores",
	penalty: "common.penaltyShootout",
	horseRaceSteeplechasing: "common.steeplechase",
	spin2Win: "common.spin2Win",
	luckySix: "common.luckySix",
	[REPORT_GAME_TYPES.TOTAL]: "cashier.total",
	[REPORT_GAME_TYPES.MIXED_MULTI]: "cashier.mixedMulti"
};

export const GAME_TYPE_ICONS = {
	[GAME_TYPE.FOOTBALL_SINGLE_MATCH]: "ic_football",
	[GAME_TYPE.HORSE_RACING]: "ic_horseRacing",
	[GAME_TYPE.GREYHOUNDS_RACE]: "ic_greyhounds",
	[GAME_TYPE.ENGLISH_LEAGUE]: "ic_league_trophy",
	[GAME_TYPE.KENO]: "ic_keno",
	[GAME_TYPE.AFRICAN_CUP]: "ic_africanCup",
	[GAME_TYPE.WORLD_CUP]: "ic_worldCup",
	[GAME_TYPE.EUROPEAN_CUP]: "ic_europeanCup",
	[GAME_TYPE.CHAMPIONS_LEAGUE]: "ic_championsLeague",
	[GAME_TYPE.COPA_LIBERTADORES]: "ic_copaLibertadores",
	[GAME_TYPE.HORSE_STEEPLECHASING]: "ic_horseRaceSteeplechasing",
	[GAME_TYPE.PENALTY_SHOOTOUT]: "ic_penaltyShootout",
	[GAME_TYPE.SPIN_TO_WIN]: "ic_spin2Win",
	[GAME_TYPE.LUCKY_SIX]: "ic_luckySix"
	// [GAME_TYPE.TURKISH_LEAGUE]: "ic_turkishLeague",
};

export const GAME_EVENT_TYPE = {
	EVENT: 1,
	WEEK: 2,
	LEAGUE: 3
};

export const GAME_STATUSES = {
	NEW: 1,
	CLOSE_FOR_BETTING: 2,
	STARTED: 4,
	FINISHED: 8,
	CANCELED: 16,
	PREAMBLE_STARTED: 32
};

export const GAME_ACTIVITY_STATE = {
	ACTIVE: 1,
	CLOSE_FOR_BETTING: 2,
	INACTIVE: 4
};

export const GAME_STREAM_CONFIGURATION_TYPE = {
	WOWZA: 1,
	NANOCOSMOS: 2,
	FLUSSONIC: 4,
	OVEN_PLAYER: 8
};

export const KENO_BALLS_COUNT = 80;
export const KENO_WINNER_BALLS_COUNT = 20;
export const KENO_BALLS_QTY_IN_ROW = 10;
export const KENO_BALLS_QTY_IN_COLUMN = 8;
export const KENO_FORMAT = {
	KENO_6: 1,
	KENO_8: 2,
	KENO_10: 4,
	KENO_12: 8
};

export const KENO_FORMAT_BALLS_QTY = {
	[KENO_FORMAT.KENO_6]: 6,
	[KENO_FORMAT.KENO_8]: 8,
	[KENO_FORMAT.KENO_10]: 10,
	[KENO_FORMAT.KENO_12]: 12
};

export const HORSE_RACE_FORMAT = {
	HORSE_6: 1,
	HORSE_8: 2,
	HORSE_10: 4,
	HORSE_12: 8
};

export const GREYHOUNDS_RACE_FORMAT = {
	GREYHOUND_6: 1,
	GREYHOUND_8: 2
};

export const CUP_GAMES_FINAL_ROUND_NUMBER = {
	[GAME_TYPE.AFRICAN_CUP]: 5,
	[GAME_TYPE.WORLD_CUP]: 5,
	[GAME_TYPE.EUROPEAN_CUP]: 5,
	[GAME_TYPE.CHAMPIONS_LEAGUE]: 7,
	[GAME_TYPE.COPA_LIBERTADORES]: 7
};

export const RESULTS = {
	WIN: 1,
	LOSE: 2,
	DRAW: 3
};
export const PENALTY_SCENE_INFO = {
	GOAL_TOP_LEFT_SPECTER: 1 << 0 /* 1 */,
	GOAL_TOP_CENTRE_SPECTER: 1 << 1 /* 2 */,
	GOAL_TOP_RIGHT_SPECTER: 1 << 2 /* 4 */,
	GOAL_CENTRE_LEFT_SPECTER: 1 << 3 /* 8 */,
	GOAL_CENTRE_CENTRE_SPECTER: 1 << 4 /* 16 */,
	GOAL_CENTRE_RIGHT_SPECTER: 1 << 5 /* 32 */,
	GOAL_BOTTOM_LEFT_SPECTER: 1 << 6 /* 64 */,
	GOAL_BOTTOM_CENTRE_SPECTER: 1 << 7 /* 128 */,
	GOAL_BOTTOM_RIGHT_SPECTER: 1 << 8 /* 256 */,
	NOGOAL_MISS_SHOOT: 1 << 9 /* 512 */,
	NOGOAL_SAVE: 1 << 10 /* 1024 */,
	NOGOAL_WOODWORK: 1 << 11 /* 2048 */
};

export const PENALTY_SCENE_INFO_NAMES = {
	[PENALTY_SCENE_INFO.GOAL_TOP_LEFT_SPECTER]: "TopLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_TOP_CENTRE_SPECTER]: "TopCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_TOP_RIGHT_SPECTER]: "TopRightSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_LEFT_SPECTER]: "CentreLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_CENTRE_SPECTER]: "CentreCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_CENTRE_RIGHT_SPECTER]: "CentreRightSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_LEFT_SPECTER]: "BottomLeftSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_CENTRE_SPECTER]: "BottomCentreSpecter",
	[PENALTY_SCENE_INFO.GOAL_BOTTOM_RIGHT_SPECTER]: "BottomRightSpecter",
	[PENALTY_SCENE_INFO.NOGOAL_MISS_SHOOT]: "MissShoot",
	[PENALTY_SCENE_INFO.NOGOAL_SAVE]: "Save",
	[PENALTY_SCENE_INFO.NOGOAL_WOODWORK]: "Woodwork"
};

export const RED_NUMBERS = [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36];

export const LUCKY_SIX_BALL_COLORS = ["red", "orange", "yellow", "green", "blue", "violet", "purple", "black"];

export const NUMBER_TO_COLOR_MAPPER = ["black", "red", "orange", "yellow", "green", "blue", "violet", "purple"];

export const BALL_POSITION_TO_STAT_ITEM = {
	FIRST: "item1",
	LAST: "item2"
};

export const LUCKY_SIX_MARKETS = {
	FIRST_BALL_COLOR: "FirstBallColour",
	LAST_BALL_COLOR: "LastBallColour",
	FIRST_BALL_OVER_UNDER: "FirstNumberOverUnder",
	LAST_BALL_OVER_UNDER: "LastNumberOverUnder",
	FIRST_BALL_ODD_EVEN: "FirstNumberOddEven",
	LAST_BALL_ODD_EVEN: "LastNumberOddEven",
	SUM_FIVE_TOTAL_OVER_UNDER: "Sum5TotalOverUnder",
	SUM_FIVE_TOTAL_ODD_EVEN: "Sum5TotalOddEven",
	MOSTLY_DRAWN_ODD_EVEN: "EvenMoreOdd",
	FIRST_LAST_NUMBER_SUM: "FirstLastNumberSum",
	BALL_NUMBERS: "LuckySix"
};

export const BALL_POSITION = {
	FIRST: "first",
	LAST: "last"
};

export const SPIN_TO_WIN_BOARD_GROUPS = {
	NONE: -1,
	TRIER: 0,
	ORPEHLINS: 1,
	VOISIN: 2,
	ZERO: 3
};

export const SPIN_TO_WIN_MARKETS_NUMBERS = {
	NUMBERS: Array.from({ length: 36 }, (_, i) => i + 1),
	FIRST_THREE: [1, 2, 3],
	BASKET: [0, 1, 2, 3],
	FIRST_TWELVE: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
	SECOND_TWELVE: [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
	THIRD_TWELVE: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
	RED_NUMBERS: [1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36],
	BLACK_NUMBERS: [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35],
	LOW: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
	HIGH: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
	FIRST_COLUMN: [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34],
	SECOND_COLUMN: [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
	THIRD_COLUMN: [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
	EVEN_NUMBERS: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36],
	ODD_NUMBERS: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35],
	[SPIN_TO_WIN_BOARD_GROUPS.TRIER]: [5, 8, 10, 11, 13, 16, 23, 24, 27, 30, 33, 36],
	[SPIN_TO_WIN_BOARD_GROUPS.ORPEHLINS]: [1, 20, 14, 31, 9, 6, 34, 17],
	[SPIN_TO_WIN_BOARD_GROUPS.VOISIN]: [22, 18, 29, 7, 28, 12, 35, 32, 0, 32, 15, 19, 4, 21, 2, 25, 3, 26],
	[SPIN_TO_WIN_BOARD_GROUPS.ZERO]: [12, 35, 32, 0, 32, 15, 3, 26]
};

export const SPIN_TO_WIN_BOTTOM_MARKETS_OUTCOMES = {
	[SPIN_TO_WIN_BOARD_GROUPS.TRIER]: ["5-8", "10-11", "13-16", "23-24", "27-30", "33-36"],
	[SPIN_TO_WIN_BOARD_GROUPS.ORPEHLINS]: ["1", "6-9", "14-17", "17-20", "31-34"],
	[SPIN_TO_WIN_BOARD_GROUPS.VOISIN]: ["0-2-3", "4-7", "12-15", "18-21", "19-22", "32-35", "25-26-28-29", "26", "0-3"],
	[SPIN_TO_WIN_BOARD_GROUPS.ZERO]: ["0-3", "12-15", "32-35", "26"]
};

export const SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME = {
	straight: 36,
	split: 18,
	trio: 12,
	street: 12,
	basket: 9,
	corner: 9,
	doublestreet: 6,
	dozen: 3,
	column: 3,
	evenOdd: 2,
	redBlack: 2,
	highLow: 2,
	trier: null,
	orpehlins: null,
	voisin: null,
	zero: null
};

export const SPIN_TO_WIN_STREAM_HIGHLIGHTING_MARKETS = [
	"straight",
	"split",
	"trio",
	"street",
	"basket",
	"corner",
	"doublestreet",
	"trier",
	"orpehlins",
	"voisin",
	"zero"
];

export const SPIN_TO_WIN_STREAM_SUGGESTED_MARKETS_GENERATOR_CONFIG = {
	straight: { count: 5 },
	split: { count: 5 },
	trio: { count: 2 },
	street: { count: 5 },
	basket: undefined,
	corner: { count: 5 },
	doublestreet: { count: 5 },
	trier: undefined,
	orpehlins: undefined,
	voisin: undefined,
	zero: undefined
};
export const COLORS_MAPPER = {
	1: "red",
	2: "orange",
	4: "yellow",
	8: "green",
	16: "blue",
	32: "violet",
	64: "purple",
	128: "black"
};
