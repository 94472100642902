import { buildPathToStaticFolderOfCDN } from "utils/common";

export const JACKPOT_LEVEL = {
	BRONZE: 1,
	SILVER: 2,
	GOLD: 4
};

export const JACKPOT_LEVELS_ICONS = {
	[`level-${JACKPOT_LEVEL.BRONZE}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-bronze-icon-level-1.gif"),
	[`level-${JACKPOT_LEVEL.SILVER}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-silver-icon-level-2.gif"),
	[`level-${JACKPOT_LEVEL.GOLD}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-gold-icon-level-4.gif"),

	[`png-level-${JACKPOT_LEVEL.BRONZE}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-bronze-icon-level-1.png"),
	[`png-level-${JACKPOT_LEVEL.SILVER}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-silver-icon-level-2.png"),
	[`png-level-${JACKPOT_LEVEL.GOLD}`]: buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-gold-icon-level-4.png")
}

export const JACKPOT_LEVELS_NAMES = {
	[JACKPOT_LEVEL.BRONZE]: "cashier.jackpotBronzeLevel",
	[JACKPOT_LEVEL.SILVER]: "cashier.jackpotSilverLevel",
	[JACKPOT_LEVEL.GOLD]: "cashier.jackpotGoldLevel"
}

export const JACKPOT_LEVELS_NAMES_LIVE_MONITOR = {
	[JACKPOT_LEVEL.BRONZE]: "livemonitor.common.jackpotBronzeLevel",
	[JACKPOT_LEVEL.SILVER]: "livemonitor.common.jackpotSilverLevel",
	[JACKPOT_LEVEL.GOLD]: "livemonitor.common.jackpotGoldLevel"
}

export const MAX_HIT_AMOUNT_NUM_LENGTH = 10; // taken from Jackpot Max Hit Amount validation in Admin project

export const JACKPOT_ANIMATIONS_TIMEOUTS = {
	ACTIVE: 800,
	INACTIVE: 600,
	TRANSITION_SVG: 2000,
	CLOSING_SVG: 12000,
	LEVELS_BETWEEN_DELAY: 1000
};