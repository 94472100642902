import { useContext, useEffect, useMemo, useRef, useState } from "react"
import RouletteTable from "../roulette-table"
import "./styles.scss"
import rouletteBetsRNG from "../roulette-rng"
import RouletteBoardSuggestedView from "../roulette-board/suggestedView"
import { mergeClassNames, toFixed } from "utils/common"
import { GAME_TYPE, SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME, SPIN_TO_WIN_STREAM_SUGGESTED_MARKETS_GENERATOR_CONFIG } from "constants/game.constants"
import { generateBetGroupAndConfigs, generateBetOutcomesByGroup } from "./utils"
import useEvent from "hooks/useEvent"
import useForceUpdate from "hooks/useForceUpdate"
import { useTranslation } from "react-i18next"
import SuggestedMarketsCurrentStatistics from "./suggested-markets-current-statistics"
import { useSelector } from "react-redux"
import ScenesFlowContext from "components/pages/scenesFlow/scenesFlowContext"

const SuggestedMarkets = ({ onFinish: argOnFinish, onMount: argOnMount }) => {
	const { t } = useTranslation()
	const animationsRef = useRef({ highLighting: null, groupChange: null })
	const [suggestedMarket, setSuggestedMarket] = useState({ activeGroup: null, groupOutcomes: [] })
	const [currentMarket, setCurrentMarket] = useState({ group: null, rngConfig: null })
	const [currentMarketView, setCurrentMarketView] = useState({ show: false, hide: false })
	const betGroupAndConfigsGenerator = useMemo(() => generateBetGroupAndConfigs(SPIN_TO_WIN_STREAM_SUGGESTED_MARKETS_GENERATOR_CONFIG), [])
	const [animationFrameUpdate, animationFrameState] = useForceUpdate()
	const fromHotToColdSorted = useSelector(state => state.game.spinToWinStatistics.fromHotToColdSorted)
	
	const sceneFlowProviderValue = useContext(ScenesFlowContext)
	const onMount = useEvent(() => {
		if (typeof argOnMount === "function") {
			argOnMount(sceneFlowProviderValue)
		}
	});
	const onFinish = useEvent(() => {
		if (typeof argOnFinish === "function") {
			argOnFinish(sceneFlowProviderValue)
		}
	});

	useEffect(() => {
		return () => {
			clearInterval(animationsRef.current.highLighting)
			clearTimeout(animationsRef.current.groupChange)
		}
	}, [])

	useEffect(() => {
		const { value: { group, rngConfig }, done } = betGroupAndConfigsGenerator.next();
		if (done) {
			setCurrentMarket({ group: null, rngConfig: null });
			onFinish();
		} else {
			setCurrentMarket({ group, rngConfig });
		}
	}, [betGroupAndConfigsGenerator, animationFrameState])

	useEffect(() => {
		if (currentMarket.group) {
			setCurrentMarketView({ show: true, hide: false })
		} else {
			setCurrentMarketView({ show: false, hide: false })
		}
	}, [currentMarket, setCurrentMarketView])

	const runHighLighting = () => {
		const betOutcomesByGroupGenerator = generateBetOutcomesByGroup(currentMarket.group, currentMarket.rngConfig);
		
		const runHighLightingFrame = () => {
			const { value: { group, outcomes } , done } = betOutcomesByGroupGenerator.next();
			if (done) {
				clearInterval(animationsRef.current.highLighting);
				setSuggestedMarket({ activeGroup: null, groupOutcomes: [] });
				setCurrentMarketView({ show: false, hide: true });
			} else {
				setSuggestedMarket({ activeGroup: group, groupOutcomes: outcomes });
			}
			
		}

		runHighLightingFrame()
		animationsRef.current.highLighting = setInterval(runHighLightingFrame, 1000)
	}

	return (
		<div
			className="vs--spin-2-win-stream-scene vs--spin-2-win-stream-scene-suggested-markets"
			onAnimationEnd={e => {
				if (e.animationName === "mount-scene-suggested-markets") {
					onMount()
				}
			}}
		>
			<RouletteBoardSuggestedView activeGroup={suggestedMarket.activeGroup} groupOutcomes={suggestedMarket.groupOutcomes} />
			<RouletteTable activeGroup={suggestedMarket.activeGroup} groupOutcomes={suggestedMarket.groupOutcomes} />
			<SuggestedMarketsCurrentStatistics fromHotToColdSorted={fromHotToColdSorted} />
			{
				currentMarket.group
					? (
						<div
							className={
								mergeClassNames(
									"vs--spin-2-win-current-suggested-market",
									Boolean(SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME[currentMarket.group]) && "vs--spin-2-win-current-suggested-market-visible",
									currentMarketView.show && "vs--spin-2-win-current-suggested-market-show",
									currentMarketView.hide && "vs--spin-2-win-current-suggested-market-hide"
								)
							}
							onAnimationEnd={(e) => {
								e.stopPropagation()
								e.preventDefault()
								switch (e.animationName) {
									case "spin2WinCurrentSuggestedMarketShow":
										runHighLighting()
										break;
									case "spin2WinCurrentSuggestedMarketHide":
										animationsRef.current.groupChange = setTimeout(() => {
											animationFrameUpdate()
										}, 1000)
										break;
									default:
										break;
								}
							}}
						>
							<span className="vs--spin-2-win-current-suggested-market-text">{t(`markets.${GAME_TYPE.SPIN_TO_WIN}.${currentMarket.group}`)}</span>
							<span className="vs--spin-2-win-current-suggested-market-num">x{toFixed(SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME[currentMarket.group], 1)}</span>
						</div>
					)
					: null
			}
		</div>
	)
}
export default SuggestedMarkets