import PropTypes from "prop-types";
import { COLORS_MAPPER } from "constants/game.constants.js";
import { mergeClassNames } from "utils/common";

import LiveBall from "./liveBall";

const BetBall = ({ number, color, colorClass, enableHighlight }) => {	
	return (
		<div
			className={mergeClassNames(
				`vs--lucky-six-live-monitor-bet-balls`,
			)}
		>

			<div className={mergeClassNames(
				`vs--lucky-six-live-monitor-bet-balls-color`,
				enableHighlight ? 'enable-highlight' : '',
				`color-${COLORS_MAPPER[color]}`
			)} >

			<LiveBall ballNumber={number}  />
			</div>

		</div>
	);
};

BetBall.propTypes = {
	number: PropTypes.number,
	color: PropTypes.number,
	colorClass: PropTypes.string,
	enableHighlight: PropTypes.bool
};

export default BetBall;