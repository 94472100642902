import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import MonitorBody from "../sections/monitorBody";
import MonitorTitle from "../sections/monitorTitle";
import MonitorSubtitle from "../sections/monitorSubtitle";
import AnimatedPages from "../animatedPages";
import AnimatedPage from "../animatedPages/animatedPage";
import MarketPage from "../marketPage";
import ContentGroup from "../contentGroup";
import ContentList from "../contentList";
import TeamsInfo from "./teamsInfo";
import Odd from "./odd";
import { buildPathToStaticFolderOfCDN, mergeClassNames, numberWithSpaces } from "utils/common";
import { ODD_STATE } from "constants/market.constants";
import { useTranslation } from "react-i18next";

const ResultsMarkets = ({ calculatedData = {}, groupsInPages = [], status = null, seasonId = null, orderNumber = null, onFinishScenes = null, allowMarketsHighlights = false, timeoutsConfig, subTitleChildren = null }) => {
	const { t } = useTranslation();
	const { parentEvent, childEvents } = calculatedData;

	return (
		<section className="live-monitor">
			<MonitorTitle status={status} seasonId={seasonId} orderNumber={orderNumber} />
			<MonitorSubtitle event={null} logoSrc={buildPathToStaticFolderOfCDN("cashier-images/live-monitor-logo.svg")}>
				{subTitleChildren}
			</MonitorSubtitle>
			<div className="live-monitor-body">
				<AnimatedPages initialMountValue={true} perPage={false} className="vs--flex vs--flex-row" style={{ width: 1920 }} pageCount={2}>
					<AnimatedPage
						pageKey={0}
						className="live-monitor-left-to-right-animation vs--flex vs--flex-row"
						style={{
							position: "relative",
							zIndex: 2,
							"--delay": timeoutsConfig?.standartDelay ? timeoutsConfig?.standartDelay + "ms" : null,
							"--duration": timeoutsConfig?.standartDuration ? timeoutsConfig?.standartDuration + "ms" : null
						}}
					>
						<ContentGroup
							title={null}
							titleClassName="monitor-bg-header vs--mb-4 monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
							contentClassName="vs--flex vs--flex-row"
							className="vs--flex-grow"
							style={{ width: 380 }}
							titleStyle={{ display: "none" }}
							contentStyle={{ columnGap: 2 }}
						>
							<ContentList
								header={<Fragment>&#8470;</Fragment>}
								headerClassName="vs--pr-2 monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-2] vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-center vs--align-center"
								style={{ width: 46, flex: "0 0 46px" }}
								headerStyle={{ height: 74, zIndex: 1 }}
								bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
							>
								{childEvents.map(({ event }) => {
									return (
										<ContentList.ListItem key={event.id} className="vs--pr-2 monitor-border monitor-border-grey monitor-border-bottom vs--fs-28 vs--font-medium list-row-color list-row-bg vs--flex vs--justify-center vs--align-center" style={{ height: 74 }}>
											{event.orderNumber}
										</ContentList.ListItem>
									);
								})}
							</ContentList>
							<ContentList
								header={<Fragment>{t("livemonitor.football_england_league.teams")}</Fragment>}
								headerClassName="vs--font-uppercas vs--pr-2 monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-2] vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-center vs--align-center"
								bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
								style={{ width: 332, flex: "0 0 332px" }}
								headerStyle={{ height: 74, width: "100%", zIndex: 1 }}
							>
								{childEvents.map(({ event }) => {
									return (
										<ContentList.ListItem key={event.id} className="monitor-border monitor-border-grey monitor-border-bottom vs--fs-28 vs--font-medium list-row-color list-row-bg vs--flex vs--justify-center vs--align-center" style={{ height: 74 }}>
											<TeamsInfo
												event={event}
												showGoalCount={true}
												showLastResults={false}
												textElementClassName="vs--fs-28 vs--font-medium vs--letter-spacing-0"
												middleColumnClassName="vs--fs-28 vs--font-medium vs--letter-spacing-0"
												leftColumnClassName="vs--justify-center"
												rightColumnClassName="vs--justify-center"
												leftColumnStyle={{ rowGap: 8, width: 122 }}
												middleColumnStyle={{ width: 88 }}
												rightColumnStyle={{ rowGap: 8, width: 122 }}
											/>
										</ContentList.ListItem>
									);
								})}
							</ContentList>
						</ContentGroup>
					</AnimatedPage>
					<AnimatedPage
						pageKey={1}
						className="live-monitor-left-to-right-animation"
						style={{
							width: 1460,
							"--delay": timeoutsConfig?.standartDelay ? timeoutsConfig?.standartDelay + "ms" : null,
							"--duration": timeoutsConfig?.standartDuration ? timeoutsConfig?.standartDuration + "ms" : null
						}}
					>
						<AnimatedPages initialMountValue={false} className="vs--ml-2" style={{ width: 1460, height: "100%", position: "relative" }} perPage={true} perChildAnimationDelay={timeoutsConfig?.standartPerChildTimout} pageCount={groupsInPages.length}>
							{groupsInPages.map((groups, index) => {
								return (
									<AnimatedPage
										key={index}
										pageKey={index}
										className="live-monitor-left-to-right-animation vs--flex vs--flex-row"
										style={{
											width: 1460,
											position: "absolute",
											top: 0,
											columnGap: 2,
											"--delay": timeoutsConfig?.standartDelay ? timeoutsConfig?.standartDelay + "ms" : null,
											"--duration": timeoutsConfig?.standartDuration ? timeoutsConfig?.standartDuration + "ms" : null
										}}
										onAnimationEnd={index === groupsInPages.length - 1 ? onFinishScenes : null}
									>
										{groups.map(([marketKey, marketInfo], i) => {
											return (
												<ContentGroup
													key={i}
													title={t(`livemonitor.football_england_league.${marketInfo.groupTranslation}`, marketInfo.groupTranslation)}
													titleClassName="vs--font-uppercas monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-1] vs--flex vs--flex-col vs--justify-center vs--align-center monitor-bg-header heading-color vs--font-bigest vs--font-medium"
													contentClassName="vs--flex vs--flex-row"
													className="vs--flex-grow"
													titleStyle={{ height: 74 }}
													style={{ width: marketInfo.groupWidth, flex: `0 0 ${marketInfo.groupWidth}px` }}
												>
													<ContentList style={{ width: "100%" }} headerStyle={{ display: "none" }} bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-1]">
														{childEvents.map((eventInfo) => {
															const { event, markets } = eventInfo;
															const currentMarkets = markets[marketKey].find((marketOfEvent) => {
																if (marketOfEvent.group !== marketInfo.group) {
																	return false;
																}
																if (marketOfEvent.argument === null || marketOfEvent.argument === undefined) {
																	return true;
																}
																if (marketInfo.argument === null || marketInfo.argument === undefined) {
																	return true;
																}
																return marketOfEvent.argument.toString() === marketInfo.argument.toString();
															});
															const [oddList] = currentMarkets.odds;
															const possibleOdds = (oddList ?? []).filter((odd) => odd.oddState === ODD_STATE.WON).slice(0, marketInfo.outcomesWidth.length);
															const odds = Array.isArray(possibleOdds) && possibleOdds.length > 0 ? possibleOdds : new Array(marketInfo.outcomesWidth.length).fill(null);
															return (
																<ContentList.ListItem key={event.id} className="monitor-border monitor-border-grey monitor-border-bottom list-row-color list-row-bg vs--flex vs--justify-around vs--align-center" style={{ height: 74, width: "100%", columnGap: 2 }}>
																	{odds.map((odd, i) => {
																		return (
																			<Odd
																				key={i}
																				odd={odd}
																				displayShowName={true}
																				showName={t(`livemonitor.football_england_league.${marketInfo.outcomeTranslations[i]}`, marketInfo.outcomeTranslations[i])}
																				allowMarketsHighlights={allowMarketsHighlights}
																				className={mergeClassNames(marketInfo.separateOdds && i < marketInfo.outcomes.length - 1 && "monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-1]")}
																				style={{ height: "100%", flex: `0 0 ${marketInfo.outcomesWidth[i]}px`, maxWidth: marketInfo.outcomesWidth[i] }}
																				showNameClassName="vs--pl-16 vs--font-bigest vs--font-medium"
																				textClassName="vs--pr-16 vs--font-bigest vs--font-medium"
																				textClassNameWhenLock="vs--ml-auto vs--mr-auto vs--pl-16"
																				textContainerClassName="vs--height-full vs--width-full vs--font-uppercas vs--flex vs--flex-row vs--justify-between vs--align-center"
																				lockClassName="vs--fs-40 vs--font-medium"
																			/>
																		);
																	})}
																</ContentList.ListItem>
															);
														})}
													</ContentList>
												</ContentGroup>
											);
										})}
									</AnimatedPage>
								);
							})}
						</AnimatedPages>
					</AnimatedPage>
				</AnimatedPages>
			</div>
			<div className="vs--mt-auto monitor-bg-footer vs--flex vs--justify-center vs--align-center" style={{ height: 92 }}>
				<span className="vs--fs-32 vs--font-medium vs--color-monitor-footer">{t("livemonitor.football_england_league.resultsAndWinningMarkets")}</span>
			</div>
		</section>
	);
};
export default ResultsMarkets;
