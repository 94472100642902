import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { initTranslations } from "translations/config";

import Loader from "components/ui/loader";

import { refreshStreamAccess, getSessionGames } from "store/actions/auth/auth.actions";
import { getUrlVars } from "utils/common";

/** Main Layout Component */
const Layout = ({ translationsLoaded, sessionLoaded, stream, refreshStreamAccess, getSessionGames }) => {
	const [decodedData, setDecodedData] = useState(null);

	const isLoaded = translationsLoaded && stream.accessLoaded && sessionLoaded;

	/** Load Translations */
	useEffect(() => {
		document.documentElement.classList.add("vs--body-unscale");
		let streamData = { languageId: "en" };
		try {
			const streamEncodedData = getUrlVars()["data"];
			streamData = JSON.parse(decodeURIComponent(atob(streamEncodedData)));

			setDecodedData(streamData);
		} catch (ex) {
			console.log("can't decode data");
		}

		initTranslations(streamData.languageFilePath, streamData.languageId, streamData.languageFilePath, streamData.languageId, Function.prototype, Function.prototype);
	}, []);

	useEffect(() => {
		if (translationsLoaded && decodedData) {
			const sessionTemplateType = decodedData?.sessionTemplateType ?? null;
			const streamRefreshToken = decodedData?.refreshToken ?? null;

			if (sessionTemplateType !== null) {
				document.documentElement.setAttribute("data-template", sessionTemplateType);
			}
			if (streamRefreshToken !== null) {
				refreshStreamAccess(streamRefreshToken);
			}
		}
	}, [translationsLoaded, decodedData]);

	useEffect(() => {
		if (stream.accessLoaded) {
			getSessionGames(true);
		}
	}, [stream.accessLoaded]);

	return <div className="vs--container">{isLoaded ? <Outlet decodedData={{ decodedData }} /> : <Loader full={true} />}</div>;
};

/** Layout propTypes
 * PropTypes
 */
Layout.propTypes = {
	/** Redux state property, Is true when translations are loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux state property, is true when games data is loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux state property, the object of stream */
	stream: PropTypes.object,
	/** Redux action to refresh stream access */
	refreshStreamAccess: PropTypes.func,
	/** Redux action to get games */
	getSessionGames: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		translationsLoaded: state.auth.translationsLoaded,
		sessionLoaded: state.auth.sessionLoaded,
		stream: state.auth.stream
	};
};

const mapDispatchToProps = (dispatch) => ({
	refreshStreamAccess: (refreshToken) => {
		dispatch(refreshStreamAccess(refreshToken));
	},
	getSessionGames: (fromStream) => {
		dispatch(getSessionGames(fromStream));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
