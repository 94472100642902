import { useSelector } from "react-redux";
import { useOutletContext } from "react-router";

import JackpotAnimations from "components/pages/jackpotLiveInfo/animations";
import Levels from "components/jackpotInfo/levels";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isNullish, toFixed } from "utils/common";

const getJackpotInfo = (state) => state.bonuses.jackpot.data;

const ActiveJackpot = () => {
	const jackpotInfo = useSelector(getJackpotInfo);
	const { t } = useTranslation();
	const { decodedData } = useOutletContext();

	const largestWinAmount = useMemo(() => {

		if (!jackpotInfo?.levels || jackpotInfo?.levels.length === 0) {
			return null
		}

		const largestWinAmounts = jackpotInfo?.levels.map((levelInfo) => {
			return levelInfo.largestWinAmount
		})

		return Math.max(...largestWinAmounts);

	}, [jackpotInfo?.levels])

	return (
		<div className="vs--active-jackpot-live-info vs--flex vs--flex-col vs--align-center vs--justify-start">
			<JackpotAnimations
				getMainContainer={() => document.querySelector(".vs--active-jackpot-live-info")}
			/>
			<div className="vs--active-jackpot-live-info-logo-container">
				<img
					alt="logo"
					className="vs--active-jackpot-live-info-logo"
					src={decodedData.betShopLogoPath}
				/>
			</div>
			<Levels
				data={jackpotInfo?.levels}
				currencyDecimalCount={decodedData?.currencyDecimalCount}
				currencySymbol={decodedData?.currencySymbol}
				currencyCode={decodedData?.currencyCode}
				isInOtherRoute={true}
			/>

			{!isNullish(largestWinAmount) && largestWinAmount !== 0 && (
				<div className="vs--active-jackpot-live-info-largest-win">
					<span className="vs--active-jackpot-live-info-largest-win">{t("cashier.largestWin")}:&nbsp;{toFixed(largestWinAmount, decodedData?.currencyDecimalCount ?? 0)}&nbsp;{decodedData?.currencyCode}</span>
				</div>
			)}
		</div>
	);
};

export default ActiveJackpot;
