import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants"
import { mergeClassNames } from "utils/common"

const SuggestedMarketsCurrentStatisticsBackground = ({ fromHotToColdSorted = [] }) => {
	const getDataColor = (number) => {
		if (number === 0) {
			return "green"
		}
		if (SPIN_TO_WIN_MARKETS_NUMBERS.RED_NUMBERS.includes(number)) {
			return "red"
		}
		if (SPIN_TO_WIN_MARKETS_NUMBERS.BLACK_NUMBERS.includes(number)) {
			return "white"
		}
		return null
	}
	return (
		<div className="vs--spin-2-win-stream-scene-suggested-markets-current-statistics-hot-cold">
			{
				fromHotToColdSorted.slice(-3).map(({ number: coldNumber }, i) => {
					const dataNumber = i+1;
					const dataColor = getDataColor(coldNumber);
					return (
						<span
							key={dataNumber}
							className="vs--spin-2-win-stream-scene-suggested-markets-current-statistics-hot-cold-number vs--spin-2-win-stream-scene-suggested-markets-current-statistics-hot-number"
							data-number={dataNumber}
							data-color={dataColor}
						>
							{coldNumber}
						</span>
					)
				})
			}
			{
				fromHotToColdSorted.slice(0, 3).map(({ number: hotNumber }, i) => {
					const dataNumber = i+1;
					const dataColor = getDataColor(hotNumber);
					return (
						<span
							key={dataNumber}
							className="vs--spin-2-win-stream-scene-suggested-markets-current-statistics-hot-cold-number vs--spin-2-win-stream-scene-suggested-markets-current-statistics-cold-number"
							data-number={dataNumber}
							data-color={dataColor}
						>
							{hotNumber}
						</span>
					)
				})
			}
		</div>
	)
}
export default SuggestedMarketsCurrentStatisticsBackground