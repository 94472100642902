import { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clearForecast, clearKenoBalls, clearLuckySixBalls } from "store/actions/betslip/betslip.actions";
import PendingBet from "../pendingBet";
import ForecastButton from "../forecastButton";
import KenoButton from "../kenoButton";
import LuckySixButton from "../luckySixButton";
import { GAME_TYPE } from "constants/game.constants.js";

/* PendingBetslip Tab Component */
const PendingBetslip = ({ forecast, kenoBalls, clearForecast, clearKenoBalls, luckySixBalls, clearLuckySixBalls }) => {
	const mapedArr = useMemo(
		() => [
			{
				pendingBet: forecast
					? {
							gameType: forecast?.gameType ?? 0,
							dataArr: forecast?.odd?.rectData ?? []
						}
					: null,
				onClose: clearForecast,
				children: <ForecastButton />
			},
			{
				pendingBet:
					(kenoBalls?.balls ?? []).length > 0
						? {
								gameType: kenoBalls?.gameType ?? 0,
								dataArr: kenoBalls?.balls ?? []
							}
						: null,
				onClose: clearKenoBalls,
				children: <KenoButton />
			},
			{
				pendingBet:
					luckySixBalls?.length > 0
						? {
								gameType: GAME_TYPE.LUCKY_SIX,
								dataArr: luckySixBalls ?? []
							}
						: null,
				onClose: clearLuckySixBalls,
				children: <LuckySixButton />
			}
		],
		[
			clearForecast,
			clearKenoBalls,
			clearLuckySixBalls,
			forecast,
			kenoBalls?.balls,
			kenoBalls?.gameType,
			luckySixBalls
		]
	);

	return (
		<>
			{mapedArr.reduce((acc, elem, i) => {
				if (elem.pendingBet !== null) {
					const { children, ...obj } = elem;
					acc.push(
						<PendingBet key={i} {...obj}>
							{children}
						</PendingBet>
					);
				}
				return acc;
			}, [])}
		</>
	);
};

/** PendingBetslip propTypes
 * PropTypes
 */
PendingBetslip.propTypes = {
	/** Redux state property, Forecast/Tricast bet selection */
	forecast: PropTypes.object,
	/** Redux state property, keno balls bet selection */
	kenoBalls: PropTypes.object,
	/** Redux action to clear forecast */
	clearForecast: PropTypes.func,
	/** Redux action to clear keno balls */
	clearKenoBalls: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		forecast: state.betslip.forecast,
		kenoBalls: state.betslip.kenoBalls,
		luckySixBalls: state.betslip.luckySixBalls
	};
};

const mapDispatchToProps = (dispatch) => ({
	clearForecast: () => {
		dispatch(clearForecast());
	},
	clearKenoBalls: () => {
		dispatch(clearKenoBalls());
	},
	clearLuckySixBalls: () => {
		dispatch(clearLuckySixBalls());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingBetslip);
