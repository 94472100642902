import { useSelector } from "react-redux";
import ProgressBarStatistics from "./progress-bar-statistics";
import RouletteStatistics from "./roulette-statistics";
import "./styles.scss";
import { useContext, useEffect } from "react";
import useEvent from "hooks/useEvent";
import { useTranslation } from "react-i18next";
import ScenesFlowContext from "components/pages/scenesFlow/scenesFlowContext";

const Statistics = ({ onFinish: argOnFinish, onMount: argOnMount }) => {
	const { t } = useTranslation()
	const fromHotToColdSorted = useSelector((state) => state.game.spinToWinStatistics.fromHotToColdSorted);

	const sceneFlowProviderValue = useContext(ScenesFlowContext)
	const onMount = useEvent(() => {
		if (typeof argOnMount === "function") {
			argOnMount(sceneFlowProviderValue)
		}
	});
	const onFinish = useEvent(() => {
		if (typeof argOnFinish === "function") {
			argOnFinish(sceneFlowProviderValue)
		}
	});
	
	useEffect(() => {
		const timeoutId = setTimeout(onFinish, 10000)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [onFinish])

	return (
		<div
			className="vs--spin-2-win-stream-scene vs--spin-2-win-stream-scene-statistics"
			onAnimationEnd={e => {
				if (e.animationName === "mount-scene-suggested-markets") {
					onMount()
				}
			}}
		>
			<div className="vs--spin-2-win-stream-scene-statistics-title">
				{t("statistics.last{count}GamesStatistics").replace("{count}", 100)}
			</div>
			<ProgressBarStatistics />
			<RouletteStatistics fromHotToColdSorted={fromHotToColdSorted} />
		</div>
	);
};
export default Statistics;
