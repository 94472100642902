import RouletteWheel from "../../roulette-wheel"
import SuggestedMarketsCurrentStatisticsBackground from "./suggested-markets-current-statistics-background"

const SuggestedMarketsCurrentStatistics = ({ fromHotToColdSorted }) => {
	return (
		<div className="vs--spin-2-win-stream-scene-suggested-markets-current-statistics">
			<SuggestedMarketsCurrentStatisticsBackground fromHotToColdSorted={fromHotToColdSorted}/>
			<div className="vs--spin-2-win-stream-scene-suggested-markets-current-statistics-wheel-board"></div>
			<RouletteWheel fromHotToColdSorted={fromHotToColdSorted} />
		</div>
	)
}
export default SuggestedMarketsCurrentStatistics