import { useSelector } from "react-redux";
import AmountNums from "components/jackpotInfo/amountNums";

import { useOutletContext } from 'react-router';

import { buildPathToStaticFolderOfCDN, mergeClassNames, toFixed } from "utils/common";

import { MAX_HIT_AMOUNT_NUM_LENGTH } from "constants/bonus.constants";

const JACKPOT_LEVELS = {
	"level-1": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-bronze-icon-level-1.gif"),
	"level-2": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-silver-icon-level-2.gif"),
	"level-4": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-gold-icon-level-4.gif")
}

const getSessionCurrencyDecimalCount = (state) => state.auth.session?.currency?.decimalCount;
const getSessionCurrencySymbol = (state) => state.auth.session?.currency?.symbol;

const Pools = ({ data, className = "", showLevelIcon = true }) => {
	const outletContext = useOutletContext();
	const decodedData = outletContext?.decodedData;

	const currencyDecimalCount = useSelector(getSessionCurrencyDecimalCount) ?? decodedData?.currencyDecimalCount ?? 0;
	const currencySymbol = useSelector(getSessionCurrencySymbol) ?? decodedData?.currencySymbol;
	const format = "( ddd)" + (currencyDecimalCount > 0 ? ".".padEnd(currencyDecimalCount + 1, "d") : "")

	return (
		<div className={mergeClassNames("vs--jackpot-pools vs--flex vs--flex-col", className)}>
			{data?.map((level) => {
				const possiblyLargeAmount = toFixed(level?.amount, currencyDecimalCount);
				const amount = Number(possiblyLargeAmount) || 0;
				return (
					<div
						key={level.levelType}
						className={mergeClassNames(
							"vs--jackpot-pools-item vs--flex vs--align-center vs--justify-center vs--pl-4 vs--pr-8",
							`vs--jackpot-pools-item-${level.levelType}`
						)}
					>
						<img className="vs--jackpot-pools-item-level-icon" src={JACKPOT_LEVELS[`level-${level.levelType}`]}></img>
	
						<div className="vs--flex vs--flex-equal vs--flex-col">
							<span className={mergeClassNames("vs--jackpot-pools-item-title vs--font-smallest vs--font-medium")}>
								{level.poolName}
							</span>
							<AmountNums
								className="vs--jackpot-pools-item-amount"
								amount={amount}
								format={format}
								currencySymbol={currencySymbol}
							/>
						</div>
					</div>
				)
			})}
		</div>
	)
};

export default Pools;