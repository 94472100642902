const SpinToWinBottomBoardNumbers = ({ hoveredGroup }) => {
	return (
		<>
			<g className="orpehlins-number">
				<path d="M232.911 2V1h-40.585v53.944h40.585z" fill="#821717" />
				<path d="M232.911 2V1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path
					className="prefix__orpehlins-num"
					d="M232.911 2V1h-40.585v53.944h40.585z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="212" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					1
				</text>
			</g>

			<g className="trier-number">
				<path d="M194.325 2V1h-40.584v53.944h40.584z" fill="#090909" />
				<path d="M194.325 2V1h-40.584v53.944h40.584z" stroke="#141414" strokeWidth="2" />
				<path d="M194.325 2V1h-40.584v53.944h40.584z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="175" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					33
				</text>
			</g>

			<g className="orpehlins-number">
				<path d="M271.508 2V1h-40.585v53.944h40.585z" fill="#090909" />
				<path d="M271.508 2V1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M271.508 2V1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="250" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					20
				</text>
			</g>

			<g className="trier-number">
				<path d="M194.325 198.839v-1h-40.584v53.943h40.584z" fill="#090909" />
				<path d="M194.325 198.839v-1h-40.584v53.943h40.584z" stroke="#141414" strokeWidth="2" />
				<path d="M194.325 198.839v-1h-40.584v53.943h40.584z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="173" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					13
				</text>
			</g>

			<g className="trier-number">
				<path d="M232.911 198.839v-1h-40.585v53.943h40.585z" fill="#821717" />
				<path d="M232.911 198.839v-1h-40.585v53.943h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M232.911 198.839v-1h-40.585v53.943h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="212" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					27
				</text>
			</g>

			<g className="orpehlins-number">
				<path d="M310.093 198.839v-1h-40.584v53.943h40.584z" fill="#821717" />
				<path d="M310.093 198.839v-1h-40.584v53.943h40.584z" stroke="#141414" strokeWidth="2" />
				<path d="M310.093 198.839v-1h-40.584v53.943h40.584z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="289" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					34
				</text>
			</g>

			<g className="trier-number">
				<path
					d="m117.819 196.556-.976-.179-.187.975-9.789 51.109-.19.994.996.179a137 137 0 0 0 24.178 2.148h23.888v-53.944h-23.888c-4.804 0-9.481-.445-14.032-1.282"
					fill="#821717"
				/>
				<path
					d="m117.819 196.556-.976-.179-.187.975-9.789 51.109-.19.994.996.179a137 137 0 0 0 24.178 2.148h23.888v-53.944h-23.888c-4.804 0-9.481-.445-14.032-1.282Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m117.819 196.556-.976-.179-.187.975-9.789 51.109-.19.994.996.179a137 137 0 0 0 24.178 2.148h23.888v-53.944h-23.888c-4.804 0-9.481-.445-14.032-1.282Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="134" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					36
				</text>
			</g>

			<g className="zero-number">
				<path
					d="M680.411 69.853h-.229l-.206.1-48.416 23.43-.925.447.472.912c4.938 9.542 7.736 20.279 7.736 31.65v1.427l1.004-.004 54.238-.177.996-.004v-1.242c0-20.138-4.964-39.146-13.769-56.002l-.281-.537z"
					fill="#821717"
				/>
				<path
					d="M680.411 69.853h-.229l-.206.1-48.416 23.43-.925.447.472.912c4.938 9.542 7.736 20.279 7.736 31.65v1.427l1.004-.004 54.238-.177.996-.004v-1.242c0-20.138-4.964-39.146-13.769-56.002l-.281-.537z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="M680.411 69.853h-.229l-.206.1-48.416 23.43-.925.447.472.912c4.938 9.542 7.736 20.279 7.736 31.65v1.427l1.004-.004 54.238-.177.996-.004v-1.242c0-20.138-4.964-39.146-13.769-56.002l-.281-.537z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="664" y="106" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					3
				</text>
			</g>

			<g className="zero-number">
				<path
					d="m609.049 69.3.783.565.592-.763 32.335-41.705.636-.82-.844-.606c-16.046-11.514-34.938-19.599-55.496-23.07l-.972-.163-.178.969-9.418 51.178-.183.997.999.17c11.763 1.997 22.579 6.639 31.746 13.248"
					fill="#821717"
				/>
				<path
					d="m609.049 69.3.783.565.592-.763 32.335-41.705.636-.82-.844-.606c-16.046-11.514-34.938-19.599-55.496-23.07l-.972-.163-.178.969-9.418 51.178-.183.997.999.17c11.763 1.997 22.579 6.639 31.746 13.248Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m609.049 69.3.783.565.592-.763 32.335-41.705.636-.82-.844-.606c-16.046-11.514-34.938-19.599-55.496-23.07l-.972-.163-.178.969-9.418 51.178-.183.997.999.17c11.763 1.997 22.579 6.639 31.746 13.248Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="604" y="38" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					12
				</text>
			</g>

			<g className="trier-number">
				<path
					d="M154.74 54.944h1V1h-23.888c-8.035 0-15.898.702-23.532 2.038l-.993.174.182.992 9.418 51.164.18.978.979-.174a78.3 78.3 0 0 1 13.766-1.228z"
					fill="#821717"
				/>
				<path
					d="M154.74 54.944h1V1h-23.888c-8.035 0-15.898.702-23.532 2.038l-.993.174.182.992 9.418 51.164.18.978.979-.174a78.3 78.3 0 0 1 13.766-1.228z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="M154.74 54.944h1V1h-23.888c-8.035 0-15.898.702-23.532 2.038l-.993.174.182.992 9.418 51.164.18.978.979-.174a78.3 78.3 0 0 1 13.766-1.228z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="133" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					16
				</text>
			</g>

			<g className="trier-number">
				<path
					d="m118.087 56.172.989-.178-.182-.988-9.418-51.164-.179-.974-.976.17c-17.524 3.057-33.813 9.447-48.163 18.469l-.873.549.576.856 29.395 43.687.544.81.823-.525a76 76 0 0 1 27.464-10.712"
					fill="#090909"
				/>
				<path
					d="m118.087 56.172.989-.178-.182-.988-9.418-51.164-.179-.974-.976.17c-17.524 3.057-33.813 9.447-48.163 18.469l-.873.549.576.856 29.395 43.687.544.81.823-.525a76 76 0 0 1 27.464-10.712Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m118.087 56.172.989-.178-.182-.988-9.418-51.164-.179-.974-.976.17c-17.524 3.057-33.813 9.447-48.163 18.469l-.873.549.576.856 29.395 43.687.544.81.823-.525a76 76 0 0 1 27.464-10.712Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="94" y="36" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					24
				</text>
			</g>

			<g className="trier-number">
				<path
					d="m90.606 66.886.866-.551-.573-.851-29.395-43.688-.542-.805-.82.518c-14.536 9.159-27.077 21.005-36.815 34.826l-.595.845.864.567 44.749 29.348.813.533.556-.798c5.502-7.898 12.625-14.683 20.892-19.945"
					fill="#821717"
				/>
				<path
					d="m90.606 66.886.866-.551-.573-.851-29.395-43.688-.542-.805-.82.518c-14.536 9.159-27.077 21.005-36.815 34.826l-.595.845.864.567 44.749 29.348.813.533.556-.798c5.502-7.898 12.625-14.683 20.892-19.945Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m90.606 66.886.866-.551-.573-.851-29.395-43.688-.542-.805-.82.518c-14.536 9.159-27.077 21.005-36.815 34.826l-.595.845.864.567 44.749 29.348.813.533.556-.798c5.502-7.898 12.625-14.683 20.892-19.945Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="60" y="58" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					5
				</text>
			</g>

			<g className="trier-number">
				<path
					d="m69.73 86.83.588-.845-.86-.564L24.71 56.073l-.81-.53-.556.79C13.792 69.893 6.913 85.329 3.49 101.968l-.204.988.99.195 53.14 10.443.977.192.196-.976c1.898-9.456 5.752-18.242 11.142-25.98"
					fill="#090909"
				/>
				<path
					d="m69.73 86.83.588-.845-.86-.564L24.71 56.073l-.81-.53-.556.79C13.792 69.893 6.913 85.329 3.49 101.968l-.204.988.99.195 53.14 10.443.977.192.196-.976c1.898-9.456 5.752-18.242 11.142-25.98Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m69.73 86.83.588-.845-.86-.564L24.71 56.073l-.81-.53-.556.79C13.792 69.893 6.913 85.329 3.49 101.968l-.204.988.99.195 53.14 10.443.977.192.196-.976c1.898-9.456 5.752-18.242 11.142-25.98Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="38" y="91" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					10
				</text>
			</g>

			<g className="trier-number">
				<path
					d="M57.231 125.392c.064-4.297.535-8.49 1.357-12.581l.198-.985-.985-.194-53.14-10.443-.972-.191-.2.971A121 121 0 0 0 1 126.392c0 8.533.907 16.845 2.604 24.893l.202.959.963-.182 53.21-10.088 1.007-.191-.215-1.002a68.4 68.4 0 0 1-1.533-14.389v-1z"
					fill="#821717"
				/>
				<path
					d="M57.231 125.392c.064-4.297.535-8.49 1.357-12.581l.198-.985-.985-.194-53.14-10.443-.972-.191-.2.971A121 121 0 0 0 1 126.392c0 8.533.907 16.845 2.604 24.893l.202.959.963-.182 53.21-10.088 1.007-.191-.215-1.002a68.4 68.4 0 0 1-1.533-14.389v-1z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="M57.231 125.392c.064-4.297.535-8.49 1.357-12.581l.198-.985-.985-.194-53.14-10.443-.972-.191-.2.971A121 121 0 0 0 1 126.392c0 8.533.907 16.845 2.604 24.893l.202.959.963-.182 53.21-10.088 1.007-.191-.215-1.002a68.4 68.4 0 0 1-1.533-14.389v-1z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="33" y="128" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					23
				</text>
			</g>

			<g className="trier-number">
				<path
					d="m58.758 140.779-.205-.954-.96.182-53.21 10.088-1.002.19.21.998c3.482 16.541 10.345 31.908 19.897 45.399l.554.782.804-.52 44.992-29.061.877-.567-.604-.851c-5.419-7.645-9.342-16.324-11.353-25.686"
					fill="#090909"
				/>
				<path
					d="m58.758 140.779-.205-.954-.96.182-53.21 10.088-1.002.19.21.998c3.482 16.541 10.345 31.908 19.897 45.399l.554.782.804-.52 44.992-29.061.877-.567-.604-.851c-5.419-7.645-9.342-16.324-11.353-25.686Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m58.758 140.779-.205-.954-.96.182-53.21 10.088-1.002.19.21.998c3.482 16.541 10.345 31.908 19.897 45.399l.554.782.804-.52 44.992-29.061.877-.567-.604-.851c-5.419-7.645-9.342-16.324-11.353-25.686Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="37" y="167" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					8
				</text>
			</g>

			<g className="trier-number">
				<path
					d="M68.295 163.874v2.612L23.76 195.252l-.877.566.603.852c9.695 13.682 22.135 25.433 36.556 34.523l.815.514.544-.796 29.695-43.468.584-.856-.876-.554a73.3 73.3 0 0 1-20.694-19.594z"
					fill="#821717"
				/>
				<path
					d="M68.295 163.874v2.612L23.76 195.252l-.877.566.603.852c9.695 13.682 22.135 25.433 36.556 34.523l.815.514.544-.796 29.695-43.468.584-.856-.876-.554a73.3 73.3 0 0 1-20.694-19.594z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="M68.295 163.874v2.612L23.76 195.252l-.877.566.603.852c9.695 13.682 22.135 25.433 36.556 34.523l.815.514.544-.796 29.695-43.468.584-.856-.876-.554a73.3 73.3 0 0 1-20.694-19.594z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="57" y="200" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					30
				</text>
			</g>

			<g className="trier-number">
				<path
					d="m90.447 186.106-.783-.392-.492.724-29.422 43.36-.581.856.875.551a133.4 133.4 0 0 0 47.617 18.428l.973.174.186-.97 9.788-51.11.188-.98-.98-.19c-6.464-1.25-12.796-3.605-17.527-5.657a108 108 0 0 1-5.68-2.659 77 77 0 0 1-1.575-.817l-.41-.222-.103-.057-.025-.014-.006-.003h-.001v-.001l-.02-.011-.022-.01z"
					fill="#090909"
				/>
				<path
					d="m90.447 186.106-.783-.392-.492.724-29.422 43.36-.581.856.875.551a133.4 133.4 0 0 0 47.617 18.428l.973.174.186-.97 9.788-51.11.188-.98-.98-.19c-6.464-1.25-12.796-3.605-17.527-5.657a108 108 0 0 1-5.68-2.659 77 77 0 0 1-1.575-.817l-.41-.222-.103-.057-.025-.014-.006-.003h-.001v-.001l-.02-.011-.022-.01z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m90.447 186.106-.783-.392-.492.724-29.422 43.36-.581.856.875.551a133.4 133.4 0 0 0 47.617 18.428l.973.174.186-.97 9.788-51.11.188-.98-.98-.19c-6.464-1.25-12.796-3.605-17.527-5.657a108 108 0 0 1-5.68-2.659 77 77 0 0 1-1.575-.817l-.41-.222-.103-.057-.025-.014-.006-.003h-.001v-.001l-.02-.011-.022-.01z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="93" y="222" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					11
				</text>
			</g>

			<g className="orpehlins-number">
				<path d="M271.508 198.839v-1h-40.585v53.943h40.585z" fill="#090909" />
				<path d="M271.508 198.839v-1h-40.585v53.943h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M271.508 198.839v-1h-40.585v53.943h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="250" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					6
				</text>
			</g>

			<g className="orpehlins-number">
				<path d="M348.675 198.839v-1h-40.584v53.943h40.584z" fill="#090909" />
				<path d="M348.675 198.839v-1h-40.584v53.943h40.584z" stroke="#141414" strokeWidth="2" />
				<path d="M348.675 198.839v-1h-40.584v53.943h40.584z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="326" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					17
				</text>
			</g>

			<g className="voisin-number">
				<path d="M387.261 198.839v-1h-40.585v53.943h40.585z" fill="#821717" />
				<path d="M387.261 198.839v-1h-40.585v53.943h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M387.261 198.839v-1h-40.585v53.943h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="366" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					25
				</text>
			</g>

			<g className="voisin-number">
				<path d="M425.846 198.837v-1h-40.585v53.944h40.585z" fill="#090909" />
				<path d="M425.846 198.837v-1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M425.846 198.837v-1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="405" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					2
				</text>
			</g>

			<g className="voisin-number">
				<path d="M464.431 198.837v-1h-40.585v53.944h40.585z" fill="#821717" />
				<path d="M464.431 198.837v-1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M464.431 198.837v-1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="444" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					21
				</text>
			</g>

			<g className="voisin-number">
				<path d="M503.029 198.839v-1h-40.585v53.943h40.585z" fill="#090909" />
				<path d="M503.029 198.839v-1h-40.585v53.943h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M503.029 198.839v-1h-40.585v53.943h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="482" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					4
				</text>
			</g>

			<g className="voisin-number">
				<path d="M541.614 198.837v-1h-40.585v53.944h40.585z" fill="#821717" />
				<path d="M541.614 198.837v-1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M541.614 198.837v-1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="519" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					19
				</text>
			</g>

			<g className="zero-number">
				<path
					d="M565.216 197.845v-.007h-25.601v53.943h24.601c8.022 0 15.871-.716 23.488-2.037l1.002-.174-.191-1-9.789-51.082-.185-.966-.969.169c-4.02.7-8.17 1.099-12.356 1.154"
					fill="#090909"
				/>
				<path
					d="M565.216 197.845v-.007h-25.601v53.943h24.601c8.022 0 15.871-.716 23.488-2.037l1.002-.174-.191-1-9.789-51.082-.185-.966-.969.169c-4.02.7-8.17 1.099-12.356 1.154Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="M565.216 197.845v-.007h-25.601v53.943h24.601c8.022 0 15.871-.716 23.488-2.037l1.002-.174-.191-1-9.789-51.082-.185-.966-.969.169c-4.02.7-8.17 1.099-12.356 1.154Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="559" y="228" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					15
				</text>
			</g>

			<g className="zero-number">
				<path
					d="m577.571 196.678-1.002.175.191.999 9.789 51.082.185.966.969-.169c20.312-3.539 39.003-11.54 54.892-22.96l.848-.609-.645-.821-32.635-41.487-.59-.75-.777.555c-9.04 6.461-19.673 11.009-31.225 13.019"
					fill="#821717"
				/>
				<path
					d="m577.571 196.678-1.002.175.191.999 9.789 51.082.185.966.969-.169c20.312-3.539 39.003-11.54 54.892-22.96l.848-.609-.645-.821-32.635-41.487-.59-.75-.777.555c-9.04 6.461-19.673 11.009-31.225 13.019Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m577.571 196.678-1.002.175.191.999 9.789 51.082.185.966.969-.169c20.312-3.539 39.003-11.54 54.892-22.96l.848-.609-.645-.821-32.635-41.487-.59-.75-.777.555c-9.04 6.461-19.673 11.009-31.225 13.019Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="604" y="222" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					32
				</text>
			</g>

			<g className="zero-number">
				<path
					d="m630.769 158.674-.489.923.943.448 48.602 23.115.867.413.447-.851c8.863-16.869 13.885-35.918 13.928-56.083l.002-1.006-1.005.003-54.239.178-.99.003-.006.991c-.071 11.465-2.981 22.283-8.06 31.866"
					fill="#090909"
				/>
				<path
					d="m630.769 158.674-.489.923.943.448 48.602 23.115.867.413.447-.851c8.863-16.869 13.885-35.918 13.928-56.083l.002-1.006-1.005.003-54.239.178-.99.003-.006.991c-.071 11.465-2.981 22.283-8.06 31.866Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m630.769 158.674-.489.923.943.448 48.602 23.115.867.413.447-.851c8.863-16.869 13.885-35.918 13.928-56.083l.002-1.006-1.005.003-54.239.178-.99.003-.006.991c-.071 11.465-2.981 22.283-8.06 31.866Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="664" y="150" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					26
				</text>
			</g>

			<g className="zero-number">
				<path
					d="m608.634 185.158 32.591 41.432.592.752.777-.558c16.133-11.584 29.394-26.655 38.544-44.062l.484-.921-.94-.447-48.601-23.115-.865-.411-.448.846c-5.234 9.865-12.792 18.415-21.987 24.984l-.981.701z"
					fill="#11402C"
				/>
				<path
					d="m608.634 185.158 32.591 41.432.592.752.777-.558c16.133-11.584 29.394-26.655 38.544-44.062l.484-.921-.94-.447-48.601-23.115-.865-.411-.448.846c-5.234 9.865-12.792 18.415-21.987 24.984l-.981.701z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m608.634 185.158 32.591 41.432.592.752.777-.558c16.133-11.584 29.394-26.655 38.544-44.062l.484-.921-.94-.447-48.601-23.115-.865-.411-.448.846c-5.234 9.865-12.792 18.415-21.987 24.984l-.981.701z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="642" y="192" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					0
				</text>
			</g>

			<g className="zero-number">
				<path
					d="m631.106 94.742.448.863.876-.423 48.416-23.43.928-.449-.477-.914c-9.164-17.543-22.498-32.753-38.746-44.406l-.783-.561-.59.761-32.335 41.705-.634.819.84.605c9.279 6.69 16.851 15.402 22.057 25.43"
					fill="#090909"
				/>
				<path
					d="m631.106 94.742.448.863.876-.423 48.416-23.43.928-.449-.477-.914c-9.164-17.543-22.498-32.753-38.746-44.406l-.783-.561-.59.761-32.335 41.705-.634.819.84.605c9.279 6.69 16.851 15.402 22.057 25.43Z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="m631.106 94.742.448.863.876-.423 48.416-23.43.928-.449-.477-.914c-9.164-17.543-22.498-32.753-38.746-44.406l-.783-.561-.59.761-32.335 41.705-.634.819.84.605c9.279 6.69 16.851 15.402 22.057 25.43Z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="644" y="66" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					35
				</text>
			</g>

			<g className="voisin-number">
				<path
					d="M578.5 54.944h.846l.14-.835 8.391-50.044.165-.985-.985-.166A137 137 0 0 0 564.216 1h-24.601v53.944z"
					fill="#090909"
				/>
				<path
					d="M578.5 54.944h.846l.14-.835 8.391-50.044.165-.985-.985-.166A137 137 0 0 0 564.216 1h-24.601v53.944z"
					stroke="#141414"
					strokeWidth="2"
				/>
				<path
					d="M578.5 54.944h.846l.14-.835 8.391-50.044.165-.985-.985-.166A137 137 0 0 0 564.216 1h-24.601v53.944z"
					stroke="#fff"
					strokeOpacity=".32"
					strokeWidth="2"
				/>
				<text x="560" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					28
				</text>
			</g>

			<g className="voisin-number">
				<path d="M541.614 2V1h-40.585v53.944h40.585z" fill="#821717" />
				<path d="M541.614 2V1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M541.614 2V1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="521" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					7
				</text>
			</g>

			<g className="voisin-number">
				<path d="M503.029 2V1h-40.585v53.944h40.585z" fill="#090909" />
				<path d="M503.029 2V1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M503.029 2V1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="484" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					29
				</text>
			</g>

			<g className="voisin-number">
				<path d="M464.431 2V1h-40.585v53.944h40.585z" fill="#821717" />
				<path d="M464.431 2V1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M464.431 2V1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="444" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					18
				</text>
			</g>

			<g className="voisin-number">
				<path d="M425.846 2V1h-40.585v53.944h40.585z" fill="#090909" />
				<path d="M425.846 2V1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M425.846 2V1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="407" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					22
				</text>
			</g>

			<g className="orpehlins-number">
				<path d="M387.261 2V1h-40.585v53.944h40.585z" fill="#821717" />
				<path d="M387.261 2V1h-40.585v53.944h40.585z" stroke="#141414" strokeWidth="2" />
				<path d="M387.261 2V1h-40.585v53.944h40.585z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="368" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					9
				</text>
			</g>

			<g className="orpehlins-number">
				<path d="M348.675 2V1h-40.584v53.944h40.584z" fill="#090909" />
				<path d="M348.675 2V1h-40.584v53.944h40.584z" stroke="#141414" strokeWidth="2" />
				<path d="M348.675 2V1h-40.584v53.944h40.584z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="330" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					31
				</text>
			</g>

			<g className="orpehlins-number">
				<path d="M310.093 2V1h-40.584v53.944h40.584z" fill="#821717" />
				<path d="M310.093 2V1h-40.584v53.944h40.584z" stroke="#141414" strokeWidth="2" />
				<path d="M310.093 2V1h-40.584v53.944h40.584z" stroke="#fff" strokeOpacity=".32" strokeWidth="2" />
				<text x="288" y="30" fill="#fff" fontSize="26" textAnchor="middle" dominantBaseline="middle">
					14
				</text>
			</g>
		</>
	);
};

export default SpinToWinBottomBoardNumbers;
