import { useEffect, useRef, useState, memo, useLayoutEffect, useMemo, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Empty from "components/monitors/empty";
import EnglishLeague from "components/monitors/englishLeague";
import runMarketUtilsFunction from "utils/markets/run";
import Loader from "components/ui/loader";
import { GAME_EVENT_TYPE, GAME_STATUSES, GAME_TYPE } from "constants/game.constants";
import { getScreensAnimationTimouts } from "utils/liveMonitor";
import { changeEventByScene, removeFromLiveAndUpcomingsLiveMonitor, updateFromLiveAndUpcomings } from "store/actions/game/game.actions";
import useEvent from "hooks/useEvent";
import { MAIN_SCENES, NEXT_SCENES, PREV_SCENES } from "constants/liveMonitor.constants";
import { flushSync } from "react-dom";
import useDebounce from "hooks/useDebounce";
import moment from "moment";
import LiveMonitorGameContext from "contexts/liveMonitorGameContext";
const MemoedEnglishLeague = memo(EnglishLeague);

const getStatus = (event) => (event ? Object.entries(GAME_STATUSES).find(([key, value]) => value === event.status)?.[0] || "EMPTY" : "EMPTY");
const getDefaultState = () => ({ calculatedData: null, groupsInPagesSuggested: [] });
const getJackpotInfo = (state) => state.bonuses.jackpot.data;

const LiveMonitorGame = () => {
	const liveMonitorGameContextValue = useContext(LiveMonitorGameContext);
	const [monitorState, setMonitorState] = useState({ monitorEvent: null, monitorSpecial: null, timeoutsConfig: null });
	const current = useSelector((state) => state.game.current);
	const matches = useSelector((state) => state.game.matches.data);
	const currentSeason = useSelector((state) => state.season.special || null);
	const currentMatch = matches.find((m) => m.id === current) || null;
	const nextMatch = useSelector((state) => state.game.liveAndUpcomings.data.find((m) => m.id > current) || null);
	const jackpotInfo = useSelector(getJackpotInfo);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const monitorEvent = currentMatch?.event || null;
			const monitorSpecial = currentSeason;
			const event = nextMatch || monitorEvent;
			const timeoutsConfig = event ? getScreensAnimationTimouts(event) : null;
			setMonitorState({ monitorEvent, monitorSpecial, timeoutsConfig });
		}, 100);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [currentMatch, currentSeason, nextMatch]);

	return (
		liveMonitorGameContextValue?.type === GAME_TYPE.ENGLISH_LEAGUE
			? (
				<MemoedEnglishLeague
					current={current}
					{...monitorState}
					jackpotInfo={jackpotInfo}
				/>
			)
			: null
	);
};
export default LiveMonitorGame;
