import ColorMarkets from "components/pages/main/markets/luckySix/markets/colorMarkets.jsx";
import { LUCKY_SIX_MARKETS } from "constants/game.constants.js";
import OddMarkets from "components/pages/main/markets/luckySix/markets/oddMarkets/index.jsx";
import { useTranslation } from "react-i18next";

const SideBets = ({ currentEvent, markets }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--lucky-six-container-other-market vs--flex">
			<div className="vs--lucky-six-container-other-market-block vs--flex vs--flex-col">
				<ColorMarkets group={LUCKY_SIX_MARKETS.FIRST_BALL_COLOR} markets={markets} currentEvent={currentEvent} />

				<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
					<span className="vs--font-exstrasmall vs--font-medium">{t("common.firstNumber").toUpperCase()}</span>

					<div className="vs--lucky-six-container-other-market-block-content-odd vs--flex vs--align-center vs--justify-center vs--flex-wrap">
						<OddMarkets
							group={LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN}
							markets={markets}
							currentEvent={currentEvent}
						/>

						<OddMarkets
							group={LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER}
							markets={markets}
							currentEvent={currentEvent}
						/>
					</div>
				</div>

				<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
					<span className="vs--font-exstrasmall vs--font-medium">{t("common.preNumbers").toUpperCase()}</span>

					<div className="vs--lucky-six-container-other-market-block-content-odd vs--flex vs--align-center vs--justify-center vs--flex-wrap">
						<OddMarkets
							group={LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN}
							markets={markets}
							currentEvent={currentEvent}
						/>

						<OddMarkets
							group={LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER}
							markets={markets}
							currentEvent={currentEvent}
						/>
					</div>
				</div>
			</div>

			<div className="vs--lucky-six-container-other-market-block vs--flex vs--flex-col">
				<ColorMarkets group={LUCKY_SIX_MARKETS.LAST_BALL_COLOR} markets={markets} currentEvent={currentEvent} />

				<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
					<span className="vs--font-exstrasmall vs--font-medium">{t("common.lastNumber").toUpperCase()}</span>

					<div className="vs--lucky-six-container-other-market-block-content-odd vs--flex vs--align-center vs--justify-center vs--flex-wrap">
						<OddMarkets
							group={LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN}
							markets={markets}
							currentEvent={currentEvent}
						/>

						<OddMarkets
							group={LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER}
							markets={markets}
							currentEvent={currentEvent}
						/>
					</div>
				</div>

				<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
					<span className="vs--font-exstrasmall vs--font-medium">{t("common.mostlyDrawn").toUpperCase()}</span>
					<div className="vs--lucky-six-container-other-market-block-content-odd vs--lucky-six-container-other-market-block-content-odd-min vs--flex vs--align-center vs--justify-center vs--flex-wrap">
						<OddMarkets
							group={LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN}
							markets={markets}
							currentEvent={currentEvent}
						/>
					</div>
				</div>
				<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
					<span className="vs--font-exstrasmall vs--font-medium">{t(`markets.${currentEvent.gameType}.firstLastNumberSum`).toUpperCase()}</span>
					<div className="vs--lucky-six-container-other-market-block-content-odd vs--lucky-six-container-other-market-block-content-odd-min vs--flex vs--align-center vs--justify-center vs--flex-wrap">
						<OddMarkets
							group={LUCKY_SIX_MARKETS.FIRST_LAST_NUMBER_SUM}
							markets={markets}
							currentEvent={currentEvent}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideBets;
