import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import {
	setCurrentGame,
	getLiveAndUpcomingsLiveMonitor,
	removeFromLiveAndUpcomingsLiveMonitor,
	updateFromLiveAndUpcomingsLiveMonitor,
	addLiveAndUpcomingsLiveMonitor,
	getEvent,
	getSpinToWinStatistics
} from "store/actions/game/game.actions";
import { getActiveJackpot, updateJackpotBonus, setWonJackpotBonuses } from "store/actions/bonuses/jackpot.actions";
import { getGameRTPs } from "store/actions/auth/auth.actions";

import { GAME_STATUSES, GAME_TYPE } from "constants/game.constants";
import useForceUpdate from "hooks/useForceUpdate";
import useEvent from "hooks/useEvent";
import {
	CASHIER_API_INVOKE_EVENTS,
	CASHIER_API_LISTENER_EVENTS,
	JOB_API_INVOKE_EVENTS,
	JOB_API_LISTENER_EVENTS
} from "constants/webSocket.contants";
import WebSocketService from "services/webSocket";
import { isNullish } from "utils/common";

/* Spin To Win Wrapper Container Component - Wrapper */
const Spin2WinWrapper = ({
	getLiveAndUpcomingsLiveMonitor,
	removeFromLiveAndUpcomingsLiveMonitor,
	updateFromLiveAndUpcomingsLiveMonitor,
	addLiveAndUpcomingsLiveMonitor,
	setCurrentGame,
	getGameRTPs,
	decodedData,
	getActiveJackpot,
	updateJackpotBonus,
	setWonJackpotBonuses,
	getEvent,
	getSpinToWinStatistics
}) => {
	const token = useSelector((state) => state.auth?.stream?.access?.wsToken ?? null);
	const [cashierForceUpdate, cashierForceUpdateState] = useForceUpdate();
	const [jobForceUpdate, jobForceUpdateState] = useForceUpdate();
	const game = decodedData?.spinToWinGame ?? null;
	const activeJackpotId = decodedData?.activeJackpotId ?? null;
	const current = useSelector((state) => state.game.current);
	const currentEvent = useSelector((state) => state.game.matches.data.find((m) => m.id === current) || null);

	const onJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		updateJackpotBonus(d);
	});

	const onWonJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		setWonJackpotBonuses(d);
	});

	const onEvents = useEvent((data) => {
		const parsedData = JSON.parse(data);

		if (parsedData.gameType !== GAME_TYPE.SPIN_TO_WIN) {
			return;
		}

		if (parsedData.status === GAME_STATUSES.FINISHED) {
			getSpinToWinStatistics(false, true)
			removeFromLiveAndUpcomingsLiveMonitor(parsedData.id);
		} else if (
			parsedData.status === GAME_STATUSES.STARTED ||
			parsedData.status === GAME_STATUSES.CLOSE_FOR_BETTING ||
			parsedData.status === GAME_STATUSES.PREAMBLE_STARTED
		) {
			updateFromLiveAndUpcomingsLiveMonitor(parsedData);
		} else if (parsedData.status === GAME_STATUSES.NEW) {
			addLiveAndUpcomingsLiveMonitor(parsedData);
		}
	});

	const handleCashierWSEvent = useEvent(
		/** @type {WebSocketSetupCallback} */ (wsServiceInstance) => {
			if (!wsServiceInstance.isConnected) {
				return;
			}

			wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.JACKPOTS);
			wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS);

			wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.JACKPOTS, onJackpots);
			wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS, onWonJackpots);

			cashierForceUpdate();
		}
	);

	const handleJobWSEvent = useEvent(
		/** @type {WebSocketSetupCallback} */ (wsServiceInstance) => {
			if (!wsServiceInstance.isConnected) {
				return;
			}

			wsServiceInstance.off(JOB_API_LISTENER_EVENTS.EVENTS);

			wsServiceInstance.on(JOB_API_LISTENER_EVENTS.EVENTS, onEvents);

			jobForceUpdate();
		}
	);

	// Create connections
	const { cashierWS, jobWS } = useMemo(
		/** @return {WebSocketConnections} */ () => {
			/** @type {WebSocketConnections} */
			const wsServices = {
				cashierWS: null,
				jobWS: null
			};

			if (!token) {
				return wsServices;
			}

			const urlCashierWS = `${import.meta.env.SYSTEM_WS_URL_CASHIER}?accessToken=${token}`;
			const urlJobWS = `${import.meta.env.SYSTEM_WS_URL_JOBS}?accessToken=${token}`;

			wsServices.cashierWS = new WebSocketService(urlCashierWS, handleCashierWSEvent);
			wsServices.jobWS = new WebSocketService(urlJobWS, handleJobWSEvent);

			return wsServices;
		},
		[token]
	);

	// start / stop connection by document visibility
	useEffect(() => {
		if (!token || isNullish(cashierWS) || isNullish(jobWS)) {
			return;
		}

		const visibilityChangeListener = () => {
			if (document.hidden) {
				return;
			}
			cashierWS.startConnection();
			jobWS.startConnection();
		};

		document.addEventListener("visibilitychange", visibilityChangeListener);

		return () => {
			cashierWS.stopConnection();
			jobWS.stopConnection();

			document.removeEventListener("visibilitychange", visibilityChangeListener);
		};
	}, [token, cashierWS, jobWS]);

	// Watch all jackpot updates
	useEffect(() => {
		if (!activeJackpotId || !cashierWS?.isConnected) {
			return;
		}
		const invokeJackpotsMessage = `${CASHIER_API_LISTENER_EVENTS.JACKPOTS}_${activeJackpotId}`;
		cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.SUBSCRIBE, invokeJackpotsMessage);
		return () => {
			if (!cashierWS?.isConnected) {
				return;
			}
			cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeJackpotsMessage);
		};
	}, [activeJackpotId, cashierWS, cashierForceUpdateState]);

	// Watch all event updates
	useEffect(() => {
		if (!token || !jobWS.isConnected || !game) {
			return;
		}
		const invokeMessage = `${JOB_API_LISTENER_EVENTS.EVENTS}_${game.type}_${game.id}`;
		jobWS.invoke(JOB_API_INVOKE_EVENTS.SUBSCRIBE, invokeMessage);
		return () => {
			if (!jobWS?.isConnected) {
				return;
			}
			jobWS.invoke(JOB_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeMessage);
		};
	}, [token, jobWS, jobForceUpdateState, game]);

	useEffect(() => {
		setCurrentGame(GAME_TYPE.SPIN_TO_WIN);
	}, []);

	/** Subscribe to signalR when session loaded,
	 * Load live and upcomings, rtps
	 * */
	useEffect(() => {
		getLiveAndUpcomingsLiveMonitor(true);
		getSpinToWinStatistics(false, true)
		if (game?.id) {
			getGameRTPs(game.id);
		}
	}, [game?.id]);

	useEffect(() => {
		if (activeJackpotId !== null) {
			getActiveJackpot(true);
		}
	}, [activeJackpotId]);

	/** Load match details on current match change */
	useEffect(() => {
		if (current) {
			if (!currentEvent) {
				getEvent(current, true);
			}
		}
	}, [current]);

	return <Outlet context={{ decodedData }} />;
};

/** Spin2WinWrapper propTypes
 * PropTypes
 */
Spin2WinWrapper.propTypes = {
	/** Redux action to get live and upcoming matches */
	getLiveAndUpcomingsLiveMonitor: PropTypes.func,
	/** Redux action to remove match from live and upcomings matches */
	removeFromLiveAndUpcomingsLiveMonitor: PropTypes.func,
	/** Redux action to update match in live and upcomings matches */
	updateFromLiveAndUpcomingsLiveMonitor: PropTypes.func,
	/** Redux action to add match to live and upcomings matches */
	addLiveAndUpcomingsLiveMonitor: PropTypes.func,
	/** Redux action to set current game */
	setCurrentGame: PropTypes.func,
	/** React property, decoded data taken from url */
	decodedData: PropTypes.object,
	/** Redux action to get game rtps */
	getGameRTPs: PropTypes.func,
	/** Redux action to get Active Jackpot Info */
	getActiveJackpot: PropTypes.func,
	/** Redux action to update jackpot info */
	updateJackpotBonus: PropTypes.func,
	/** Redux action, to set won Jackpots */
	setWonJackpotBonuses: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getLiveAndUpcomingsLiveMonitor: (fromStream) => {
		dispatch(getLiveAndUpcomingsLiveMonitor(fromStream));
	},
	removeFromLiveAndUpcomingsLiveMonitor: (id) => {
		dispatch(removeFromLiveAndUpcomingsLiveMonitor(id));
	},
	updateFromLiveAndUpcomingsLiveMonitor: (game) => {
		dispatch(updateFromLiveAndUpcomingsLiveMonitor(game));
	},
	addLiveAndUpcomingsLiveMonitor: (game) => {
		dispatch(addLiveAndUpcomingsLiveMonitor(game));
	},
	getEvent: (eventId, fromStream) => {
		dispatch(getEvent(eventId, fromStream));
	},
	setCurrentGame: (type) => {
		dispatch(setCurrentGame(type));
	},
	getGameRTPs: (id, fromStream) => {
		dispatch(getGameRTPs(id, fromStream));
	},
	getActiveJackpot: (fromStream) => {
		dispatch(getActiveJackpot(fromStream));
	},
	updateJackpotBonus: (data) => {
		dispatch(updateJackpotBonus(data));
	},
	setWonJackpotBonuses: (data) => {
		dispatch(setWonJackpotBonuses(data));
	},
	getSpinToWinStatistics: (allTime, fromStream) => {
		dispatch(getSpinToWinStatistics(allTime, fromStream))
	}
});

export default connect(null, mapDispatchToProps)(Spin2WinWrapper);
