import { useEffect, useRef } from "react";
import FlowField from "./flowField";
import "./styles.scss"

const StreamBackground = () => {

	const ref = useRef()

	useEffect(() => {
		const settings = {
			frequency: 0.1,
		}

		const canvas = ref.current;
		const ff = new FlowField({ canvas, settings });

		ff.start()
		return () => {
			ff.stop()
		}
	}, [])

	return (
		<div className="vs--spin-2-win-stream-background">
			<div className="vs--spin-2-win-stream-flow-field-container">
				<canvas ref={ref} className="vs--spin-2-win-stream-flow-field" width="400" height="400" />
			</div>
		</div>
	)
}
export default StreamBackground