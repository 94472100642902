
import { useEffect, useMemo } from "react";
import { GAME_TYPE, LUCKY_SIX_MARKETS } from "constants/game.constants";
import { useSelector, useDispatch } from "react-redux";
import { getEvent } from "store/actions/game/game.actions";
import { RESULTS } from "constants/game.constants";

import BetColorButton from "components/pages/main/markets/luckySix/selectColor/betColorButton";
import resultType from "types/result.type";
import Ball from "components/pages/main/markets/luckySix/ball/ball";

import { useTranslation } from "react-i18next";


const LuckySixResultComponent = ({ item }) => {

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const scenes = item?.gameData?.scenes ?? [];

	const matches = useSelector((state) => state.game.matches.data);

	const sessionGames = useSelector((state) => state.auth.session.games);

	useEffect(() => {
		if (item?.id) {
			dispatch(getEvent(item.id));
		}
	}, [item?.id]);

	const event = useMemo(() => {
		return matches.find(event => event.id === item.id)
	}, [item.id, matches])

	const resultMarkets = event?.event?.markets ?? [];

	const firstBallColor = useMemo(
		() =>
			resultMarkets
				.find((market) => market.group === LUCKY_SIX_MARKETS.FIRST_BALL_COLOR)
				?.oddList?.find((odd) => odd.state === RESULTS.WIN),
		[resultMarkets]
	);

	const lastBallColor = useMemo(
		() =>
			resultMarkets
				.find((market) => market.group === LUCKY_SIX_MARKETS.LAST_BALL_COLOR)
				?.oddList?.find((odd) => odd.state === RESULTS.WIN),
		[resultMarkets]
	);

	const numberOverUnderFirst = useMemo(() => {
		const overUnderMarket =
			resultMarkets.find((market) => market.group === LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER) || {};

		const { argument } = overUnderMarket;
		const { outcome, factor } = overUnderMarket.oddList?.find((odd) => odd.state === RESULTS.WIN) || {};

		return {
			argument,
			outcome,
			factor,
		};
	}, [resultMarkets]);

	const numberOverUnderLast = useMemo(() => {
		const overUnderMarket =
			resultMarkets.find((market) => market.group === LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER) || {};

		const { argument } = overUnderMarket;
		const { outcome, factor } = overUnderMarket.oddList?.find((odd) => odd.state === RESULTS.WIN) || {};

		return {
			argument,
			outcome,
			factor,
		};
	}, [resultMarkets]);


	const preNumberOddEven = useMemo(() => {
		const markets = resultMarkets.find((market) => {
			return market.group === LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN;
		});
		const winningMarket = markets?.oddList?.find((odd) => odd.state === RESULTS.WIN);

		return {
			factor: winningMarket?.factor,
			outcome: winningMarket?.outcome
		};
	}, [resultMarkets]);


	const preNumberOverUnder = useMemo(() => {
		const markets = resultMarkets.find((market) => {
			return market.group === LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER;
		});
		const winningMarket = markets?.oddList?.find((odd) => odd.state === RESULTS.WIN);

		return {
			argument: markets?.argument,
			factor: winningMarket?.factor,
			outcome: winningMarket?.outcome
		};
	}, [resultMarkets]);

	const firstLastNumberSum = useMemo(() => {
		const markets = resultMarkets.find((market) => {
			return market.group === LUCKY_SIX_MARKETS.FIRST_LAST_NUMBER_SUM;
		});
		const winningMarket = markets?.oddList?.find((odd) => odd.state === RESULTS.WIN);

		return {
			factor: winningMarket?.factor,
			outcome: winningMarket?.outcome,
			argument: markets?.argument
		};
	}, [resultMarkets]);


	const mostlyDrawnNumberOddEven = useMemo(() => {
		const markets = resultMarkets.find((market) => {
			return market.group === LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN;
		});
		const winningMarket = markets?.oddList?.find((odd) => odd.state === RESULTS.WIN);
		return {
			factor: winningMarket?.factor,
			outcome: winningMarket?.outcome === "Yes" ? "EVEN" : "ODD"
		};
	}, [resultMarkets]);


	const numberOddEvenFirst = useMemo(
		() =>
			resultMarkets
				.find((market) => market.group === LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN)
				?.oddList?.find((odd) => odd.state === RESULTS.WIN),
		[resultMarkets]
	);

	const numberOddEvenLast = useMemo(
		() =>
			resultMarkets
				.find((market) => market.group === LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN)
				?.oddList?.find((odd) => odd.state === RESULTS.WIN),
		[resultMarkets]
	);

	const luckySixGame = useMemo(() => sessionGames.find(g => g.type === GAME_TYPE.LUCKY_SIX), [sessionGames]);

	const luckySixRtPs = luckySixGame ? luckySixGame.rtPs : [];


	return (
		<div className="vs--lucky-six-inner-result vs--flex vs--pt-12 vs--pr-18 vs--pb-12 vs--pl-18">
			<div className="vs--flex vs--flex-col">
				<div className="vs--lucky-six-details vs--flex vs--mb-8">
					{scenes
						.slice(0, 5)
						.map((sc) => sc.number)
						.map((number) => (
							<Ball number={number} active />
						))}
				</div>

				<div className="vs--lucky-six-details-bottom vs--flex vs--flex-wrap">
					{
						scenes
							.slice(5)
							.map(
								(scene, index) => (
									<div className="vs--lucky-six-details-bottom-block vs--flex vs--flex-col vs--align-center vs--justify-center vs--pt-2" key={scene.number}>
										<Ball number={scene.number} active />
										<span className="vs--lucky-six-details-bottom-margin">{luckySixRtPs[index]?.margin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}x</span>
									</div>
								)
							)
					}
				</div>
			</div>

			<div className="vs--lucky-six-inner-result-divider"></div>

			<div className="vs--lucky-six-result-other vs--flex vs--flex-col">
				<div className="vs--lucky-six-result-number-section vs--flex vs--align-center">
					<span className="vs--lucky-six-result-number-section-title vs--mr-12">{t("common.firstNumber")}</span>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<BetColorButton color={firstBallColor?.outcome.toLowerCase()} />
						<span className="vs--lucky-six-result-number-section-block-title">{firstBallColor?.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{firstBallColor?.factor}</span>
					</div>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">{numberOverUnderFirst?.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-title">{numberOverUnderFirst?.argument}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{numberOverUnderFirst?.factor}</span>
					</div>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">{numberOddEvenFirst?.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{numberOddEvenFirst?.factor}</span>
					</div>
				</div>

				<div className="vs--lucky-six-result-number-section vs--flex vs--align-center">
					<span className="vs--lucky-six-result-number-section-title vs--mr-12">{t("common.preNumbers")}</span>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">
							{preNumberOverUnder.outcome} {preNumberOverUnder.argument}
						</span>
						<span className="vs--lucky-six-result-number-section-block-value">{preNumberOverUnder.factor}</span>
					</div>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">{preNumberOddEven.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{preNumberOddEven.factor}</span>
					</div>
				</div>

				<div className="vs--lucky-six-result-number-section vs--flex vs--align-center">
					<span className="vs--lucky-six-result-number-section-title vs--mr-12">{t("common.lastNumber")}</span>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<BetColorButton color={lastBallColor?.outcome.toLowerCase()} />
						<span className="vs--lucky-six-result-number-section-block-title">{lastBallColor?.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{lastBallColor?.factor}</span>
					</div>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">{numberOverUnderLast?.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-title">{numberOverUnderLast?.argument}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{numberOverUnderLast?.factor}</span>
					</div>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">{numberOddEvenLast?.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{numberOddEvenLast?.factor}</span>
					</div>
				</div>
				<div className="vs--lucky-six-result-number-section vs--flex vs--align-center">
					<span className="vs--lucky-six-result-number-section-title vs--mr-12">{t(`markets.${GAME_TYPE.LUCKY_SIX}.firstLastNumberSum`)}</span>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">{firstLastNumberSum?.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-title">{firstLastNumberSum?.argument}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{firstLastNumberSum?.factor}</span>
					</div>
				</div>
				<div className="vs--lucky-six-result-number-section vs--flex vs--align-center">
					<span className="vs--lucky-six-result-number-section-title vs--mr-12">{t("common.mostlyDrawn")}</span>

					<div className="vs--lucky-six-result-number-section-block vs--flex vs--align-center">
						<span className="vs--lucky-six-result-number-section-block-title">{mostlyDrawnNumberOddEven.outcome}</span>
						<span className="vs--lucky-six-result-number-section-block-value">{mostlyDrawnNumberOddEven.factor}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

LuckySixResultComponent.propTypes = {
	item: resultType,
};

export default LuckySixResultComponent;
