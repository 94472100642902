import { connect, useSelector } from "react-redux";
import { useMemo } from "react";
import { GAME_TYPE } from "constants/game.constants.js";
import PropTypes from "prop-types";
import Paytable from "components/pages/luckySix/newGameFlow/components/paytable";
import Markets from "components/pages/luckySix/newGameFlow/components/markets";
import LastGameStatistic from "components/pages/luckySix/newGameFlow/components/lastGameStatistic";

const NewGameFlow = ({ sessionGames }) => {
	const rtPs = useSelector((state) => state.auth.stream.rtps);

	return (
		<div className="vs--lucky-six-live-monitor-container-new-game-flow-container">
			<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group">
				<Paytable luckySixRtPs={rtPs} />
				<Markets luckySixRtPs={rtPs} />
			</div>
			<LastGameStatistic />
		</div>
	);
};

NewGameFlow.propTypes = {
	sessionGames: PropTypes.array
};

export default NewGameFlow;
