import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { LUCKY_SIX_MARKETS } from "constants/game.constants";
import { findMarketWinnerByGroup } from "components/pages/luckySix/components/markets/helpers";

const FirstAndLastMarket = ({ currentEventScenes, markets }) => {
	const { t } = useTranslation();

	const firstBall = currentEventScenes?.at(0);
	const lastBall = currentEventScenes?.at(-1);
	const firstLastResult = firstBall?.number + lastBall?.number;
	const overUnder = findMarketWinnerByGroup(LUCKY_SIX_MARKETS.FIRST_LAST_NUMBER_SUM, markets)?.outcome;

	return (
		<>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-name">
				{`${t("common.first")} + ${t("common.last")}`}:
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-number">
				{firstLastResult}
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				- {overUnder}
			</span>
		</>
	);
};

FirstAndLastMarket.propTypes = {
	currentEventScenes: PropTypes.array,
	markets: PropTypes.array
};

export default FirstAndLastMarket;
