import { mergeClassNames } from "utils/common"
import SceneFlowItem from "./sceneFlowItem"
import ScenesFlowContext from "./scenesFlowContext"
import useSceneFlowProcess from "./useSceneFlowProcess"
import "./styles.scss"

const SceneFlow = ({ defaultItemKey = null, imperativeHandlerRef, className, children, ...props }) => {
	const providerValue = useSceneFlowProcess({ defaultItemKey, imperativeHandlerRef });
	return (
		<ScenesFlowContext.Provider value={providerValue}>
			<div className={mergeClassNames("vs--scene-flow", className)} data-mounting-scene={providerValue.mountingScene} {...props}>
				{children}
			</div>
		</ScenesFlowContext.Provider>
	)
}

SceneFlow.Item = SceneFlowItem;

export default SceneFlow
