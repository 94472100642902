import React from "react";


export const LUCKY_SIX_LIVE_BALL_COLORS = [
	{
		stroke: "#666666",
		stop1: "#484848",
		stop2: "#000000"
	},
	{
		stroke: "#FF075A",
		stop1: "#FF0055",
		stop2: "#330011"
	},
	{
		stroke: "#E96B2C",
		stop1: "#FF7733",
		stop2: "#7C300A"
	},
	{
		stroke: "#E9D223",
		stop1: "#FFEA2A",
		stop2: "#795700"
	},
	{
		stroke: "#10FF21",
		stop1: "#0DFF1F",
		stop2: "#003304"
	},
	{
		stroke: "#02B9C7",
		stop1: "#03D5E5",
		stop2: "#002F33"
	},
	{
		stroke: "#4F00FB",
		stop1: "#661FFF",
		stop2: "#210066"
	},
	{
		stroke: "#CE0CFF",
		stop1: "#C600F7",
		stop2: "#4A045C"
	}
];

const LiveBall = ({ ballNumber }) => {
	const { stroke, stop1, stop2 } = LUCKY_SIX_LIVE_BALL_COLORS[ballNumber % 8] || {};

	return (
		<div>
		<svg
			className="vs--lucky-six-ball-svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="vs--lucky-six-ball-svg-border"
				opacity="0.7"
				d="M24.0002 45.6002C35.9295 45.6002 45.6002 35.9295 45.6002 24.0002C45.6002 12.0708 35.9295 2.40015 24.0002 2.40015C12.0708 2.40015 2.40015 12.0708 2.40015 24.0002C2.40015 35.9295 12.0708 45.6002 24.0002 45.6002Z"
				stroke="black"
				stroke-width="3.6"
				stroke-linecap="round"
			/>
			<path
				className="vs--lucky-six-ball-svg-border"
				d="M24 46.8001C36.592 46.8001 46.8 36.5922 46.8 24.0001C46.8 11.408 36.592 1.20007 24 1.20007C11.4079 1.20007 1.19995 11.408 1.19995 24.0001C1.19995 36.5922 11.4079 46.8001 24 46.8001Z"
				stroke={stroke}
				stroke-width="1.2"
				stroke-linecap="round"	
			/>
			<path
				d="M23.9999 44.6667C35.4138 44.6667 44.6666 35.4139 44.6666 24C44.6666 12.5862 35.4138 3.33337 23.9999 3.33337C12.586 3.33337 3.33325 12.5862 3.33325 24C3.33325 35.4139 12.586 44.6667 23.9999 44.6667Z"
				fill={`url(#paint0_linear_5526_58528${ballNumber})`}
			/>
			<mask
				id="path-5-outside-1_5526_58528"
				maskUnits="userSpaceOnUse"
				x="10.6665"
				y="14.6669"
				width="27"
				height="20"
				fill="black"
			>
				<rect fill="white" x="10.6665" y="14.6669" width="27" height="20" />
				<path d="M18.4137 15.3986H21.9431V33.6669H18.4137V26.4669H15.1101C12.776 26.4669 11.609 25.2245 11.609 22.7398V15.3986H15.1101V22.5704C15.1101 22.721 15.1572 22.8528 15.2513 22.9657C15.3643 23.0786 15.496 23.1351 15.6466 23.1351H17.8772C18.0278 23.1351 18.1501 23.0786 18.2443 22.9657C18.3572 22.8528 18.4137 22.721 18.4137 22.5704V15.3986ZM29.6456 18.4198V21.6669C29.6456 21.8175 29.6926 21.9492 29.7868 22.0622C29.8997 22.1751 30.0315 22.2316 30.182 22.2316H32.8644C35.1985 22.2316 36.3656 23.4739 36.3656 25.9586V30.2222C36.3656 32.7069 35.1985 33.9492 32.8644 33.9492H29.7303C27.3962 33.9492 26.2291 32.7069 26.2291 30.2222V28.8669H29.9844V30.3069C29.9844 30.4575 30.0315 30.5892 30.1256 30.7022C30.2385 30.8151 30.3703 30.8716 30.5209 30.8716H32.1868C32.3373 30.8716 32.4597 30.8151 32.5538 30.7022C32.6668 30.5892 32.7232 30.4575 32.7232 30.3069V26.2692C32.7232 26.1186 32.6668 25.9869 32.5538 25.8739C32.4597 25.761 32.3373 25.7045 32.1868 25.7045H29.5326C27.1985 25.7045 26.0315 24.4622 26.0315 21.9775V15.3986H35.6315V18.4198H29.6456Z" />
			</mask>
			<ellipse
				opacity="0.8"
				cx="31.7148"
				cy="13.4599"
				rx="7.44609"
				ry="14.8555"
				transform="rotate(-53.7185 31.7148 13.4599)"
				fill="url(#paint2_radial_5526_58528)"
			/>
			<path
				opacity="0.35"
				d="M27.3313 20.9981C36.3876 27.8537 33.2985 42.4463 33.2985 42.4463C27.3313 45.3335 17.3048 45.8378 10.2202 39.3854C3.13569 32.9331 2.00008 24.0002 4.83695 16.2748C4.83695 16.2748 17.426 13.4999 27.3313 20.9981Z"
				fill="url(#paint3_linear_5526_58528)"
			/>

			<text
				x="50%"
				y="54%"
				dominantBaseline="middle"
				textAnchor="middle"
				fill="#ffffff"
				stroke="url(#textStrokeGradient)"
				strokeWidth="0.7"
				className="vs--lucky-six-live-content-ball-num"
			>
				{ballNumber}
			</text>

			<defs>
				<linearGradient
					id={`paint0_linear_5526_58528${ballNumber}`}
					x1="44.6666"
					y1="3.33337"
					x2="3.33325"
					y2="44.6667"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={stop1} />
					<stop offset="1" stopColor={stop2} />
				</linearGradient>

				<radialGradient
					id="paint1_radial_5526_58528"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(23.9998 24.0002) rotate(89.9995) scale(20.6667)"
				>
					<stop stop-color="#666666" />
					<stop offset="1" />
				</radialGradient>

				<radialGradient
					id="paint2_radial_5526_58528"
					cx="0"
					cy="0"
					r="1"
					gradientUnits="userSpaceOnUse"
					gradientTransform="translate(32.5749 13.561) rotate(93.3365) scale(14.7794 6.37516)"
				>
					<stop offset="0.306595" stop-color="white" stop-opacity="0.5" />
					<stop offset="1" stop-color="white" stop-opacity="0" />
				</radialGradient>

				<linearGradient
					id="paint3_linear_5526_58528"
					x1="10.3334"
					y1="38.6668"
					x2="21.5757"
					y2="25.7195"
					gradientUnits="userSpaceOnUse"
				>
					<stop />
					<stop offset="0.704542" stop-opacity="0" />
				</linearGradient>

				<radialGradient id="textStrokeGradient" cx="50%" cy="50%" r="50%">
					<stop offset="0%" stopColor="#666666" />
					<stop offset="100%" stopColor="#000000" />
				</radialGradient>
			</defs>
		</svg>


		<svg className="vs--lucky-six-ball-svg-shadow" width="94" height="93" viewBox="0 0 94 93" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path opacity="0.02" d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z" stroke={stroke} stroke-width="40.4673" stroke-linecap="round"/>
				<path opacity="0.03" d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z"  stroke={stroke} stroke-width="35.6113" stroke-linecap="round"/>
				<path opacity="0.05" d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z"  stroke={stroke} stroke-width="30.7552" stroke-linecap="round"/>
				<path opacity="0.07" d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z"  stroke={stroke} stroke-width="25.8991" stroke-linecap="round"/>
				<path opacity="0.1" d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z"  stroke={stroke} stroke-width="21.043" stroke-linecap="round"/>
				<path opacity="0.2" d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z"  stroke={stroke} stroke-width="16.1869" stroke-linecap="round"/>
				<path opacity="0.3" d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z"  stroke={stroke} stroke-width="11.3309" stroke-linecap="round"/>
				<path d="M73.1615 46.6068C73.1615 32.1553 61.4462 20.4401 46.9948 20.4401C32.5433 20.4401 20.8281 32.1553 20.8281 46.6068C20.8281 61.0582 32.5433 72.7734 46.9948 72.7734C61.4462 72.7734 73.1615 61.0582 73.1615 46.6068Z"  stroke={stroke} stroke-width="6.47478" stroke-linecap="round"/>
				<path opacity="0.7" d="M46.9947 73.1008C61.6268 73.1008 73.4885 61.2391 73.4885 46.607C73.4885 31.9749 61.6268 20.1133 46.9947 20.1133C32.3626 20.1133 20.501 31.9749 20.501 46.607C20.501 61.2391 32.3626 73.1008 46.9947 73.1008Z" stroke="black" stroke-width="4.41563" stroke-linecap="round"/>
				<path d="M46.9944 74.5728C62.4394 74.5728 74.9601 62.0522 74.9601 46.6072C74.9601 31.1622 62.4394 18.6416 46.9944 18.6416C31.5494 18.6416 19.0288 31.1622 19.0288 46.6072C19.0288 62.0522 31.5494 74.5728 46.9944 74.5728Z"  stroke={stroke} stroke-width="1.47187" stroke-linecap="round"/>
				<path d="M46.9945 71.9557C60.9943 71.9557 72.3434 60.6066 72.3434 46.6068C72.3434 32.6069 60.9943 21.2578 46.9945 21.2578C32.9946 21.2578 21.6455 32.6069 21.6455 46.6068C21.6455 60.6066 32.9946 71.9557 46.9945 71.9557Z" fill="url(#paint0_linear_5402_16090)"/>
				
				<mask id="path-13-outside-1_5402_16090" maskUnits="userSpaceOnUse" x="39.2808" y="33.8936" width="15" height="26" fill="black">
					<rect fill="white" x="39.2808" y="33.8936" width="15" height="26"/>
					<path d="M46.6293 49.0623H44.8977C42.0348 49.0623 40.6033 47.5385 40.6033 44.4908V39.7116C40.6033 36.6639 42.0348 35.1401 44.8977 35.1401H48.9843C51.8473 35.1401 53.2787 36.6639 53.2787 39.7116V53.6684C53.2787 56.7161 51.8473 58.2399 48.9843 58.2399H45.244C42.3811 58.2399 40.9496 56.7161 40.9496 53.6684V52.0061H45.3825V53.7723C45.3825 53.957 45.4403 54.1186 45.5557 54.2572C45.6942 54.3957 45.8559 54.465 46.0406 54.465H48.2917C48.4764 54.465 48.6264 54.3957 48.7419 54.2572C48.8804 54.1186 48.9497 53.957 48.9497 53.7723V48.6121C48.3263 48.9122 47.5528 49.0623 46.6293 49.0623ZM44.8977 40.0925V44.1099C44.8977 44.2946 44.9554 44.4562 45.0709 44.5947C45.2094 44.7333 45.371 44.8025 45.5557 44.8025H48.2917C48.4764 44.8025 48.6264 44.7333 48.7419 44.5947C48.8804 44.4562 48.9497 44.2946 48.9497 44.1099V40.0925C48.9497 39.9078 48.8804 39.7462 48.7419 39.6077C48.6264 39.4691 48.4764 39.3999 48.2917 39.3999H45.5557C45.371 39.3999 45.2094 39.4691 45.0709 39.6077C44.9554 39.7462 44.8977 39.9078 44.8977 40.0925Z"/>
				</mask>
			</svg>


		</div>
		

	);
};

export default LiveBall;
