import PropTypes from "prop-types";
import { NUMBER_TO_COLOR_MAPPER } from "constants/game.constants.js";
import BetBall from "components/pages/luckySix/components/betBall";

const BetGroupBalls = ({ numbers = [] }) => {
	return (
		<>
			{numbers.map((number, i) => {
				const colorClass = NUMBER_TO_COLOR_MAPPER[number % 8];

				return <BetBall key={i} number={number} colorClass={colorClass} />;
			})}
		</>
	);
};

BetGroupBalls.propTypes = {
	numbers: PropTypes.array.isRequired
};

export default BetGroupBalls;
