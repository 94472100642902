import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { COLORS_MAPPER } from "constants/game.constants.js";
import { Fragment, useEffect, useState } from "react";
import eventType from "types/event.type.js";

const MostlyDrawnMarket = ({ colors, currentEventScenes, title }) => {
	const { t } = useTranslation();
	const [evenCount, setEvenCount] = useState(0);
	const [oddCount, setOddCount] = useState(0);

	useEffect(() => {
		let evenCountLoc = 0;
		let oddCountLoc = 0;
		currentEventScenes?.forEach((gameData) => {
			if (gameData.number % 2 === 0) {
				evenCountLoc++;
			} else {
				oddCountLoc++;
			}
		});
		setEvenCount(evenCountLoc);
		setOddCount(oddCountLoc);
	}, [currentEventScenes]);

	return (
		<>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-name">
				{title || t("common.mostlyDrawn")}:
			</span>
			{colors && (
				<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-group">
					{Object.keys(colors).map((color) => {
						return (
							<div
								className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-group-color"
								key={color}
							>
								<div
									className={`vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-group-color-text`}
								>
									{colors[color]}
								</div>
								<div
									className={`vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-group-color-item height-${colors[color]} color-${COLORS_MAPPER[color]}`}
								></div>
							</div>
						);
					})}
				</div>
			)}
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				- {evenCount > oddCount ? t("common.even") : t("common.odd")}
			</span>
		</>
	);
};

MostlyDrawnMarket.propTypes = {
	colors: PropTypes.object,
	currentEventScenes: PropTypes.array,
	title: PropTypes.string
};

export default MostlyDrawnMarket;
