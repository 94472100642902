import { applyMiddleware, compose, legacy_createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "store/reducers/root.reducer";
import initialState from "store/initialState";

const configureStore = (preloadedState) => {
	return legacy_createStore(
		rootReducer(),
		preloadedState,
		...(window.__REDUX_DEVTOOLS_EXTENSION__
			? [compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__())]
			: [compose(applyMiddleware(thunk))])
	);
};

export default configureStore(initialState);
