import FirstMarket from "components/pages/luckySix/components/markets/firstMarket";
import FiveMarket from "components/pages/luckySix/components/markets/fiveMarket";
import MostlyDrawnMarket from "components/pages/luckySix/components/markets/mostlyDrawnMarket";
import LastMarket from "components/pages/luckySix/components/markets/lastMarket";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useMemo } from "react";

const StartedGameFlowFooter = ({ scenes }) => {
	const { t } = useTranslation();

	const firstMarketOverUnderOutcome = useMemo(() => {
		if (scenes?.[0]?.number) {
			return scenes?.[0]?.number > 24.5 ? t("common.over") : t("common.under");
		}
		return null;
	}, [scenes?.[0]?.number]);

	const firstMarketOddEvenOutcome = useMemo(() => {
		if (scenes?.[0]?.number) {
			return scenes?.[0]?.number % 2 === 0 ? t("common.even") : t("common.odd");
		}
		return null;
	}, [scenes?.[0]?.number]);

	const sumOfFiveNumbers = useMemo(() => {
		if (scenes.length >= 5) {
			return scenes.slice(0, 5).reduce((acc, cur) => {
				acc += cur.number;
				return acc;
			}, 0);
		}
		return null;
	}, [scenes]);

	const fiveMarketOverUnderOutcome = useMemo(() => {
		if (scenes?.[0]?.number) {
			return sumOfFiveNumbers > 122.5 ? t("common.over") : t("common.under");
		}
		return null;
	}, [sumOfFiveNumbers]);

	const fiveMarketOddEvenOutcome = useMemo(() => {
		if (sumOfFiveNumbers) {
			return sumOfFiveNumbers % 2 === 0 ? t("common.even") : t("common.odd");
		}
		return null;
	}, [sumOfFiveNumbers]);

	const colorsStatistic = useMemo(() => {
		let temp = {
			1: 0,
			2: 0,
			4: 0,
			8: 0,
			16: 0,
			32: 0,
			64: 0,
			128: 0
		};

		scenes.forEach((game) => {
			if (temp[game.ballColour]) {
				temp[game.ballColour] = temp[game.ballColour] + 1;
			} else {
				temp[game.ballColour] = 1;
			}
		});
		return temp;
	}, [scenes]);

	const lastMarketOverUnderOutcome = useMemo(() => {
		if (scenes?.[34]?.number) {
			return scenes?.[34]?.number > 24.5 ? t("common.over") : t("common.under");
		}
		return null;
	}, [scenes?.[34]?.number]);

	const lastMarketOddEvenOutcome = useMemo(() => {
		if (scenes?.[34]?.number) {
			return scenes?.[34]?.number % 2 === 0 ? t("common.even") : t("common.odd");
		}
		return null;
	}, [scenes?.[34]?.number]);

	return (
		<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer">
			<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-left">
				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-left-vector">
					<svg width="843" height="68" viewBox="0 0 843 68" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M741.209 2.34218L740.702 2H740.09H4H2V4V64V66H4H829H835.539L830.119 62.3422L741.209 2.34218Z"
							fill="#010C13"
							stroke="url(#paint0_linear_8328_4982)"
							strokeWidth="4"
						></path>

						<rect x="348.5" y="4" width="1" height="60" fill="#404040"></rect>

						<defs>
							<linearGradient
								id="paint0_linear_8328_4982"
								x1="3.9973"
								y1="34"
								x2="829.003"
								y2="34"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#825B48"></stop>
								<stop offset="0.0219" stopColor="#CD884F"></stop>
								<stop offset="0.0751" stopColor="#AD7769"></stop>
								<stop offset="0.1331" stopColor="#92697E"></stop>
								<stop offset="0.1965" stopColor="#7E5E8F"></stop>
								<stop offset="0.2683" stopColor="#70579A"></stop>
								<stop offset="0.3553" stopColor="#6852A1"></stop>
								<stop offset="0.5" stopColor="#6551A3"></stop>
								<stop offset="0.6396" stopColor="#6852A1"></stop>
								<stop offset="0.7235" stopColor="#70579A"></stop>
								<stop offset="0.7928" stopColor="#7E5E8F"></stop>
								<stop offset="0.854" stopColor="#93697E"></stop>
								<stop offset="0.91" stopColor="#AD7769"></stop>
								<stop offset="0.9613" stopColor="#CD8850"></stop>
								<stop offset="1" stopColor="#EA9838"></stop>
							</linearGradient>
						</defs>
					</svg>
				</div>
				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-left-group">
					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-left-group-item">
						<FirstMarket
							oddEvenOutcome={firstMarketOddEvenOutcome}
							overUnderOutcome={firstMarketOverUnderOutcome}
							markets={[]}
							currentEventScenes={scenes}
						/>
					</div>

					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-left-group-item">
						<FiveMarket
							oddEvenOutcome={fiveMarketOddEvenOutcome}
							overUnderOutcome={fiveMarketOverUnderOutcome}
							markets={[]}
							title={t("common.1stFive")}
							sumOfFiveNumbers={sumOfFiveNumbers}
						/>
					</div>
				</div>
			</div>
			<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-right">
				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-right-vector">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 843 68" height="68" width="843">
						<path
							strokeWidth="4"
							stroke="url(#paint0)"
							fill="#010C13"
							d="M101.791 2.342L102.298 2H102.91H839H841L841 4L841 64L841 66H839H14H7.461L12.8812 62.3422L101.791 2.34218Z"
						></path>
						<rect fill="#404040" height="60" width="1" y="4" x="501"></rect>

						<defs>
							<linearGradient
								gradientUnits="userSpaceOnUse"
								y2="34"
								x2="13.9973"
								y1="34"
								x1="839.003"
								id="paint0"
							>
								<stop stopColor="#885F43"></stop>
								<stop stopColor="#CD884F" offset="0.0219"></stop>
								<stop stopColor="#AD7769" offset="0.0751"></stop>
								<stop stopColor="#92697E" offset="0.1331"></stop>
								<stop stopColor="#7E5E8F" offset="0.1965"></stop>
								<stop stopColor="#70579A" offset="0.2683"></stop>
								<stop stopColor="#6852A1" offset="0.3553"></stop>
								<stop stopColor="#6551A3" offset="0.5"></stop>
								<stop stopColor="#6852A1" offset="0.6396"></stop>
								<stop stopColor="#70579A" offset="0.7235"></stop>
								<stop stopColor="#7E5E8F" offset="0.7928"></stop>
								<stop stopColor="#93697E" offset="0.854"></stop>
								<stop stopColor="#AD7769" offset="0.91"></stop>
								<stop stopColor="#CD8850" offset="0.9613"></stop>
								<stop stopColor="#EA9838" offset="1"></stop>
							</linearGradient>
						</defs>
					</svg>
				</div>
				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-right-group">
					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-right-group-item">
						<MostlyDrawnMarket colors={colorsStatistic} currentEventScenes={scenes} title={t("common.most")} />
					</div>
					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-footer-to-right-group-item">
						<LastMarket
							oddEvenOutcome={lastMarketOddEvenOutcome}
							overUnderOutcome={lastMarketOverUnderOutcome}
							markets={[]}
							currentEventScenes={scenes}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

StartedGameFlowFooter.propTypes = {
	scenes: PropTypes.array.isRequired
};

export default StartedGameFlowFooter;
