import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const slitArrayBySize = (array, size) => {
	let matr = [];
	for (let i = 0; i < array.length; i += size) {
		matr.push(array.slice(i, i + size));
	}

	return matr;
};

const Paytable = ({ luckySixRtPs }) => {
	const itemsRtPs = useMemo(() => {
		const numbersRtPs = luckySixRtPs.slice(0, 30);
		return slitArrayBySize(numbersRtPs, 5);
	}, [luckySixRtPs]);
	const { t } = useTranslation();

	return (
		<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-paytable">
			<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-paytable-title">
				{t("common.paytable")}
			</div>

			<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-paytable-table vs--flex">
				{itemsRtPs.map((itemsRtP, index) => (
					<div
						className={`vs--flex-col vs--lucky-six-live-monitor-container-new-game-flow-container-group-paytable-table-container index-${index}`}
						key={index}
					>
						{itemsRtP.map((item) => (
							<div
								className="vs--flex vs--lucky-six-live-monitor-container-new-game-flow-container-group-paytable-table-container-item"
								key={item.name}
							>
								<span className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-paytable-table-container-item-number">
									{item.name.split("-")[1]}
								</span>
								<span className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-paytable-table-container-item-coefficient">
									{item.margin}x
								</span>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

Paytable.propTypes = {
	luckySixRtPs: PropTypes.array
};

export default Paytable;
