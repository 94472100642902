import TopBoard from "components/pages/main/markets/spin2Win/topBoard/index.jsx";
import BottomBoard from "components/pages/main/markets/spin2Win/bottomBoard/index.jsx";
import { GAME_ACTIVITY_STATE, GAME_STATUSES } from "constants/game.constants";
import { useSelector } from "react-redux";
import { useMemo } from "react";

import SingleMatch from "components/pages/singleMatch";

const Spin2WinMarkets = ({ markets, eventInfo }) => {
	const session = useSelector((state) => state.auth.session);
	const currentGameType = useSelector((state) => state.game.currentGameType);

	const isOddDisabled = useMemo(() => {
		const game = session?.games?.find((g) => g.type === currentGameType);

		return (
			game?.state === GAME_ACTIVITY_STATE.CLOSE_FOR_BETTING ||
			[GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventInfo?.status)
		);
	}, [session, currentGameType, eventInfo]);

	return (
		<div className="vs--markets-list vs--pl-16 vs--pr-16 vs--pb-12">
			{/* <SingleMatch/> */}
			<TopBoard markets={markets} eventInfo={eventInfo} isOddDisabled={isOddDisabled} />
			<div className="vs--roulete-divider vs--mt-24 vs--mb-24" />
			<BottomBoard markets={markets} eventInfo={eventInfo} isOddDisabled={isOddDisabled} />
		</div>
	);
};

export default Spin2WinMarkets;
