import useLuckySixBetting from "hooks/useLuckySixBetting.js";
import { mergeClassNames } from "utils/common.js";

const OddButton = ({ currentEvent, currentMarket, currentOdd }) => {
	const { isOddSelected, handleOddClick } = useLuckySixBetting({ currentEvent, currentMarket, currentOdd });

	return (
		<div
			className={mergeClassNames(
				"vs--markets-list-item-content-odd-item selected vs--flex vs--align-center",
				isOddSelected(currentOdd?.id) && "vs--markets-list-item-content-odd-item-selected"
			)}
			onClick={() => handleOddClick(false)}
		>
			<div className="vs--markets-list-item-content-odd-item-title">
				<span className="vs--font-small">{currentOdd.showName}</span>
			</div>
		</div>
	);
};

export default OddButton;
