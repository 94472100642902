const numbers = [
	6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24,
	16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7,
	28, 12, 35, 3, 26, 0, 32, 15, 19, 4, 21,
	2, 25, 17, 34
];

const ALLOWED_STATISTICS_NAMES = {
	HOT: "hot",
	COLD: "cold",
	NEUTRAL: "neutral"
}

// Helper function to get the color for a number
const getColorForNumber = ({ number, hotNumbers = [], coldNumbers = [] }) => {
	switch (true) {
		case hotNumbers.includes(number):
			return ALLOWED_STATISTICS_NAMES.HOT; // Hot numbers will use a gradient;
		case coldNumbers.includes(number):
			return ALLOWED_STATISTICS_NAMES.COLD; // Cold numbers will use a gradient
		default:
			break;
	}
	// Neutral numbers will use a gradient
	return ALLOWED_STATISTICS_NAMES.NEUTRAL;
}

const numSections = 37;
const sectionAngle = (2 * Math.PI) / numSections;

const RESET_GRADIENT_GRAY_COLOR_STOPS = [
	{ offset: 0.665, color: '#B4B4B4' },
	{ offset: 0.855, color: '#9C9C9C' },
	{ offset: 0.945, color: '#C0C0C0' }
];

const SHADOW_GRADIENT_BLACK_COLOR_STOPS = [
	{ offset: 0.9, color: 'rgba(0, 0, 0, 0.5)' },
	{ offset: 1, color: 'rgba(0, 0, 0, 0.2)' },
	{ offset: 1, color: 'rgba(0, 0, 0, 0)' }
];

const SHADOW_COLORS = {
	BLACK: "#00000080",
	TRANSPARENT: "transparent"
}

const CIRCLE_COLORS = {
	OUTER: {
		MAIN: "#474747"
	},
	INNER: {
		VARIANT_1: "#CECECE",
		VARIANT_2: "#9E9E9E"
	}
}

const BORDER_COLORS = {
	VARIANT_1: "#878787",
	VARIANT_2: "#5B5959"
}

const COLORED_GRADIENT_VALUES = {
	// Gradient for hot numbers (#FF5E3D to #D64F33 to #A53C27)
	[ALLOWED_STATISTICS_NAMES.HOT]: [
		{ offset: 0, color: '#A53C27' },
		{ offset: 0.4, color: '#D64F33' },
		{ offset: 1, color: '#FF5E3D' }
	],
	// Gradient for cold numbers (#4171B8 to #3593FF)
	[ALLOWED_STATISTICS_NAMES.COLD]: [
		{ offset: 0, color: '#4171B8' },
		{ offset: 0.6, color: '#3593FF' }
	],
	// Gradient for neutral numbers (white to purple)
	[ALLOWED_STATISTICS_NAMES.NEUTRAL]: [
		{ offset: 0, color: 'rgba(191, 191, 191, 0.86)' },
		{ offset: 0.6, color: 'rgba(237, 237, 237, 0.9)' }
	]
}



/**
 * Function to draw roulette with number statistics
 * @param {RouletteDrawConfig} config 
 * @param {HTMLCanvasElement} config.canvas
 * @param {CanvasRenderingContext2D} config.ctx
 * @param {number} config.radialGradientRadius
 * @param {number} config.innerCircleRadius
 * @param {number} config.totalElectedCount
 * @param {number[]} config.hotNumbers
 * @param {number[]} config.coldNumbers
 * @param {Dictionary<string, number>} config.electedCountByNumber
 * @param {number} config.minValuePart
 * @return {void}
 */
const drawRoulette = ({
	ctx,
	canvas,
	radialGradientRadius = 400,
	innerCircleRadius = 50,
	electedCountByNumber = {},
	totalElectedCount,
	hotNumbers = [],
	coldNumbers = [],
	minValuePart = 0.3
}) => {

	const centerX = canvas.width / 2;
	const centerY = canvas.height / 2;

	ctx.clearRect(0, 0, canvas.width, canvas.height);
	ctx.save();
	ctx.translate(centerX, centerY);
	ctx.translate(-centerX, -centerY);

	// Gradient for the outer part of the roulette wheel
	const canvasResetGradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radialGradientRadius);
	RESET_GRADIENT_GRAY_COLOR_STOPS.forEach(({ offset, color }) => {
		canvasResetGradient.addColorStop(offset, color);
	});

	ctx.fillStyle = canvasResetGradient;

	// Draw sections
	for (let i = 0; i < numSections; i++) {
		const startAngle = sectionAngle * i;
		const endAngle = sectionAngle * (i + 1);
		const currentNumber = numbers[i];

		ctx.beginPath();
		ctx.moveTo(centerX, centerY);
		ctx.arc(centerX, centerY, radialGradientRadius, startAngle, endAngle);
		ctx.closePath();
		ctx.fill();

		// Get the color for the number
		const numberColor = getColorForNumber({ number: currentNumber, hotNumbers, coldNumbers });

		const minValue = minValuePart * radialGradientRadius;
		const valueForCalculationPercent = radialGradientRadius - minValue;

		// Draw the red section with custom percentage for each number
		const percentage = 10 * electedCountByNumber[currentNumber] / totalElectedCount;
		const colorLevel = (minValue + (valueForCalculationPercent * percentage)); // Convert percentage to a height
		const colorLevelToDraw = Math.min(colorLevel, radialGradientRadius)

		ctx.beginPath();
		ctx.moveTo(centerX, centerY);
		ctx.arc(centerX, centerY, colorLevelToDraw, startAngle, endAngle);
		ctx.closePath();

		// Apply inner shadow before drawing the section
		if (Object.values(ALLOWED_STATISTICS_NAMES).includes(numberColor)) {
			ctx.shadowOffsetX = 0;
			ctx.shadowOffsetY = 0;
			ctx.shadowBlur = 4;
			ctx.shadowColor = SHADOW_COLORS.BLACK; // Apply the inner shadow effect
		}

		const canvasColoredGradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, radialGradientRadius);

		const coloredGradientConfigs = COLORED_GRADIENT_VALUES[numberColor];
		if (Array.isArray(coloredGradientConfigs)) {
			coloredGradientConfigs.forEach(({ offset, color }) => {
				canvasColoredGradient.addColorStop(offset, color);
			})
		}

		ctx.fillStyle = canvasColoredGradient;

		ctx.fill();

		// Reset shadow properties for the next section
		ctx.shadowOffsetX = 0;
		ctx.shadowOffsetY = 0;
		ctx.shadowBlur = 0;
		ctx.shadowColor = SHADOW_COLORS.TRANSPARENT;

		// Reset fill style for the next section
		ctx.fillStyle = canvasResetGradient;
	}

	// Outer circle border
	ctx.beginPath();
	ctx.arc(centerX, centerY, radialGradientRadius, 0, 2 * Math.PI);
	ctx.lineWidth = 2;
	ctx.strokeStyle = CIRCLE_COLORS.OUTER.MAIN;
	ctx.stroke();

	// Draw section dividers
	ctx.beginPath();
	for (let i = 0; i < numSections; i++) {
		const angle = sectionAngle * i;
		ctx.moveTo(centerX, centerY);
		ctx.lineTo(centerX + radialGradientRadius * Math.cos(angle), centerY + radialGradientRadius * Math.sin(angle));
	}
	ctx.stroke();

	// Create the inner circle gradient
	const innerCircleGradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, innerCircleRadius);
	innerCircleGradient.addColorStop(0, CIRCLE_COLORS.INNER.VARIANT_1);
	innerCircleGradient.addColorStop(1, CIRCLE_COLORS.INNER.VARIANT_2);

	ctx.beginPath();
	ctx.arc(centerX, centerY, innerCircleRadius, 0, 2 * Math.PI);
	ctx.fillStyle = innerCircleGradient;
	ctx.fill();

	// Simulate the inner shadow
	const shadowGradient = ctx.createRadialGradient(centerX, centerY, 10, centerX, centerY, innerCircleRadius);
	Object.values(SHADOW_GRADIENT_BLACK_COLOR_STOPS).forEach(({ offset, color }) => {
		shadowGradient.addColorStop(offset, color);
	})

	ctx.globalCompositeOperation = 'multiply'; // Darkens the underlying color
	ctx.beginPath();
	ctx.arc(centerX, centerY, innerCircleRadius, 0, 2 * Math.PI);
	ctx.fillStyle = shadowGradient;
	ctx.fill();

	ctx.globalCompositeOperation = 'source-over'; // Reset blending mode

	const borderGradient = ctx.createLinearGradient(centerX - innerCircleRadius, centerY - innerCircleRadius, centerX + innerCircleRadius, centerY + innerCircleRadius);
	borderGradient.addColorStop(0.1371, BORDER_COLORS.VARIANT_1);
	borderGradient.addColorStop(0.8612, BORDER_COLORS.VARIANT_2);

	ctx.lineWidth = 6;
	ctx.strokeStyle = borderGradient;
	ctx.stroke();
	ctx.restore();
}
export default drawRoulette;