import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LUCKY_SIX_MARKETS } from "constants/game.constants.js";
import { Fragment } from "react";
import BetBall from "components/pages/luckySix/components/betBall";
import { findMarketWinnerByGroup } from "components/pages/luckySix/components/markets/helpers";

const LastMarket = ({ markets, currentEventScenes, overUnderOutcome, oddEvenOutcome }) => {
	const { t } = useTranslation();

	const lastOverUnder =
		overUnderOutcome || findMarketWinnerByGroup(LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER, markets)?.outcome;
	const lastOddEven =
		oddEvenOutcome || findMarketWinnerByGroup(LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN, markets)?.outcome;
	const lastBall = currentEventScenes?.[34];

	return (
		<>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-name">
				{t("common.last")}:
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{lastBall?.ballColour && <BetBall number={lastBall.number} color={lastBall.ballColour} />}
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{lastOverUnder ? (
					<>
						<span style={{ color: "#A3A0C0" }}> - </span>
						{lastOverUnder}
					</>
				) : null}
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{lastOddEven ? (
					<>
						<span style={{ color: "#A3A0C0" }}> - </span>
						{lastOddEven}
					</>
				) : null}
			</span>
		</>
	);
};

LastMarket.propTypes = {
	markets: PropTypes.array,
	currentEventScenes: PropTypes.array,
	overUnderOutcome: PropTypes.string,
	oddEvenOutcome: PropTypes.string
};

export default LastMarket;
