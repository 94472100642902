import { SPIN_TO_WIN_MARKET_GROUPS } from "constants/market.constants.js";
import BulkSelectionButton from "components/pages/main/markets/spin2Win/topBoard/bulkSelectionButton.jsx";
import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants.js";
import { useTranslation } from "react-i18next";

const HighLowMarkets = ({ markets, handleOddClick, handleMarketHover, bets, isOddDisabled }) => {
	const { t } = useTranslation();

	const highLowMarket = markets.find((market) => market.group === SPIN_TO_WIN_MARKET_GROUPS.HIGH_LOW);
	const highLowMarketOdds = highLowMarket?.odds[0] || [];

	const isLowSelected = bets.some((bet) => bet.oddId === highLowMarketOdds[1]?.id);
	const isHighSelected = bets.some((bet) => bet.oddId === highLowMarketOdds[0]?.id);

	return (
		<div className="vs--roulete-select-block vs--roulete-select-block-top">
			<BulkSelectionButton
				className={isLowSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(highLowMarket, highLowMarketOdds[1])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.LOW}
				text={t("cashier.low(1-18)")}
				subText={highLowMarketOdds[1]?.factor ? `x${highLowMarketOdds[1].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={isHighSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(highLowMarket, highLowMarketOdds[0])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.HIGH}
				text={t("cashier.high(19-36)")}
				subText={highLowMarketOdds[0]?.factor ? `x${highLowMarketOdds[0].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
		</div>
	);
};

export default HighLowMarkets;
