import { ODD_STATE } from "constants/market.constants.js";

export const findMarketWinnerByGroup = (group, markets) => {
	const temp = markets.find((m) => m.group === group);
	if (temp) {
		const odds = temp.odds[0];

		return odds.find((odd) => odd.oddState === ODD_STATE.WON);
	}
};
