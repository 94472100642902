import { useEffect, useMemo, useRef, useState } from "react";
import { GAME_TYPE } from "constants/game.constants.js";
import { useSelector } from "react-redux";
import EmptyBall from "components/pages/luckySix/startedGameFlow/components/emptyBall";
import NewBallMount from "components/pages/luckySix/startedGameFlow/components/newBallMount";
import StartedGameFlowFooter from "components/pages/luckySix/startedGameFlow/components/startedGameFlowFooter";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StartedGameFlowMain = ({ scenes, onEnd, liveMatch }) => {
	const { t } = useTranslation();
	const myContainerRef = useRef(null);
	const resultsTextTimoutRef = useRef(null);
	const luckySixRtPs = useSelector((state) => state.auth.stream.rtps);
	const [renderScenes, setRenderScenes] = useState([]);
	const intervalIdRef = useRef(null);
	const [isLiveEnded, setIsLiveEnded] = useState(false);

	const [centerBalls, setCenterBalls] = useState({
		"6-1": 0,
		"6-2": 0,
		"6-3": 0,
		"6-4": 0,
		"6-5": 0
	});
	const [leftBalls, setLeftBalls] = useState({
		"6-6": 0,
		"6-7": 0,
		"6-8": 0,
		"6-9": 0,
		"6-10": 0,
		"6-11": 0,
		"6-12": 0,
		"6-13": 0,
		"6-14": 0,
		"6-15": 0
	});
	const [bottomBalls, setBottomBalls] = useState({
		"6-16": 0,
		"6-17": 0,
		"6-18": 0,
		"6-19": 0,
		"6-20": 0,
		"6-21": 0,
		"6-22": 0,
		"6-23": 0,
		"6-24": 0,
		"6-25": 0
	});
	const [rightBalls, setRightBalls] = useState({
		"6-26": 0,
		"6-27": 0,
		"6-28": 0,
		"6-29": 0,
		"6-30": 0,
		"6-31": 0,
		"6-32": 0,
		"6-33": 0,
		"6-34": 0,
		"6-35": 0
	});

	useEffect(() => {
		if (luckySixRtPs) {
			luckySixRtPs.forEach((rtp) => {
				if (leftBalls[rtp.name] === 0 && rtp.margin !== leftBalls[rtp.name]) {
					setLeftBalls((prev) => ({ ...prev, [rtp.name]: rtp.margin }));
				} else if (bottomBalls[rtp.name] === 0 && rtp.margin !== bottomBalls[rtp.name]) {
					setBottomBalls((prev) => ({ ...prev, [rtp.name]: rtp.margin }));
				} else if (rightBalls[rtp.name] === 0 && rtp.margin !== rightBalls[rtp.name]) {
					setRightBalls((prev) => ({ ...prev, [rtp.name]: rtp.margin }));
				}
			});
		}
	}, [luckySixRtPs, leftBalls, bottomBalls, rightBalls]);

	useEffect(() => {
		if (scenes?.length > 0 && liveMatch?.startTime) {
			const startTime = liveMatch?.startTime ?? undefined;
			const date = moment.utc(startTime).local().toDate();
			const diff = moment().diff(date, "seconds");
			const skippedCount = Math.floor(diff / 1.5);

			if (skippedCount > 0) {
				setRenderScenes(scenes.slice(0, skippedCount).map((item) => ({ ...item, disableAnimation: true })));
			}

			intervalIdRef.current = setInterval(() => {
				setRenderScenes((prev) => (prev.length < 35 ? [...prev, scenes[prev.length]] : prev));
			}, 1500);
		}

		return () => {
			setRenderScenes([]);
			clearInterval(intervalIdRef.current);
		};
	}, [scenes, liveMatch?.startTime]);

	useEffect(() => {
		if (renderScenes.length === 35) {
			clearInterval(intervalIdRef.current);

			resultsTextTimoutRef.current = setTimeout(() => {
				setIsLiveEnded(true);
				onEnd();
			}, 2000);
		}

		return () => {
			if (resultsTextTimoutRef.current) {
				clearInterval(intervalIdRef.current);
			}
		};
	}, [renderScenes, onEnd]);

	return (
		<>
			<div
				className="vs--lucky-six-live-monitor-container-started-game-flow-container-main"
				id="main-element"
				ref={myContainerRef}
			>
				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-left">
					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-left-group">
						{Object.keys(leftBalls)
							.splice(0, 5)
							.map((key) => (
								<EmptyBall key={key} factor={leftBalls[key]} id={key} />
							))}
					</div>
					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-left-group">
						{Object.keys(leftBalls)
							.splice(5)
							.map((key) => (
								<EmptyBall key={key} factor={leftBalls[key]} id={key} />
							))}
					</div>
				</div>

				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-center">
					<svg
						className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-center-left-svg"
						width="93"
						height="108"
						viewBox="0 0 93 108"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							opacity="0.7"
							d="M0 53.9936L39.2975 108H91.7932L85.5402 101.689H45.0452L12.1121 54.1277L45.0452 6.57531H85.5402L92.0416 0.00878906H39.2975L0 53.9936Z"
							fill="url(#paint0_linear_5402_15166)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_5402_15166"
								x1="88.5223"
								y1="54.02"
								x2="13.5193"
								y2="54.02"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#AC7669" />
								<stop offset="0.03" stopColor="#92697E" />
								<stop offset="0.2" stopColor="#7E5E8E" />
								<stop offset="0.27" stopColor="#6F569A" />
								<stop offset="0.36" stopColor="#6752A0" />
								<stop offset="0.5" stopColor="#6551A3" />
								<stop offset="0.64" stopColor="#6752A0" />
								<stop offset="0.72" stopColor="#6F569A" />
								<stop offset="0.79" stopColor="#7E5E8E" />
								<stop offset="0.905" stopColor="#92697E" />
								<stop offset="1" stopColor="#AC7769" />
							</linearGradient>
						</defs>
					</svg>

					{Object.keys(centerBalls).map((key) => (
						<EmptyBall key={key} id={key} />
					))}

					<svg
						className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-center-right-svg"
						width="93"
						height="108"
						viewBox="0 0 93 108"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							opacity="0.7"
							d="M93 53.9936L53.7025 108H1.20679L7.45978 101.689H47.9548L80.8879 54.1277L47.9548 6.57531H7.45978L0.958351 0.00878906H53.7025L93 53.9936Z"
							fill="url(#paint0_linear_5402_15167)"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_5402_15167"
								x1="4.47768"
								y1="54.02"
								x2="79.4807"
								y2="54.02"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#AC7669" />
								<stop offset="0.03" stopColor="#92697E" />
								<stop offset="0.2" stopColor="#7E5E8E" />
								<stop offset="0.27" stopColor="#6F569A" />
								<stop offset="0.36" stopColor="#6752A0" />
								<stop offset="0.5" stopColor="#6551A3" />
								<stop offset="0.64" stopColor="#6752A0" />
								<stop offset="0.72" stopColor="#6F569A" />
								<stop offset="0.79" stopColor="#7E5E8E" />
								<stop offset="0.905" stopColor="#92697E" />
								<stop offset="1" stopColor="#AC7769" />
							</linearGradient>
						</defs>
					</svg>
				</div>

				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-right">
					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-right-group">
						{Object.keys(rightBalls)
							.splice(0, 5)
							.map((key) => (
								<EmptyBall key={key} factor={rightBalls[key]} id={key} toLeft />
							))}
					</div>
					<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-right-group">
						{Object.keys(rightBalls)
							.splice(5)
							.map((key) => (
								<EmptyBall key={key} factor={rightBalls[key]} id={key} toLeft />
							))}
					</div>
				</div>

				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-main-bottom">
					{Object.keys(bottomBalls).map((key) => (
						<EmptyBall key={key} factor={bottomBalls[key]} id={key} />
					))}
				</div>
			</div>

			{isLiveEnded && (
				<div className="vs--lucky-six-live-monitor-container-started-game-flow-container-results">
					<span className="vs--lucky-six-live-monitor-container-started-game-flow-container-results-text">
						{t("common.results")}
					</span>
					<svg width="313" height="103" viewBox="0 0 313 103" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M0.408854 9.8125C0.408854 4.61901 4.61901 0.408854 9.8125 0.408854H303.021C308.214 0.408854 312.424 4.61901 312.424 9.8125V92.4375C312.424 97.631 308.214 101.841 303.021 101.841H9.81251C4.61902 101.841 0.408854 97.631 0.408854 92.4375V9.8125Z"
							fill="url(#paint0_linear_5402_16064)"
						/>
						<path
							d="M0.408854 9.8125C0.408854 4.61901 4.61901 0.408854 9.8125 0.408854H303.021C308.214 0.408854 312.424 4.61901 312.424 9.8125V92.4375C312.424 97.631 308.214 101.841 303.021 101.841H9.81251C4.61902 101.841 0.408854 97.631 0.408854 92.4375V9.8125Z"
							stroke="url(#paint1_linear_5402_16064)"
							strokeWidth="0.817708"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_5402_16064"
								x1="156.417"
								y1="0"
								x2="156.417"
								y2="102.25"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="#1A1A1A" stopOpacity="0.5" />
								<stop offset="1" stopColor="#0D0D0D" />
							</linearGradient>
							<linearGradient
								id="paint1_linear_5402_16064"
								x1="156.417"
								y1="0"
								x2="156.417"
								y2="102.25"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="white" stopOpacity="0.2" />
								<stop offset="1" stopColor="white" stopOpacity="0.5" />
							</linearGradient>
						</defs>
					</svg>
				</div>
			)}

			{renderScenes.map((scene, index) => (
				<NewBallMount
					key={index}
					className="vs--lucky-six-live-monitor-container-started-game-flow-container-balls"
					color={scene.ballColour}
					number={scene.number}
					newPositionId={`6-${scene.orderNumber}`}
					disableAnimation={scene.disableAnimation}
				/>
			))}
			<StartedGameFlowFooter scenes={renderScenes} />
		</>
	);
};

StartedGameFlowMain.propTypes = {
	scenes: PropTypes.array,
	onEnd: PropTypes.func.isRequired
};

export default StartedGameFlowMain;
