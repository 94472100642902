import { connect, useDispatch } from "react-redux";
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router";
import { GAME_STATUSES } from "constants/game.constants.js";

import "./styles.scss";
import StreamBackground from "./components/stream-background";
import StreamHeader from "./components/stream-header";
import StreamScenes from "./components/stream-scenes";
import SuggestedMarkets from "./components/suggested-markets";
import Statistics from "./components/statistics";
import LiveProcess from "./components/live-process";
import { buildPathToStaticFolderOfCDN, loadVideo, mergeClassNames } from "utils/common";
import Result from "./components/result";
import CloseForBetting from "./components/close-for-betting";
import { removeFromLiveAndUpcomingsLiveMonitor } from "store/actions/game/game.actions";
import Slider from "components/slider";
import SceneFlow from "../scenesFlow";
import ScenesFlowContext from "../scenesFlow/scenesFlowContext";

const GAME_FLOW = {
	SUGGESTED: "SUGGESTED",
	STATISTICS: "STATISTICS",
	CLOSE_FOR_BETTING: "CLOSE_FOR_BETTING",
	FINISHED: "FINISHED",
	STARTED: "STARTED"
};

/** Spin To Win Component */
const SpinToWin = ({ liveAndUpcomings, isLoading, jackpotInfo }) => {
	const { decodedData } = useOutletContext();
	const dispatch = useDispatch()
	const [gameFlow, setGameFlow] = useState(null);
	const [videoSrc, setVideoSrc] = useState(null);
	const sceneFlowImperativeHandlerRef = useRef()
	const isFirstRenderRef = useRef(true)
	const game = decodedData?.spinToWinGame ?? null;
	const logoPath = decodedData?.betShopLogoPath ?? null;

	useEffect(() => {
		const videoUrl = buildPathToStaticFolderOfCDN("cashier-videos/stream/spin-to-win/roulette-loop.webm");
		const controller = new AbortController();
		const { signal } = controller;
		let videoData = { url: null, error: null, dispose: Function.prototype };

		(async () => {
			videoData = await loadVideo({ videoUrl, signal });
			if (videoData.url) {
				setVideoSrc(videoData.url);
			}
		})();

		return () => {
			controller.abort(); // Cancel the request if component unmounts
			videoData.dispose(); // Clean up blob URL
		};
	}, []);

	const currentEvent = useMemo(() => {
		const event = liveAndUpcomings?.find((ev) =>
			[GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(ev.status)
		);

		if (event) {
			return event;
		}

		return liveAndUpcomings[0];
	}, [liveAndUpcomings]);

	useEffect(() => {
		const sceneFlowImperativeHandler = sceneFlowImperativeHandlerRef.current
		if (!sceneFlowImperativeHandler) {
			return
		}
		switch (currentEvent?.status) {
			case GAME_STATUSES.CLOSE_FOR_BETTING:
				isFirstRenderRef.current = false
				console.log(GAME_FLOW.CLOSE_FOR_BETTING);
				setGameFlow(true)
				sceneFlowImperativeHandler.changeSceneByKey(GAME_FLOW.CLOSE_FOR_BETTING)
				break;
			case GAME_STATUSES.STARTED:
				isFirstRenderRef.current = false
				console.log(GAME_FLOW.STARTED);
				setGameFlow(true)
				sceneFlowImperativeHandler.changeSceneByKey(GAME_FLOW.STARTED)
				break;
			default:
				if (isFirstRenderRef.current) {
					isFirstRenderRef.current = false
					setGameFlow(GAME_FLOW.SUGGESTED)
					sceneFlowImperativeHandler.changeSceneByKey(GAME_FLOW.SUGGESTED)
				}
				break;
		}
	}, [currentEvent?.status]);

	const suggestedMarketsOnMounted = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.sceneIsMounted(GAME_FLOW.SUGGESTED)
	}
	const suggestedMarketsOnFinish = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.changeSceneByKey(GAME_FLOW.STATISTICS)
	}

	const statisticsOnMounted = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.sceneIsMounted(GAME_FLOW.STATISTICS)
	}
	const statisticsOnFinish = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.changeSceneByKey(GAME_FLOW.SUGGESTED)
	}

	const closeForBettingOnMounted = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.sceneIsMounted(GAME_FLOW.CLOSE_FOR_BETTING)
	};

	const liveProcessOnMounted = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.sceneIsMounted(GAME_FLOW.STARTED)
	};
	const liveProcessOnFinish = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.changeSceneByKey(GAME_FLOW.FINISHED)
	};

	const resultOnMounted = (sceneFlowProviderValue) => {
		sceneFlowProviderValue.sceneIsMounted(GAME_FLOW.FINISHED)
	};
	const resultOnFinish = (sceneFlowProviderValue) => {
		dispatch(removeFromLiveAndUpcomingsLiveMonitor(currentEvent.id));
		sceneFlowProviderValue.changeSceneByKey(GAME_FLOW.SUGGESTED)
	};


	return (
		<div className={mergeClassNames("vs--spin-2-win-stream", import.meta.env.MODE === "production" && "vs--user-select-none")}>
			<StreamBackground />
			<div className="vs--spin-2-win-stream-container">
				<StreamHeader event={currentEvent} logoPath={logoPath} />
				<StreamScenes className={currentEvent?.status === GAME_STATUSES.CLOSE_FOR_BETTING ? "close-scene" : null}>
					<SceneFlow defaultItemKey={null} imperativeHandlerRef={sceneFlowImperativeHandlerRef}>
						<SceneFlow.Item pageKey={GAME_FLOW.SUGGESTED}>
							<SuggestedMarkets onMount={suggestedMarketsOnMounted} onFinish={suggestedMarketsOnFinish} />
						</SceneFlow.Item>
						<SceneFlow.Item pageKey={GAME_FLOW.STATISTICS}>
							<Statistics onMount={statisticsOnMounted} onFinish={statisticsOnFinish} />
						</SceneFlow.Item>
						<SceneFlow.Item pageKey={GAME_FLOW.CLOSE_FOR_BETTING}>
							<CloseForBetting event={currentEvent} onMount={closeForBettingOnMounted} />
						</SceneFlow.Item>
						<SceneFlow.Item pageKey={GAME_FLOW.STARTED}>
							<LiveProcess event={currentEvent} loopSrc={videoSrc} onMount={liveProcessOnMounted} onFinish={liveProcessOnFinish} />
						</SceneFlow.Item>
						<SceneFlow.Item pageKey={GAME_FLOW.FINISHED}>
							<Result event={currentEvent} onMount={resultOnMounted} onFinish={resultOnFinish} />
						</SceneFlow.Item>
					</SceneFlow>
				</StreamScenes>
			</div>
		</div>
	);
};

/** SpinToWin propTypes
 * PropTypes
 */
SpinToWin.propTypes = {};

const mapStateToProps = (state) => {
	return {
		liveAndUpcomings: state.game.liveAndUpcomings.data,
		isLoading: state.game.liveAndUpcomings.loading,
		jackpotInfo: state.bonuses.jackpot.data
	};
};

export default connect(mapStateToProps, null)(SpinToWin);