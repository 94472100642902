import  { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";

import Collapse from "components/ui/collapse";
import ModalWrapper from "components/modalWrapper";

import Ball from "components/pages/main/markets/luckySix/ball/ball";

import { DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "constants/date.constants";

import Details from "./details";
import Filters from "./filters";
import EventVideo from "./eventVideo";
import Pagination from "components/ui/pagination";
import Loader from "components/ui/loader";
import NoData from "components/ui/noData";
import RaceRects from "components/ui/raceRects";
import { getResults, setResultsFilters } from "store/actions/results/results.actions";

import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE, GAME_TYPE_TEXT_KEYS, CUP_GAMES_FINAL_ROUND_NUMBER } from "constants/game.constants";

import {
	numberWithSpaces,
	isSeasonGame,
	isCupGame,
	isLeagueGame,
	GetSeasonRoundText,
	isCupGameFinalRound,
	isRacingGame,
} from "utils/common";

import resultType from "types/result.type";
import resultsFilterType from "types/resultsFilter.type";
import EventComponent from "./details/eventComponent";
import ScenesComponent from "./details/scenesComponent";
import PenaltyResultComponent from "./details/penaltyResultComponent";

/** Results List COmponent */
const Results = ({ coordinates, onCancel, results, isLoading, getResults, setResultsFilters, total, filters }) => {
	const { t } = useTranslation();

	const coordinatesRef = useRef();

	const [opened, setOpened] = useState([]);
	const [currentEventVideo, setCurrentEventVideo] = useState(null);
	

	/** Load results */
	useEffect(() => {
		getResults();
	}, []);

	/** Function which Render the result string
	 * @function
	 * @param {object} item - the item to show in row
	 * @returns {string}
	 * @memberOf Results
	 */
	const renderResult = (item, isText = true, rowIndex = null) => {
		let str = "";
		if (item.gameType === GAME_TYPE.FOOTBALL_SINGLE_MATCH || isCupGameFinalRound(item)) {
			const nessesaryEvent = (
				isCupGame(item.gameType)
					? item?.events?.[0] ?? {}
					: item
			);
			const team1 = nessesaryEvent?.gameData?.team1 ?? {};
			const team2 = nessesaryEvent?.gameData?.team2 ?? {};

			if (isText) {
				const team1CountryCode = t(`countries.${team1.countryCode ?? ""}`);
				const team2CountryCode = t(`countries.${team2.countryCode ?? ""}`);
				const team1GoalCount = team1.goalCount ?? "";
				const team2GoalCount = team2.goalCount ?? "";
				str = (`${team1CountryCode} ${team1GoalCount} - ${team2GoalCount} ${team2CountryCode}`);
			} else {
				str = (
					<EventComponent
						className={"vs--modal-table-league-result-final"}
						event={nessesaryEvent}
						expanded={false}
					/>
				)
			}
		} else if (isSeasonGame(item.gameType)) {
			const events = item?.events ?? [];

			str = (
				<div>
					<div className="vs--flex vs--align-center">
						{events
							.slice(0, 2)
							.map((event) => {
								const isCup = isCupGame(event?.gameType ?? 0);
								const team1 = event?.gameData?.team1 ?? {};
								const team2 = event?.gameData?.team2 ?? {};
								let additionalInfo = null;


								if (isCup) {
									additionalInfo = {};
									additionalInfo.team1OT = team1.overTimeGoalCount ?? 0;
									additionalInfo.team2OT = team2.overTimeGoalCount ?? 0;
									additionalInfo.OT = additionalInfo.team1OT !== null || additionalInfo.team2OT !== null;
									additionalInfo.team1PS = team1.penaltyShootoutGoalCount ?? 0;
									additionalInfo.team2PS = team2.penaltyShootoutGoalCount ?? 0;
									additionalInfo.PS = additionalInfo.team1PS !== null || additionalInfo.team2PS !== null;
								}
								const team1Goals = team1.goalCount ?? 0;
								const team2Goals = team2.goalCount ?? 0;

								const isTeam1Win = team1Goals !== team2Goals ? team1Goals > team2Goals : additionalInfo ? (additionalInfo.team1OT !== additionalInfo.team2OT ? additionalInfo.team1OT > additionalInfo.team2OT : additionalInfo.team1PS > additionalInfo.team2PS) : false;

								/* isTeam1Win !== !isTeam2Win, becouse data can be buget */

								const isTeam2Win = team2Goals !== team1Goals ? team2Goals > team1Goals : additionalInfo ? (additionalInfo.team2OT !== additionalInfo.team1OT ? additionalInfo.team2OT > additionalInfo.team1OT : additionalInfo.team2PS > additionalInfo.team1PS) : false;

								return (
									<div key={event.id} className="vs--flex vs--align-center vs--mr-8">
										<span>{team1.countryCode ?? null}</span>
										<div className="vs--pl-8 vs--pr-8">
											<span className={"vs--league-match-result vs--font-exstrasmall vs--font-medium" + (isTeam1Win ? " vs--match-winner" : "")}>{team1Goals}</span>
											<span className="vs--league-match-result vs--font-exstrasmall vs--font-medium vs--pl-4 vs--pr-4">:</span>
											<span className={"vs--league-match-result vs--font-exstrasmall vs--font-medium" + (isTeam2Win ? " vs--match-winner" : "")}>{team2Goals}</span>
										</div>
										<span>{team2.countryCode ?? null}</span>
									</div>
								);
							})}
						{events.slice(2).length == 0 ? null : (
							<div className="vs--flex vs--align-center">
								<div className="vs--match-result-count vs--flex vs--align-center vs--justify-center">
									<span className="vs--font-smallest vs--font-regular">+{events.slice(2).length}</span>
								</div>
							</div>
						)}
					</div>
				</div>
			);
		} else if (isRacingGame(item.gameType)) {
			const participants = item?.gameData?.participants ?? [];
			const first = participants.find((p) => p.place === 1);
			const second = participants.find((p) => p.place === 2);
			const third = participants.find((p) => p.place === 3);
			if (!first || !second || !third) return "";
			if (isText) {
				str = `1.(#${first.number}) ${first.name}, 2.(#${second.number}) ${second.name}, 3.(#${third.number}) ${third.name}`;
			} else {
				return (
					<RaceRects
						gameType={item.gameType}
						rectData={[
							{ name: first.name, number: first.number, title: "1" },
							{ name: second.name, number: second.number, title: "2" },
							{ name: third.name, number: third.number, title: "3" }
						]}
						placement="top"
					/>
				);
			}
		} else if (item.gameType === GAME_TYPE.KENO) {
			const scenes = item?.gameData?.scenes ?? [];

			return (
				<Fragment>
					{
						scenes
							.map((sc) => sc.number)
							.map((ball) => (
								<div className="vs--flex" key={ball}>
									<div className="vs--result-keno vs--markets-keno-selection-num-sphere vs--flex vs--align-center vs--justify-center vs--mr-4 vs--mb-4">
										<span className="vs--flex-wrap vs--font-mini">{ball}</span>
									</div>
								</div>
							))
					}
				</Fragment>
			);
		} else if (item.gameType === GAME_TYPE.PENALTY_SHOOTOUT) {
			if (isText) {
				const team1 = item?.gameData?.team1 ?? {};
				const team2 = item?.gameData?.team2 ?? {};

				const team1CountryCode = t(`countries.${team1.countryCode ?? ""}`);
				const team2CountryCode = t(`countries.${team2.countryCode ?? ""}`);
				const team1GoalCount = team1.goalCount ?? "";
				const team2GoalCount = team2.goalCount ?? "";
				str = (`${team1CountryCode} ${team1GoalCount} - ${team2GoalCount} ${team2CountryCode}`);
			} else {
				str = <PenaltyResultComponent event={item} placement="top" />
			}
		} else if (item.gameType === GAME_TYPE.SPIN_TO_WIN) {
			const scenes = item?.gameData?.scenes ?? [];

			return (
				<Fragment>
					{
						scenes.map((sc) => (
							<div
								className={`vs--spin-to-win-number vs--flex vs--align-center vs--justify-center ${
									sc.colour === 1 
									? 'vs--spin-to-win-number-green' 
									: sc.colour === 2 
									? 'vs--spin-to-win-number-red' 
									: sc.colour === 4 
									? 'vs--spin-to-win-number-black' 
									: ''
								}`}
								key={sc.number}
							>
								<span className="vs--flex-wrap vs--font-mini">{sc.number}</span>
							</div>
						))
					}
				</Fragment>
			);
		} else if (item.gameType === GAME_TYPE.LUCKY_SIX) {
			const scenes = item?.gameData?.scenes ?? [];

			return (
				<Fragment>
					{
						scenes
							.slice(0, 5)
							.map((sc) => sc.number)
							.map((number) => (
								<Ball number={number} active/>
							))
					}
				</Fragment>
			);
		}

		return str;
	};

	/** Function which Renders row
	 * @function
	 * @param {object} item - the item to show in row
	 * @param {number} index - the row index
	 * @returns {JSX}
	 * @memberOf Results
	 */
	const renderRow = (item, index) => {

		const collapsibleCondition = isLeagueGame(item.gameType) || (isCupGame(item.gameType) && !isCupGameFinalRound(item)) || item.gameType === GAME_TYPE.LUCKY_SIX;
		const needRenderScenes = [GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(item.gameType) || isCupGameFinalRound(item);

		return (
			<div
				className={
					"vs--modal-table-row vs--flex vs--flex-row vs--align-center vs--justify-between vs--font-regular vs--pr-17" +
					(index !== 0 ? " vs--mt-2" : "")
				}
				onClick={(e) => (!collapsibleCondition && e.stopPropagation())}
			>
				<div data-type="expand">
					{
						collapsibleCondition
							? (
								<i className="ic_down vs--font-bigest vs--title "></i>
							)
							: null
					}
				</div>
				<div data-type="id">
					<span>{item.id}</span>
				</div>
				<div data-type="type">
					<span>
						{((type) => {
							switch (type) {
								case GAME_EVENT_TYPE.EVENT:
									return t("cashier.event");
								case GAME_EVENT_TYPE.WEEK:
									return GetSeasonRoundText(item.gameType, item.orderNumber);
								case GAME_EVENT_TYPE.LEAGUE:
									return "";
								default:
									return "";
							}
						})(item.type)}
					</span>
				</div>
				<div data-type="startDate">
					<span>{moment.utc(item.startTime).local().format(DATE_TIME_FORMAT)}</span>
				</div>
				<div data-type="endDate">
					<span>
						{
							moment
								.utc(item.finishTime)
								.local()
								.format(DATE_TIME_FORMAT)
						}
					</span>
				</div>
				<div data-type="gameType">
					<span>{t(`common.${GAME_TYPE_TEXT_KEYS[item.gameType] || ""}`)}</span>
				</div>
				<div data-type="result">
					<span
						{...(needRenderScenes ? { title: renderResult(item, true, index) } : {})}
						className="vs--result-keno-sphere vs--flex vs--flex-wrap vs--pt-8 vs--pb-4"
					>
						{renderResult(item, false, index)}
					</span>
				</div>
				<div data-type="status">
					<div className="vs--result-status">
						<span>
							{
								item.status === GAME_STATUSES.FINISHED
									? t("cashier.completed")
									: item.status === GAME_STATUSES.CANCELED
										? t("bet.cancelled")
										: ""
							}
						</span>
					</div>
				</div>
				<div data-type="betSlipsCount">
					<span>
						{
							numberWithSpaces(
								item.betSlipsCount +
								(
									!isSeasonGame(item.gameType)
										? 0
										: Array.isArray(item.events)
											? item.events.reduce((acc, ev) => ((acc += ev.betSlipsCount || 0), acc), 0)
											: 0
								)
							)
						}
					</span>
				</div>
				{
					item.gameType === GAME_TYPE.KENO || item.gameType === GAME_TYPE.LUCKY_SIX || item.gameType === GAME_TYPE.SPIN_TO_WIN
						? <div data-type="actions" />
						: (
							<div data-type="actions" className="vs--flex vs--align-center">
								{
									needRenderScenes
										? (
											<ScenesComponent
												event={isCupGameFinalRound(item) ? item?.events[0] : item}
												size="big"
											/>
										)
										: null
								}
								<i
									className="ic_webcam vs--font-bigest"
									onClick={(e) => handleWebCamButtonClick(e, item)}
								/>
							</div>
						)
				}
			</div>
		);
	};

	/** Function which will fire on pagination change
	 * @function
	 * @param { object } e - new filters
	 * @memberOf Results
	 */
	const handlePaginationChange = (e) => {
		setResultsFilters(e);
		getResults();
	};

	/** Function which will fire on webCam button click
	 * @function
	 * @param { object } e - event object
	 * @param { object } item - the event
	 * @memberOf Results
	 */
	const handleWebCamButtonClick = (e, item) => {
		coordinatesRef.current = { clientX: e.clientX + "px", clientY: e.clientY + "px" };
		e.stopPropagation();
		const eventWithVideo = isSeasonGame(item?.gameType ?? 0) ? item?.events?.[0] : item;
		setCurrentEventVideo(eventWithVideo);
	};

	const renderDetails = (event) => {
		if (isCupGame(event.gameType) && event.orderNumber === CUP_GAMES_FINAL_ROUND_NUMBER[event.gameType]) {
			return null;
		}
		return <Details item={event} />;
	};

	return (
		<Fragment>
			<ModalWrapper
				coordinates={coordinates}
				title={t("common.results")}
				onCancel={onCancel}
				visible={true}
				classNames={{ content: "vs--results-modal" }}
				modalContentWrapperStyles={{ height: '100%' }}
			>
				<Fragment>
					<Filters />
					{!isLoading ? (
						results.length > 0 ? (
							<div className="vs--modal-wrapper vs--flex-equal">
								<div className="vs--modal-table vs--modal-table-results vs--flex vs--flex-col vs--results-table">
									<div className="vs--modal-table-head vs--flex vs--flex-row vs--align-center vs--justify-between vs--font-medium vs--font-small">
										<div data-type="expand"></div>
										<div data-type="id">
											<span>{t("common.id")}</span>
										</div>
										<div data-type="type">
											<span>{t("cashier.type")}</span>
										</div>
										<div data-type="startDate">
											<span>{t("cashier.startDate")}</span>
										</div>
										<div data-type="endDate">
											<span>{t("cashier.endDate")}</span>
										</div>
										<div data-type="gameType">
											<span>{t("cashier.gameType")}</span>
										</div>
										<div data-type="result">
											<span>{t("cashier.result")}</span>
										</div>
										<div data-type="status">
											<span>{t("bet.status")}</span>
										</div>
										<div data-type="betSlipsCount">
											<span>{t("cashier.betslips")}</span>
										</div>
										<div data-type="actions"></div>
									</div>
									<div className="vs--modal-table-body vs--flex-equal">
										<Collapse activeRowIds={opened} onChange={setOpened} className={"vs--ui-collapse"}>
											{results.map((item, index) => {
												return (
													<Collapse.Panel header={renderRow(item, index)} key={item.id} rowId={item.id} showArrow={false}>
														{renderDetails(item)}
													</Collapse.Panel>
												);
											})}
										</Collapse>
									</div>
								</div>
							</div>
						) : (
							<NoData />
						)
					) : (
						<Loader style={{ height: "100%" }} />
					)}
					<div className="vs--modal-pagination-bottom">
						{total > 0 && (
							<Pagination
								total={total}
								onChange={handlePaginationChange}
								page={filters.page}
								renderTotal={(total, pageSize) => {
									return <span>{t("common.itemsOf").replace("%COUNT%", `${pageSize[0]} - ${pageSize[1]}`).replace("%TOTAL%", total)}</span>;
								}}
								siblingCount={2}
							/>
						)}
					</div>
				</Fragment>
			</ModalWrapper>
			{currentEventVideo && <EventVideo coordinates={coordinatesRef.current} event={currentEventVideo} onClose={() => setCurrentEventVideo(null)} />}
		</Fragment>
	);
};

/** Results propTypes
 * PropTypes
 */
Results.propTypes = {
	/** Css variables` viewport x, y coordinates */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** Function that will be called on results list modal close */
	onCancel: PropTypes.func,
	/** Redux state property, array of results */
	results: PropTypes.arrayOf(resultType),
	/** Redux state property, is true when loading sresults */
	isLoading: PropTypes.bool,
	/** Redux action to get results history */
	getResults: PropTypes.func,
	/** Total count of items */
	total: PropTypes.number,
	/** Redux state property, current filters of results */
	filters: resultsFilterType,
	/** Redux action to update results filters */
	setResultsFilters: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		results: state.results.results,
		isLoading: state.results.isLoading,
		filters: state.results.filters,
		total: state.results.total
	};
};

const mapDispatchToProps = (dispatch) => ({
	getResults: () => {
		dispatch(getResults());
	},
	setResultsFilters: (filters) => {
		dispatch(setResultsFilters(filters));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
