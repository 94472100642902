import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import TransitionSvg from "./transitionSvg";

import { buildPathToStaticFolderOfCDN, mergeClassNames, toFixed } from "utils/common";
import { JACKPOT_LEVEL, JACKPOT_LEVELS_NAMES, JACKPOT_LEVELS_NAMES_LIVE_MONITOR } from 'constants/bonus.constants';

const LEVELS_HIERARCHY = {
	[JACKPOT_LEVEL.BRONZE]: [JACKPOT_LEVEL.BRONZE],
	[JACKPOT_LEVEL.SILVER]: [JACKPOT_LEVEL.BRONZE, JACKPOT_LEVEL.SILVER],
	[JACKPOT_LEVEL.GOLD]: [JACKPOT_LEVEL.BRONZE, JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.GOLD]
}

const JACKPOT_LEVELS = {
	"level-1": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-bronze-icon-level-1.gif"),
	"level-2": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-silver-icon-level-2.gif"),
	"level-4": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-gold-icon-level-4.gif"),

	"png-level-1": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-bronze-icon-level-1.png"),
	"png-level-2": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-silver-icon-level-2.png"),
	"png-level-4": buildPathToStaticFolderOfCDN("cashier-images/jackpot/jackpot-gold-icon-level-4.png")
}

const JACKPOT_WINNER_BACKGROUND_VIDEO = buildPathToStaticFolderOfCDN("cashier-videos/jackpot/jackpot-winner-background.mp4")

const JackpotWinAnimation = ({
	animateableJackpot,
	getMainContainer,
	currencySymbol,
	currencyDecimalCount,
	setAnimationFinish,
	setShowCanfetti,
	isLiveMonitor = false,
	disableAnimation = false,
	cleanupCallback = Function.prototype
}) => {
	const { t } = useTranslation();
	const amount = toFixed(animateableJackpot?.amount, currencyDecimalCount)

	const levelNameConstant = isLiveMonitor ? JACKPOT_LEVELS_NAMES_LIVE_MONITOR : JACKPOT_LEVELS_NAMES;
	const resourceNameConstant = isLiveMonitor ? "livemonitor.common" : "cashier";

	if (animateableJackpot === null || disableAnimation) {
		return null;
	}

	return (
		<Fragment key={animateableJackpot.levelType}>
			<TransitionSvg getMainContainer={getMainContainer} jackpot={animateableJackpot} setShowCanfetti={setShowCanfetti} setAnimationFinish={setAnimationFinish} cleanupCallback={cleanupCallback} />
			<div className={mergeClassNames("vs--active-jackpot-live-info-win-winner", `vs--active-jackpot-live-info-win-winner-${animateableJackpot.levelType}`)}>
				<video className='vs--active-jackpot-live-info-win-winner-video' autoPlay muted loop playsInline>
					<source src={JACKPOT_WINNER_BACKGROUND_VIDEO} type="video/mp4" />
				</video>
				<div className="vs--active-jackpot-live-info-win-winner-content vs--flex vs--flex-col vs--align-center vs--justify-center">
					<span className="vs--active-jackpot-live-info-win-winner-content-title">{t(`${resourceNameConstant}.youWonJackpot`)}</span>

					<span className="vs--active-jackpot-live-info-win-winner-content-name">{t(levelNameConstant[animateableJackpot.levelType])}</span>

					<img className='vs--active-jackpot-live-info-win-winner-content-level-icon' src={JACKPOT_LEVELS[`level-${animateableJackpot.levelType}`]}></img>

					<div className="vs--active-jackpot-live-info-win-winner-content-amount vs--flex">
						<span className="vs--active-jackpot-live-info-win-winner-content-amount-title" id="countdown">{amount}</span>
						<span className="vs--active-jackpot-live-info-win-winner-content-amount-title">{currencySymbol}</span>
					</div>

					<div className="vs--active-jackpot-live-info-win-winner-content-ticket vs--flex">
						<span className="vs--active-jackpot-live-info-win-winner-content-ticket-item vs--active-jackpot-live-info-win-winner-content-ticket-id">{t(`${resourceNameConstant}.ticketID`)}:</span>
						<span className="vs--active-jackpot-live-info-win-winner-content-ticket-item">{animateableJackpot.betSlipId}</span>
					</div>

					<div className="vs--active-jackpot-live-info-win-winner-content-images vs--flex vs--align-center vs--justify-center">
						{LEVELS_HIERARCHY[animateableJackpot.levelType].map((level) => (
							<img key={level} className="vs--active-jackpot-live-info-win-winner-content-level-icon-footer" src={JACKPOT_LEVELS[`png-level-${level}`]}></img>
						))}
					</div>

				</div>
			</div>
		</Fragment>
	)
};

export default JackpotWinAnimation;