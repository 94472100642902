import OddButton from "components/pages/main/markets/luckySix/markets/oddMarkets/oddButton.jsx";

const OddMarkets = ({ group, markets, currentEvent, haveTitle }) => {
	const currentMarket = markets?.find((market) => market.group === group);
	const currentOdds = currentMarket?.odds?.[0];

	return (
		<>
			{currentOdds?.map((odd) => (
				<OddButton key={odd.id} currentEvent={currentEvent} currentMarket={currentMarket} currentOdd={odd} />
			))}
		</>
	);
};

export default OddMarkets;
