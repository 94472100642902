import BaseMarkets from "utils/markets/baseMarkets.js";
import {
	PENALTY_SHOOTOUT_GENERATED_MARKET_GROUPS,
	PENALTY_SHOOTOUT_MARKETS_GROUPS,
	PERIOD,
	SPIN_TO_WIN_MARKET_GROUPS,
	SPIN_TO_WIN_MARKET_TABS
} from "constants/market.constants.js";
import i18n from "translations/config.js";

class Spin2WinMarkets extends BaseMarkets {
	getGroupsStructure() {
		return {
			[SPIN_TO_WIN_MARKET_TABS.MAIN]: [
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.STRAIGHT,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.STRAIGHT,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.SPLIT,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.SPLIT,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.TRIO,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.TRIO,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.STREET,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.STREET,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.BASKET,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.BASKET,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.CORNER,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.CORNER,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.DOUBLE_STREET,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.DOUBLE_STREET,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.COLUMN,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.COLUMN,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.DOZEN,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.DOZEN,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.EVEN_ODD,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.EVEN_ODD,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.RED_BLACK,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.RED_BLACK,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				},
				{
					group: SPIN_TO_WIN_MARKET_GROUPS.HIGH_LOW,
					period: PERIOD.NONE,
					title: SPIN_TO_WIN_MARKET_GROUPS.HIGH_LOW,
					outcomes: "*",
					marketsCount: 2,
					marketCol: 2,
					gridBy: "col",
					oddBy: "row",
					argument: null
				}
			]
		};
	}

	getNameForMarketGroup(marketName, outcome, gameType) {
		switch (marketName) {
			case SPIN_TO_WIN_MARKET_GROUPS.SPLIT:
			case SPIN_TO_WIN_MARKET_GROUPS.TRIO:
			case SPIN_TO_WIN_MARKET_GROUPS.STREET:
			case SPIN_TO_WIN_MARKET_GROUPS.DOUBLE_STREET:
			case SPIN_TO_WIN_MARKET_GROUPS.CORNER:
			case SPIN_TO_WIN_MARKET_GROUPS.BASKET:
				return outcome.split("-").join("/");
			case SPIN_TO_WIN_MARKET_GROUPS.DOZEN:
				return `${outcome} ${i18n.t(`markets.${gameType}.pick`)}`;
			case SPIN_TO_WIN_MARKET_GROUPS.EVEN_ODD:
			case SPIN_TO_WIN_MARKET_GROUPS.RED_BLACK:
			case SPIN_TO_WIN_MARKET_GROUPS.COLUMN:
				return `${i18n.t(`common.${outcome.toLowerCase()}`)}`;
			case SPIN_TO_WIN_MARKET_GROUPS.HIGH_LOW:
				return `${i18n.t(outcome === "Low" ? "cashier.low(1-18)" : "cashier.high(19-36)")}`;
			case SPIN_TO_WIN_MARKET_GROUPS.STRAIGHT:
				return outcome;
			default:
				return marketName;
		}
	}

	makeName({ str, gameData, argument, gameType, group, isTicket, isDefaultTranslation = false }) {
		let result = str;
		switch (true) {
			case str.includes("%Home%"):
				break;
		}
		return result;
	}

	makeMarketsArray(data, gameData, rtps, gameType) {
		const obj = {};
		data.forEach((d) => {
			const key = typeof d.argument === "number" ? d.title + "_" + d.argument : d.title;
			obj[key] = {
				group: d.group,
				odds: Array(d.marketsCount > d.matching.length ? d.matching.length : d.marketsCount).fill(
					d.outcomes !== "*" ? d.outcomes : (d.matching[0] ? d.matching[0].oddList : []).map((odd) => odd.outcome)
				),
				matching: d.matching,
				marketCol: d.marketCol,
				marketsCount: d.marketsCount,
				type: d.type,
				argument: d.argument,
				gridBy: d.gridBy,
				oddBy: d.oddBy
			};
		});

		Object.keys(obj).forEach((o) => {
			obj[o].oddsCopy = obj[o].odds.map((m) => m.slice());
			for (let index = 0; index < obj[o].oddsCopy.length; index++) {
				const groupName = o.split("_")[0];
				const matchingOdd = obj[o].matching[index];
				if (matchingOdd) {
					for (let a = 0; a < matchingOdd.oddList.length; a++) {
						const odd = matchingOdd.oddList[a];
						if (odd) {
							const i = obj[o].oddsCopy[index].indexOf(odd.outcome);
							obj[o].odds[index] = [...obj[o].odds[index]];
							obj[o].odds[index][i] = {
								name: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, gameType),
								factor: odd.factor,
								oddState: odd.state,
								outcome: odd.outcome,
								argument: matchingOdd.argument,
								id: odd.id,
								showName: this.makeName({
									str: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, gameType),
									gameData,
									group: matchingOdd.group,
									argument: matchingOdd.argument,
									gameType
								}),
								ticketShowName: this.makeName({
									str: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, gameType),
									gameData,
									group: matchingOdd.group,
									argument: matchingOdd.argument,
									gameType,
									isTicket: true
								}),
								rectData: this.getRectData({
									outcome: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, gameType),
									group: matchingOdd.group,
									gameData,
									gameType
								})
							};
						}
					}
				}
			}
			delete obj[o].matching;
			delete obj[o].oddsCopy;
		});

		const arr = [];
		Object.keys(obj).forEach((o) => {
			let item = {
				group: o.split("_")[0],
				argument: o.split("_")[1],
				marketCol: obj[o].marketCol,
				marketsCount: obj[o].marketsCount,
				odds: obj[o].odds.map((oddGroup) =>
					oddGroup.map((odd) =>
						typeof odd === "string"
							? {
									name: this.getNameForMarketGroup(o.split("_")[0], odd),
									showName: this.makeName({
										str: this.getNameForMarketGroup(o.split("_")[0], odd),
										gameData,
										group: o.split("_")[0],
										argument: o.split("_")[1],
										gameType
									}),
									ticketShowName: this.makeName({
										str: this.getNameForMarketGroup(o.split("_")[0], odd),
										gameData,
										group: o.split("_")[0],
										argument: o.split("_")[1],
										gameType,
										isTicket: true
									})
								}
							: odd
					)
				),
				type: obj[o].type,
				gridBy: obj[o].gridBy,
				oddBy: obj[o].oddBy
			};
			item.odds = item.odds.map((oddGroup) => oddGroup.filter((odd) => typeof odd !== "string"));
			arr.push(item);
		});
		return arr;
	}

	makeGroupTitle(market) {
		let res = "";
		const ns = market.isTicket ? "ticket:" : "";

		res += i18n.t(`${ns}markets.${market.gameType}.${market.group.toLowerCase()}`);

		return res;
	}
}

export default Spin2WinMarkets;
