import BulkSelectionButton from "components/pages/main/markets/spin2Win/topBoard/bulkSelectionButton.jsx";
import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants.js";
import { SPIN_TO_WIN_MARKET_GROUPS } from "constants/market.constants.js";
import { useTranslation } from "react-i18next";

const DozenMarkets = ({ markets, handleOddClick, handleMarketHover, bets, isOddDisabled }) => {
	const { t } = useTranslation();

	const dozenMarket = markets.find((market) => market.group === SPIN_TO_WIN_MARKET_GROUPS.DOZEN);
	const dozenMarketOdds = dozenMarket?.odds[0] || [];

	const isFirstDozenSelected = bets.some((bet) => bet.oddId === dozenMarketOdds[0]?.id);
	const isSecondDozenSelected = bets.some((bet) => bet.oddId === dozenMarketOdds[1]?.id);
	const isThirdDozenSelected = bets.some((bet) => bet.oddId === dozenMarketOdds[2]?.id);

	return (
		<div className="vs--roulete-select-block">
			<BulkSelectionButton
				className={isFirstDozenSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(dozenMarket, dozenMarketOdds[0])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.FIRST_TWELVE}
				text={`${t("common.1st")} 12`}
				subText={dozenMarketOdds[0]?.factor ? `x${dozenMarketOdds[0].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={isSecondDozenSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(dozenMarket, dozenMarketOdds[1])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.SECOND_TWELVE}
				text={`${t("common.2nd")} 12`}
				subText={dozenMarketOdds[1]?.factor ? `x${dozenMarketOdds[1].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={isThirdDozenSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(dozenMarket, dozenMarketOdds[2])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.THIRD_TWELVE}
				text={`${t("common.3rd")} 12`}
				subText={dozenMarketOdds[2]?.factor ? `x${dozenMarketOdds[2].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
		</div>
	);
};

export default DozenMarkets;
