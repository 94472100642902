import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { initTranslations } from "translations/config";

import Loader from "components/ui/loader";
import KenoWrapper from "components/pages/keno/wrapper";

import { refreshStreamAccess, getSessionGames } from "store/actions/auth/auth.actions";
import { getUrlVars } from "utils/common";
import { GAME_TYPE, GAME_TYPE_TEXT_KEYS } from "constants/game.constants.js";
import LuckySixWrapper from "components/pages/luckySix/wrapper";
import Spin2WinWrapper from "components/pages/spin2Win/wrapper";

const WRAPPER_MAPPING = {
	[GAME_TYPE.LUCKY_SIX]: LuckySixWrapper,
	[GAME_TYPE.KENO]: KenoWrapper,
	[GAME_TYPE.SPIN_TO_WIN]: Spin2WinWrapper,
}

/** Web Page Layout Component */
const Layout = ({ translationsLoaded, sessionLoaded, stream, refreshStreamAccess, gameType, getSessionGames }) => {
	const [decodedData, setDecodedData] = useState(null);

	const streamRefreshToken = decodedData?.refreshToken ?? null;
	const WrapperComponent = WRAPPER_MAPPING[gameType];
	const isLoaded = translationsLoaded && stream.accessLoaded && sessionLoaded;

	const scale = () => {
		const coificientX = window.innerWidth / 1920;
		const coificientY = window.innerHeight / 1080;
		document.body.style.transform = "scaleX(" + coificientX + ") scaleY(" + coificientY + ")";
	};

	/** Scale functionality */
	useEffect(() => {
		scale();
		window.addEventListener("resize", scale);
		return () => {
			window.removeEventListener("resize", scale);
		};
	}, []);

	/** Load translations */
	useEffect(() => {
		document.documentElement.classList.add(`vs--html-${GAME_TYPE_TEXT_KEYS[gameType]}`);
		let streamData = { languageId: "en" };
		try {
			const streamEncodedData = getUrlVars()["data"];
			streamData = JSON.parse(decodeURIComponent(atob(streamEncodedData)));
			setDecodedData(streamData);
		} catch (ex) {
			console.log("can't decode data");
		}

		initTranslations(streamData.languageFilePath, streamData.languageId, streamData.languageFilePath, streamData.languageId, Function.prototype, Function.prototype);
	}, [gameType]);

	useEffect(() => {

		if (translationsLoaded && decodedData) {
			const sessionTemplateType = decodedData?.sessionTemplateType ?? null;
			const streamRefreshToken = decodedData?.refreshToken ?? null;

			if (sessionTemplateType !== null) {
				document.documentElement.setAttribute("data-template", sessionTemplateType);
			}
			if (streamRefreshToken !== null) {
				refreshStreamAccess(streamRefreshToken);
			}
		}
	}, [translationsLoaded, decodedData]);

	useEffect(() => {
		if (stream.accessLoaded) {
			getSessionGames(true);
		}
	}, [stream.accessLoaded]);

	return (
		<div className={`vs--container vs--container-${GAME_TYPE_TEXT_KEYS[gameType]}`}>
			<div className={`vs--container-${GAME_TYPE_TEXT_KEYS[gameType]}-blur`}>
				{isLoaded ? (
					<WrapperComponent decodedData={decodedData} />
				) : (
					<Loader full={true} />
				)}
			</div>
		</div>
	);
};

/** Layout propTypes
 * PropTypes
 */
Layout.propTypes = {
	/** Redux state porperty, Is true when translations are loaded */
	translationsLoaded: PropTypes.bool,
	/** Redux state property, the object of stream */
	stream: PropTypes.object,
	/** Redux action to refresh stream access */
	refreshStreamAccess: PropTypes.func,
	/** Game Type Number */
	gameType: PropTypes.number.isRequired,
	/** Redux state property, is true when games data is loaded */
	sessionLoaded: PropTypes.bool,
	/** Redux action to get games */
	getSessionGames: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		translationsLoaded: state.auth.translationsLoaded,
		sessionLoaded: state.auth.sessionLoaded,
		stream: state.auth.stream
	};
};

const mapDispatchToProps = (dispatch) => ({
	refreshStreamAccess: (refreshToken) => {
		dispatch(refreshStreamAccess(refreshToken));
	},
	getSessionGames: (fromStream) => {
		dispatch(getSessionGames(fromStream));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
