import { Fragment } from "react";
import { useSelector } from "react-redux";
import MonitorBody from "../sections/monitorBody";
import MonitorTitle from "../sections/monitorTitle";
import MonitorSubtitle from "../sections/monitorSubtitle";
import AnimatedPages from "../animatedPages";
import AnimatedPage from "../animatedPages/animatedPage";
import MarketPage from "../marketPage";
import ContentGroup from "../contentGroup";
import ContentList from "../contentList";
import TeamsInfo from "./teamsInfo";
import Odd from "./odd";
import { buildPathToStaticFolderOfCDN, mergeClassNames, numberWithSpaces } from "utils/common";
import { useTranslation } from "react-i18next";

const SuggestedMarkets = ({ calculatedData = {}, groupsInPages = [], status = null, seasonId = null, orderNumber = null, onFinishScenes = null, allowMarketsHighlights = false, timeoutsConfig, subTitleChildren = null }) => {
	const { t } = useTranslation();
	const { parentEvent, childEvents } = calculatedData;
	const teamsHeadToHead = useSelector((state) => state.season.teamsHeadToHead);

	return (
		<section className="live-monitor">
			<MonitorTitle status={status} seasonId={seasonId} orderNumber={orderNumber} />
			<MonitorSubtitle event={parentEvent} logoSrc={buildPathToStaticFolderOfCDN("cashier-images/live-monitor-logo.svg")}>
				{subTitleChildren}
			</MonitorSubtitle>
			<div className="live-monitor-body">
				<AnimatedPages initialMountValue={true} perPage={false} className="vs--flex vs--flex-row" style={{ width: 1920 }} pageCount={2}>
					<AnimatedPage
						pageKey={0}
						className="live-monitor-left-to-right-animation vs--flex vs--flex-row"
						style={{
							position: "relative",
							zIndex: 2,
							"--delay": timeoutsConfig?.mainDelay ? timeoutsConfig?.mainDelay + "ms" : null,
							"--duration": timeoutsConfig?.mainDuration ? timeoutsConfig?.mainDuration + "ms" : null
						}}
					>
						<ContentGroup
							title={null}
							titleClassName="monitor-bg-header vs--mb-4 monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
							contentClassName="vs--flex vs--flex-row"
							className="vs--flex-grow"
							style={{ width: 460 }}
							titleStyle={{ height: 60 }}
							contentStyle={{ columnGap: 2 }}
						>
							<ContentList
								header={<Fragment>&#8470;</Fragment>}
								headerClassName="vs--pr-2 monitor-content-title-shadow monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-2] vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-center vs--align-center"
								style={{ width: 66, flex: "0 0 66px" }}
								headerStyle={{ height: 60, zIndex: 1 }}
								bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
							>
								{childEvents.map(({ event }) => {
									return (
										<ContentList.ListItem key={event.id} className="vs--pr-2 monitor-border monitor-border-grey monitor-border-bottom vs--fs-28 vs--font-medium list-row-color list-row-bg vs--flex vs--justify-center vs--align-center" style={{ height: 80 }}>
											{event.orderNumber}
										</ContentList.ListItem>
									);
								})}
							</ContentList>
							<ContentList
								header={<Fragment>{t("livemonitor.football_england_league.teams")}</Fragment>}
								headerClassName="vs--font-uppercas vs--font-uppercas vs--pr-2 monitor-content-title-shadow monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-2] vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-center vs--align-center"
								bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-2]"
								style={{ width: 392, flex: "0 0 392px" }}
								headerStyle={{ height: 60, width: "100%", zIndex: 1 }}
							>
								{childEvents.map(({ event }) => {
									return (
										<ContentList.ListItem key={event.id} className="monitor-border monitor-border-grey monitor-border-bottom vs--fs-28 vs--font-medium list-row-color list-row-bg vs--flex vs--justify-center vs--align-center" style={{ height: 80 }}>
											<TeamsInfo
												event={event}
												showGoalCount={false}
												showLastResults={true}
												textElementClassName="vs--fs-28 vs--font-medium vs--letter-spacing-0"
												middleColumnClassName="vs--fs-28 vs--font-medium vs--letter-spacing-0"
												leftColumnStyle={{ rowGap: 8, width: 122 }}
												middleColumnStyle={{ width: 88 }}
												rightColumnStyle={{ rowGap: 8, width: 122 }}
												teamsHeadToHead={teamsHeadToHead}
											/>
										</ContentList.ListItem>
									);
								})}
							</ContentList>
						</ContentGroup>
					</AnimatedPage>
					<AnimatedPage
						pageKey={1}
						className="live-monitor-left-to-right-animation"
						style={{
							width: 1460,
							"--delay": timeoutsConfig?.mainDelay ? timeoutsConfig?.mainDelay + "ms" : null,
							"--duration": timeoutsConfig?.mainDuration ? timeoutsConfig?.mainDuration + "ms" : null
						}}
					>
						<AnimatedPages initialMountValue={false} className="vs--ml-2" style={{ width: 1460, height: "100%", position: "relative" }} perPage={true} perChildAnimationDelay={timeoutsConfig?.mainPerChildTimout} pageCount={groupsInPages.length}>
							{groupsInPages.map((groups, index) => {
								return (
									<AnimatedPage
										key={index}
										pageKey={index}
										className="live-monitor-left-to-right-animation vs--flex vs--flex-row"
										style={{
											width: 1460,
											position: "absolute",
											top: 0,
											columnGap: 2,
											"--delay": timeoutsConfig?.mainDelay ? timeoutsConfig?.mainDelay + "ms" : null,
											"--duration": timeoutsConfig?.mainDuration ? timeoutsConfig?.mainDuration + "ms" : null
										}}
										onAnimationEnd={index === groupsInPages.length - 1 ? onFinishScenes : null}
									>
										{groups.map(([marketKey, marketInfo], i) => {
											return (
												<ContentGroup
													key={i}
													title={t(`livemonitor.football_england_league.${marketInfo.groupTranslation}`, marketInfo.groupTranslation)}
													titleClassName="vs--font-uppercas monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-1] vs--flex vs--flex-col vs--justify-center vs--align-center monitor-bg-header heading-color vs--fs-28 vs--font-medium vs--mb-4"
													contentClassName="vs--flex vs--flex-row"
													className="vs--flex-grow"
													titleStyle={{ height: 60 }}
													style={{ width: marketInfo.groupWidth, flex: `0 0 ${marketInfo.groupWidth}px` }}
												>
													<ContentList
														header={marketInfo.outcomes.map((outcome, i) => {
															return (
																<div
																	key={outcome + i}
																	style={{ height: "100%", flex: `0 0 ${marketInfo.outcomesWidth[i]}px`, maxWidth: marketInfo.outcomesWidth[i] }}
																	className={mergeClassNames(marketInfo.separateOutcomes && i < marketInfo.outcomes.length - 1 && "monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-1]")}
																>
																	<div className="vs--font-uppercas vs--height-full vs--width-full vs--font-uppercas vs--flex vs--justify-center vs--align-center">
																		<span className="vs--pr-2 vs--fs-28 vs--font-medium">{t(`livemonitor.football_england_league.${marketInfo.outcomeTranslations[i]}`, marketInfo.outcomeTranslations[i])}</span>
																	</div>
																</div>
															);
														})}
														headerClassName="monitor-content-title-shadow monitor-border monitor-border-white monitor-border-right monitor-border-right_right-[-1] vs--fs-28 vs--font-medium monitor-bg-header vs--flex vs--justify-around vs--align-center"
														style={{ width: "100%" }}
														headerStyle={{ height: 60, width: "100%", zIndex: 1, columnGap: 2 }}
														bodyClassName="monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-1]"
													>
														{childEvents.map((eventInfo) => {
															const { event, markets } = eventInfo;
															const currentMarkets = markets[marketKey].find((marketOfEvent) => {
																if (marketOfEvent.group !== marketInfo.group) {
																	return false;
																}
																if (marketOfEvent.argument === null || marketOfEvent.argument === undefined) {
																	return true;
																}
																if (marketInfo.argument === null || marketInfo.argument === undefined) {
																	return true;
																}
																return marketOfEvent.argument.toString() === marketInfo.argument.toString();
															});
															const oddList = currentMarkets.odds.at(0) || [];
															return (
																<ContentList.ListItem key={event.id} className="monitor-border monitor-border-grey monitor-border-bottom list-row-color list-row-bg vs--flex vs--justify-around vs--align-center" style={{ height: 80, width: "100%", columnGap: 2 }}>
																	{marketInfo.outcomes.map((outcome, i) => {
																		const odd = oddList.find((odd) => Boolean(odd) && odd.outcome && odd.outcome === outcome) || null;
																		return (
																			<Odd
																				key={i}
																				odd={odd}
																				displayShowName={false}
																				allowMarketsHighlights={allowMarketsHighlights}
																				className={mergeClassNames(marketInfo.separateOdds && i < marketInfo.outcomes.length - 1 && "monitor-border monitor-border-grey monitor-border-right monitor-border-right_right-[-1]")}
																				style={{ height: "100%", flex: `0 0 ${marketInfo.outcomesWidth[i]}px`, maxWidth: marketInfo.outcomesWidth[i] }}
																				textClassName="vs--font-exta-bigest vs--font-medium"
																				textContainerClassName="vs--pr-2 vs--height-full vs--width-full vs--font-uppercas vs--flex vs--flex-col vs--justify-center vs--align-center"
																				lockClassName="vs--fs-40 vs--font-medium"
																			/>
																		);
																	})}
																</ContentList.ListItem>
															);
														})}
													</ContentList>
												</ContentGroup>
											);
										})}
									</AnimatedPage>
								);
							})}
						</AnimatedPages>
					</AnimatedPage>
				</AnimatedPages>
			</div>
		</section>
	);
};
export default SuggestedMarkets;
