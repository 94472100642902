import { Fragment, useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getFootballLeagueStatistics, getFootballTeamToTeamStatistics } from "store/actions/season/season.actions";
import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { MAIN_SCENES, MARKETS_GROUP_NAME } from "constants/liveMonitor.constants";
import { generateMarketsPerPeageGroups } from "utils/liveMonitor";
import { mergeClassNames, numberWithSpaces } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";
import { getEvent, removeFromLiveAndUpcomingsLiveMonitor } from "store/actions/game/game.actions";
import useEvent from "hooks/useEvent";
import SuggestedMarkets from "./suggestedMarkets";
import LeagSpecialAndStatistics from "./leagSpecialAndStatistics";
import LiveMarkets from "./liveMarkets";
import ResultsMarkets from "./resultsMarkets";
import MonitorJackpotPools from "../monitorJackpotPools";

const SCENES = MAIN_SCENES[GAME_TYPE.ENGLISH_LEAGUE];
const groupsInPagesSuggested = generateMarketsPerPeageGroups(GAME_TYPE.ENGLISH_LEAGUE, MARKETS_GROUP_NAME.REGULAR);
const groupsInPagesLive = generateMarketsPerPeageGroups(GAME_TYPE.ENGLISH_LEAGUE, MARKETS_GROUP_NAME.LIVE);
const groupsInPagesResults = generateMarketsPerPeageGroups(GAME_TYPE.ENGLISH_LEAGUE, MARKETS_GROUP_NAME.RESULTS);
const getStatus = (event) => (event ? Object.entries(GAME_STATUSES).find(([key, value]) => value === event.status)?.[0] || "EMPTY" : "EMPTY");

const EnglishLeague = ({ current, monitorEvent, monitorSpecial, timeoutsConfig, jackpotInfo }) => {
	const { t } = useTranslation();

	const [data, setData] = useState(null);
	const [scene, setScene] = useState(null);
	const dispatch = useDispatch();
	const rtPs = useSelector((state) => state.auth.stream.rtps);
	const projectId = useSelector((state) => state.auth.session.projectId);
	const isFirstTimeRef = useRef(true);

	const calcMarkets = useEvent((eventInfo, orderNumber) => {
		const markets = runMarketUtilsFunction("makeAllMarkets", [eventInfo?.markets || [], eventInfo?.gameData || {}, rtPs, eventInfo?.gameType || 0, null, orderNumber], eventInfo?.gameType || 0);

		return [eventInfo, markets];
	});

	const calculateSceneData = useEvent((event) => {
		const settledResults = [
			calcMarkets(event, event.orderNumber),
			...(event?.events || []).map((childEvent) => {
				return calcMarkets(childEvent, childEvent.orderNumber);
			})
		];

		const calculatedData = settledResults.reduce(
			(acc, value) => {
				const [event, markets] = value;

				if (event.type === GAME_EVENT_TYPE.WEEK) {
					acc.parentEvent = { event, markets };
				}

				if (event.type === GAME_EVENT_TYPE.EVENT) {
					acc.childEvents.push({ event, markets });
				}

				return acc;
			},
			{ parentEvent: null, childEvents: [] }
		);

		return { calculatedData, groupsInPagesSuggested, groupsInPagesLive, groupsInPagesResults };
	});

	useEffect(() => {
		dispatch(getFootballLeagueStatistics(projectId, true, GAME_TYPE.ENGLISH_LEAGUE));
	}, []);

	useEffect(() => {
		if (!monitorEvent || !isFirstTimeRef.current) {
			return;
		}
		dispatch(
			getFootballTeamToTeamStatistics(
				monitorEvent.gameType,
				monitorEvent.events.map((event) => {
					return [event.gameData.team1.countryCode, event.gameData.team2.countryCode];
				}),
				true
			)
		);
		isFirstTimeRef.current = false;
	}, [monitorEvent]);

	useEffect(() => {
		if (!rtPs || !monitorEvent || current !== monitorEvent.id || (monitorEvent.status === GAME_STATUSES.FINISHED && monitorEvent.events && monitorEvent.events.some((event) => event.status !== GAME_STATUSES.FINISHED))) {
			return;
		}

		const sceneData = calculateSceneData(monitorEvent);
		setData(sceneData);

		switch (monitorEvent.status) {
			case GAME_STATUSES.STARTED:
				setScene(SCENES.LIVE_INFO);
				break;
			case GAME_STATUSES.FINISHED:
				dispatch(getFootballLeagueStatistics(projectId, true, GAME_TYPE.ENGLISH_LEAGUE));
				dispatch(
					getFootballTeamToTeamStatistics(
						monitorEvent.gameType,
						monitorEvent.events.map((event) => {
							return [event.gameData.team1.countryCode, event.gameData.team2.countryCode];
						}),
						true
					)
				);
				setScene(SCENES.RESULTS);
				break;
			default:
				setScene((prev) => {
					if ([SCENES.SUGGESTED_MARKETS, SCENES.LEAG_SPECIAL_AND_STATISTICS].includes(prev)) {
						return prev;
					}
					return SCENES.SUGGESTED_MARKETS;
				});
				break;
		}
	}, [monitorEvent, /* current, */ rtPs, projectId]);

	const subTitleChildren = useMemo(() => {
		const status = data?.calculatedData.parentEvent.event.status;
		const disableAnimation = (
			[SCENES.RESULTS].includes(scene) ||
			[GAME_STATUSES.FINISHED, GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(data?.calculatedData.parentEvent.event.status)
		);
		return (
			!!jackpotInfo?.levels
				? <MonitorJackpotPools jackpotInfo={jackpotInfo} disableAnimation={disableAnimation} />
				: null
		)
	}, [jackpotInfo, scene, data?.calculatedData.parentEvent.event.status])

	if (!data) {
		return;
	}
	return SCENES.LIVE_INFO === scene ? (
		<LiveMarkets
			id={data.calculatedData.parentEvent.event.id}
			calculatedData={data.calculatedData}
			groupsInPages={data.groupsInPagesLive}
			status={data.calculatedData.parentEvent.event.status}
			seasonId={data.calculatedData.parentEvent.event.seasonId}
			orderNumber={data.calculatedData.parentEvent.event.orderNumber}
		/>
	) : SCENES.RESULTS === scene ? (
		<ResultsMarkets
			calculatedData={data.calculatedData}
			groupsInPages={data.groupsInPagesResults}
			status={data.calculatedData.parentEvent.event.status}
			seasonId={data.calculatedData.parentEvent.event.seasonId}
			orderNumber={data.calculatedData.parentEvent.event.orderNumber}
			onFinishScenes={() => {
				dispatch(removeFromLiveAndUpcomingsLiveMonitor(data.calculatedData.parentEvent.event.id));
			}}
			timeoutsConfig={timeoutsConfig}
			subTitleChildren={subTitleChildren}
		/>
	) : scene === SCENES.SUGGESTED_MARKETS ? (
		<SuggestedMarkets
			id={data.calculatedData.parentEvent.event.id}
			calculatedData={data.calculatedData}
			groupsInPages={data.groupsInPagesSuggested}
			status={data.calculatedData.parentEvent.event.status}
			seasonId={data.calculatedData.parentEvent.event.seasonId}
			orderNumber={data.calculatedData.parentEvent.event.orderNumber}
			onFinishScenes={() => {
				if (scene === SCENES.SUGGESTED_MARKETS) {
					setScene(SCENES.LEAG_SPECIAL_AND_STATISTICS);
				}
			}}
			timeoutsConfig={timeoutsConfig}
			subTitleChildren={subTitleChildren}
		/>
	) : scene === SCENES.LEAG_SPECIAL_AND_STATISTICS ? (
		<LeagSpecialAndStatistics
			id={data.calculatedData.parentEvent.event.id}
			gameType={data.calculatedData.parentEvent.event.gameType}
			calculatedData={data.calculatedData}
			status={data.calculatedData.parentEvent.event.status}
			seasonId={data.calculatedData.parentEvent.event.seasonId}
			orderNumber={data.calculatedData.parentEvent.event.orderNumber}
			monitorSpecial={monitorSpecial}
			onFinishScenes={() => {
				if (scene === SCENES.LEAG_SPECIAL_AND_STATISTICS) {
					setScene(SCENES.SUGGESTED_MARKETS);
				}
			}}
			timeoutsConfig={timeoutsConfig}
			subTitleChildren={subTitleChildren}
		/>
	) : null;
};
export default EnglishLeague;
