import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { mergeClassNames } from "utils/common.js";
import eventType from "types/event.type.js";
import { useEffect, useMemo } from "react";
import { GAME_TYPE } from "constants/game.constants.js";
import { useDispatch, useSelector } from "react-redux";
import { getEvent } from "store/actions/game/game.actions.js";
import BetBall from "components/pages/luckySix/components/betBall";
import FirstMarket from "components/pages/luckySix/components/markets/firstMarket";
import FiveMarket from "components/pages/luckySix/components/markets/fiveMarket";
import FirstAndLastMarket from "components/pages/luckySix/components/markets/firstAndLastMarket";
import MostlyDrawnMarket from "components/pages/luckySix/components/markets/mostlyDrawnMarket";
import LastMarket from "components/pages/luckySix/components/markets/lastMarket";

const FinishedGameResults = ({ simpleView, currentEvent }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const matches = useSelector((state) => state.game.matches.data);
	const currentGameMarkets = useMemo(() => {
		return matches.find((match) => match.id === currentEvent.id)?.markets?.main;
	}, [currentEvent.id, matches]);

	const luckySixRtPs = useSelector((state) => state.auth.stream.rtps);
	const firstFiveScenes = useMemo(() => {
		return currentEvent.gameData.scenes.slice(0, 5);
	}, [currentEvent.gameData.scenes]);

	const lastScenes = useMemo(() => {
		const columns = [[], [], []]; // 3 columns

		currentEvent.gameData.scenes.slice(5, currentEvent.gameData.scenes.length).forEach((scene, index) => {
			columns[index % 3].push({ ...scene, luckySixRtPs: luckySixRtPs[index]?.margin });
		});
		return columns;
	}, [currentEvent.gameData.scenes, luckySixRtPs]);

	useEffect(() => {
		if (!simpleView) {
			dispatch(getEvent(currentEvent.id, true));
		}
	}, [dispatch, currentEvent.id, simpleView]);

	const colorsStatistic = useMemo(() => {
		if (!simpleView) {
			let temp = {};
			currentEvent.gameData.scenes.forEach((game) => {
				if (temp[game.ballColour]) {
					temp[game.ballColour] = temp[game.ballColour] + 1;
				} else {
					temp[game.ballColour] = 1;
				}
			});
			return temp;
		}
	}, [currentEvent.gameData.scenes, simpleView]);

	const sumOfFiveNumbers = useMemo(() => {
		if (currentEvent?.gameData?.scenes?.length >= 5) {
			return currentEvent?.gameData?.scenes.slice(0, 5).reduce((acc, cur) => {
				acc += cur.number;
				return acc;
			}, 0);
		}
		return null;
	}, [currentEvent?.gameData?.scenes]);

	return (
		<div className="vs--lucky-six-live-monitor-finished-game-results">
			<div className="vs--lucky-six-live-monitor-finished-game-results-title">
				{simpleView ? `${t("common.roundId")} ${currentEvent.id}` : t("common.lastEventResults")}
			</div>
			<div
				className={mergeClassNames(
					"vs--lucky-six-live-monitor-finished-game-results-container",
					simpleView ? "simple-view" : ""
				)}
			>
				{simpleView ? (
					<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls simple-view">
						<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-item simple-view">
							{firstFiveScenes.map((scene, index) => (
								<div
									className="vs--lucky-six-live-monitor-finished-game-results-container-balls-item-wrapper simple-view"
									key={index}
								>
									<BetBall number={scene.number} color={scene.ballColour} />
								</div>
							))}
						</div>

						<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-item simple-view">
							{lastScenes.map((scanes) =>
								scanes.map((scene, index) => (
									<div
										className={`vs--lucky-six-live-monitor-finished-game-results-container-balls-item-wrapper simple-view`}
										key={index}
									>
										<BetBall number={scene.number} color={scene.ballColour} />
									</div>
								))
							)}
						</div>
					</div>
				) : (
					<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls">
						<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-item-top ">
							{firstFiveScenes.map((scene, index) => (
								<div key={index}>
									<BetBall number={scene.number} color={scene.ballColour} />
								</div>
							))}
						</div>

						<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-item">
							{lastScenes.map((scenes) =>
								scenes.map((scene, index) => (
									<div
										className={`vs--lucky-six-live-monitor-finished-game-results-container-balls-item-wrapper index-${index}`}
										key={index}
									>
										<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-item-wrapper-ball">
											<BetBall number={scene.number} color={scene.ballColour} />
										</div>
										<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-item-wrapper-number">
											{scene.luckySixRtPs}x
										</span>
									</div>
								))
							)}
						</div>

						{currentGameMarkets && (
							<>
								<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer">
									<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item">
										<FirstMarket
											markets={currentGameMarkets}
											currentEventScenes={currentEvent?.gameData?.scenes}
										/>
									</div>
									<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item">
										<FiveMarket markets={currentGameMarkets} sumOfFiveNumbers={sumOfFiveNumbers} />
									</div>

									<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item">
										<FirstAndLastMarket
											markets={currentGameMarkets}
											currentEventScenes={currentEvent?.gameData?.scenes}
										/>
									</div>
								</div>

								<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer">
									<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item big">
										<MostlyDrawnMarket
											markets={currentGameMarkets}
											colors={colorsStatistic}
											currentEventScenes={currentEvent?.gameData?.scenes}
										/>
									</div>
									<div className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item big">
										<LastMarket
											markets={currentGameMarkets}
											currentEventScenes={currentEvent?.gameData?.scenes}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				)}
			</div>

			<div className="vs--lucky-six-live-monitor-finished-game-results-footer"></div>
		</div>
	);
};

FinishedGameResults.propTypes = {
	simpleView: PropTypes.bool.isRequired,
	currentEvent: eventType
};

export default FinishedGameResults;
