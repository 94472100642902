import { PERIOD } from "constants/market.constants";
import BaseMarkets from "./baseMarkets";
import i18next from "i18next";
import { GAME_TYPE, LUCKY_SIX_MARKETS } from "constants/game.constants.js";

class LuckySixMarkets extends BaseMarkets {
	getGroupsStructure() {
		return {
			main: [
				{
					group: LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER,
					argument: 24.5,
					outcomes: "*",
					marketsCount: 2
				},
				{
					group: LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER,
					argument: 24.5,
					outcomes: "*",
					marketsCount: 2
				},
				{
					group: LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN,
					outcomes: "*",
					marketsCount: 2
				},
				{
					group: LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN,
					outcomes: "*",
					marketsCount: 2
				},
				{
					group: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER,
					argument: 122.5,
					outcomes: "*",
					marketsCount: 2
				},
				{
					group: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN,
					outcomes: "*",
					marketsCount: 2
				},
				{
					group: LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN,
					outcomes: "*",
					marketsCount: 2
				},
				{
					group: LUCKY_SIX_MARKETS.FIRST_BALL_COLOR,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.FIRST_BALL_COLOR,
					outcomes: "*",
					marketsCount: 8
				},
				{
					group: LUCKY_SIX_MARKETS.LAST_BALL_COLOR,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.LAST_BALL_COLOR,
					outcomes: "*",
					marketsCount: 8
				},
				{
					group: LUCKY_SIX_MARKETS.FIRST_LAST_NUMBER_SUM,
					period: PERIOD.NONE,
					title: LUCKY_SIX_MARKETS.FIRST_LAST_NUMBER_SUM,
					argument: 49.5,
					outcomes: "*",
					marketsCount: 2
				}
			]
		}
	}

	getNameForMarketGroup(marketName, outcome, argument) {
		return `${outcome}${argument ? `(${argument})` : ""}`;
	}

	makeMarketsArray(data, gameData, rtps, gameType) {
		const obj = {};
		data.forEach((d) => {
			const key = typeof d.argument === "number" ? d.title + "_" + d.argument : d.title;
			obj[key] = {
				group: d.group,
				odds: Array(d.marketsCount > d.matching.length ? d.matching.length : d.marketsCount).fill(
					d.outcomes !== "*" ? d.outcomes : (d.matching[0] ? d.matching[0].oddList : []).map((odd) => odd.outcome)
				),
				matching: d.matching,
				marketCol: d.marketCol,
				marketsCount: d.marketsCount,
				type: d.type,
				argument: d.argument,
				gridBy: d.gridBy,
				oddBy: d.oddBy
			};
		});

		Object.keys(obj).forEach((o) => {
			obj[o].oddsCopy = obj[o].odds.map((m) => m.slice());
			for (let index = 0; index < obj[o].oddsCopy.length; index++) {
				const matchingOdd = obj[o].matching[index];
				if (matchingOdd) {
					for (let a = 0; a < matchingOdd.oddList.length; a++) {
						const odd = matchingOdd.oddList[a];
						if (odd) {
							const i = obj[o].oddsCopy[index].indexOf(odd.outcome);
							obj[o].odds[index] = [...obj[o].odds[index]];
							obj[o].odds[index][i] = {
								name: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument),
								factor: odd.factor,
								oddState: odd.state,
								outcome: odd.outcome,
								argument: matchingOdd.argument,
								id: odd.id,
								showName: this.makeName({
									str: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument),
									gameData,
									group: matchingOdd.group,
									argument: matchingOdd.argument,
									gameType: GAME_TYPE.LUCKY_SIX
								}),
								ticketShowName: this.makeName({
									str: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument),
									gameData,
									group: matchingOdd.group,
									argument: matchingOdd.argument,
									gameType: GAME_TYPE.LUCKY_SIX,
									isTicket: true
								}),
								rectData: this.getRectData({
									outcome: this.getNameForMarketGroup(matchingOdd.group, odd.outcome, matchingOdd.argument),
									group: matchingOdd.group,
									gameData,
									gameType: GAME_TYPE.LUCKY_SIX
								})
							};
						}
					}
				}
			}
			delete obj[o].matching;
			delete obj[o].oddsCopy;
		});

		const arr = [];
		Object.keys(obj).forEach((o) => {
			let item = {
				group: o.split("_")[0],
				argument: obj[o].argument,
				marketCol: obj[o].marketCol,
				marketsCount: obj[o].marketsCount,
				odds: obj[o].odds.map((oddGroup) =>
					oddGroup.map((odd) =>
						typeof odd === "string"
							? {
									name: this.getNameForMarketGroup(o.split("_")[0], odd),
									showName: this.makeName({
										str: this.getNameForMarketGroup(o.split("_")[0], odd),
										gameData,
										group: o.split("_")[0],
										argument: o.split("_")[1],
										gameType
									}),
									ticketShowName: this.makeName({
										str: this.getNameForMarketGroup(o.split("_")[0], odd),
										gameData,
										group: o.split("_")[0],
										argument: o.split("_")[1],
										gameType,
										isTicket: true
									})
								}
							: odd
					)
				),
				type: obj[o].type,
				gridBy: obj[o].gridBy,
				oddBy: obj[o].oddBy
			};
			item.odds = item.odds.map((oddGroup) => oddGroup.filter((odd) => typeof odd !== "string"));
			arr.push(item);
		});

		return arr;
	}

	makeGroupTitle({ group }) {
		switch (group) {
			case LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER:
			case LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.firstNumber`);
			case LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER:
			case LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.lastNumber`);
			case LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER:
			case LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.preNumbers`);
			case LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.mostlyDrawn`);
			case LUCKY_SIX_MARKETS.FIRST_BALL_COLOR:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.firstBallColor`);
			case LUCKY_SIX_MARKETS.LAST_BALL_COLOR:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.lastBallColor`);
			case LUCKY_SIX_MARKETS.BALL_NUMBERS:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.selectedNumbers`);
			case LUCKY_SIX_MARKETS.FIRST_LAST_NUMBER_SUM:
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.firstLastNumberSum`);
		}
	}

	makeName({ str, argument }) {
		switch (true) {
			case str.includes("Over"):
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.over`) + ` (${argument})`;
			case str.includes("Under"):
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.under`) + ` (${argument})`;
			case str.includes("Odd"):
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.odd`);
			case str.includes("Even"):
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.even`);
			case str.includes("Yes"):
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.even`);
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.even`);
			case str.includes("No"):
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.odd`);
			case str.includes("Red"):
			case str.includes("Orange"):
			case str.includes("Yellow"):
			case str.includes("Green"):
			case str.includes("Blue"):
			case str.includes("Violet"):
			case str.includes("Purple"):
			case str.includes("Black"):
				return i18next.t(`markets.${GAME_TYPE.LUCKY_SIX}.${str.toLowerCase()}`);
		}
	}

	makeBetText(bet) {
		const { gameType, market: group, outcome, argument } = bet;

		return `${this.makeGroupTitle({ group, gameType })} : ${this.makeName({ str: outcome })} ${argument ?? ""}`;
	}
}

export default LuckySixMarkets;
