import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import useEvent from "hooks/useEvent";
import RaceRects from "components/ui/raceRects";
import { BETSLIP_MODES } from "constants/betslip.constants.js";
import { GAME_TYPE, NUMBER_TO_COLOR_MAPPER } from "constants/game.constants";
import { PAYMENT_TYPE } from "constants/common.constants";
import {
	removeBet,
	updateBetStake,
	placeBet,
	showPaymentMethod,
	setOnFocusBets,
	setOnBlurBets,
	setOnBlurBetsWithTimeout
} from "store/actions/betslip/betslip.actions";
import { isValidAmount, numberWithSpaces, makeCurrencyText, isLuckySixBallsBet } from "utils/common";
import betType from "types/bet.type";
import sessionType from "types/session.type";
import { isSeasonGame, isLeagueGame, isChampionsCupGame, isRacingGame, GetSeasonRoundText } from "utils/common";
import { GAME_EVENT_TYPE, GAME_TYPE_TEXT_KEYS } from "constants/game.constants";
import KEY_CODE from "constants/keyboard.constants";
import { offset, shift, flip } from "@floating-ui/dom";
import Tooltip from "components/ui/tooltip";

/** Single Bet Component in Betslip */
const SingleBet = ({
	bet,
	mode,
	removeBet,
	index,
	updateBetStake,
	bets,
	currency,
	paymentType,
	placeBet,
	stake,
	useBonus,
	showPaymentMethod,
	setOnFocusBets,
	setOnBlurBets,
	setOnBlurBetsWithTimeout,
	groupedRepeatedBets,
	...otherProps
}) => {
	const { t } = useTranslation();

	const didMountRef = useRef(false);
	const inputRef = useRef();

	const onFocus = useEvent(() => {
		setOnBlurBets();
		setOnFocusBets(bet);
	});
	const onBlur = useEvent(() => {
		setOnBlurBetsWithTimeout();
	});
	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.addEventListener("focus", onFocus);
				inputRef.current.addEventListener("blur", onBlur);
			}
		}, 0);
		return () => {
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.removeEventListener("focus", onFocus);
					inputRef.current.removeEventListener("blur", onBlur);
				}
			}, 0);
		};
	}, [bets]);

	/** Autofocus input */
	useEffect(() => {
		setTimeout(() => {
			let inputsList = document.querySelectorAll(".vs--single-bet-stake input");
			let inputEl = inputsList[0];
			inputEl && inputEl.focus();
		}, 0);
	}, []);

	/** Function which fires on stake input change
	 * @function
	 * @param {object} e - event object
	 * @memberOf SingleBet
	 */
	const onHandleInputChange = (e) => {
		if (useBonus) {
			return;
		}
		let value = e.target.value.replace(/\s/g, "");
		if (isValidAmount(value, currency?.decimalCount ?? 0)) updateBetStake(value, bet.key);
	};

	/** Function which fires on stake input key down
	 * @function
	 * @param {object} e - event object
	 * @memberOf SingleBet
	 */
	const onHandleInputKeyDown = (e) => {
		if (e.keyCode === KEY_CODE.ENTER) {
			if (useBonus) {
				placeBet(paymentType === PAYMENT_TYPE.CREDIT_CARD ? PAYMENT_TYPE.CREDIT_CARD : PAYMENT_TYPE.CASH);
			} else {
				if (paymentType === PAYMENT_TYPE.BOTH) {
					showPaymentMethod(true);
				} else {
					placeBet(paymentType);
				}
			}
		}
	};

	/** Function which checks if the stake is valid
	 * @function
	 * @param {string} value - stake value
	 * @returns {boolean}
	 * @memberOf SingleBet
	 */
	const hasError = (value) =>
		(Number(value) < (currency?.singleMin ?? 0) || Number(value) > (currency?.singleMax ?? Infinity)) && value !== "";

	/** Remove bet in 3sec after expiration */
	useEffect(() => {
		if (bet.expired) {
			setTimeout(() => {
				removeBet(bet.key);
			}, 3000);
		}
	}, [bet.expired]);

	/** Update stake input value on "stake per bet switcher" or common stake input value change */
	useEffect(() => {
		if (didMountRef.current) {
			if (!bet.isExpired) {
				updateBetStake(stake, bet.key);
			}
		} else didMountRef.current = true;
	}, [stake]);

	const renderFootballEvent = (bet) => {
		let txt = " - ";
		const team1 = bet?.gameData?.team1 ?? {};
		const team2 = bet?.gameData?.team2 ?? {};
		const gameType = bet?.gameType ?? 0;

		if (gameType) {
			switch (bet?.type ?? 0) {
				case GAME_EVENT_TYPE.LEAGUE:
					txt = t(`common.${GAME_TYPE_TEXT_KEYS[gameType]}`);
					break;
				case GAME_EVENT_TYPE.WEEK:
					txt = `${t(`common.${GAME_TYPE_TEXT_KEYS[gameType]}`)}: ${GetSeasonRoundText(gameType, bet?.orderNumber ?? 0)}`;
					break;
				case GAME_EVENT_TYPE.EVENT:
					txt =
						isLeagueGame(gameType) || isChampionsCupGame(gameType)
							? `${team1.countryCode ?? ""} vs ${team2.countryCode ?? ""}`
							: `${t(`countries.${team1.countryCode ?? ""}`)} - ${t(`countries.${team2.countryCode ?? ""}`)}`;
					break;
				default:
					break;
			}
		}

		return <span>{txt}</span>;
	};

	return (
		<div
			className={
				"vs--single-bet vs--mb-2 vs--pt-12 vs--pb-12 vs--pl-11 vs--pr-12 vs--flex vs--flex-col" +
				(bet.expired ? " vs--single-bet-expired" : "")
			}
			data-expired={t("bet.expired")}
			data-index={index}
			{...otherProps}
		>
			<div className="vs--flex vs--flex-row vs--justify-start vs--flex-equal">
				<div className="vs--bet-remove vs--flex vs--flex-col vs--align-center vs--justify-start">
					<div className="vs--single-bet-remove vs--pt-12 vs--pr-14 vs--pb-4" onClick={() => removeBet(bet.key)}>
						<i className="ic_close" />
					</div>
				</div>
				<div
					className="vs--flex vs--flex-col vs--align-center vs--justify-start vs--single-bet-text"
					data-game={bet.gameType}
				>
					<div
						style={{ border: "0px solid blue" }}
						className="vs--flex vs--flex-row vs--flex-equal vs--justify-start vs--single-bet-text-content"
					>
						{![GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX].includes(bet.gameType) ||
							(!isLuckySixBallsBet(bet) && (
								<div className={"vs--flex vs--align-center vs--single-bet-col"}>
									<Tooltip placement="bottom" title={bet.groupTitle}>
										<span className="vs--title vs--font-regular vs--single-bet-odd vs--align-center vs--font-exstrasmall vs--flex-column-value-ellipsed">
											{bet.groupTitle}
											:&nbsp;
										</span>
									</Tooltip>
								</div>
							))}

						{/* Value block */}
						<div className="vs--flex vs--align-center vs--flex-equal vs--pr-6 vs--single-bet-col-second">
							{isRacingGame(bet.gameType) && Array.isArray(bet.rectData) && bet.rectData.length > 0 ? (
								<RaceRects
									gameType={bet.gameType}
									rectData={bet.rectData}
									middlewares={[offset({ mainAxis: 6 }), flip(), shift()]}
									overlayClassName="vs--result-block-place-to-bottom"
								/>
							) : bet.gameType === GAME_TYPE.SPIN_TO_WIN ? (
								<span className="vs--title vs--font-regular vs--font-exstrasmall">
									<span>{bet.groupTitle}:&nbsp;</span>
									<span>{(bet?.showName ?? "")}</span>
								</span>
							) : ![GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX].includes(bet.gameType) || !isLuckySixBallsBet(bet) ? (
								<Tooltip placement="bottom" title={bet.showName}>
									<span className="vs--title vs--font-regular vs--single-bet-odd vs--font-exstrasmall">
										{bet.showName}
									</span>
								</Tooltip>
							) : bet.gameType === GAME_TYPE.KENO ? (
								<span className="vs--title vs--font-regular vs--single-bet-odd vs--single-bet-odd-keno vs--font-exstrasmall">
									<span>{bet.groupTitle}:&nbsp;</span>
									{(bet?.showName ?? "").split(", ").map((ball) => (
										<div key={ball} className="vs--flex">
											<div className="vs--markets-keno-selection-num-sphere vs--flex vs--align-center vs--justify-center vs--mr-4">
												<span className="vs--flex-wrap vs--font-mini">{ball}</span>
											</div>
										</div>
									))}
								</span>
							) : bet.gameType === GAME_TYPE.LUCKY_SIX && isLuckySixBallsBet(bet) ? (
								<span className="vs--title vs--font-regular vs--single-bet-odd vs--single-bet-odd-lucky-six vs--font-exstrasmall">
									<span>{bet.groupTitle}:&nbsp;</span>
									<div className="vs--lucky-six-pending-balls vs--mt-6">
										{(bet?.showName ?? "").split(", ").map((number) => (
											<div
												key={number}
												className={`vs--lucky-six-ball vs--lucky-six-ball-active vs--lucky-six-ball-${NUMBER_TO_COLOR_MAPPER[number % 8]} vs--flex vs--justify-center vs--align-center`}
											>
												<span className="vs--lucky-six-ball-text">{number}</span>
											</div>
										))}
									</div>
								</span>
							) : (
								""
							)}
						</div>
					</div>
					{bet?.gameType !== GAME_TYPE.KENO && (
						<div className="vs--flex vs--flex-row vs--flex-equal vs--justify-start vs--single-bet-text-content vs--mt-6">
							<span className="vs--title vs--font-regular vs--font-smallest vs--single-bet-teams vs--pb-14">
								{[GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(bet?.gameType) ||
								isSeasonGame(bet?.gameType)
									? renderFootballEvent(bet)
									: isRacingGame(bet?.gameType)
										? bet?.gameData?.venue ?? ""
										: ""}
							</span>
						</div>
					)}

					{mode === BETSLIP_MODES.SINGLE ? (
						<div className="vs--flex vs--flex-row vs--flex-equal vs--justify-start vs--single-bet-text-content">
							<div
								className={"vs--single-bet-stake" + (hasError(bet.stake) ? " vs--single-bet-stake-error" : "")}
							>
								<input
									ref={inputRef}
									disabled={useBonus}
									type="text"
									className="vs--font-regular vs--font-smallest vs--pl-16 vs--pr-16"
									value={numberWithSpaces(bet.stake)}
									onChange={onHandleInputChange}
									placeholder={t("bet.stake")}
									onKeyDown={onHandleInputKeyDown}
								/>
								{useBonus ? <i className="ic_gift vs--font-small" /> : null}
							</div>
						</div>
					) : null}
				</div>
				{/* Right block */}
				<div className="vs--flex vs--flex-col vs--align-center vs--justify-between vs--single-bet-right">
					<div className="vs--single-bet-factor vs--flex vs--align-center vs--justify-end">
						<Tooltip placement="bottom" title={numberWithSpaces(bet.factor)}>
							<span className="vs--title vs--font-medium vs--text-right vs--font-exstrasmall vs--flex-column-value-ellipsed">
								{numberWithSpaces(bet.factor)}
							</span>
						</Tooltip>
					</div>
					{mode === BETSLIP_MODES.SINGLE ? (
						<div
							className="vs--single-bet-pos-win vs--flex vs--flex-col vs--align-end"
							title={`${t("bet.possibleWin")}: ${makeCurrencyText(bet.stake * bet.factor, currency)}`}
						>
							<span className="vs--title vs--font-medium vs--mb-4">{t("bet.possibleWin")}</span>
							<span className="vs--title vs--font-medium vs--text-right">
								{makeCurrencyText(bet.stake.replaceAll(" ", "") * bet.factor, currency)}
							</span>
						</div>
					) : null}
				</div>
			</div>
			<div className="vs--flex vs--flex-row vs--justify-start vs--flex-equal vs--pl-28">
				{mode === BETSLIP_MODES.MULTI && groupedRepeatedBets?.[bet.eventId] ? (
					<div className="vs--flex vs--align-center vs--single-bet-error vs--mt-12">
						<i className="ic_warning"></i>
						<span className="vs--title vs--font-regular vs--font-smallest vs--pl-5">
							{t("bet.notCombinable")}
						</span>
					</div>
				) : null}
				{mode === BETSLIP_MODES.SINGLE ? (
					Number(bet.stake) > (currency?.singleMax ?? Infinity) && bet.stake !== "" ? (
						<div className="vs--flex vs--align-center vs--single-bet-error vs--mt-12">
							<i className="ic_warning"></i>
							<span className="vs--title vs--font-regular vs--font-smallest vs--pl-5">
								{t("bet.maxBetAmountIs")} {makeCurrencyText(currency?.singleMax ?? Infinity, currency)}{" "}
							</span>
						</div>
					) : Number(bet.stake) < (currency?.singleMin ?? 0) && bet.stake !== "" ? (
						<div className="vs--flex vs--align-center vs--single-bet-error vs--mt-12">
							<i className="ic_warning"></i>
							<span className="vs--title vs--font-regular vs--font-smallest vs--pl-5">
								{t("bet.minBetAmountIs")} {makeCurrencyText(currency?.singleMin ?? 0, currency)}
							</span>
						</div>
					) : null
				) : null}
			</div>
		</div>
	);
};

/** SingleBet propTypes
 * PropTypes
 */
SingleBet.propTypes = {
	/** The bet of component */
	bet: betType,
	/** Redux state property, current bets in betslip section */
	bets: PropTypes.arrayOf(betType),
	/** Redux state property, current betslip mode */
	mode: PropTypes.oneOf(Object.values(BETSLIP_MODES)),
	/** Redux action to remove bet from betslip bets */
	removeBet: PropTypes.func,
	/** Index of bet in bets array */
	index: PropTypes.number,
	/** Redux action to update single bet stake */
	updateBetStake: PropTypes.func,
	/** Redux state property, current session currency */
	currency: PropTypes.object,
	/** Redux state property, current session paymentType */
	paymentType: PropTypes.number,
	/** Redux action to place bet */
	placeBet: PropTypes.func,
	/** Redux state property, common stake of betslip */
	stake: PropTypes.string,
	/** Redux state property, is bonus available */
	useBonus: PropTypes.bool,
	/** Redux action to show/hide payment method modal */
	showPaymentMethod: PropTypes.func,
	/** Redux action to set focused stake input identifiers */
	setOnFocusBets: PropTypes.func,
	/** Redux action to clear focused input data */
	setOnBlurBets: PropTypes.func,
	/** Redux action to clear focused input data with using timeout */
	setOnBlurBetsWithTimeout: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		mode: state.betslip.mode,
		bets: state.betslip.bets,
		currency: state.auth.session.currency,
		paymentType: state.auth.session.paymentType,
		stake: state.betslip.stake,
		useBonus: state.bonuses.standard.useBonus
	};
};

const mapDispatchToProps = (dispatch) => ({
	removeBet: (key, oddId, eventId) => {
		dispatch(removeBet(key, oddId, eventId));
	},
	updateBetStake: (stake, key) => {
		dispatch(updateBetStake(stake, key));
	},
	placeBet: (method) => {
		dispatch(placeBet(method));
	},
	showPaymentMethod: (show) => {
		dispatch(showPaymentMethod(show));
	},
	setOnFocusBets: (bet) => {
		dispatch(setOnFocusBets({ key: bet.key, main: false }));
	},
	setOnBlurBets: () => {
		dispatch(setOnBlurBets());
	},
	setOnBlurBetsWithTimeout: () => {
		dispatch(setOnBlurBetsWithTimeout());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleBet);
