import Vector from "./vector";
import "./noise"

class FlowField {
	static settings = {
		// Defaults:
		frequency: 0.2,
	};

	constructor({ width, height, canvas, settings = {} }) {
		this.canvas = canvas;
		this.settings = { ...FlowField.settings, ...settings };
		this.ctx = canvas.getContext('2d');
		this.time = 0;
		this.setup()
	}

	defineAreas(setupConfigs = { tileSize: 40, tileRatio: 2 }) {
		const box = this.canvas.getBoundingClientRect();
		const container = this.canvas.parentElement;
		const tileSize = setupConfigs.tileSize; // Pixel width of tiles
		const tileRatio = setupConfigs.tileRatio; // y/x ratio

		// Set up height to match full container size
		this.canvas.height = box.width * (container.clientHeight / container.clientWidth);
		this.canvas.width = box.width;

		// Flowfield setup
		const cols = Math.ceil(container.clientWidth / tileSize);
		const rows = Math.ceil(container.clientHeight / (tileSize * tileRatio));

		const ctxScale = {
			x: this.canvas.width / cols,
			y: this.canvas.height / rows,
		};
		const widthColorScaling = 255 / cols;
		const heightColorScaling = 255 / rows;

		this.w = cols || 10;
		this.h = rows || 10;
		this.scaleConfigs = { ctxScale, widthColorScaling, heightColorScaling };
	}

	build() {
		this.cols = Math.ceil(this.w);
		this.rows = Math.ceil(this.h);

		// Prepare columns
		this.field = new Array(this.cols);
		for (let x = 0; x < this.cols; x++) {
			// Prepare rows
			this.field[x] = new Array(this.rows);
			for (let y = 0; y < this.rows; y++) {
				// Prepare data
				this.field[x][y] = new Vector(0, 0);
			}
		}
	}

	setup(setupConfigs) {
		this.defineAreas(setupConfigs);
		this.build(setupConfigs);
	}

	update(delta) {
		this.time += delta;
		const updateTime = (this.time * this.settings.frequency) / 1000;
		// Clear canvas
		this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
		for (let x = 0; x < this.field.length; x++) {
			for (let y = 0; y < this.field[x].length; y++) {

				// Update field
				const angle = noise.simplex3(x / 20, y / 20, updateTime) * Math.PI * 2;
				const length = noise.simplex3(x / 10 + 40000, y / 10 + 40000, updateTime);
				this.field[x][y].setAngle(angle);
				this.field[x][y].setLength(length);

				// Render field
				this.draw(this.field[x][y], x, y);
			}
		}
	}

	draw(vector, x, y) {

		// Vector angular size
		const xmove = vector.getLength() * Math.abs(vector.x);
		const ymove = vector.getLength() * Math.abs(vector.y);

		const { heightColorScaling, widthColorScaling, ctxScale } = this.scaleConfigs;

		// Color mapping
		const red = Math.round((-20 * xmove) + (80 * ymove) + (50 - (0.6 * (y + 10) * heightColorScaling)));
		const green = Math.round((180 * xmove) + (20 * ymove) - 60 + (0.4 * y * heightColorScaling));
		const blue = Math.round((50 * xmove) + (30 * ymove) + (40 - (0.5 * y * heightColorScaling)) + (0.5 * y * heightColorScaling));


		// Open
		this.ctx.save();

		// Draw tile
		this.ctx.fillStyle = `rgba(${red}, ${green}, ${blue}, 0.8)`;
		this.ctx.fillRect(x * ctxScale.x, y * ctxScale.y, ctxScale.x, ctxScale.y);

		// Close
		this.ctx.restore();
	}

	start() {
		if (this.isAnimated || this.isStope) {
			window.cancelAnimationFrame(this.lastAnimationFrameId);
			return;
		}

		// Animate
		let lastStep = 0;
		const runAnimationFrame = (time) => {
			if (this.isStope) {
				return;
			}
			this.isAnimated = true;
			this.update(time - lastStep || 0);
			lastStep = time;
			this.lastAnimationFrameId = window.requestAnimationFrame(runAnimationFrame);
		};
		runAnimationFrame();
	}

	stop() {
		this.isAnimated = false;
		this.isStope = true;
		window.cancelAnimationFrame(this.lastAnimationFrameId);
	}
}


export default FlowField;