import { NUMBER_TO_COLOR_MAPPER } from "constants/game.constants.js";
import { mergeClassNames } from "utils/common.js";
import { useSelector } from "react-redux";

const Ball = ({ number, disabled, handleBallClick, active }) => {
	const luckySixBalls = useSelector((state) => state.betslip.luckySixBalls);
	const color = NUMBER_TO_COLOR_MAPPER[number % 8];

	return (
		<div
			className={mergeClassNames(
				"vs--lucky-six-ball vs--flex vs--justify-center vs--align-center",
				`vs--lucky-six-ball-${color}`,
				(luckySixBalls.includes(number) || active) && "vs--lucky-six-ball-active",
				(disabled || (luckySixBalls.length === 6 && !luckySixBalls.includes(number))) &&
					"vs--lucky-six-ball-disabled"
			)}
			onClick={() => handleBallClick(number)}
		>
			<span className="vs--lucky-six-ball-text">{number}</span>
		</div>
	);
};

export default Ball;
