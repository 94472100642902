import { buildPathToStaticFolderOfCDN, isNullish, mergeClassNames } from "utils/common"
import "./styles.scss"
import { useTranslation } from "react-i18next"
import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants";
import RouletteBoardResultView from "../roulette-board/resultView";
import useEvent from "hooks/useEvent";
import { useContext, useEffect, useMemo, useState } from "react";
import ScenesFlowContext from "components/pages/scenesFlow/scenesFlowContext";

const Result = ({ event, onFinish: argOnFinish, onMount: argOnMount }) => {
	const { t } = useTranslation();
	const [number, setNumber] = useState(event?.gameData?.scenes?.at(0)?.number)

	const sceneFlowProviderValue = useContext(ScenesFlowContext)

	const finishedEvent = useMemo(() => {
		if (!event) {
			return event
		}

		if (!Array.isArray(event?.gameData?.scenes)) {
			return event
		}

		const newEvent = {
			...event,
			gameData: {
				...event.gameData,
				scenes: [
					...event?.gameData?.scenes
				]
			}
		}

		newEvent.gameData.scenes[0] = {
			...newEvent.gameData.scenes[0],
			number: number
		}

		return newEvent

	}, [event, number])

	const onMount = useEvent(() => {
		if (typeof argOnMount === "function") {
			argOnMount(sceneFlowProviderValue)
		}
	});
	const onFinish = useEvent(() => {
		if (typeof argOnFinish === "function") {
			argOnFinish(sceneFlowProviderValue)
		}
	});

	useEffect(() => {
		if (typeof event?.gameData?.scenes?.at(0)?.number !== "number") {
			return
		}
		setNumber(event?.gameData?.scenes?.at(0)?.number)
	}, [event?.gameData?.scenes?.at(0)?.number])

	useEffect(() => {
		const timeoutId = setTimeout(onFinish, 10000)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [])

	return (
		<div
			className="vs--spin-2-win-stream-scene vs--spin-2-win-stream-scene-result"
			onAnimationEnd={e => {
				if (e.animationName === "appear-to-view") {
					onMount()
				}
			}}
		>
			<img
				className="vs--spin-2-win-stream-scene-result-background"
				src={buildPathToStaticFolderOfCDN("cashier-images/stream/spin-to-win/live-process-bg.png")}
				alt="background"
			/>
			<div className="vs--spin-2-win-stream-result-background"></div>
			<div
				className="vs--spin-2-win-stream-result-container"
			>
				<img
					className="vs--spin-2-win-stream-scene-result-container-bg"
					src={buildPathToStaticFolderOfCDN("cashier-images/stream/spin-to-win/shadow.svg")}
					alt="background"
				/>
				<div
					className="vs--spin-2-win-stream-result-content"
				>
					<div className="vs--spin-2-win-stream-result-content-title">
						<span className="vs--spin-2-win-stream-result-content-title-text">{t("common.winningMarkets")}</span>
					</div>
					<div
						className={
							mergeClassNames(
								"vs--spin-2-win-stream-result-content-result",
								number === 0
									? "vs--green"
									: SPIN_TO_WIN_MARKETS_NUMBERS.RED_NUMBERS.includes(number)
										? "vs--red"
										: SPIN_TO_WIN_MARKETS_NUMBERS.BLACK_NUMBERS.includes(number)
											? "vs--black"
											: null
							)
						}
					>
						<span className="vs--spin-2-win-stream-result-content-result-text">
							{number}
						</span>
					</div>
					<RouletteBoardResultView event={finishedEvent} />
				</div>
			</div>
		</div>
	)
}
export default Result