import { SET_SPIN_TO_WIN_HOVERED_NUMBERS } from "store/actionTypes.js";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_SPIN_TO_WIN_HOVERED_NUMBERS:
			return {
				...state,
				hoveredNumbers: [...payload]
			};
		default:
			return state;
	}
};
