import { useEffect, useRef } from "react";
import drawRoulette from "./drawRoulette";
import "./styles.scss"
import { buildPathToStaticFolderOfCDN } from "utils/common";

const RouletteWheel = ({ fromHotToColdSorted = [] }) => {
	const canvasRef = useRef(null); // Create a ref for the canvas element

	useEffect(() => {
		/** @type {HTMLCanvasElement} */
		const canvas = canvasRef.current; // Access the canvas element using the ref
		const ctx = canvas.getContext('2d');
		// Call the drawRoulette function from rouletteCanvas.js
		if (!canvas || !ctx || !fromHotToColdSorted.length) {
			return
		}
		const hotNumbers = fromHotToColdSorted.slice(0, 10).map(({ number }) => number);
		const coldNumbers = fromHotToColdSorted.slice(-10).map(({ number }) => number);
		let totalElectedCount = 0;
		const electedCountByNumber = {};
		fromHotToColdSorted.forEach(({ number, electedCount }) => {
			electedCountByNumber[number] = electedCount;
			totalElectedCount += electedCount;
		})
		drawRoulette({ ctx, canvas, hotNumbers, coldNumbers, electedCountByNumber, totalElectedCount });
	}, [fromHotToColdSorted]);

	return (
		<div className="vs--spin-2-win-roulette-wheel">
			<div className="vs--spin-2-win-roulette-wheel-container">
				<img className="vs--spin-2-win-roulette-wheel-numbers" src={buildPathToStaticFolderOfCDN("cashier-images/stream/spin-to-win/roulette-board-numbers.svg")} alt="Roulette Numbers" />
				<canvas className='spin-2-win-roulette-wheel-canvas' ref={canvasRef} width="800" height="800"></canvas>
			</div>
		</div>
	)
}
export default RouletteWheel