import moment from "moment";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const Timer = ({ startTime }) => {
	const [countdown, setCountDown] = useState(null);
	const intervalId = useRef(null);

	useEffect(() => {
		if (intervalId.current) {
			clearInterval(intervalId.current);
		}

		intervalId.current = setInterval(() => {
			const date = moment.utc(startTime).local().toDate();
			const diff = moment().diff(date, "seconds");
			if (diff <= 0) {
				setCountDown(moment.utc((0 - diff) * 1000).format("mm:ss"));
			} else {
				setCountDown(null);
			}
		}, 1000);

		return () => {
			clearInterval(intervalId.current);
		};
	}, [startTime]);

	return <span className="vs--lucky-six-live-monitor-header-main-timer-text">{countdown}</span>;
};

Timer.propTypes = {
	startTime: PropTypes.string
};

export default Timer;
