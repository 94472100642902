import useEvent from "hooks/useEvent";
import "./styles.scss"
import { useContext, useEffect, useState } from "react";
import { buildPathToStaticFolderOfCDN, isNullish, loadVideo, mergeClassNames } from "utils/common";
import ScenesFlowContext from "components/pages/scenesFlow/scenesFlowContext";

const defaultAttributes = {
	autoPlay: true,
	muted: true,
	controls: false,
	playsInline: true,
	webkitplaysinline: "true"
}

const LiveProcess = ({ event, loopSrc, onFinish: argOnFinish, onMount: argOnMount }) => {
	const [sceneVideoSrc, setSceneVideoSrc] = useState(null);
	const [liveVideoSrc, setLiveVideoSrc] = useState(null);
	const [sceneData, setSceneData] = useState({ number: null, isCompleted: false })
	const eventSceneData = event?.gameData?.scenes?.at(0) ?? null;

	const sceneFlowProviderValue = useContext(ScenesFlowContext);

	const onMount = useEvent(() => {
		if (typeof argOnMount === "function") {
			argOnMount(sceneFlowProviderValue)
		}
	});
	const onFinish = useEvent(() => {
		if (typeof argOnFinish === "function") {
			argOnFinish(sceneFlowProviderValue)
		}
	});

	useEffect(() => {
		onMount()
	}, [])

	useEffect(() => {
		if (isNullish(eventSceneData)) {
			return
		}
		setSceneData({ number: eventSceneData?.number, isCompleted: eventSceneData?.isCompleted })
	}, [eventSceneData?.number, eventSceneData?.isCompleted])

	useEffect(() => {

		if (isNullish(sceneData.number)) {
			return
		}

		let onFinishImitationTimeoutId = null;
		const ballNo = sceneData.number;
		const videoUrl = buildPathToStaticFolderOfCDN(`cashier-videos/stream/spin-to-win/roulette-ball-${ballNo}.webm`);
		const controller = new AbortController();
		const { signal } = controller;
		let videoData = { url: null, error: null, dispose: Function.prototype };

		(async () => {
			videoData = await loadVideo({ videoUrl, signal });
			const url = videoData.error ? videoUrl : videoData.url;
			if (videoData.error) {
				console.log(videoData.error);
				onFinishImitationTimeoutId = setTimeout(onFinish, 10000)
			} else {
				setLiveVideoSrc(url)
			}
		})();

		return () => {
			controller.abort(); // Cancel the request if component unmounts
			videoData.dispose(); // Clean up blob URL
			clearTimeout(onFinishImitationTimeoutId)
		};
	}, [sceneData.number])

	const onEndedLoopVideo = (e) => {
		if (liveVideoSrc) {
			setSceneVideoSrc(liveVideoSrc)
		}

		e.target.play()
	}

	const onEndedCurrentVideo = (e) => {
		setSceneVideoSrc(null)
		setLiveVideoSrc(null)
		onFinish()
	}

	return (
		<div className="vs--spin-2-win-stream-scene vs--spin-2-win-stream-scene-live-process">
			<img
				className="vs--spin-2-win-stream-scene-live-process-background"
				src={buildPathToStaticFolderOfCDN("cashier-images/stream/spin-to-win/live-process-bg.png")}
				alt="background"
			/>
			{
				loopSrc
					? (
						<video
							className={
								mergeClassNames(
									"vs--spin-2-win-stream-scene-live-process-video",
									"vs--spin-2-win-stream-scene-live-process-video-loop"
								)
							}
							src={loopSrc}
							{...defaultAttributes}
							onEnded={onEndedLoopVideo}
						>
							Your browser does not support the video tag.
						</video>
					)
					: null
			}
			{
				sceneVideoSrc && sceneData.isCompleted
					? (
						<video
							className={
								mergeClassNames(
									"vs--spin-2-win-stream-scene-live-process-video",
									"vs--spin-2-win-stream-scene-live-process-video-ball"
								)
							}
							src={sceneVideoSrc}
							{...defaultAttributes}
							onEnded={onEndedCurrentVideo}
						>
							Your browser does not support the video tag.
						</video>
					)
					: null
			}
		</div>
	)
}
export default LiveProcess