import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { BETSLIP_MODES, BET_STATE } from "constants/betslip.constants";
import { GAME_STATUSES, GAME_TYPE_TEXT_KEYS, NUMBER_TO_COLOR_MAPPER } from "constants/game.constants";
import { setTicketConfirmation } from "store/actions/tickets/confirmation.actions";
import { TICKETS_LIST_TYPE, TICKET_CONFIRMATION_MODAL_TYPE, BONUS_TYPE } from "constants/common.constants";
import { GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import Loader from "components/ui/loader";
import RaceRects from "components/ui/raceRects";
import Tooltip from "components/ui/tooltip";
import runMarketUtilsFunction from "utils/markets/run";
import {
	numberWithSpaces,
	makeCurrencyText,
	isRacingGame,
	isSeasonGame,
	isLeagueGame,
	isChampionsCupGame,
	GetSeasonRoundText
} from "utils/common";
import { getBetStateText } from "utils/bets";
import historyType from "types/history.type";
import sessionType from "types/session.type";
import { DATE_TIME_FORMAT } from "constants/date.constants";
import React from "react";

let copiedTimer = null;

/** Details of single ticket in tickets list */
const TicketDetails = ({ type, id, items, isOpened, session, setTicketConfirmation, currentGameType, onCancel }) => {
	const { t } = useTranslation();

	/** Function to get tciket from loaded items
	 * @function
	 * @returns {object}
	 * @memberOf TicketDetails
	 */
	const getTicket = () => {
		return items.find((i) => i.id === id);
	};

	/** Function to get item details from loaded items
	 * @function
	 * @returns {object}
	 * @memberOf TicketDetails
	 */
	const getDetails = () => {
		const ticket = getTicket();
		return ticket ? ticket.bets : null;
	};

	/** Function, fires on bet cancel button click
	 * @function
	 * @param {number} ticketId - ticket Id , bet belongs to
	 * @param {number} betId - bet Id to cancel
	 * @memberOf TicketDetails
	 */
	const handleBetCancel = (ticketId, betId) => {
		setTicketConfirmation({ ticketId, betId });
	};

	/** Function which detects if there is a bet with winn
	 * @function
	 * @returns {boolean}
	 * @memberOf TicketDetails
	 */
	const hasWinItem = () =>
		items.find((i) => [BET_STATE.RETURN, BET_STATE.WON, BET_STATE.SEMIWON, BET_STATE.SEMILOST].includes(i.state));

	const renderFootballEvent = (item) => {
		const gameType = item?.gameType ?? 0;
		const team1 = item?.gameData?.team1 ?? {};
		const team2 = item?.gameData?.team2 ?? {};

		if (item?.eventType === GAME_EVENT_TYPE.LEAGUE) {
			return gameType ? <span>{t(`common.${GAME_TYPE_TEXT_KEYS[gameType]}`)}</span> : " - ";
		}
		if (item?.eventType === GAME_EVENT_TYPE.WEEK) {
			return gameType && item?.eventOrderNumber ? (
				<span>{`${t(`common.${GAME_TYPE_TEXT_KEYS[gameType]}`)}: ${GetSeasonRoundText(gameType, item?.eventOrderNumber ?? 0)}`}</span>
			) : (
				" - "
			);
		}
		if (!item.gameData) {
			return " - ";
		}
		const teamNameMapper =
			isLeagueGame(gameType) || isChampionsCupGame(gameType)
				? (teamName) => teamName
				: (teamName) => t(`countries.${teamName}`);
		return (
			<span>
				{teamNameMapper(team1.countryCode ?? "")}
				{(team1.goalCount ?? null) !== null && (team2.goalCount ?? null) !== null
					? ` ${team1.goalCount}:${team2.goalCount} `
					: " - "}
				{teamNameMapper(team2.countryCode ?? "")}
			</span>
		);
	};

	const isBonusBet = Boolean(getDetails().find((dtl) => dtl.bonusId)?.bonusId);

	return (
		<div className="vs--modal-table-details vs--pl-16 vs--pr-16">
			<h4 className="vs--modal-table-details-title vs--pt-16 vs--mb-8">
				{!isBonusBet ? t("bet.bets") : t("bet.bonusBets")}
			</h4>
			{getDetails() ? (
				<div className="vs--modal-table-details-body">
					{getDetails().map((item, index) => {
						return (
							<div
								key={index}
								className={
									"vs--modal-table-details-row vs--flex vs--flex-row vs--pb-16 vs--pt-16 " +
									(item.matchState === GAME_STATUSES.STARTED ? " vs--modal-table-details-row-live" : "")
								}
							>
								<div className="vs--modal-table-details-row-section vs--flex-equal">
									<div className="vs--modal-table-details-row-section vs--flex-row vs--align-center vs--mb-8">
										<b className="vs--font-regular">{t("bet.status")}:</b>
										<div className="vs--ticket-bet-status" data-state={item.state}>
											<span className={"vs--tickets-status vs--tickets-status-" + item.state}>
												{getBetStateText(item.state)}
											</span>
										</div>
									</div>
									<div className="vs--modal-table-details-row-section vs--flex-row vs--align-start">
										<b className="vs--font-regular">{t("cashier.market")}:</b>
										{isRacingGame(item.gameType) ? (
											<span className="vs--flex vs--align-center">
												<Tooltip
													placement="right"
													overlayClassName="vs--result-block-body"
													title={runMarketUtilsFunction("makeGroupTitle", [item], item.gameType)}
												>
													{runMarketUtilsFunction("makeGroupTitle", [item], item.gameType)}
													{/* html space code */}
													&nbsp;:&nbsp;
												</Tooltip>
												<RaceRects
													gameType={item.gameType}
													rectData={runMarketUtilsFunction(
														"getRectData",
														[
															{
																group: item.group,
																outcome: item.outcome,
																gameData: item.gameData,
																gameType: item.gameType
															}
														],
														item.gameType
													)}
													placement="top"
												/>
											</span>
										) : [GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(
												item.gameType
										  ) || isSeasonGame(item.gameType) ? (
											<span title={runMarketUtilsFunction("makeBetText", [item], item.gameType)}>
												{runMarketUtilsFunction("makeBetText", [item], item.gameType)}
											</span>
										) : item.gameType === GAME_TYPE.KENO ? (
											<div className="vs--flex vs--flex-equal vs--flex-wrap vs--pr-16">
												{item.outcome.split(":").map((ball) => (
													<div className="vs--flex" key={ball}>
														<div className="vs--result-keno vs--markets-keno-selection-num-sphere vs--flex vs--align-center vs--justify-center vs--mr-4 vs--mb-4">
															<span className="vs--flex-wrap vs--font-mini">{ball}</span>
														</div>
													</div>
												))}
											</div>
										) : item.gameType === GAME_TYPE.LUCKY_SIX ? (
											!item.outcome.includes(":") ? (
												runMarketUtilsFunction("makeGroupTitle", [item], item.gameType)
											) : (
												<div className="vs--lucky-six-pending-balls vs--mt-6">
													{item.outcome.split(":").map((number) => (
														<div
															key={number}
															className={`vs--lucky-six-ball vs--lucky-six-ball-active vs--lucky-six-ball-${NUMBER_TO_COLOR_MAPPER[number % 8]} vs--flex vs--justify-center vs--align-center`}
														>
															<span className="vs--lucky-six-ball-text">{number}</span>
														</div>
													))}
												</div>
											)
										) : item.gameType === GAME_TYPE.SPIN_TO_WIN ? (
											`${runMarketUtilsFunction("makeGroupTitle", [item], item.gameType)} ${runMarketUtilsFunction("getNameForMarketGroup", [item.group, item.outcome, item.gameType], item.gameType)}`
										) : (
											""
										)}
									</div>
								</div>

								<div className="vs--modal-table-details-row-section vs--flex-equal">
									<div className="vs--modal-table-details-row-section vs--flex-row vs--align-center vs--mb-8">
										<b className="vs--font-rvs--modal-table-details-row-section vs--flex-row vs--align-center vs--mb-8egular">
											{t("common.date")}:
										</b>
										{item.startTime ? (
											<span>{moment.utc(item.startTime).local().format(DATE_TIME_FORMAT)}</span>
										) : (
											<span className="vs--font-error">{t("common.eventNotFound")}</span>
										)}
									</div>
									<div
										className="vs--modal-table-details-row-section vs--flex-row vs--align-center"
										data-active={item.matchState === GAME_STATUSES.STARTED ? "true" : "false"}
									>
										<b className="vs--font-regular">{t("common.event")}:</b>
										{[GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(item?.gameType) ||
										isSeasonGame(item?.gameType) ? (
											renderFootballEvent(item)
										) : isRacingGame(item?.gameType) ? (
											<span>{item?.gameData?.venue ?? ""}</span>
										) : item?.gameType === GAME_TYPE.KENO ? (
											t("common.keno")
										) : item?.gameType === GAME_TYPE.LUCKY_SIX ? (
											t("common.luckySix")
										) : item?.gameType === GAME_TYPE.SPIN_TO_WIN ? (
											t("common.spin2Win")
										) : (
											""
										)}
									</div>
								</div>

								<div className="vs--modal-table-details-row-section vs--flex-equal">
									<div className="vs--modal-table-details-row-section vs--flex-row vs--align-center vs--mb-8">
										<b className="vs--font-regular">{t("common.eventId")}:</b>
										<span>{item.eventId}</span>
									</div>
									<div className="vs--modal-table-details-row-section vs--flex-row vs--align-center">
										<b className="vs--font-regular">{t("bet.odds")}:</b>
										<span>{numberWithSpaces(item.factor) + "x"}</span>
									</div>
								</div>

								<div className="vs--modal-table-details-row-section vs--flex-equal">
									<div className="vs--modal-table-details-row-section vs--flex-row vs--align-center vs--mb-8">
										<b className="vs--font-regular">
											{item.bonusType === BONUS_TYPE.FREEBET
												? t("bonus.bonus") + " " + t("bet.stake")
												: t("bet.stake")}
											:
										</b>
										<span>
											{item.type === BETSLIP_MODES.SINGLE
												? makeCurrencyText(item.amount, session.currency)
												: "-"}
										</span>
									</div>
									<div className="vs--modal-table-details-row-section vs--flex-row vs--align-center">
										<b className="vs--font-regular">{t("bet.possibleWin")}:</b>
										{item.type === BETSLIP_MODES.SINGLE
											? makeCurrencyText(
													item.bonusId && item.bonusType === BONUS_TYPE.DOUBLEDOOBLE
														? item.possibleWin * 2
														: item.possibleWin,
													session.currency
												)
											: "-"}
									</div>
								</div>
								{hasWinItem() && (
									<div className="vs--modal-table-details-row-section vs--flex-equal">
										<div className="vs--modal-table-details-row-section vs--flex-row vs--align-center vs--mb-8">
											<b className="vs--font-regular">{t("bet.winning")}:</b>
											<span>
												{makeCurrencyText(item.bonusId ? item.redeem : item.winning, session.currency)}
											</span>
										</div>
									</div>
								)}
								{item.allowCancel &&
								item.type === BETSLIP_MODES.SINGLE &&
								item.state === BET_STATE.PENDING &&
								type === TICKETS_LIST_TYPE.SINGLE ? (
									<button
										type="button"
										className="vs--ticket-bet-button vs--ticket-bet-button-cancel vs--button vs--modal-table-button vs--font-smallest vs--font-medium"
										onClick={() => handleBetCancel(getTicket().id, item.id)}
										title={t("common.cancel")}
									>
										<span>{t("common.cancel")}</span>
									</button>
								) : null}
							</div>
						);
					})}
				</div>
			) : (
				<Loader />
			)}
		</div>
	);
};

/** TicketDetails propTypes
 * PropTypes
 */
TicketDetails.propTypes = {
	/** Tickets list type (Pending, Single) */
	type: PropTypes.oneOf(Object.values(TICKETS_LIST_TYPE)),
	/** Betslip id */
	id: PropTypes.number,
	/** Is true when betslip collapsable row is opened */
	isOpened: PropTypes.bool,
	/** Redux state property, current session */
	session: sessionType,
	/** List of tickets to show in list */
	items: PropTypes.arrayOf(historyType),
	/** Redux action ,to open bet cancel confirmation popup */
	setTicketConfirmation: PropTypes.func,
	/** Function to close ticket list popup */
	onCancel: PropTypes.func,
	/** Redux state property, Current game type */
	currentGameType: PropTypes.oneOf(Object.values(GAME_TYPE))
};

const mapStateToProps = (state) => {
	return {
		session: state.auth.session,
		currentGameType: state.game.currentGameType
	};
};

const mapDispatchToProps = (dispatch) => ({
	setTicketConfirmation: (params) => {
		dispatch(setTicketConfirmation({ ...params, type: TICKET_CONFIRMATION_MODAL_TYPE.BET_CANCEL }));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
