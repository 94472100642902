export const VS_PROJECT_TYPE = {
	ONLINE: 1,
	RETAIL: 2
}

export const PROJECT_TYPE = {
	STANDALONE: 1,
	IFRAME: 2
};

export const TICKETS_LIST_TYPE = {
	PENDING: 1,
	SINGLE: 2,
	SETTLED: 3
};

export const TICKET_CONFIRMATION_MODAL_TYPE = {
	CANCEL: 1,
	PAYOUT: 2,
	BET_CANCEL: 3,
	PAYOUT_ALL: 4,
	JACKPOT_PAYOUT: 5
};

export const TICKET_PAYOUT_TYPE = {
	ALL: 1,
	BETSLIP: 2,
	JACKPOT: 4
};

export const PARTNER_SECURITY_PASSWORD_PATTERN = {
	CONTAINS: 1,
	PATTERN: 2
};

export const PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS = {
	UPPERCASE: 1,
	LOWERCASE: 2,
	DIGITS: 4,
	DASH: 8,
	UNDERSCORE: 16,
	SPACE: 32,
	SPECIAL: 64,
	BRACKETS: 128
};

export const PARTNER_SECURITY_PASSWORD_PATTERN_CONTAINS_TYPE = {
	MAY: 1,
	MUST: 2,
	NONE: 4
};

export const REPORT_TYPE = {
	INTERIM: 1,
	SETTLEMENT: 2
};

export const TURNOVER_TYPE = {
	SIMPLE: 1,
	BONUS: 2
};

export const AUTH_TOKEN_TYPE = {
	NONE: "None",
	QR: "QR",
	TOKEN: "Token",
	PASSWORD_EXPIRED: "PasswordExpired"
};

export const BETSHOP_BONUS_CONFIG_TYPE = {
	EQUAL: 1,
	GREAT_OR_EQUAL: 2
};

export const PAYMENT_TYPE = {
	CASH: 1,
	CREDIT_CARD: 2,
	BOTH: 3
};

export const BONUS_TYPE = {
	FREEBET: 1,
	FREEAMOUNT: 2,
	DOUBLEDOOBLE: 3
};


export const CAPTCHA_KEY = "6Lcsl9MqAAAAALNr-Ne0ZEccHGItLHsM9p1xE0uX";