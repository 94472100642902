import BetColorButton from "components/pages/main/markets/luckySix/selectColor/betColorButton.jsx";
import { LUCKY_SIX_BALL_COLORS } from "constants/game.constants.js";

const SelectColor = ({ currentMarket = {}, currentEvent = {}, isSelectingColumn = false }) => (
	<>
		{LUCKY_SIX_BALL_COLORS.map((color) => (
			<BetColorButton key={color} color={color} currentMarket={currentMarket} currentEvent={currentEvent} isSelectingColumn={isSelectingColumn} />
		))}
	</>
);

export default SelectColor;
