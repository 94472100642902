
import React, { useState, useEffect } from "react";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css"; // Required for styling
import "./style.scss"

const JackpotOdometer = ({ value = 0, duration = 2000, format = "( ddd).dd" }) => {
	return (
		<Odometer value={value} format={format} duration={duration} />
	);
};

export default JackpotOdometer;
