import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import moment from "moment";
import eventType from "types/event.type";

import {
	updateFromLiveAndUpcomings,
	addLiveAndUpcomings,
	getLastResults,
	addLastResult,
	setCurrentGame,
	getEvent,
	updateEventLiveMonitor,
	updateFromLiveAndUpcomingsLiveMonitor,
	addLiveAndUpcomingsLiveMonitor,
	getLiveAndUpcomings
} from "store/actions/game/game.actions";
import { updateSeasonMarkets, getSeasonMarkets } from "store/actions/season/season.actions";
import { getGameRTPs } from "store/actions/auth/auth.actions";

import { GAME_EVENT_TYPE, GAME_STATUSES, GAME_TYPE } from "constants/game.constants";
import { isNullish, isSeasonGame } from "utils/common";
import useEvent from "hooks/useEvent";
import useForceUpdate from "hooks/useForceUpdate";
import { CASHIER_API_INVOKE_EVENTS, CASHIER_API_LISTENER_EVENTS, JOB_API_INVOKE_EVENTS, JOB_API_LISTENER_EVENTS } from "constants/webSocket.contants";
import WebSocketService from "services/webSocket";
import { getActiveJackpot, setWonJackpotBonuses, updateJackpotBonus } from "store/actions/bonuses/jackpot.actions";
import LiveMonitorGameContext from "contexts/liveMonitorGameContext";

/* Keno Page Wrapper Container Component - Wrapper */
const Wrapper = ({ decodedData }) => {
	const params = useParams();
	const gameType = Number(params.gameType);
	const games = decodedData?.games ?? [];
	const game = games.find((game) => game?.type === gameType);
	const [cashierForceUpdate, cashierForceUpdateState] = useForceUpdate()
	const [jobForceUpdate, jobForceUpdateState] = useForceUpdate()
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth?.stream?.access?.wsToken ?? null)
	const current = useSelector((state) => state.game.current);
	const currentEvent = useSelector((state) => state.game.matches.data.find((m) => m.id === current) || null);
	const seassionGames = useSelector((state) => state.auth.session.games);
	const liveAndUpcomingEvent = useSelector((state) => {
		const liveAndUpcomings = state.game.liveAndUpcomings.data;
		return liveAndUpcomings.find((e) => e.id === current);
	});

	const activeJackpotId = decodedData?.activeJackpotId ?? null;
	const seasonId = liveAndUpcomingEvent?.seasonId || null;
	
	const getCurrentGameRtps = useEvent(() => {
		const seassionGame = seassionGames.find((g) => g.type === gameType);
		return seassionGame ? seassionGame.rtPs : [];
	});

	const onJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		dispatch(updateJackpotBonus(d));
	})

	const onWonJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		dispatch(setWonJackpotBonuses(d));
	})

	const onEvents = useEvent((data) => {
		const d = JSON.parse(data);
		if (d.gameType === gameType) {
			if (!isSeasonGame(d.gameType) || d.type === GAME_EVENT_TYPE.WEEK) {
				switch (true) {
					case d.status === GAME_STATUSES.NEW:
						dispatch(addLiveAndUpcomingsLiveMonitor(d));
						break;
					default:
						dispatch(updateFromLiveAndUpcomingsLiveMonitor(d));
						break;
				}
			}

			/** If season markets are updated */
			if (isSeasonGame(d.gameType) && d.seasonId === d.id && d.markets) {
				dispatch(updateSeasonMarkets(d));
			}
		}

		dispatch(updateEventLiveMonitor(d.id, d, getCurrentGameRtps(), d.status === GAME_STATUSES.FINISHED));
	})

	const handleCashierWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}

		wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.JACKPOTS);
		wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS);

		wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.JACKPOTS, onJackpots);
		wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS, onWonJackpots);

		cashierForceUpdate();
	})

	const handleJobWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}
		
		wsServiceInstance.off(JOB_API_LISTENER_EVENTS.EVENTS);

		wsServiceInstance.on(JOB_API_LISTENER_EVENTS.EVENTS, onEvents);

		jobForceUpdate();
	})

	// Create connections
	const { cashierWS, jobWS } = useMemo(/** @return {WebSocketConnections} */() => {

		/** @type {WebSocketConnections} */
		const wsServices = {
			cashierWS: null,
			jobWS: null
		};

		if (!token) {
			return wsServices
		}

		const urlCashierWS = `${import.meta.env.SYSTEM_WS_URL_CASHIER}?accessToken=${token}`;
		const urlJobWS = `${import.meta.env.SYSTEM_WS_URL_JOBS}?accessToken=${token}`;

		wsServices.cashierWS = new WebSocketService(urlCashierWS, handleCashierWSEvent)
		wsServices.jobWS = new WebSocketService(urlJobWS, handleJobWSEvent)

		return wsServices

	}, [token])

	// start / stop connection by document visibility
	useEffect(() => {
		if (!token || isNullish(jobWS)) {
			return;
		}

		const visibilityChangeListener = () => {
			if (document.hidden) {
				return;
			}
			jobWS.startConnection();
		};

		document.addEventListener("visibilitychange", visibilityChangeListener);

		return () => {
			jobWS.stopConnection();

			document.removeEventListener("visibilitychange", visibilityChangeListener);
		};
	}, [token, jobWS]);

	// Watch all jackpot updates
		useEffect(() => {
			if (!activeJackpotId || !cashierWS?.isConnected) {
				return
			}
			const invokeJackpotsMessage = `${CASHIER_API_LISTENER_EVENTS.JACKPOTS}_${activeJackpotId}`;
			cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.SUBSCRIBE, invokeJackpotsMessage);
			return () => {
				if (!cashierWS?.isConnected) {
					return;
				}
				cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeJackpotsMessage);
			};
	
		}, [activeJackpotId, cashierWS, cashierForceUpdateState]);

	// Watch all event updates
	useEffect(() => {
		if (!token || !jobWS.isConnected || !game) {
			return
		}
		const invokeMessage = `${JOB_API_LISTENER_EVENTS.EVENTS}_${game.type}_${game.id}`;
		jobWS.invoke(JOB_API_INVOKE_EVENTS.SUBSCRIBE, invokeMessage);
		return () => {
			if (!jobWS?.isConnected) {
				return;
			}
			jobWS.invoke(JOB_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeMessage);
		};

	}, [token, jobWS, jobForceUpdateState, game]);

	useEffect(() => {
		dispatch(setCurrentGame(gameType));
	}, [gameType]);

	/** Subscribe to signalR when session loaded,
	 * Load live and upcomings, rtps
	 * */
	useEffect(() => {
		dispatch(getLiveAndUpcomings(true));
		dispatch(getLastResults(true));
		if (game?.id) {
			dispatch(getGameRTPs(game.id));
		}
	}, [game?.id])

	useEffect(() => {
		if (isNullish(activeJackpotId)) {
			return
		}
		dispatch(getActiveJackpot(true));
	}, [activeJackpotId]);

	/** Load match details on current match change */
	useEffect(() => {
		if (current) {
			if (!currentEvent) {
				dispatch(getEvent(current, true));
			}
		}
	}, [current]);

	useEffect(() => {
		if (seasonId && isSeasonGame(gameType)) {
			dispatch(getSeasonMarkets(seasonId, true));
		}
	}, [seasonId]);

	useEffect(() => {
		if (!liveAndUpcomingEvent) {
			return;
		}
		const startTime = liveAndUpcomingEvent.startTime;
		const eventTime = moment.utc(startTime).local();
		const currentTime = moment();
		const diffTime = eventTime - currentTime;
		const duration = moment.duration(diffTime * 1000, "milliseconds");
		const seconds = duration.asSeconds() / 1000;
		if (!game) {
			return;
		}
		const cycle = game.cycleMinutes * 60;
		if (seconds > cycle) {
			dispatch(getLiveAndUpcomings(true))
		}
	}, [current]);

	return (
		<LiveMonitorGameContext.Provider value={game}>
			<Outlet context={{ decodedData }} />
		</LiveMonitorGameContext.Provider>
	);
};

export default Wrapper;
