import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LUCKY_SIX_MARKETS } from "constants/game.constants.js";
import OddMarkets from "components/pages/luckySix/newGameFlow/components/oddMarkets";
import ColorMarketsLiveMonitor from "components/pages/luckySix/newGameFlow/components/colorMarketsLiveMonitor";

const Markets = ({ luckySixRtPs }) => {
	const { t } = useTranslation();

	return (
		<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets">
			<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-title">
				{t("common.markets")}
			</div>

			<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table">
				<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section">
					<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section-container">
						<OddMarkets group={LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER} haveTitle />
						<OddMarkets group={LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN} />
					</div>

					<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section-container">
						<OddMarkets group={LUCKY_SIX_MARKETS.LAST_BALL_OVER_UNDER} haveTitle />
						<OddMarkets group={LUCKY_SIX_MARKETS.LAST_BALL_ODD_EVEN} />
					</div>
				</div>

				<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section">
					<ColorMarketsLiveMonitor group={LUCKY_SIX_MARKETS.FIRST_BALL_COLOR} title={t("common.firstBallColor")} />

					<ColorMarketsLiveMonitor group={LUCKY_SIX_MARKETS.LAST_BALL_COLOR} title={t("common.lastBallColor")} />
				</div>

				<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section">
					<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section-container">
						<OddMarkets group={LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER} haveTitle />
						<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section-container-item">
							<OddMarkets group={LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN}  />
						</div>
					</div>

					<div className="vs--lucky-six-live-monitor-container-new-game-flow-container-group-markets-table-section-container">
						<OddMarkets group={LUCKY_SIX_MARKETS.MOSTLY_DRAWN_ODD_EVEN} haveTitle />
						<OddMarkets group={LUCKY_SIX_MARKETS.FIRST_LAST_NUMBER_SUM} haveTitle />
					</div>
				</div>
			</div>
		</div>
	);
};

Markets.propTypes = {
	luckySixRtPs: PropTypes.array
};

export default Markets;
