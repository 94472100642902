import { connect } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment/moment.js";
import Slider from "components/slider";
import { GAME_STATUSES } from "constants/game.constants.js";
import Header from "components/pages/luckySix/header";
import NewGameFlow from "components/pages/luckySix/newGameFlow";
import StartedGameFlow from "components/pages/luckySix/startedGameFlow";
import FinishedGameFlow from "components/pages/luckySix/finishedGameFlow";
import BackgroundVideo from "components/backgroundVideo";

import "./styles.scss";

const GAME_FLOW = {
	NEW: "NEW",
	FINISHED: "FINISHED",
	STARTED: "STARTED"
};

const FINISHED_FLOW_RENDER_DURATION = 10000;
const MIN_INTERVAL_STARTING_FLOW = -60;

/** Lucky Six Component */
const LuckySix = ({ liveAndUpcomings, lastResults }) => {
	const [gameFlow, setGameFlow] = useState(null);
	const timeoutRef = useRef(null);
	const [startLiveStream, setStartLiveStream] = useState(false);

	const currentEvent = useMemo(() => {
		const event = liveAndUpcomings?.find((ev) =>
			[GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(ev.status)
		);

		if (event) {
			return event;
		}

		return liveAndUpcomings[0];
	}, [liveAndUpcomings]);

	useEffect(() => {
		switch (currentEvent?.status) {
			case GAME_STATUSES.STARTED:
			case GAME_STATUSES.CLOSE_FOR_BETTING:
				setGameFlow(GAME_FLOW.STARTED);
				break;
			case GAME_STATUSES.NEW:
			case GAME_STATUSES.PREAMBLE_STARTED:
				setGameFlow(GAME_FLOW.NEW);
				break;
			case GAME_STATUSES.FINISHED:
				setGameFlow(GAME_FLOW.FINISHED);
				break;
			default:
				setGameFlow(null);
				break;
		}
	}, [currentEvent?.status]);

	useEffect(() => {
		if (currentEvent) {
			const date = moment.utc(currentEvent.startTime).local().toDate();
			const diff = moment().diff(date, "seconds");

			if (diff < MIN_INTERVAL_STARTING_FLOW) {
				setGameFlow(GAME_FLOW.FINISHED);
				timeoutRef.current = setTimeout(() => {
					setGameFlow(GAME_FLOW.NEW);
				}, FINISHED_FLOW_RENDER_DURATION);
			}
		}

		return () => {
			clearTimeout(timeoutRef.current);
		};
	}, [lastResults, currentEvent]);

	const onStart = useCallback(() => {
		setStartLiveStream(true);
	}, []);

	const onEnd = useCallback(() => {
		setStartLiveStream(false);
	}, []);

	return (
		<div className="vs--lucky-six-live-monitor">
			<Header
				startTime={currentEvent?.startTime}
				eventId={currentEvent?.id}
				isVisibleHeader={gameFlow !== GAME_FLOW.STARTED}
			/>
			<div className={"vs--lucky-six-live-monitor-container"}>
				<div className="vs--lucky-six-live-monitor-container-border"></div>
				<BackgroundVideo isVisible={gameFlow === GAME_FLOW.STARTED} showLottery={startLiveStream} />

				<Slider
					activeKey={gameFlow}
					leaveAnimationClass={"vs--slider-leave"}
					mountAnimationClass={"vs--slider-mount"}
				>
					<Slider.Item sliderKey={GAME_FLOW.STARTED}>
						<div className="vs--lucky-six-live-monitor-container-started-game-flow">
							<StartedGameFlow onStart={onStart} onEnd={onEnd} />
						</div>
					</Slider.Item>
					<Slider.Item sliderKey={GAME_FLOW.NEW}>
						<div className="vs--lucky-six-live-monitor-container-new-game-flow">
							<NewGameFlow />
						</div>
					</Slider.Item>
					<Slider.Item sliderKey={GAME_FLOW.FINISHED}>
						<div className="vs--lucky-six-live-monitor-container-finished-game-flow">
							<FinishedGameFlow />
						</div>
					</Slider.Item>
				</Slider>
			</div>
		</div>
	);
};

/** LuckySix propTypes
 * PropTypes
 */
LuckySix.propTypes = {};

const mapStateToProps = (state) => {
	return {
		liveAndUpcomings: state.game.liveAndUpcomings.data,
		lastResults: state.game.lastResults.data
	};
};

export default connect(mapStateToProps, null)(LuckySix);
