import axios from "axios";
import { sha256 } from "js-sha256";
import { initTranslations } from "translations/config";

import {
	SET_PLAYER,
	SET_SESSION,
	SET_SESSION_GAMES,
	SET_SESSION_ID,
	SET_SESSION_LOADED,
	SET_SESSION_FAILED,
	SET_SESSION_NO_GAME_FOUND,
	SET_TOKEN_EXPIRATION,
	SET_LOGIN_BEFORE,
	SET_LOGIN_AFTER,
	SET_BALANCE,
	SET_LOGO_ID,
	SET_MAINTENANCE_MODE,
	SET_TRANSLATIONS_LOADED,
	SET_STREAM_ACCESS,
	SET_STREAM_CONFIGURATION,
	SET_BONUS_CONFIGS,
	SET_GAME_RTPS,
	SET_SESSION_TEMPLATE_TYPE
} from "../../actionTypes";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import Paths from "constants/path.constants";
import { AUTH_TOKEN_TYPE } from "constants/common.constants";
import { GAME_ACTIVITY_STATE } from "constants/game.constants";

import LocalStorageUtils from "utils/localStorage";
import { getUrlVars } from "utils/common";
import { loginUser, logout as logoutFromSystem } from "utils/auth";
import { redirectToPage } from "utils/navigate";
import { showError } from "utils/message";

export const setPlayer = (player) => ({
	type: SET_PLAYER,
	payload: { player }
});

const setSession = (session) => ({
	type: SET_SESSION,
	payload: { session }
});

const setSessionId = (sessionId) => ({
	type: SET_SESSION_ID,
	payload: { sessionId }
});

export const setTokenExpiration = (seconds) => ({
	type: SET_TOKEN_EXPIRATION,
	payload: { seconds }
});

const setSessionLoaded = (loaded) => ({
	type: SET_SESSION_LOADED,
	payload: { loaded }
});

export const setSessionFailed = (failed) => ({
	type: SET_SESSION_FAILED,
	payload: { failed }
});

const setSessionGames = (games) => ({
	type: SET_SESSION_GAMES,
	payload: { games }
});

export const setNoGameFound = (noGameFound) => ({
	type: SET_SESSION_NO_GAME_FOUND,
	payload: { noGameFound }
});

export const setBalance = (balance) => ({
	type: SET_BALANCE,
	payload: { balance }
});

export const setLogoId = (logoId) => ({
	type: SET_LOGO_ID,
	payload: { logoId }
});

export const setBonusConfigs = (configs) => ({
	type: SET_BONUS_CONFIGS,
	payload: { configs }
});

export const setSessionTemplateType = (templateType) => ({
	type: SET_SESSION_TEMPLATE_TYPE,
	payload: templateType
});

const getUser = () => {
	let PartnerId = "a57ce327d81b4bc589f54930a9a8c064";
	if (getUrlVars()["partnerId"]) {
		PartnerId = getUrlVars()["partnerId"];
	}
	let isTesting = false;
	if (getUrlVars()["isTesting"]) {
		isTesting = getUrlVars()["isTesting"];
	}
	let betShop = {
		Id: "2b4a5e73d9724049a40da21ae841078e",
		Name: "digitain_test_betshop",
		LanguageId: "EN",
		Address: "Test Address 1/1",
		CurrencyId: "USD",
		BetLimit: {
			SingleMin: 40,
			SingleMax: 50,
			MultiMin: 10,
			MultiMax: 20,
			SinglePossibleWinMax: 600,
			MultiPossibleWinMax: 300
		},
		FavoriteBets: ["x2", "x10", "x20", "x50", "x3", "x4", "x5", "x7"]
	};

	if (getUrlVars()["betShopId"]) {
		betShop.Id = getUrlVars()["betShopId"];
	}
	if (getUrlVars()["betShopName"]) {
		betShop.Name = getUrlVars()["betShopName"];
	}
	if (getUrlVars()["language"]) {
		betShop.LanguageId = getUrlVars()["language"];
	}
	if (getUrlVars()["address"]) {
		betShop.Address = getUrlVars()["address"];
	}
	if (getUrlVars()["currency"]) {
		betShop.CurrencyId = getUrlVars()["currency"];
	}

	let userObj = {
		Id: "test_user_id",
		UserName: "test_user",
		FirstName: "test_user_first_name",
		LastName: "test_user_last_name",
		Email: "test_user_first_name.test_user_last_name@test.test"
	};

	if (getUrlVars()["Id"]) {
		userObj.Id = getUrlVars()["Id"];
	}
	if (getUrlVars()["user"]) {
		userObj.UserName = getUrlVars()["user"];
	}
	if (getUrlVars()["firstName"]) {
		userObj.FirstName = getUrlVars()["firstName"];
	}
	if (getUrlVars()["lastName"]) {
		userObj.LastName = getUrlVars()["lastName"];
	}
	if (getUrlVars()["email"]) {
		userObj.Email = getUrlVars()["email"];
	}

	let secret = "79C1EBB9AF";
	if (getUrlVars()["secret"]) {
		secret = getUrlVars()["secret"];
	}

	const result = {
		user: {
			PartnerId: PartnerId,
			IsTesting: isTesting,
			BetShop: betShop,
			User: userObj,
			Urls: {
				ExitUrl: "return_url",
				LoginUrl: "login_url"
			}
		}
	};

	result.sign = sha256.hmac(secret, JSON.stringify(result.user));
	return result;
};

export const createSession = () => {
	return (dispatch) => {
		const sessionId = getUrlVars()["sessionId"];

		const d = getUser().user;
		const sign = getUser().sign;

		if (!sessionId) {
			return axios({
				url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_SESSION}`,
				method: Methods.POST,
				headers: { "X-REQUEST-SIGN": sign },
				data: d
			})
				.then(({ status, data }) => {
					if (status < 400) {
						const sessionId = data?.session?.sessionId;
						if (sessionId) {
							dispatch(setSessionId(sessionId));
							dispatch(authenticate(sessionId));
						}
					}
				})
				.catch((err) => {
					const data = err?.response?.data ?? {};

					initTranslations(
						null,
						null,
						null,
						null,
						() => {
							dispatch(setSessionFailed(true));
							showError({
								msg: data.message ?? "",
								status: data.status ?? "",
								resource: data.resource ?? ""
							});
						},
						Function.prototype
					);
				});
		} else {
			dispatch(setSessionId(sessionId));
			dispatch(authenticate(sessionId));
		}
	};
};

export const authenticate = (sessionId) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.AUTHENTICATE}`,
			method: Methods.POST,
			data: { sessionId }
		}).then(({ status, data: { value } }) => {
			if (status < 400) {
				loginUser(value);
				dispatch(getSessionDetails());
			}
		});
	};
};

export const getSessionDetails = () => {
	return (dispatch) => {
		const languageCode = getUrlVars()["language"] || "EN";

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SESSION_DETAILS}`,
			method: Methods.GET,
			params: { languageCode }
		})
			.then(({ status, data }) => {
				if (status < 400) {
					const session = { ...(data?.value ?? {}) };

					const activeGamesCount = (session?.games ?? []).filter((g) => g.state !== GAME_ACTIVITY_STATE.INACTIVE).length;
					if (activeGamesCount === 0) {
						dispatch(setSessionFailed(true));
						showError({ msg: "Blocked", status: "NotFound", resource: "Game" });
						initTranslations(null, "en", null, "en");
					}

					dispatch(setSession(session));
				} else {
					dispatch(setSessionFailed(true));
					dispatch(setSessionLoaded(true));
				}
			})
			.catch((error) => {
				dispatch(setSessionFailed(true));
				dispatch(setSessionLoaded(true));
			});
	};
};

export const getSessionGames = (fromStream) => {
	return (dispatch) => {
		dispatch(setSessionLoaded(false));

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SESSION_GAMES}`,
			method: Methods.GET,
			headers: fromStream ? { stream: true } : {}
		})
			.then(({ data: { value } }) => {
				dispatch(setSessionLoaded(true));
				dispatch(setSessionGames(value));
			})
			.catch((error) => {
				dispatch(setSessionLoaded(true));
			});
	};
};

const setLoginBefore = () => ({
	type: SET_LOGIN_BEFORE
});

const setLoginAfter = () => ({
	type: SET_LOGIN_AFTER
});

export const loginCashier = (userName, password, token, navigate) => {
	return (dispatch) => {
		dispatch(setLoginBefore());
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.LOGIN}`,
			method: Methods.POST,
			data: { userName, password, token }
		})
			.then(({ status, data: { value } }) => {
				dispatch(setLoginAfter());
				if (value?.tokenType === AUTH_TOKEN_TYPE.NONE) {
					loginUser(value);
					setTimeout(() => {
						location.reload();
					}, 0);
				} else if (value?.tokenType === AUTH_TOKEN_TYPE.PASSWORD_EXPIRED) {
					redirectToPage(navigate, Paths.RESET_PASSWORD + "?t=" + value.token + "&type=forceChange");
				}
			})
			.catch((ex) => {
				dispatch(setLoginAfter());
			});
	};
};

export const logout = () => {
	return (_, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.LOGOUT}`,
			method: Methods.POST
		})
			.then(() => {
				const {
					auth: { session, player }
				} = getState();

				logoutFromSystem();
				LocalStorageUtils.remove(`vs__cashier__${session.projectId}__${player.userId}`);
			})
			.catch(Function.prototype);
	};
};

export const setTranslationsLoaded = () => ({
	type: SET_TRANSLATIONS_LOADED
});

export const setMaintenanceMode = () => ({
	type: SET_MAINTENANCE_MODE
});

export const setStreamAccess = (session) => ({
	type: SET_STREAM_ACCESS,
	payload: { session }
});

export const createStreamAccess = (onSuccess) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.CREATE_STREAM_ACCESS}`,
			method: Methods.POST,
			data: {}
		})
			.then(({ data: { value: session } }) => {
				onSuccess && onSuccess(session.refreshToken);
			})
			.catch((ex) => {
				console.log(ex);
			});
	};
};

export const refreshStreamAccess = (refreshToken) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REFRESH_STREAM_ACCESS}`,
			method: Methods.POST,
			data: { refreshToken },
			headers: { grant_type: "refresh_token" }
		})
			.then(({ status, data: { value: session } }) => {
				if (status === 200) {
					dispatch(setStreamAccess(session));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

/** Stream Configuration */

const setStreamConfiguration = (id, configuration) => ({
	type: SET_STREAM_CONFIGURATION,
	payload: { id, configuration }
});

export const getStreamConfiguration = (id, callback) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_STREAM_CONFIGURATION}`,
			method: Methods.GET,
			params: { id },
			headers: { stream: true }
		})
			.then(({ status, data: { value: configuration } }) => {
				if (status < 400) {
					callback && callback(configuration);
				}
			})
			.catch(Function.prototype);
	};
};

/** Game RTPs */

const setGameRTPs = (rtPs) => ({
	type: SET_GAME_RTPS,
	payload: { rtPs }
});

export const getGameRTPs = (id, fromStream = true) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_GAME_RTPS}`,
			method: Methods.GET,
			params: { id },
			headers: fromStream ? { stream: true } : null
		})
			.then(({ status, data: { value: data } }) => {
				if (status === 200) {
					dispatch(setGameRTPs(data.rtPs));
				}
			})
			.catch(Function.prototype);
	};
};
