import PropTypes from "prop-types";

import PortalWrapper from "components/portalWrapper";
import OutsideAlerter from "components/ui/outsideAlerter";

import useDocumentListener from "hooks/useDocumentListener";
import KEY_CODE from "constants/keyboard.constants";

const Modal = ({
	coordinates: { clientX = "50%", clientY = "50%" },
	classNames: { outside = "", content = "", header = "", body = "", footer = "" },
	isOpen = false,
	headerContent = null,
	footerContent = null,
	closeIcon = <i className="ic_close" />,
	onCancel = () => {},
	onOutsideClick = onCancel,
	onEscapePress = onCancel,
	children
}) => {
	// Unchangeable referance of ESC button click handler
	useDocumentListener("keydown", (event) => {
		if (event.keyCode === KEY_CODE.ESCAPE) {
			onEscapePress();
		}
	});

	if (!isOpen) {
		return null;
	}

	return (
		<PortalWrapper wrapperId="modal">
			<OutsideAlerter className={`vs--ui-modal-overlay ${outside}`} callback={onOutsideClick}>
				<div className={`vs--ui-modal-content ${content}`} style={{ "--x": clientX, "--y": clientY }}>
					{closeIcon !== null && (
						<div className="vs--ui-modal-close-button" onClick={onCancel}>
							{closeIcon}
						</div>
					)}
					{headerContent !== null && (
						<div className={`vs--ui-modal-header ${header}`}>
							{headerContent !== null && <div className="vs--ui-modal-header-title">{headerContent}</div>}
						</div>
					)}
					<div className={`vs--ui-modal-body ${body}`}>{children}</div>
					{footerContent !== null && <div className={`vs--ui-modal-footer ${footer}`}>{footerContent}</div>}
				</div>
			</OutsideAlerter>
		</PortalWrapper>
	);
};

Modal.propTypes = {
	/** Css variables` viewport x, y coordinates and position top */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** React property, additional classNames for modal */
	classNames: PropTypes.shape({
		outside: PropTypes.string,
		content: PropTypes.string,
		header: PropTypes.string,
		body: PropTypes.string,
		footer: PropTypes.string
	}),
	/** React property, represents whether modal is open */
	isOpen: PropTypes.bool,
	/** React property, some jsx in header part */
	headerContent: PropTypes.node,
	/** React property, some jsx for footer  */
	footerContent: PropTypes.node,
	/** React property, custom close icon */
	closeIcon: PropTypes.element,
	/** React property, callback function that will be called on outside click */
	onOutsideClick: PropTypes.func,
	/** React property, callback function that will be called on Esc keyboard press */
	onEscapePress: PropTypes.bool,
	/** React property, function that will be called on modal close */
	onCancel: PropTypes.func,
	/** React property, modal content */
	children: PropTypes.node
};

export default Modal;
