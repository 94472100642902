import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BetGroupBalls from "components/pages/luckySix/components/betGroupBalls";
import PropTypes from "prop-types";

const HotColdNumbers = ({ ballStatistics }) => {
	const { t } = useTranslation();

	const hotNumbers = useMemo(() => {
		return ballStatistics.slice(0, 6).map((statistic) => statistic?.number);
	}, [ballStatistics]);

	const coldNumbers = useMemo(() => {
		const numbers = [];

		for (let i = ballStatistics.length - 1; i > ballStatistics.length - 7; i--) {
			numbers.push(ballStatistics[i]?.number);
		}

		return numbers;
	}, [ballStatistics]);

	return (
		<div className="vs--lucky-six-live-monitor-hot-cold-numbers">
			<div className="vs--lucky-six-live-monitor-hot-cold-numbers-container">
				<span className="vs--lucky-six-live-monitor-hot-cold-numbers-container-title">
					{t("common.hotNumbers")}
				</span>

				<div className="vs--lucky-six-live-monitor-hot-cold-numbers-container-section hot">
					<svg
						className="vs--lucky-six-hot-svg"
						width="32"
						height="32"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11.9988 17.9379C11.4419 18.3395 10.3325 19.2766 10.3325 20.3707C10.3325 21.2691 11.0805 22 12 22C12.9195 22 13.6675 21.2691 13.6675 20.3707C13.6675 19.2852 12.5545 18.3414 11.9988 17.9379Z"
							fill="#EC1320"
						/>
						<path
							d="M16.1914 14.2367C16.0718 14.3152 15.9503 14.402 15.8372 14.4961L15.2543 14.9801L14.9124 14.3117C13.9949 12.5168 12.6417 11.2211 12.0004 10.6691C11.3587 11.223 10.0019 12.5234 9.08755 14.3117L8.74574 14.9801L8.16285 14.4961C8.04971 14.402 7.92817 14.3152 7.80864 14.2367C7.71509 15.1125 7.4944 15.7539 7.29371 16.3371C7.07902 16.9609 6.89352 17.5 6.89352 18.2461C6.89352 19.9074 7.79664 21.0754 9.44097 21.6312C9.24467 21.252 9.13313 20.8238 9.13313 20.3707C9.13313 18.2527 11.5866 16.7867 11.691 16.7254L12 16.5437L12.309 16.7254C12.4134 16.7867 14.8669 18.2527 14.8669 20.3707C14.8669 20.8238 14.7553 21.252 14.559 21.6312C16.2034 21.0754 17.1065 19.9074 17.1065 18.2461C17.1065 17.5 16.921 16.9613 16.7063 16.3371C16.5056 15.7539 16.2849 15.1125 16.1914 14.2367Z"
							fill="#EC1320"
						/>
						<path
							d="M19.4852 7.30352C19.4852 7.30352 18.3446 7.78477 17.4399 8.53594C15.373 4.49336 12 2 12 2C12 2 8.627 4.49336 6.5601 8.53594C5.65538 7.78477 4.51479 7.30352 4.51479 7.30352C4.54837 11.1414 3 12.275 3 15.1547C3 17.5383 4.08222 19.6371 6.45496 20.8629C5.95402 20.1234 5.69416 19.2367 5.69416 18.2461C5.69416 17.3086 5.92964 16.6246 6.15712 15.9637C6.40698 15.2375 6.66525 14.4863 6.65485 13.2734L6.64686 12.3738L7.492 12.7305C7.51519 12.7402 7.90139 12.9047 8.34795 13.1816C9.70083 10.8867 11.5534 9.50117 11.6382 9.43867L12 9.17109L12.3618 9.43867C12.4466 9.50117 14.2992 10.8867 15.6521 13.1816C16.0986 12.9047 16.4848 12.7402 16.508 12.7305L17.3531 12.3738L17.3451 13.2734C17.3348 14.4863 17.593 15.2375 17.8429 15.9637C18.0704 16.6246 18.3058 17.3086 18.3058 18.2461C18.3058 19.2367 18.046 20.1234 17.545 20.8629C19.9178 19.6371 21 17.5383 21 15.1547C21 12.275 19.4516 11.1414 19.4852 7.30352Z"
							fill="#EC1320"
						/>
					</svg>

					<BetGroupBalls numbers={hotNumbers} />
				</div>
			</div>

			<div className="vs--lucky-six-live-monitor-hot-cold-numbers-container">
				<span className="vs--lucky-six-live-monitor-hot-cold-numbers-container-title">
					{t("common.coldNumbers")}
				</span>

				<div className="vs--lucky-six-live-monitor-hot-cold-numbers-container-section cold">
					<svg
						className="vs--lucky-six-cold-svg"
						width="32"
						height="32"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M22 11.4141H20.9675L21.9734 10.4082L21.1448 9.57957L19.3103 11.4141H17.8712L18.8771 10.4082L18.0484 9.57957L16.2139 11.4141H14.4395L13.9688 10.9434L16.7828 8.1293H20.2613V6.95742H17.9546L19.5688 5.34324L18.7402 4.51461L17.1261 6.12879V3.82215H15.9542V7.30062L13.1402 10.1147L12.5859 9.56051V7.78605L14.4204 5.95156L13.5918 5.12293L12.5859 6.12879V4.68969L14.4204 2.8552L13.5918 2.0266L12.5859 3.03246V2H11.4141V3.03242L10.4082 2.02656L9.57961 2.8552L11.4141 4.68969V6.12879L10.4082 5.12293L9.57961 5.95156L11.4141 7.78605V9.56051L10.9016 10.073L8.1293 7.30066V3.82215H6.95742V6.12879L5.34324 4.51461L4.51461 5.34324L6.12879 6.95742H3.82215V8.1293H7.30066L10.073 10.9016L9.56051 11.4141H7.78605L5.95156 9.57957L5.12293 10.4082L6.12879 11.4141H4.68969L2.8552 9.57957L2.0266 10.4082L3.03246 11.4141H2V12.5859H3.03246L2.0266 13.5918L2.85523 14.4204L4.68969 12.5859H6.12879L5.12293 13.5918L5.95156 14.4204L7.78605 12.5859H9.56051L10.073 13.0984L7.30066 15.8707H3.82215V17.0426H6.12879L4.51461 18.6568L5.34324 19.4854L6.95742 17.8712V20.1779H8.1293V16.6993L10.9016 13.927L11.4141 14.4395V16.2139L9.57961 18.0484L10.4082 18.8771L11.4141 17.8712V19.3103L9.57961 21.1448L10.4082 21.9734L11.4141 20.9676V22H12.5859V20.9675L13.5918 21.9734L14.4204 21.1448L12.5859 19.3103V17.8712L13.5918 18.8771L14.4204 18.0484L12.5859 16.2139V14.4395L13.1402 13.8853L15.9542 16.6993V20.1778H17.1261V17.8712L18.7402 19.4854L19.5689 18.6568L17.9547 17.0426H20.2613V15.8707H16.7828L13.9688 13.0566L14.4395 12.5859H16.2139L18.0484 14.4204L18.8771 13.5918L17.8712 12.5859H19.3103L21.1448 14.4204L21.9734 13.5918L20.9675 12.5859H22V11.4141ZM13.3682 12L12 13.3682L10.6318 12L12 10.6318L13.3682 12Z"
							fill="#0076C1"
						/>
					</svg>

					<BetGroupBalls numbers={coldNumbers} />
				</div>
			</div>
		</div>
	);
};

HotColdNumbers.propTypes = {
	ballStatistics: PropTypes.array.isRequired
};

export default HotColdNumbers;
