import { connect, useSelector } from "react-redux";
import { useMemo } from "react";
import { GAME_TYPE } from "constants/game.constants.js";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import eventType from "types/event.type.js";
import FinishedGameResults from "components/pages/luckySix/finishedGameFlow/components/finishedGameResults";

const FinishedGameFlow = ({ lastResults }) => {
	const { t } = useTranslation();

	if (lastResults.length < 2) {
		return null;
	}

	return (
		<div className="vs--lucky-six-live-monitor-container-finished-game-flow-component">
			<FinishedGameResults simpleView={false} currentEvent={lastResults[0]} />
			<div className="vs--lucky-six-live-monitor-container-finished-game-flow-component-group">
				<FinishedGameResults simpleView={true} currentEvent={lastResults[1]} />
				<FinishedGameResults simpleView={true} currentEvent={lastResults[2]} />
			</div>
		</div>
	);
};

FinishedGameFlow.propTypes = {
	lastResults: PropTypes.arrayOf(eventType)
};

const mapStateToProps = (state) => {
	return {
		lastResults: state.game.lastResults.data
	};
};

export default connect(mapStateToProps, null)(FinishedGameFlow);
