import { CASHIER_API_INVOKE_EVENTS, CASHIER_API_LISTENER_EVENTS } from "constants/webSocket.contants";
import useEvent from "hooks/useEvent";
import useForceUpdate from "hooks/useForceUpdate";
import { useCallback, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import WebSocketService from "services/webSocket";

import { setWonJackpotBonuses, updateJackpotBonus } from "store/actions/bonuses/jackpot.actions";
import { isNullish } from "utils/common";

/* Active Jackpot Live Info Wrapper Container Component - Wrapper */
const Wrapper = ({ decodedData }) => {
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth?.stream?.access?.wsToken ?? null)
	const [cashierForceUpdate, cashierForceUpdateState] = useForceUpdate()
	const activeJackpotId = decodedData?.activeJackpotId ?? null;

	const onJackpots = useCallback((data) => {
		const d = JSON.parse(data);
		dispatch(updateJackpotBonus(d));
	}, [dispatch])

	const onWonJackpots = useCallback((data) => {
		const d = JSON.parse(data);
		dispatch(setWonJackpotBonuses(d));
	}, [dispatch])

	const handleCashierWSEvent = useEvent(/** @type {WebSocketSetupCallback} */(wsServiceInstance) => {
		if (!wsServiceInstance.isConnected) {
			return;
		}
		
		wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.JACKPOTS);
		wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS);

		wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.JACKPOTS, onJackpots);
		wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS, onWonJackpots);

		cashierForceUpdate();
	})

	// Create connections
	const { cashierWS } = useMemo(/** @return {WebSocketConnections} */() => {

		/** @type {WebSocketConnections} */
		const wsServices = {
			cashierWS: null
		};

		if (!token) {
			return wsServices
		}

		const urlCashierWS = `${import.meta.env.SYSTEM_WS_URL_CASHIER}?accessToken=${token}`;

		wsServices.cashierWS = new WebSocketService(urlCashierWS, handleCashierWSEvent)

		return wsServices

	}, [token])

	// start / stop connection by document visibility
	useEffect(() => {
		if (!token || isNullish(cashierWS)) {
			return;
		}

		const visibilityChangeListener = () => {
			if (document.hidden) {
				return;
			}
			cashierWS.startConnection();
		};

		document.addEventListener("visibilitychange", visibilityChangeListener);

		return () => {
			cashierWS.stopConnection();

			document.removeEventListener("visibilitychange", visibilityChangeListener);
		};
	}, [token, cashierWS]);

	// Watch all jackpot updates
	useEffect(() => {
		if (!activeJackpotId || !cashierWS?.isConnected) {
			return
		}
		const invokeJackpotsMessage = `${CASHIER_API_LISTENER_EVENTS.JACKPOTS}_${activeJackpotId}`;
		cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.SUBSCRIBE, invokeJackpotsMessage);
		return () => {
			if (!cashierWS?.isConnected) {
				return;
			}
			cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeJackpotsMessage);
		};

	}, [activeJackpotId, cashierWS, cashierForceUpdateState]);

	return <Outlet context={{ decodedData }} />;
};

export default Wrapper;
