import Item from "components/slider/Item/index.jsx";
import { Children, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

const Slider = ({ children, activeKey, mountAnimationClass, leaveAnimationClass }) => {
	const [prevSliderKey, setPrevSliderKey] = useState(null);
	const [activeSlideKey, setActiveSlideKey] = useState(activeKey);
	const [childrenToLeave, setChildrenToLeave] = useState(null);
	const [childrenToBring, setChildrenToBring] = useState(null);

	const updateKey = useCallback(
		(key) => {
			if (activeSlideKey !== key) {
				setActiveSlideKey(key);
				setPrevSliderKey(activeSlideKey);
			}
		},
		[activeSlideKey]
	);

	useEffect(() => {
		updateKey(activeKey);
	}, [updateKey, activeKey]);

	useEffect(() => {
		if (prevSliderKey) {
			const prevEl = Children.toArray(children).find((el) => el.props.sliderKey === prevSliderKey);
			setChildrenToLeave(prevEl);
		} else {
			setChildrenToLeave(null);
		}
	}, [prevSliderKey, children]);

	useEffect(() => {
		if (activeSlideKey) {
			const prevEl = Children.toArray(children).find((el) => el.props.sliderKey === activeSlideKey);
			setChildrenToBring(prevEl);
		}
	}, [activeSlideKey, children]);

	return (
		<div className="vs--slider">
			{childrenToLeave && prevSliderKey && (
				<div onAnimationEnd={() => setPrevSliderKey(null)} className={leaveAnimationClass} key={prevSliderKey}>
					{childrenToLeave}
				</div>
			)}
			{childrenToBring && activeSlideKey && (
				<div className={mountAnimationClass} key={activeSlideKey}>
					{childrenToBring}
				</div>
			)}
		</div>
	);
};

Slider.Item = Item;

Slider.propTypes = {
	children: PropTypes.node.isRequired,
	activeKey: PropTypes.string.isRequired,
	leaveAnimationClass: PropTypes.string.isRequired,
	mountAnimationClass: PropTypes.string.isRequired
};

export default Slider;
