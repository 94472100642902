import { GAME_TYPE, SPIN_TO_WIN_MARKETS_NUMBERS, SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME } from "constants/game.constants";
import "./styles.scss"
import { forwardRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { mergeClassNames } from "utils/common";

const RouletteBoardDefaultView = forwardRef(({ boardClassName, ...props }, ref) => {
	const { t } = useTranslation()
	return (
		<div ref={ref} className="vs--spin-2-win-roulette-board-container">
			<div className={mergeClassNames("vs--spin-2-win-roulette-board", boardClassName)}>
				<button
					className="vs--spin-2-win-roulette-board-zero vs--green vs--spin-2-win-roulette-board-number vs--spin-2-win-roulette-board-number-0 vs--spin-2-win-roulette-opacity-element"
				>0</button>
				<div>
					<div className="vs--spin-2-win-roulette-board-block">
						{
							[
								{
									counts: "1-18",
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.highLow,
									translationKey: `markets.${GAME_TYPE.SPIN_TO_WIN}.low`,
									className: "vs--spin-2-win-roulette-market-low"
								},
								{
									counts: "19-36",
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.highLow,
									translationKey: `markets.${GAME_TYPE.SPIN_TO_WIN}.high`,
									className: "vs--spin-2-win-roulette-market-high"
								}
							].map(({ counts, factor, translationKey, className }) => (
								<div
									key={counts}
									className={
										mergeClassNames(
											"vs--spin-2-win-roulette-board-block-bar vs--spin-2-win-roulette-board-block-bar-top vs--spin-2-win-roulette-opacity-element",
											className
										)
									}
								>
									<div className="vs--spin-2-win-roulette-board-block-bar-content">
										<div>
											<span>{t(translationKey)}</span>
											&nbsp;
											<span>({counts})</span>
										</div>
										<div>
											<span>x{factor}</span>
										</div>
									</div>
								</div>
							))
						}
					</div>
					<div className="vs--spin-2-win-roulette-board-numbers">
						{Array.from({ length: 36 }, (_, index) => {
							const number = index + 1;
							const colorClass = SPIN_TO_WIN_MARKETS_NUMBERS.RED_NUMBERS.includes(number) ? "vs--red" : "vs--black";
							return (
								<div
									className={`vs--spin-2-win-roulette-opacity-element vs--spin-2-win-roulette-board-number vs--spin-2-win-roulette-board-number-${number} ${colorClass}`} //vs--spin-2-win-roulette-board-number-active
									key={number}
								>
									<span className="vs--spin-2-win-roulette-board-number-text">{number}</span>
								</div>
							);
						})}
					</div>
					<div className="vs--spin-2-win-roulette-board-block">
						{
							[
								{
									count: 12,
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.dozen,
									translationKey: "common.1st",
									className: "vs--spin-2-win-roulette-market-first-twelve"
								},
								{
									count: 12,
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.dozen,
									translationKey: "common.2nd",
									className: "vs--spin-2-win-roulette-market-second-twelve"
								},
								{
									count: 12,
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.dozen,
									translationKey: "common.3rd",
									className: "vs--spin-2-win-roulette-market-third-twelve"
								}
							].map(({ count, factor, translationKey, className }) => (
								<div
									key={translationKey}
									className={
										mergeClassNames(
											"vs--spin-2-win-roulette-board-block-bar vs--spin-2-win-roulette-board-block-bar-bottom vs--spin-2-win-roulette-opacity-element",
											className
										)
									}
								>
									<div className="vs--spin-2-win-roulette-board-block-bar-content">
										<div>
											<span>{t(translationKey)}</span>
											&nbsp;
											<span>{count}</span>
										</div>
										<div>
											<span>x{factor}</span>
										</div>
									</div>
								</div>
							))
						}
					</div>
					<div className="vs--spin-2-win-roulette-board-info">
						{
							[
								{
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.evenOdd,
									translationKey: "common.even",
									className: "vs--spin-2-win-roulette-market-even"
								},
								{
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.redBlack,
									translationKey: "common.red",
									className: "vs--spin-2-win-roulette-board-info-block-red vs--spin-2-win-roulette-market-red"
								},
								{
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.redBlack,
									translationKey: "common.black",
									className: "vs--spin-2-win-roulette-board-info-block-black vs--spin-2-win-roulette-market-black"
								},
								{
									factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.evenOdd,
									translationKey: "common.odd",
									className: "vs--spin-2-win-roulette-market-odd"
								}
							].map(({ factor, translationKey, className }) => (
								<div key={translationKey} className={mergeClassNames("vs--spin-2-win-roulette-board-info-block vs--spin-2-win-roulette-opacity-element", className)}>
									<span>{t(translationKey)}</span>
									<span className="vs--spin-2-win-roulette-board-info-block-num">x{factor}</span>
								</div>
							))
						}
					</div>
				</div>
				<div className="vs--spin-2-win-roulette-board-right-block">
					{
						[
							{
								count: 12,
								factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.column,
								translationKey: "common.3rd",
								className: "vs--spin-2-win-roulette-market-third-column"
							},
							{
								count: 12,
								factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.column,
								translationKey: "common.2nd",
								className: "vs--spin-2-win-roulette-market-second-column"
							},
							{
								count: 12,
								factor: SPIN_TO_WIN_MARKETS_FACTORS_BY_GROUP_NAME.column,
								translationKey: "common.1st",
								className: "vs--spin-2-win-roulette-market-first-column"
							}
						].map(({ count, factor, translationKey, className }) => (
							<div
								key={translationKey}
								className={
									mergeClassNames(
										"vs--spin-2-win-roulette-board-right-block-content vs--spin-2-win-roulette-opacity-element",
										className
									)
								}
							>
								<div className="vs--spin-2-win-roulette-board-right-block-content-divider"></div>
								<span className="vs--spin-2-win-roulette-board-right-block-content-text">{t(translationKey)}</span>
								<span className="vs--spin-2-win-roulette-board-right-block-content-text">&#9;{t("common.column")}</span>
								<span>&#9;x{factor}</span>
							</div>
						))
					} 
				</div>
			</div>
		</div>
	)
})

export default RouletteBoardDefaultView;