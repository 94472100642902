import {
	SET_BETSLIP_MODE,
	ADD_BET,
	REMOVE_BET,
	REMOVE_LAST_BET,
	UPDATE_BET_STAKE,
	UPDATE_EVENT_BETS,
	CLEAR_BETS,
	SET_STAKE,
	PLACE_BET_BEFORE,
	PLACE_BET_AFTER,
	SET_BETSLIP,
	SET_FORECAST,
	CLEAR_FORECAST,
	SET_KENO_BALLS,
	CLEAR_KENO_BALLS,
	SHOW_PAYMENT_METHOD,
	SET_CONFIRMING_TRANSACTION,
	SET_ON_FOCUS_STAKE,
	SET_ON_BLUR_TIMEOUT_ID,
	CLEAR_LUCKY_SIX_BALLS,
	ADD_LUCKY_SIX_BALLS,
	REMOVE_LUCKY_SIX_BALL
} from "../../actionTypes";

import { GAME_STATUSES } from "constants/game.constants";

export default (state = {}, { type, payload }) => {
	switch (type) {
		case SET_BETSLIP_MODE:
			return {
				...state,
				mode: payload.mode
			};
		case ADD_BET:
			return {
				...state,
				success: false,
				bets: [payload.bet, ...state.bets]
			};
		case REMOVE_BET:
			const removeBetArr = state.bets.filter((bet) => (payload.oddId && bet.oddId !== payload.oddId) || (payload.eventId && bet.eventId !== payload.eventId) || (payload.key && bet.key !== payload.key));
			return {
				...state,
				bets: removeBetArr,
				stake: removeBetArr.length > 0 ? state.stake : ""
			};
		case REMOVE_LAST_BET:
			const removeLastBetArr = state.bets.filter((b, i) => i !== state.bets.length - 1);
			return {
				...state,
				bets: removeLastBetArr,
				stake: removeLastBetArr.length > 0 ? state.stake : ""
			};
		case UPDATE_BET_STAKE:
			return {
				...state,
				bets: state.bets.map((b) => (b.key !== payload.key ? b : { ...b, stake: payload.stake }))
			};
		case UPDATE_EVENT_BETS:
			return {
				...state,
				bets: state.bets
					.filter((b) => b.eventId !== payload.event.id || payload.event.status !== GAME_STATUSES.FINISHED)
					.map((b) =>
						b.eventId !== payload.event.id
							? b
							: {
									...b,
									expired: payload.event.status === GAME_STATUSES.STARTED || payload.event.status === GAME_STATUSES.CLOSE_FOR_BETTING
								}
					)
			};
		case CLEAR_BETS:
			return {
				...state,
				bets: [],
				stake: ""
			};
		case SET_STAKE:
			return {
				...state,
				stake: payload.stake
			};
		case PLACE_BET_BEFORE:
			return {
				...state,
				loading: true
			};
		case PLACE_BET_AFTER:
			return {
				...state,
				loading: false
			};
		case SET_BETSLIP:
			const newData = {};
			if (payload.betslip.stake !== undefined) {
				newData.stake = payload.betslip.stake;
			}
			if (payload.betslip.mode !== undefined) {
				newData.mode = payload.betslip.mode;
			}
			if (payload.betslip.bets !== undefined) {
				newData.bets = payload.betslip.bets;
			}
			return {
				...state,
				...newData
			};
		case SET_FORECAST:
			return {
				...state,
				forecast: payload.forecast
			};
		case CLEAR_FORECAST:
			return {
				...state,
				forecast: null
			};
		case SET_KENO_BALLS:
			return {
				...state,
				kenoBalls: payload.kenoBalls
			};
		case CLEAR_KENO_BALLS:
			return {
				...state,
				kenoBalls: { balls: [] }
			};
		case REMOVE_LUCKY_SIX_BALL:
			return {
				...state,
				luckySixBalls: state.luckySixBalls.filter((ball) => ball !== payload.ball)
			};
		case ADD_LUCKY_SIX_BALLS:
			return {
				...state,
				luckySixBalls: [...state.luckySixBalls, ...payload.balls]
			};
		case CLEAR_LUCKY_SIX_BALLS:
			return {
				...state,
				luckySixBalls: []
			};
		case SHOW_PAYMENT_METHOD:
			return {
				...state,
				showPaymentMethod: payload.show
			};
		case SET_CONFIRMING_TRANSACTION:
			return {
				...state,
				confirmingTransaction: payload.transaction
			};
		case SET_ON_FOCUS_STAKE:
			return {
				...state,
				focusStake: {
					...state.focusStake,
					betIdentifiers: payload.betIdentifiers
				}
			};
		case SET_ON_BLUR_TIMEOUT_ID:
			return {
				...state,
				focusStake: {
					...state.focusStake,
					timeoutId: payload.timeoutId
				}
			};
		default:
			return state;
	}
};
