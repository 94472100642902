const PATHS = {
	CREATE_SESSION: "/Session/Create",
	GET_SESSION_DETAILS: "/Session/GetDetails",
	GET_STREAM_CONFIGURATION: "/Session/StreamConfiguration",
	GET_SESSION_GAMES: "/Session/Games",
	CREATE_STREAM_ACCESS: "/Session/CreateStreamAccess",
	REFRESH_STREAM_ACCESS: "/Session/RefreshStreamAccess",
	GET_GAME_RTPS: "/ScheduledGame/RTPs",
	AUTHENTICATE: "/Cashier/Authenticate",
	LOGIN: "/Cashier/Login",
	LOGOUT: "/Cashier/Logout",
	REFRESH_TOKEN: "/Cashier/RefreshToken",
	USER_ACTIVATE: "/Cashier/Activate",
	RESET_PASSWORD: "/Cashier/ResetPassword",
	SET_PASSWORD: "/Cashier/SetPassword",
	FORGOT_PASSWORD: "/Cashier/ForgotPassword",
	GET_ACTIVATION_PASSWORD_SETTINGS: "/Cashier/ActivationPasswordSettings",
	GET_PASSWORD_SETTINGS: "/Cashier/PasswordSettings",
	CHANGE_PASSWORD: "/Cashier/ChangePassword",
	SYSTEM_LANGUAGES: "/Cashier/SystemLanguages",
	GET_LIVE_AND_UPCOMING: "/ScheduledEvents/LiveAndUpcomings",
	GET_LAST_FINISHED_RESULTS: "/ScheduledEvents/LastFinished",
	GET_EVENT: "/ScheduledEvents/Event",
	GET_RESULTS: "/ScheduledEvents/Results",
	GET_FOOTBALL_EVENT: "/ScheduledEvents/Event",
	GET_SEASON: "/ScheduledEvents/Season",
	PLACE_BET: "/Bet/PlaceBet",
	GET_PENDINGS: "/Bet/PendingHistory",
	GET_SETTLED: "/Bet/History",
	CANCEL_TICKET: "/Bet/CancelBetSlip",
	CANCEL_BET: "/Bet/CancelBet",
	REJECT_BETSLIP: "/Bet/RejectBetSlip",
	PAYOUT_TICKET: "/Bet/Payout",
	PRINT_TICKET: "/Bet/PrintTicket",
	GET_TICKET: "/Bet/BetSlip",
	GET_QR: "/Bet/QR",
	REPRINT_REQUEST_TICKET: "/Bet/ReprintRequest",
	REPRINT_TICKET: "/Bet/ReprintTicket",
	GET_TURNOVER_REPORT: "/Report/Turnover",
	GET_BONUS: "/Bonus/ScanBarCode",
	GET_ACTIVE_JACKPOT: "/Jackpot/Active",
	GET_LAST_REPRINT_TICKET: "/Bet/GetLastBetTicket",
	REPRINT_LAST_TICKET: "/Bet/ReprintLastBetTicket",
	SAVE_TEMPLATE: "/Cashier/ChangeTemplate",
	FOOTBALL_LEAGUE_STATISTICS: "/Statistics/FootballLeagueStatistics",
	FOOTBALL_TEAM_TO_TEAM_STATISTICS: "/Statistics/FootballTeamToTeamStatistics",
	GET_LUCKY_SIX_STATISTICS: "/Statistics/LuckySixStatistics",
	GET_SPIN_TO_WIN_STATISTICS: "/Statistics/Spin2WinStatistics"
};

export default PATHS;
