import BaseMarkets from "./baseMarkets";
import PlayingSportMarkets from "./playingSportMarkets";
import RacingSportMarkets from "./racingSportMarkets";
import PenaltyShotoutsMarkets from "./penaltyShootoutMarkets";
import KenoMarkets from "./kenoMarkets";
import SeasonMarkets from "./seasonMarkets";
import { isSeasonGame, isRacingGame } from "utils/common";
import { GAME_TYPE } from "constants/game.constants";
import SteeplechaseSportMarkets from "./steeplechaseSportMarkets";
import LuckySixMarkets from "utils/markets/luckySixMarkets.js";
import Spin2WinMarkets from "utils/markets/spin2WinMarkets.js";

/** Method to run class method
 * @function
 * @param {string} fn - method name
 * @param {array} args - array of arguments to pass to the method
 * @param {gameType} number - the game type
 * @returns {any}
 */
const runMarketUtilsFunction = (fn, args, gameType) => {
	const instance = isRacingGame(gameType)
		? gameType === GAME_TYPE.HORSE_STEEPLECHASING
			? new SteeplechaseSportMarkets()
			: new RacingSportMarkets()
		: GAME_TYPE.FOOTBALL_SINGLE_MATCH === gameType
			? new PlayingSportMarkets()
			: GAME_TYPE.PENALTY_SHOOTOUT === gameType
				? new PenaltyShotoutsMarkets()
				: gameType === GAME_TYPE.KENO
					? new KenoMarkets()
					: isSeasonGame(gameType)
						? new SeasonMarkets(gameType)
						: gameType === GAME_TYPE.LUCKY_SIX
							? new LuckySixMarkets()
							: gameType === GAME_TYPE.SPIN_TO_WIN
								? new Spin2WinMarkets()
								: isSeasonGame(gameType)
									? new SeasonMarkets(gameType)
									: new BaseMarkets();

	return instance[fn].apply(instance, args);
};

export default runMarketUtilsFunction;
