import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TOTAL_PERCENT_VALUE } from "constants/statistic.constants.js";
import StatTicket from "components/pages/luckySix/newGameFlow/components/statTicket";

const MostlyDrawnNumbersStats = ({ ballStatistics }) => {
	const { t } = useTranslation();

	const oddEvenObj = useMemo(() => {
		let oddCount = 0;
		let allCount = 0;

		ballStatistics.forEach((stat) => {
			if (stat?.number % 2 === 1) {
				oddCount += stat?.electedCount;
			}

			allCount += stat?.electedCount;
		});

		const odd = Math.round((oddCount / allCount) * TOTAL_PERCENT_VALUE);

		return {
			odd,
			even: TOTAL_PERCENT_VALUE - odd
		};
	}, [ballStatistics]);

	return <StatTicket title={t("common.mostlyDrawnNumers")} even={oddEvenObj.even} odd={oddEvenObj.odd} />;
};

export default MostlyDrawnNumbersStats;
