import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import SvgTextShadow from "components/countdown/SvgTextShadow.jsx";

import "./styles.scss";

const Countdown = ({ start, endValue = 1, finished, closingText = "0" }) => {
	const timerRef = useRef(null);
	const lastValueTimeoutRef = useRef(null);
	const finishedTimeoutRef = useRef(null);
	const [currentValue, setCurrentValue] = useState(start);
	const [lastValue, setLastValue] = useState(null);

	useEffect(() => {
		timerRef.current = setInterval(() => {
			setCurrentValue((prev) => --prev);
		}, 1000);

		return () => {
			clearInterval(timerRef.current);
		};
	}, []);

	useEffect(() => {
		if (currentValue === endValue && lastValue === null) {
			clearInterval(timerRef.current);

			lastValueTimeoutRef.current = setTimeout(() => {
				setLastValue(closingText);
			}, 1000);
		}

		return () => {
			clearInterval(lastValueTimeoutRef.current);
		};
	}, [closingText, currentValue, endValue, lastValue]);

	useEffect(() => {
		if (lastValue !== null) {
			finishedTimeoutRef.current = setTimeout(() => {
				finished();
			}, 1000);
		}

		return () => {
			clearInterval(finishedTimeoutRef.current);
		};
	}, [finished, lastValue]);

	return (
		<div className="vs--countdown" key={lastValue || currentValue}>
			<SvgTextShadow text={lastValue || currentValue} />
		</div>
	);
};

Countdown.propTypes = {
	start: PropTypes.number.isRequired,
	endValue: PropTypes.number,
	closingText: PropTypes.string,
	finished: PropTypes.func
};

export default Countdown;
