import { GAME_TYPE, SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants";
import "./styles.scss"
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { mergeClassNames } from "utils/common";
import RouletteBoardDefaultView from "./defaultView";

const RouletteBoardSuggestedView = ({ activeGroup, groupOutcomes }) => {
	const ref = useRef()
	const { t } = useTranslation()

	useEffect(() => {
		if (!activeGroup || !Array.isArray(groupOutcomes)) {
			return
		}
		const numbers = groupOutcomes.map(outcome => {
			const numTag = ref.current.querySelector(`.vs--spin-2-win-roulette-board-number-${outcome}`);
			if (numTag) {
				numTag.classList.add("vs--spin-2-win-roulette-board-number-active")
				return numTag
			}
			return null
		})

		const filtered = numbers.filter(Boolean)

		return () => {
			filtered.forEach(numTag => {
				numTag.classList.remove("vs--spin-2-win-roulette-board-number-active")
			})
		}

	}, [activeGroup, groupOutcomes])

	return <RouletteBoardDefaultView ref={ref} />
}
export default RouletteBoardSuggestedView;