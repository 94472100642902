import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getLuckySixStatistics } from "store/actions/game/game.actions.js";
import { useEffect } from "react";
import HotColdNumbers from "components/pages/luckySix/newGameFlow/components/hotColdNumbers";
import ColorFrequency from "components/pages/luckySix/newGameFlow/components/colorFrequency";
import { BALL_POSITION_TO_STAT_ITEM, LUCKY_SIX_MARKETS } from "constants/game.constants.js";
import StatTicket from "components/pages/luckySix/newGameFlow/components/statTicket";
import MostlyDrawnNumbersStats from "components/pages/luckySix/newGameFlow/components/mostlyDrawnNumbersStats";

const LastGameStatistic = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const luckySixStatistics = useSelector((state) => state.game.lucySixStatistics);

	useEffect(() => {
		dispatch(getLuckySixStatistics(false));
	}, [dispatch]);

	return (
		<>
			<div className="vs--lucky-six-live-monitor-last-game-statistic">
				<div className="vs--lucky-six-live-monitor-last-game-statistic-section">
					<div className="vs--lucky-six-live-monitor-last-game-statistic-section-title">
						{t("common.last100gamesstats")}
					</div>
					<div className="vs--lucky-six-live-monitor-last-game-statistic-section-content">
						{luckySixStatistics?.ballStatistics && (
							<HotColdNumbers ballStatistics={luckySixStatistics.ballStatistics} />
						)}
						<div className="vs--lucky-six-live-monitor-last-game-statistic-section-content-divider vs--mt-18 vs--mb-18"></div>
						{luckySixStatistics?.colourStatistics && (
							<div className="vs--lucky-six-live-monitor-last-game-statistic-section-content-color-frequency">
								<ColorFrequency
									ball={BALL_POSITION_TO_STAT_ITEM.FIRST}
									group={LUCKY_SIX_MARKETS.FIRST_BALL_COLOR}
									colourStatistics={luckySixStatistics.colourStatistics}
								/>
								<ColorFrequency
									ball={BALL_POSITION_TO_STAT_ITEM.LAST}
									group={LUCKY_SIX_MARKETS.LAST_BALL_COLOR}
									colourStatistics={luckySixStatistics.colourStatistics}
								/>
							</div>
						)}
					</div>
				</div>

				<div className="vs--lucky-six-live-monitor-last-game-statistic-section">
					<div className="vs--lucky-six-live-monitor-last-game-statistic-section-content">
						<div className="vs--lucky-six-live-monitor-last-game-statistic-section-content-group">
							{luckySixStatistics?.firstShowedEvenCount &&
								luckySixStatistics?.firstShowedOddCount &&
								luckySixStatistics?.firstShowedOverCount &&
								luckySixStatistics?.firstShowedUnderCount && (
									<StatTicket
										title={t("common.firstNumber")}
										over={luckySixStatistics?.firstShowedOverCount}
										under={luckySixStatistics?.firstShowedUnderCount}
										even={luckySixStatistics?.firstShowedEvenCount}
										odd={luckySixStatistics?.firstShowedOddCount}
									/>
								)}

							{luckySixStatistics?.preNumberSumEvenCount &&
								luckySixStatistics?.preNumberSumOddCount &&
								luckySixStatistics?.preNumberSumOverCount &&
								luckySixStatistics?.preNumberSumUnderCount && (
									<StatTicket
										title={t("common.preNumbers")}
										over={luckySixStatistics?.preNumberSumOverCount}
										under={luckySixStatistics?.preNumberSumUnderCount}
										even={luckySixStatistics?.preNumberSumEvenCount}
										odd={luckySixStatistics?.preNumberSumOddCount}
									/>
								)}
						</div>

						<div className="vs--lucky-six-live-monitor-last-game-statistic-section-content-group">
							{luckySixStatistics?.lastShowedEvenCount &&
								luckySixStatistics?.lastShowedOddCount &&
								luckySixStatistics?.lastShowedOverCount &&
								luckySixStatistics?.lastShowedUnderCount && (
									<StatTicket
										enableExtraSpace
										title={t("common.lastNumber")}
										over={luckySixStatistics?.lastShowedOverCount}
										under={luckySixStatistics?.lastShowedUnderCount}
										even={luckySixStatistics?.lastShowedEvenCount}
										odd={luckySixStatistics?.lastShowedOddCount}
									/>
								)}
							<div className="vs--lucky-six-live-monitor-last-game-statistic-section-block">

							{luckySixStatistics?.ballStatistics && (
								<div className="vs--lucky-six-live-monitor-last-game-statistic-section-single">
									<MostlyDrawnNumbersStats ballStatistics={luckySixStatistics.ballStatistics} />
								</div>
							)}

								{
									luckySixStatistics?.firstLastNumberSumOverCount &&
									luckySixStatistics?.firstLastNumberSumUnderCount && (
										<div className="vs--lucky-six-live-monitor-last-game-statistic-section-single">
											<StatTicket
												shortName
												title={`${t("common.first")} + ${t("common.last")}`}
												over={luckySixStatistics?.firstLastNumberSumOverCount}
												under={luckySixStatistics?.firstLastNumberSumUnderCount}
											/>
										</div>
									)}
							</div>
						</div>


					</div>
				</div>
			</div>
		</>
	);
};

export default LastGameStatistic;
