import { useEffect } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { Provider } from "react-redux";

// Layouts
import RootLayout from "layouts/root.layout";
import AuthLayoutRoute from 'layouts/auth.layout';
import MainLayoutRoute from 'layouts/main.layout';
import StreamLayoutRoute from 'layouts/stream.layout';
import JackpotLiveInfoLayoutRoute from 'layouts/jackpotLiveInfo.layout';
import ActiveJackpotLiveInfoLayoutRoute from 'layouts/jackpotLiveInfoActive.layout';
import QRLayoutRoute from 'layouts/qr.layout';
import KenoLayoutRoute from 'layouts/keno.layout';
import LiveMonitorGameLayoutRoute from 'layouts/liveMonitorGame.layout';
import LiveMonitorLayoutRoute from 'layouts/liveMonitor.layout';

// Routes
import LoginRoute from 'routes/login.route';
import ActivateRoute from 'routes/activate.route';
import ForgotPasswordRoute from 'routes/forgotPassword.route';
import ResetPasswordRoute from 'routes/resetPassword.route';
import MainRoute from 'routes/main.route';
import ReportRoute from 'routes/report.route';
import StreamRoute from 'routes/stream.route';
import JackpotLiveInfoRoute from 'routes/jackpot.route';
import ActiveJackpotLiveInfoRoute from "routes/jackpotActive.route";
import KenoRoute from 'routes/keno.route';
import QRRoute from 'routes/qr.route';

import store from "store/configureStore";

import "utils/axios";

import "translations/config";

import { isMobile, isTablet } from "utils/common";

import Paths from "constants/path.constants";
import LiveMonitorRoute from "routes/liveMonitor.route";
import LiveMonitorGameRoute from "routes/liveMonitorGame.route";
import WebPageLayout from "layouts/webPage.layout.jsx";
import { GAME_TYPE, GAME_TYPE_TEXT_KEYS } from "constants/game.constants.js";
import LuckySix from "components/pages/luckySix";
import Spin2Win from "components/pages/spin2Win";

const App = () => {
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path={Paths.MAIN} element={<RootLayout />} errorElement={<></>}>
				<Route path={Paths.MAIN} element={<MainLayoutRoute />}>
					<Route path="" element={<MainRoute />} />
				</Route>
				<Route path={Paths.LOGIN} element={<AuthLayoutRoute />}>
					<Route path="" element={<LoginRoute />} />
				</Route>
				<Route path={Paths.ACTIVATE} element={<AuthLayoutRoute />}>
					<Route path="" element={<ActivateRoute />} />
				</Route>
				<Route path={Paths.FORGOT_PASSWORD} element={<AuthLayoutRoute />}>
					<Route path="" element={<ForgotPasswordRoute />} />
				</Route>
				<Route path={Paths.RESET_PASSWORD} element={<AuthLayoutRoute />}>
					<Route path="" element={<ResetPasswordRoute />} />
				</Route>
				<Route path={Paths.REPORT} element={<MainLayoutRoute />}>
					<Route path="" element={<ReportRoute />} />
				</Route>
				<Route path={Paths.STREAM} element={<StreamLayoutRoute />}>
					<Route path="" element={<StreamRoute />} />
				</Route>
				<Route path={Paths.LIVE_MONITOR} element={<LiveMonitorLayoutRoute />}>
					<Route path="" element={<LiveMonitorRoute />} />
				</Route>
				<Route path={Paths.LIVE_MONITOR_GAME} element={<LiveMonitorGameLayoutRoute />}>
					<Route path="" element={<LiveMonitorGameRoute />} />
				</Route>
				<Route path={Paths.KENO} element={<WebPageLayout gameType={GAME_TYPE.KENO}  /> }>
					<Route path="" element={<KenoRoute />} />
				</Route>
				<Route path={Paths.QR} element={<QRLayoutRoute />}>
					<Route path="" element={<QRRoute />} />
				</Route>
				<Route path={Paths.JACKPOT_LIVE_INFO} element={<JackpotLiveInfoLayoutRoute />}>
					<Route path="" element={<JackpotLiveInfoRoute />} />
				</Route>
				<Route path={Paths.JACKPOT_LIVE_INFO_ACTIVE} element={<ActiveJackpotLiveInfoLayoutRoute />}>
					<Route path="" element={<ActiveJackpotLiveInfoRoute />} />
				</Route>
				<Route path={Paths.LUCKY_SIX} element={<WebPageLayout gameType={GAME_TYPE.LUCKY_SIX}  />}>
					<Route path="" element={<LuckySix />} />
				</Route>
				<Route path={Paths.SPIN_TO_WIN} element={<WebPageLayout gameType={GAME_TYPE.SPIN_TO_WIN}  />}>
					<Route path="" element={<Spin2Win />} />
				</Route>
			</Route>
		));

	useEffect(() => {
		if ((isMobile() && !isTablet()) || location.pathname.startsWith(Paths.QR)) {
			document.documentElement.classList.add("vs--body-unscale");
			document.body.setAttribute("data-ismobile", 1);
		} else {
			document.body.setAttribute("data-ismobile", 0);
		}
	}, []);

	return (
		<Provider store={store}>
			<div className="App">
				<RouterProvider router={router} />
			</div>
		</Provider>
	);
};

export default App;
