import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common.js";
import { useEffect, useRef } from "react";

import "./styles.scss";

const BackgroundVideo = ({ showLottery, isVisible }) => {
	const ref = useRef(null);

	useEffect(() => {
		const handler = () => {
			if (!document.hidden) {
				setTimeout(() => ref.current?.play(), 200);
			}
		};

		document.addEventListener("visibilitychange", handler);

		return () => document.removeEventListener("visibilitychange", handler);
	}, []);

	return (
		<div
			className={mergeClassNames(
				"vs--lucky-six-live-background-video",
				isVisible ? "is-visible" : "",
				isVisible ? (showLottery ? "lottery-video" : "stars-video") : ""
			)}
		>
			<video
				className="lottery-video"
				ref={ref}
				width="100%"
				autoPlay
				muted
				loop
				playsInline
				src={buildPathToStaticFolderOfCDN(`videos/lucky-six/lottery-video.mp4`)}
			/>
			<video
				className="stars-video"
				ref={ref}
				width="100%"
				autoPlay
				muted
				loop
				playsInline
				src={buildPathToStaticFolderOfCDN(`videos/lucky-six/stars-video.mp4`)}
			/>
		</div>
	);
};

export default BackgroundVideo;
