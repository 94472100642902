import BulkSelectionButton from "components/pages/main/markets/spin2Win/topBoard/bulkSelectionButton.jsx";
import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants.js";
import { SPIN_TO_WIN_MARKET_GROUPS } from "constants/market.constants.js";
import { useTranslation } from "react-i18next";
import { mergeClassNames } from "utils/common";

const ColumnMarkets = ({ markets, handleOddClick, handleMarketHover, bets, isOddDisabled }) => {
	const { t } = useTranslation();

	const columnMarket = markets.find((market) => market.group === SPIN_TO_WIN_MARKET_GROUPS.COLUMN);
	const columnMarketOdds = columnMarket?.odds[0] || [];

	const isFirstColumnSelected = bets.some((bet) => bet.oddId === columnMarketOdds[0]?.id);
	const isSecondColumnSelected = bets.some((bet) => bet.oddId === columnMarketOdds[1]?.id);
	const isThirdColumnSelected = bets.some((bet) => bet.oddId === columnMarketOdds[2]?.id);

	return (
		<div className="vs--roulete-right-block">
			<BulkSelectionButton
				className={isThirdColumnSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(columnMarket, columnMarketOdds[2])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.THIRD_COLUMN}
				text={`${t("common.3rd")} ${t("common.column")}`}
				subText={columnMarketOdds[2]?.factor ? `x${columnMarketOdds[2].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={isSecondColumnSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(columnMarket, columnMarketOdds[1])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.SECOND_COLUMN}
				text={`${t("common.2nd")} ${t("common.column")}`}
				subText={columnMarketOdds[1]?.factor ? `x${columnMarketOdds[1].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={isFirstColumnSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(columnMarket, columnMarketOdds[0])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.FIRST_COLUMN}
				text={`${t("common.1st")} ${t("common.column")}`}
				subText={columnMarketOdds[0]?.factor ? `x${columnMarketOdds[0].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
		</div>
	);
};

export default ColumnMarkets;
