const ProgressBarStatisticsBackground = () => {
	return (
		<div className="vs--spin-2-win-stream-progress-bar-statistics-container-group-background">
			<svg
				className="statistic-background open"
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="100%"
				viewBox="0 0 1811 758"
				fill="none"
			>
				<g filter="url(#filter0_b_5924_16796)">
					<path
						d="M0 123.5C0 119.082 3.58172 115.5 8 115.5H628.347C631.005 115.5 633.531 114.465 635.448 112.623C646.583 101.922 687.683 63.908 731.5 41.9999C786.5 14.5001 841 1.81719e-09 904 0C962.5 -1.68739e-09 1019.5 12 1083 44.5003C1121.52 64.2154 1163.35 102.219 1174.59 112.741C1176.49 114.517 1178.95 115.5 1181.55 115.5H1803C1807.42 115.5 1811 119.082 1811 123.5V647.418C1811 651.868 1807.37 655.463 1802.92 655.418L1175.48 649.04C1172.93 649.014 1170.49 649.942 1168.58 651.645C1156.44 662.469 1108.41 703.822 1060 725C990.371 758.58 907.573 758.019 905.538 758C905.474 758 905.525 758 905.461 758C903.427 758.02 822.145 758.601 755 727.25C709.46 709.684 660.533 668.605 648.362 658.022C646.492 656.397 644.129 655.5 641.652 655.5H7.99999C3.58171 655.5 0 651.918 0 647.5V123.5Z"
						fill="url(#paint0_linear_5924_16796)"
					/>
					<path
						d="M1059.8 724.542L1059.79 724.546L1059.78 724.55C990.265 758.076 907.569 757.519 905.543 757.5L905.515 757.5L905.509 757.5L905.498 757.5L905.489 757.5L905.484 757.5L905.456 757.5C903.428 757.52 822.246 758.096 755.212 726.797L755.196 726.79L755.18 726.784C709.731 709.253 660.864 668.23 648.69 657.645C646.731 655.942 644.251 655 641.652 655H7.99999C3.85785 655 0.5 651.642 0.5 647.5V123.5C0.5 119.358 3.85786 116 8 116H628.347C631.137 116 633.786 114.914 635.794 112.984C646.925 102.287 687.979 64.3191 731.724 42.4471C786.662 14.9782 841.086 0.5 904 0.5C962.422 0.5 1019.34 12.4817 1082.77 44.9454C1121.22 64.6248 1163.01 102.583 1174.25 113.106C1176.24 114.968 1178.82 116 1181.55 116H1803C1807.14 116 1810.5 119.358 1810.5 123.5V647.418C1810.5 651.59 1807.1 654.96 1802.92 654.918L1175.49 648.54C1172.8 648.513 1170.24 649.487 1168.24 651.272C1156.11 662.094 1108.13 703.398 1059.8 724.542Z"
						stroke="url(#paint1_linear_5924_16796)"
						strokeOpacity="0.4"
					/>
				</g>

				<defs>
					<filter
						id="filter0_b_5924_16796"
						x="-20"
						y="-20"
						width="1851"
						height="798.003"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
						<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5924_16796" />
						<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5924_16796" result="shape" />
					</filter>

					<linearGradient
						id="paint0_linear_5924_16796"
						x1="0"
						y1="0"
						x2="406.232"
						y2="1224.17"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#A0A2C2" stopOpacity="0.5" />
						<stop offset="1" stopColor="#C7C7C7" stopOpacity="0.1" />
					</linearGradient>

					<linearGradient
						id="paint1_linear_5924_16796"
						x1="-3.4421e-05"
						y1="14.7101"
						x2="464.441"
						y2="1326.73"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="white" />
						<stop offset="1" stopColor="white" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	);
};
export default ProgressBarStatisticsBackground;
