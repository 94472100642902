import { Fragment } from "react"
import Card from "./card";
import { mergeClassNames, isNullish } from "utils/common";
import { useTranslation } from "react-i18next";

const Cards = ({
	className = "", 
	data,
	currencyDecimalCount,
	currencyCode,
	currencySymbol
}) => {
	const { t } = useTranslation();
	return (
		<Fragment>
			{
				data?.map((level) => (
					<div
						key={level.levelType}
						className={mergeClassNames(
							className,
							"vs--flex vs--flex-col",
							"vs--jackpot-content-levels",
							`vs--jackpot-content-levels-${level.levelType}`
						)}
					>
							<Card
								currencyDecimalCount={currencyDecimalCount}
								currencyCode={currencyCode}
								currencySymbol={currencySymbol}
								jackpot={level}
							/>
					</div>
				))
			}
		</Fragment>
	)
}
export default Cards