import { memo } from "react";
import MonitorSubtitleCountDown from "./monitorSubtitleCountDown";

const MonitorSubtitle = ({ event, logoSrc, children }) => {
	return (
		<div className="live-monitor-subtitle">
			<div className="live-monitor-subtitle-logo-section">
				<img className="live-monitor-subtitle-logo live-monitor-subtitle-logo-animation" src={logoSrc} alt="logo" />
			</div>
			<div className="live-monitor-subtitle-clock-section">
				<div className="live-monitor-subtitle-clock">
					<MonitorSubtitleCountDown event={event?.event || null} />
				</div>
			</div>
			<div className="live-monitor-subtitle-logo-section">
				{children}
			</div>
		</div>
	);
};
export default MonitorSubtitle;
