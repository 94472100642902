import { useEffect, useRef } from "react";
import anime from "animejs/lib/anime.es.js";

import { JACKPOT_LEVEL, JACKPOT_ANIMATIONS_TIMEOUTS } from "constants/bonus.constants";
import useEvent from "hooks/useEvent";

const vW = 1920;
const vH = 1080;

const LEVELS_STOP_COLORS = {
	[JACKPOT_LEVEL.BRONZE]: {
		stop1: "#EC9B59",
		stop2: "#BE9060",
		stop3: "#B5743F",
		stop4: "#BF7E43",
	},
	[JACKPOT_LEVEL.SILVER]: {
		stop1: "#676A70",
		stop2: "#6A6C74",
		stop3: "#9A9CA6",
		stop4: "#7D7E84 ",
	},
	[JACKPOT_LEVEL.GOLD]: {
		stop1: "#B19C65",
		stop2: "#D8BD73",
		stop3: "#BF9E48",
		stop4: "#C59239",
	},
}

const TransitionSvg = ({
	getMainContainer, 
	jackpot, 
	toggleTransitionFrom = "top", 
	setShowCanfetti, 
	setAnimationFinish,
	cleanupCallback: argCleanupCallback = Function.prototype
}) => {
	const navigationRef = useRef();
	const cleanupCallback = useEvent(argCleanupCallback);

	useEffect(() => {
		const transitionTimeoutId = setTimeout(() => {
			const mainContainer = getMainContainer();
			mainContainer.classList.add('is-open');
			setTimeout(() => {
				mainContainer.classList.add('vs--active-jackpot-live-info-winner-active')
			}, JACKPOT_ANIMATIONS_TIMEOUTS.ACTIVE);
		}, JACKPOT_ANIMATIONS_TIMEOUTS.TRANSITION_SVG)

		const closingTimeoutId = setTimeout(() => {
			navigationRef.current.style.transition = "all 1.1s ease-in-out"
			navigationRef.current.style.height = 0;
			const mainContainer = getMainContainer();
			mainContainer.classList.remove('is-open');
			setTimeout(() => {
				mainContainer.classList.remove('vs--active-jackpot-live-info-winner-active')
				setAnimationFinish(true);
				cleanupCallback(jackpot)
			}, JACKPOT_ANIMATIONS_TIMEOUTS.INACTIVE);
		}, JACKPOT_ANIMATIONS_TIMEOUTS.CLOSING_SVG);

		return () => {
			clearTimeout(transitionTimeoutId);
			clearTimeout(closingTimeoutId);
		}
	}, [jackpot]);

	return (
		<nav ref={navigationRef}>
		</nav>
	);
};

export default TransitionSvg;