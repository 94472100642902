import { cloneElement, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Modal from "components/ui/modal";

const ModalWrapper = ({
	title = null,
	onCancel,
	children,
	footer = null,
	tabs = null,
	subTabs = null,
	onTabClick,
	onSubTabClick,
	visible,
	coordinates = { clientX: "50%", clientY: "50%" },
	classNames = { outside: "", content: "", header: "", body: "", footer: "" },
	activeTabKey,
	activeSubTabKey,
	isTabDisabled = Function.prototype,
	isSubTabDisabled = Function.prototype,
	closeIcon,
	modalContentPreWrapper = null,
	modalContentWrapperStyles = {},
	modalContentHeaderStyles = {},
	modalContentBodyStyles = {},
	modalContentFooterStyles = {}
}) => {
	/** Active tab and sub tab key */
	const [activeTab, setActiveTab] = useState(null);
	const [activeSubTab, setActiveSubTab] = useState(null);

	useEffect(() => {
		if (Array.isArray(tabs) && tabs.length > 0) {
			setActiveTab(activeTabKey ? activeTabKey : tabs ? tabs[0]?.key ?? null : null);
		} else {
			setActiveTab(null);
		}
	}, [tabs?.length]);

	useEffect(() => {
		if (Array.isArray(subTabs) && subTabs.length > 0) {
			setActiveSubTab(
				activeSubTabKey
					? activeSubTabKey
					: Array.isArray(subTabs)
						? subTabs[0]?.key ?? null
						: subTabs instanceof Object && Array.isArray(subTabs[activeTab])
							? subTabs[activeTab]?.[0]?.key ?? null
							: null
			);
		} else {
			setActiveSubTab(null);
		}
	}, [subTabs?.length]);

	/** Function which will fire on tab click
	 * @function
	 * @param {object} tab - clicked tab info
	 * @memberOf Modal
	 */
	const handleTabClick = (tab) => {
		if (tab.key !== activeTab) {
			setActiveTab(tab.key);
			setActiveSubTab(
				Array.isArray(subTabs)
					? subTabs[0].key
					: subTabs instanceof Object && Array.isArray(subTabs[tab.key])
						? subTabs[tab.key][0].key
						: null
			);
			onTabClick && onTabClick(tab.key);
		}
	};

	const handleSubTabClick = (subTab) => {
		if (subTab.key !== activeSubTab) {
			setActiveSubTab(subTab.key);
			onSubTabClick && onSubTabClick(subTab.key);
		}
	};

	/** Function , renders the JSX of tabs and subTabs
	 * @function
	 * @returns {JSX}
	 * @memberOf Modal
	 */
	const renderTabs = () => (
		<div className="vs--modal-tabs vs--flex vs--align-center">
			{tabs.map((tab, i) => (
				<div
					key={tab.key === undefined || tab.key === null ? i : tab.key}
					className={
						"vs--modal-tabs-item vs--pl-8 vs--pr-8 vs--mr-8" +
						(tab.key === activeTab ? " vs--modal-tabs-item-active" : "")
					}
					onClick={() => handleTabClick(tab)}
					disabled={isTabDisabled(tab)}
				>
					<span>{tab.element}</span>
				</div>
			))}
		</div>
	);

	const renderSubTabs = (subTabsArg) => (
		<div className="vs--modal-content-subTabs">
			{subTabsArg.map((subTab, i) => (
				<button
					className={
						"vs--subTabs-btn vs--font-exstrasmall vs--font-regular vs--pl-8 vs--pr-8 vs--ml-12" +
						(subTab.key === activeSubTab ? " vs--subTabs-btn-active" : "")
					}
					type="button"
					onClick={() => handleSubTabClick(subTab)}
					key={subTab.key === undefined || subTab.key === null ? i : subTab.key}
					disabled={isSubTabDisabled(subTab)}
				>
					{subTab.element}
				</button>
			))}
		</div>
	);

	const renderFooter = (footerData) => {
		const sourceType = Array.isArray(footerData) ? "array" : "object";
		const _render = (data, id) => {
			const { text, className, type, ...otherProps } = data;
			return (
				<button
					key={id}
					{...otherProps}
					className={
						"vs--modal-content-startedGameFlowFooter-btn" +
						(type === "secondary" ? " vs--modal-content-startedGameFlowFooter-btn-secondary" : "") +
						(className ? " " + className : "")
					}
				>
					{text}
				</button>
			);
		};
		switch (sourceType) {
			case "array":
				return footerData.map(_render);
			case "object":
				const keyList = Object.keys(footerData);
				return keyList.map((key) => _render(footerData[key], key));
			default:
				break;
		}
		return null;
	};

	return (
		<Modal
			coordinates={coordinates}
			classNames={{ ...classNames, content: `vs--modal ${classNames.content ? classNames.content : ""}` }}
			headerContent={tabs ? renderTabs() : title ? <div className="vs--modal-title">{title}</div> : null}
			closeIcon={closeIcon}
			isOpen={visible}
			onCancel={onCancel}
			onOutsideClick={() => {}}
		>
			<div className="vs--modal-content-wrapper" style={modalContentWrapperStyles}>
				{modalContentPreWrapper}
				<div className="vs--modal-content-header" style={modalContentHeaderStyles}>
					{Array.isArray(subTabs) && subTabs.length > 0
						? renderSubTabs(subTabs)
						: subTabs instanceof Object && Array.isArray(subTabs[activeTab]) && subTabs[activeTab].length > 0
							? renderSubTabs(subTabs[activeTab])
							: null}
				</div>
				<div className="vs--modal-content-body" style={modalContentBodyStyles}>
					{typeof children === "function" ? children({ activeTab, activeSubTab }) : children}
				</div>
				{footer ? (
					<div className="vs--modal-content-footer" style={modalContentFooterStyles}>
						{typeof footer === "function"
							? footer({ activeTab, activeSubTab })
							: Array.isArray(footer)
								? renderFooter(footer)
								: footer instanceof Object
									? footer.$$typeof === Symbol.for("react.element") &&
										footer.type !== Symbol.for("react.fragment")
										? cloneElement(footer, { ...footer.props, activeTab, activeSubTab })
										: footer.type === Symbol.for("react.fragment")
											? footer
											: renderFooter(footer)
									: footer}
					</div>
				) : null}
			</div>
		</Modal>
	);
};

ModalWrapper.propTypes = {
	/** The modal dialog's title */
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
	/** Function that will be called on modal close */
	onCancel: PropTypes.func,
	/** Array of modal Tabs */
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			/** Tab key */
			key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			/** Tab text */
			element: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string])
		})
	),
	subTabs: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				/** SubTab key */
				key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
				/** SubTab text */
				element: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string])
			})
		),
		PropTypes.shape({
			[PropTypes.oneOf([PropTypes.string, PropTypes.symbol])]: PropTypes.arrayOf(
				PropTypes.shape({
					/** SubTab key */
					key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
					/** SubTab text */
					element: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string])
				})
			)
		})
	]),
	/** Function Will fire on tab click */
	onTabClick: PropTypes.func,
	/** Function Will fire on subTab click */
	onSubTabClick: PropTypes.func,
	/** Modal Content */
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.func,
		PropTypes.element
	]),
	/** Modal Footer */
	footer: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.arrayOf(PropTypes.object),
		PropTypes.node,
		PropTypes.func,
		PropTypes.element
	]),
	/** If true the modal will be visible */
	visible: PropTypes.bool,
	/** Css variables` viewport x, y coordinates */
	coordinates: PropTypes.shape({
		clientX: PropTypes.string,
		clientY: PropTypes.string
	}),
	/** Css classes for modal */
	classNames: PropTypes.shape({
		outside: PropTypes.string,
		content: PropTypes.string,
		header: PropTypes.string,
		body: PropTypes.string,
		footer: PropTypes.string
	}),
	/** Active tab key by default */
	activeTabKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** Active subTab key by default */
	activeSubTabKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** Function for checking tab state */
	isTabDisabled: PropTypes.func,
	/** Function for checking subTab state */
	isSubTabDisabled: PropTypes.func,
	/** Custom close icon */
	closeIcon: PropTypes.bool,
	/** Properties for Modal component for displaying befor header */
	modalContentPreWrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
	/** Properties for Wrapper of Modal component*/
	modalContentWrapperStyles: PropTypes.object,
	/** Properties for Header of Modal component*/
	modalContentHeaderStyles: PropTypes.object,
	/** Properties for Body of Modal component*/
	modalContentBodyStyles: PropTypes.object,
	/** Properties for Footer of Modal component*/
	modalContentFooterStyles: PropTypes.object
};

export default ModalWrapper;
