import useLuckySixBetting from "hooks/useLuckySixBetting.js";
import { mergeClassNames } from "utils/common.js";

const BetColorButton = ({ color, currentMarket, currentEvent, isSelectingColumn }) => {
	const marketOdds = currentMarket?.odds?.[0];
	const currentOdd = marketOdds?.find((odd) => odd.outcome.toLowerCase() === color);

	const { isOddSelected, handleOddClick } = useLuckySixBetting({ currentEvent, currentMarket, currentOdd });

	const isSelected = isOddSelected(currentOdd?.id);
	const isDisabled = false;

	return (
		<div
			className={mergeClassNames(
				"vs--lucky-six-select-color",
				isSelected && !isSelectingColumn && "vs--lucky-six-select-color-active",
				isSelectingColumn && "vs--flex vs--align-center vs--justify-center"
			)}
			key={color}
		>
			<div
				className={`vs--lucky-six-select-color-button vs--lucky-six-select-color-button-${color}${isDisabled ? " disabled" : ""}`}
				onClick={() => handleOddClick(isSelectingColumn)}
			>
				<div className={`vs--lucky-six-select-color-button-inner`}></div>
			</div>
		</div>
	);
};

export default BetColorButton;
