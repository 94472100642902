import { GAME_TYPE, SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants";
import "./styles.scss"
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { mergeClassNames } from "utils/common";
import RouletteBoardDefaultView from "./defaultView";

const RouletteBoardResultView = ({ event }) => {
	const ref = useRef()


	useEffect(() => {
		if (!event) {
			return
		}

		const number = event?.gameData?.scenes?.at(0)?.number;

		if (typeof number !== "number") {
			return
		}

		const boardContainer = ref.current;
		const board = boardContainer.querySelector(".vs--spin-2-win-roulette-board")

		const classSelectors = [
			`.vs--spin-2-win-roulette-board-number-${number}`,
			(
				SPIN_TO_WIN_MARKETS_NUMBERS.HIGH.includes(number)
					? ".vs--spin-2-win-roulette-market-high"
					: SPIN_TO_WIN_MARKETS_NUMBERS.LOW.includes(number)
						? ".vs--spin-2-win-roulette-market-low"
						: null
			),
			(
				SPIN_TO_WIN_MARKETS_NUMBERS.FIRST_TWELVE.includes(number)
					? ".vs--spin-2-win-roulette-market-first-twelve"
					: SPIN_TO_WIN_MARKETS_NUMBERS.SECOND_TWELVE.includes(number)
						? ".vs--spin-2-win-roulette-market-second-twelve"
						: SPIN_TO_WIN_MARKETS_NUMBERS.THIRD_TWELVE.includes(number)
							? ".vs--spin-2-win-roulette-market-third-twelve"
							: null
			),
			(
				SPIN_TO_WIN_MARKETS_NUMBERS.FIRST_COLUMN.includes(number)
					? ".vs--spin-2-win-roulette-market-first-column"
					: SPIN_TO_WIN_MARKETS_NUMBERS.SECOND_COLUMN.includes(number)
						? ".vs--spin-2-win-roulette-market-second-column"
						: SPIN_TO_WIN_MARKETS_NUMBERS.THIRD_COLUMN.includes(number)
							? ".vs--spin-2-win-roulette-market-third-column"
							: null
			),
			(
				SPIN_TO_WIN_MARKETS_NUMBERS.EVEN_NUMBERS.includes(number)
					? ".vs--spin-2-win-roulette-market-even"
					: SPIN_TO_WIN_MARKETS_NUMBERS.ODD_NUMBERS.includes(number)
						? ".vs--spin-2-win-roulette-market-odd"
						: null
			),
			(
				SPIN_TO_WIN_MARKETS_NUMBERS.RED_NUMBERS.includes(number)
					? ".vs--spin-2-win-roulette-market-red"
					: SPIN_TO_WIN_MARKETS_NUMBERS.BLACK_NUMBERS.includes(number)
						? ".vs--spin-2-win-roulette-market-black"
						: null
			)
		];

		const filteredClassSelectors = classSelectors.filter(Boolean)
		
		const tags = filteredClassSelectors.map(selector => {
			const tag = board.querySelector(selector);
			if (tag) {
				tag.classList.add("vs--spin-2-win-highlight-element")
			}
			
			return tag
		})

		const filtered = tags.filter(Boolean)

		return () => {
			filtered.forEach(tag => {
				tag.classList.remove("vs--spin-2-win-highlight-element")
			})
		}

	}, [event])

	return <RouletteBoardDefaultView boardClassName="vs--spin-2-win-roulette-board-opacity" ref={ref} />
}
export default RouletteBoardResultView;