import React, { useMemo } from "react";
import { LUCKY_SIX_BALL_COLORS } from "constants/game.constants.js";
import BetColor from "components/pages/luckySix/newGameFlow/components/betColor";
import { useSelector } from "react-redux";
import runMarketUtilsFunction from "utils/markets/run.js";

const ColorMarketsLiveMonitor = ({ group, title }) => {
	const current = useSelector((state) => state.game.current);
	const currentEvent = useSelector((state) => state.game.matches.data.find((m) => m.id === current) || null);
	const market = currentEvent?.markets?.main?.find((marketItem) => marketItem.group === group);
	return (
		<div className="vs--lucky-six-live-monitor-color-markets">
			{title && <div className="vs--lucky-six-live-monitor-color-markets-title">{title}</div>}

			<div className="vs--lucky-six-live-monitor-color-markets-container">
				<div className="vs--lucky-six-live-monitor-color-markets-container-balls">
					{LUCKY_SIX_BALL_COLORS.map((color) => (
						<BetColor key={color} color={color} />
					))}
				</div>
				<span className="vs--lucky-six-live-monitor-color-markets-container-factor">
					{market?.odds?.[0]?.[0]?.factor}
				</span>
			</div>
		</div>
	);
};

export default ColorMarketsLiveMonitor;
