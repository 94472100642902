import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LUCKY_SIX_MARKETS } from "constants/game.constants.js";
import { Fragment } from "react";
import { findMarketWinnerByGroup } from "components/pages/luckySix/components/markets/helpers";

const FiveMarket = ({ markets, title, overUnderOutcome, oddEvenOutcome, sumOfFiveNumbers }) => {
	const { t } = useTranslation();

	const fiveOverUnder =
		overUnderOutcome || findMarketWinnerByGroup(LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_OVER_UNDER, markets)?.outcome;
	const fiveOddEven =
		oddEvenOutcome || findMarketWinnerByGroup(LUCKY_SIX_MARKETS.SUM_FIVE_TOTAL_ODD_EVEN, markets)?.outcome;

	return (
		<>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-name">
				{title || t("common.five")}:
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-number">
				{sumOfFiveNumbers}
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{fiveOverUnder ? (
					<>
						<span style={{ color: "#A3A0C0" }}> - </span>
						{fiveOverUnder}
					</>
				) : null}
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{fiveOddEven ? (
					<>
						<span style={{ color: "#A3A0C0" }}> - </span>
						{fiveOddEven}
					</>
				) : null}
			</span>
		</>
	);
};

FiveMarket.propTypes = {
	markets: PropTypes.array,
	title: PropTypes.string,
	overUnderOutcome: PropTypes.string,
	oddEvenOutcome: PropTypes.string,
	sumOfFiveNumbers: PropTypes.number
};

export default FiveMarket;
