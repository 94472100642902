import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { initTranslations } from 'translations/config';

import Loader from 'components/ui/loader';

import { refreshStreamAccess } from 'store/actions/auth/auth.actions';
import { getUrlVars } from "utils/common";

const getIsTranslationLoaded = (state) => state.auth.translationsLoaded;
const getStreamAccessLoaded = (state) => state.auth.stream.accessLoaded;

/** Jackpot Live Info Layout Component */
const Layout = () => {
	const [decodedData, setDecodedData] = useState(null);

	const translationsLoaded = useSelector(getIsTranslationLoaded);
	const streamAccessLoaded = useSelector(getStreamAccessLoaded);

	const dispatch = useDispatch();

	const isLoaded = translationsLoaded && streamAccessLoaded;

	/** Load Translations */
	useEffect(() => {
		document.documentElement.classList.add("vs--body-unscale");
		let streamData = { languageId: "en" }
		try {
			const streamEncodedData = getUrlVars()['data'];
			streamData = JSON.parse(decodeURIComponent(atob(streamEncodedData)));

			setDecodedData(streamData);
		} catch (ex) {
			console.log("can't decode data")
		}


		initTranslations(
			streamData.languageFilePath,
			streamData.languageId,
			streamData.languageFilePath,
			streamData.languageId,
			Function.prototype,
			Function.prototype
		);
	}, []);

	useEffect(() => {
		if (translationsLoaded && decodedData) {
			const sessionTemplateType = decodedData?.sessionTemplateType ?? null;
			const streamRefreshToken = decodedData?.refreshToken ?? null;

			if (sessionTemplateType !== null) {
				document.documentElement.setAttribute("data-template", sessionTemplateType);
			}
			if (streamRefreshToken !== null) {
				dispatch(refreshStreamAccess(streamRefreshToken));
			}
		}
	}, [translationsLoaded, decodedData]);

	return (
		<div className="vs--container">
			{
				isLoaded ? <Outlet context={{ decodedData }} /> : <Loader full={true} />
			}
		</div>
	)
}

export default Layout;
