import PropTypes from "prop-types";
import BetBall from "components/pages/luckySix/components/betBall";
import { useEffect, useRef, useState } from "react";
import { mergeClassNames } from "utils/common.js";

const mappingCoordinates = {
	"6-1": {
		left: "36.35%",
		top: "8%"
	},
	"6-2": {
		left: "42.23%",
		top: "8%"
	},
	"6-3": {
		left: "48.1%",
		top: "8%"
	},
	"6-4": {
		left: "54%",
		top: "8%"
	},
	"6-5": {
		left: "59.97%",
		top: "8%"
	},

	"6-6": {
		left: "3.75%",
		top: "7.8%"
	},
	"6-7": {
		left: "3.75%",
		top: "20.8%"
	},
	"6-8": {
		left: "3.75%",
		top: "33.7%"
	},
	"6-9": {
		left: "3.75%",
		top: "46.7%"
	},
	"6-10": {
		left: "3.75%",
		top: "59.5%"
	},
	"6-11": {
		left: "15.4%",
		top: "7.8%"
	},
	"6-12": {
		left: "15.4%",
		top: "20.8%"
	},
	"6-13": {
		left: "15.4%",
		top: "33.7%"
	},
	"6-14": {
		left: "15.4%",
		top: "46.7%"
	},
	"6-15": {
		left: "15.4%",
		top: "59.5%"
	},
	
	"6-16": {
		left: "3.75%",
		top: "81.5%"
	},
	"6-17": {
		left: "13.2%",
		top: "81.5%"
	},
	"6-18": {
		left: "22.65%",
		top: "81.5%"
	},
	"6-19": {
		left: "32.1%",
		top: "81.5%"
	},
	"6-20": {
		left: "41.55%",
		top: "81.5%"
	},
	"6-21": {
		left: "51%",
		top: "81.6%"
	},
	"6-22": {
		left: "60.4%",
		top: "81.5%"
	},
	"6-23": {
		left: "69.9%",
		top: "81.5%"
	},
	"6-24": {
		left: "79.3%",
		top: "81.5%"
	},
	"6-25": {
		left: "88.75%",
		top: "81.5%"
	},

	"6-26": {
		left: "85.2%",
		top: "7.8%"
	},
	"6-27": {
		left: "85.2%",
		top: "20.8%"
	},
	"6-28": {
		left: "85.2%",
		top: "33.7%"
	},
	"6-29": {
		left: "85.2%",
		top: "46.7%"
	},
	"6-30": {
		left: "85.2%",
		top: "59.5%"
	},
	"6-31": {
		left: "92.9%",
		top: "7.8%"
	},
	"6-32": {
		left: "92.9%",
		top: "20.8%"
	},
	"6-33": {
		left: "92.9%",
		top: "33.7%"
	},
	"6-34": {
		left: "92.9%",
		top: "46.7%"
	},
	"6-35": {
		left: "92.9%",
		top: "59.5%"
	}
};

const NewBallMount = ({ number, color, newPositionId, disableAnimation }) => {
	const [flyingPosition, setFlyingPosition] = useState(mappingCoordinates[newPositionId]);
	const [isAnimationStarted, setIsAnimationStarted] = useState(false);
	const timerRef = useRef(null);

	useEffect(() => {
		timerRef.current = setTimeout(() => {
			setIsAnimationStarted(true);
		}, 1000);

		return () => clearTimeout(timerRef.current);
	}, []);

	return (
		<>
			<div
				className={mergeClassNames(
					"vs--lucky-six-live-monitor-new-ball-mount",
					disableAnimation ? "" : isAnimationStarted ? "flying-animation" : "mount-animation"
				)}
				style={{
					left: isAnimationStarted ? flyingPosition.left : "48%",
					top: isAnimationStarted ? flyingPosition.top : "46%"
				}}
			>
				<BetBall number={number} color={color} enableHighlight={isAnimationStarted} />
			</div>

			{!disableAnimation && (
				<div
					className="vs--lucky-six-live-monitor-new-ball-border"
					style={{
						left: flyingPosition.left,
						top: flyingPosition.top
					}}
				/>
			)}
		</>
	);
};

NewBallMount.propTypes = {
	number: PropTypes.number.isRequired,
	color: PropTypes.number.isRequired,
	newPositionId: PropTypes.string.isRequired,
	disableAnimation: PropTypes.bool
};

export default NewBallMount;
