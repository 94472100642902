import BulkSelectionButton from "components/pages/main/markets/spin2Win/topBoard/bulkSelectionButton.jsx";
import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants.js";
import { mergeClassNames } from "utils/common.js";
import { useSelector } from "react-redux";
import { SPIN_TO_WIN_MARKET_GROUPS } from "constants/market.constants.js";
import { useTranslation } from "react-i18next";

const BasketMarkets = ({ markets, handleMarketHover, handleOddClick, bets, isOddDisabled }) => {
	const { t } = useTranslation();

	const hoveredNumbers = useSelector((state) => state.spin2Win.hoveredNumbers);

	const basketMarket = markets.find((market) => market.group === SPIN_TO_WIN_MARKET_GROUPS.BASKET) ?? {};
	const straightUpMarket = markets.find((market) => market.group === SPIN_TO_WIN_MARKET_GROUPS.STRAIGHT) ?? {};

	const isBasketSelected = bets.some((bet) => bet.oddId === basketMarket.odds?.[0][0].id);
	const isZeroSelected = bets.some((bet) => bet.oddId === straightUpMarket.odds?.[0][0].id);

	return (
		<div className="vs--roulete-left-block">
			<BulkSelectionButton
				className={mergeClassNames("vs--roulete-basket", isBasketSelected && "selected")}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(basketMarket, basketMarket.odds[0][0])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.BASKET}
				text={t("cashier.basket")}
				subText={basketMarket.odds?.[0][0]?.factor ? `x${basketMarket?.odds[0][0].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<button
				className={mergeClassNames(
					"vs--roulete-zero",
					isOddDisabled && "disabled",
					hoveredNumbers.includes(0) && "hovered"
				)}
				onClick={() => handleOddClick(straightUpMarket, straightUpMarket?.odds[0][0])}
				disabled={isOddDisabled}
			>
				{isZeroSelected && !isOddDisabled && <div className="vs--roulete-number-selected" />}0
			</button>
		</div>
	);
};

export default BasketMarkets;
