import { useState, Fragment, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import VideoPlayer from "components/ui/videoPlayer";
import ScrollableNavbar from "components/ui/scrollableNavbar";
import Dropdown from "components/ui/dropdown";
import GameButton from "components/ui/buttons/gameButton";

import { getUrlVars, isNullish } from "utils/common";
import fullScreenAPI from "utils/fullscreen";

import {
	GAME_TYPE,
	GAME_TYPE_TEXT_KEYS,
	GAME_TYPE_ICONS,
	GAME_STREAM_CONFIGURATION_TYPE,
	GAME_STATUSES
} from "constants/game.constants";

import { getStreamConfiguration } from "store/actions/auth/auth.actions";

import StreamJackpotPools from "./streamJackpotPools";
import useEvent from "hooks/useEvent";
import useForceUpdate from "hooks/useForceUpdate";
import {
	addLiveAndUpcomings,
	getLiveAndUpcomings,
	removeFromLiveAndUpcomings,
	setCurrentGame,
	updateFromLiveAndUpcomingsLiveMonitor
} from "store/actions/game/game.actions";
import {
	CASHIER_API_INVOKE_EVENTS,
	CASHIER_API_LISTENER_EVENTS,
	JOB_API_INVOKE_EVENTS,
	JOB_API_LISTENER_EVENTS
} from "constants/webSocket.contants";
import { useOutletContext } from "react-router";
import { getActiveJackpot, setWonJackpotBonuses, updateJackpotBonus } from "store/actions/bonuses/jackpot.actions";
import WebSocketService from "services/webSocket";

/** Report print view page Component */
const StreamPage = ({
	getStreamConfiguration,
	getLiveAndUpcomings,
	removeFromLiveAndUpcomings,
	updateFromLiveAndUpcomingsLiveMonitor,
	addLiveAndUpcomings,
	setCurrentGame,
	getActiveJackpot,
	updateJackpotBonus,
	setWonJackpotBonuses
}) => {
	const { t } = useTranslation();

	const games = useSelector((state) => state.auth.session.games);
	const jackpotInfo = useSelector((state) => state.bonuses.jackpot.data);
	const token = useSelector((state) => state.auth?.stream?.access?.wsToken ?? null);
	const currentEvent = useSelector((state) => {
		const list = state.game.liveAndUpcomings.data;
		const statuses = [
			GAME_STATUSES.STARTED,
			GAME_STATUSES.CLOSE_FOR_BETTING,
			GAME_STATUSES.PREAMBLE_STARTED,
			GAME_STATUSES.NEW
		];
		return list.find((event) => statuses.includes(event.status)) ?? list.at(0);
	});

	const [cashierForceUpdate, cashierForceUpdateState] = useForceUpdate();
	const [jobForceUpdate, jobForceUpdateState] = useForceUpdate();
	const [selectedGame, setSelectedGame] = useState(null);
	const [showMoreGames, setShowMoreGames] = useState(false);
	const [overlayInfo, setOverlayInfo] = useState({ visible: false, selector: null });
	const { decodedData } = useOutletContext();

	const streamConfiguration = selectedGame?.streamConfiguration ?? null;
	const selectedGameType = selectedGame?.type ?? null;
	const activeJackpotId = decodedData?.activeJackpotId ?? null;
	const isCurrentEventStarted =
		Boolean(currentEvent) && [GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(currentEvent.status);
	const disableJackpotWinAnimation = Boolean(currentEvent) && [GAME_STATUSES.FINISHED].includes(currentEvent.status);

	/** Function , fires on game button click
	 * @function
	 * @param {object} game - clicked game
	 * @memberOf StreamPage
	 */
	const handleGameClick = (game) => {
		if (game.type !== selectedGameType) {
			setSelectedGame(game);
		}
	};

	/** Function to enter Fullscreen
	 * @function
	 * @memberOf StreamPage
	 */
	const enterFullscreen = () => {
		const element = document.querySelector(".vs--stream-content-iframe");
		fullScreenAPI.toggle(element);
	};

	/** Function to exit Fullscreen
	 * @function
	 * @memberOf StreamPage
	 */
	const exitFullscreen = () => {
		fullScreenAPI.toggle();
	};

	/** Function fires on fullscreen button click
	 * @function
	 * @memberOf StreamPage
	 */
	const onFullscreen = () => {
		if (!fullScreenAPI.isFullscreen) {
			enterFullscreen();
		} else {
			exitFullscreen();
		}
	};

	const onJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		updateJackpotBonus(d);
	});

	const onWonJackpots = useEvent((data) => {
		const d = JSON.parse(data);
		setWonJackpotBonuses(d);
	});

	const onEvents = useEvent((data) => {
		const parsedData = JSON.parse(data);
		if (parsedData.status === GAME_STATUSES.FINISHED) {
			removeFromLiveAndUpcomings(parsedData.id);
		} else if (
			parsedData.status === GAME_STATUSES.STARTED ||
			parsedData.status === GAME_STATUSES.CLOSE_FOR_BETTING ||
			parsedData.status === GAME_STATUSES.PREAMBLE_STARTED
		) {
			updateFromLiveAndUpcomingsLiveMonitor(parsedData);
		} else if (parsedData.status === GAME_STATUSES.NEW) {
			addLiveAndUpcomings(parsedData);
		}
	});

	const handleCashierWSEvent = useEvent(
		/** @type {WebSocketSetupCallback} */ (wsServiceInstance) => {
			if (!wsServiceInstance.isConnected) {
				return;
			}

			wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.JACKPOTS);
			wsServiceInstance.off(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS);

			wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.JACKPOTS, onJackpots);
			wsServiceInstance.on(CASHIER_API_LISTENER_EVENTS.WON_JACKPOTS, onWonJackpots);

			cashierForceUpdate();
		}
	);

	const handleJobWSEvent = useEvent(
		/** @type {WebSocketSetupCallback} */ (wsServiceInstance) => {
			if (!wsServiceInstance.isConnected) {
				return;
			}

			wsServiceInstance.off(JOB_API_LISTENER_EVENTS.EVENTS);

			wsServiceInstance.on(JOB_API_LISTENER_EVENTS.EVENTS, onEvents);

			jobForceUpdate();
		}
	);

	// Create connections
	const { cashierWS, jobWS } = useMemo(
		/** @return {WebSocketConnections} */ () => {
			/** @type {WebSocketConnections} */
			const wsServices = {
				cashierWS: null,
				jobWS: null
			};

			if (
				!activeJackpotId ||
				!token ||
				!selectedGame ||
				[GAME_TYPE.HORSE_RACING, GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(
					selectedGame.type
				)
			) {
				return wsServices;
			}

			const urlCashierWS = `${import.meta.env.SYSTEM_WS_URL_CASHIER}?accessToken=${token}`;
			const urlJobWS = `${import.meta.env.SYSTEM_WS_URL_JOBS}?accessToken=${token}`;

			wsServices.cashierWS = new WebSocketService(urlCashierWS, handleCashierWSEvent);
			wsServices.jobWS = new WebSocketService(urlJobWS, handleJobWSEvent);

			return wsServices;
		},
		[token, selectedGame, activeJackpotId]
	);

	useEffect(() => {
		if (
			!activeJackpotId ||
			!selectedGame ||
			[GAME_TYPE.HORSE_RACING, GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(
				selectedGame.type
			)
		) {
			return;
		}
		return () => {
			cashierWS.stopConnection();
			jobWS.stopConnection();
		};
	}, [token, selectedGame, cashierWS, jobWS, activeJackpotId]);

	// start / stop connection by document visibility
	useEffect(() => {
		if (
			!activeJackpotId ||
			!token ||
			isNullish(cashierWS) ||
			isNullish(jobWS) ||
			!selectedGame ||
			[GAME_TYPE.HORSE_RACING, GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(
				selectedGame.type
			)
		) {
			return;
		}

		const visibilityChangeListener = () => {
			if (document.hidden) {
				return;
			}
			cashierWS.startConnection();
			jobWS.startConnection();
		};

		document.addEventListener("visibilitychange", visibilityChangeListener);

		return () => {
			cashierWS.stopConnection();
			jobWS.stopConnection();

			document.removeEventListener("visibilitychange", visibilityChangeListener);
		};
	}, [token, cashierWS, jobWS, activeJackpotId]);

	// Watch all jackpot updates
	useEffect(() => {
		if (
			!activeJackpotId ||
			!cashierWS?.isConnected ||
			!selectedGame ||
			[GAME_TYPE.HORSE_RACING, GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(
				selectedGame.type
			)
		) {
			return;
		}
		const invokeJackpotsMessage = `${CASHIER_API_LISTENER_EVENTS.JACKPOTS}_${activeJackpotId}`;
		cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.SUBSCRIBE, invokeJackpotsMessage);
		return () => {
			if (!cashierWS?.isConnected) {
				return;
			}
			cashierWS.invoke(CASHIER_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeJackpotsMessage);
		};
	}, [activeJackpotId, cashierWS, cashierForceUpdateState, selectedGame]);

	// Watch all event updates
	useEffect(() => {
		if (
			!activeJackpotId ||
			!token ||
			!jobWS?.isConnected ||
			!selectedGame ||
			[GAME_TYPE.HORSE_RACING, GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(
				selectedGame.type
			)
		) {
			return;
		}
		const invokeMessage = `${JOB_API_LISTENER_EVENTS.EVENTS}_${selectedGame.type}_${selectedGame.id}`;
		jobWS.invoke(JOB_API_INVOKE_EVENTS.SUBSCRIBE, invokeMessage);
		return () => {
			if (!jobWS?.isConnected) {
				return;
			}
			jobWS.invoke(JOB_API_INVOKE_EVENTS.UNSUBSCRIBE, invokeMessage);
		};
	}, [token, jobWS, jobForceUpdateState, selectedGame, activeJackpotId]);

	/** Subscribe to signalR when session loaded,
	 * Load live and upcomings, rtps
	 * */
	useEffect(() => {
		if (
			!activeJackpotId ||
			!selectedGame ||
			[GAME_TYPE.HORSE_RACING, GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(
				selectedGame.type
			)
		) {
			return;
		}
		setCurrentGame(selectedGame.type);
		getLiveAndUpcomings();
	}, [selectedGame, activeJackpotId]);

	useEffect(() => {
		if (
			isNullish(activeJackpotId) ||
			!selectedGame ||
			[GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(selectedGame.type)
		) {
			return;
		}
		getActiveJackpot(true);
	}, [activeJackpotId, selectedGame]);

	useEffect(() => {
		const visible =
			!!jackpotInfo?.levels &&
			selectedGame?.streamProvider === GAME_STREAM_CONFIGURATION_TYPE.OVEN_PLAYER &&
			!isCurrentEventStarted &&
			selectedGame.type !== GAME_TYPE.HORSE_RACING;
		setOverlayInfo({ visible, selector: visible ? ".vs-oven-player-overlay" : null });
		return () => {
			setOverlayInfo({ visible: false, selector: null });
		};
	}, [jackpotInfo, selectedGame, isCurrentEventStarted]);

	return (
		<>
			<div className="vs--stream vs--flex vs--flex-col">
				<span className="vs--title vs--stream-title vs--font-medium vs--mb-16">{t("cashier.gameStream")}</span>
				<div className="vs--stream-content vs--flex-equal" data-game={selectedGameType}>
					{!isNullish(selectedGameType) ? (
						[GAME_TYPE.KENO, GAME_TYPE.LUCKY_SIX, GAME_TYPE.SPIN_TO_WIN].includes(selectedGameType) ? (
							<div className="vs--stream-content-iframe">
								<iframe
									className="vs--stream-content-iframe-element"
									src={`${import.meta.env.SYSTEM_STREAM_PAGE_URL}/${GAME_TYPE_TEXT_KEYS[selectedGameType]}?data=${getUrlVars()["data"]}`}
									allowFullScreen
								/>

								<div className="vs--stream-content-iframe-controls">
									<div className="vs--stream-content-iframe-controls-inner vs--flex vs--flex-row vs--align-center vs--justify-end vs--pl-8 vs--pr-8">
										<div className="vs--flex vs--flex-row vs--align-center">
											<div
												className="vs--stream-content-iframe-control"
												onClick={onFullscreen}
												data-action="fullscreen"
											>
												<i className="ic_video-fullscreen" title={t("common.fullscreen")} />
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
							<VideoPlayer
								streamConfiguration={streamConfiguration}
								streamProvider={selectedGame?.streamProvider ?? null}
								streamUpdateFn={(player) => {
									getStreamConfiguration(selectedGame?.id ?? null, (configuration) => {
										player.destroy();
										player.setStreamConfiguration(configuration);
										player.init();
									});
								}}
								showFullscreen={true}
								overlay={
									overlayInfo.visible ? (
										<StreamJackpotPools
											jackpotInfo={jackpotInfo}
											disableAnimation={disableJackpotWinAnimation}
										/>
									) : null
								}
								overlaySelector={overlayInfo.selector}
							/>
						)
					) : (
						<div className="vs--stream-content-empty vs--flex vs--flex-col vs--justify-center vs--align-center">
							<i className="ic_stream" />
							<span className="vs--title vs--font-regular vs--font-smallest vs--mt-16 vs--text-center">
								{t("cashier.streamText")}
							</span>
						</div>
					)}
				</div>
			</div>
			<footer className="vs--stream-footer">
				<ScrollableNavbar
					wrapperClassName="vs--stream-games"
					containerClassName="vs--mt-24 vs--mb-24"
					innerClassName="vs--stream-games-tabs vs--flex vs--flex-row"
					elements={games}
					keyFieldName="id"
					isActiveChecking={(game) => selectedGameType === game.type}
					onContentFullyFit={(isContentFullyFit) => {
						setShowMoreGames(!isContentFullyFit);
					}}
					renderFunction={(game) => {
						return (
							<GameButton
								className="vs--stream-games-tabs-btn"
								onClick={() => handleGameClick(game)}
								selected={selectedGameType === game.type}
							>
								<i className={`${GAME_TYPE_ICONS[game.type] || ""} vs--stream-games-tabs-btn-icon`} />
								<span className="vs--stream-games-tabs-btn-text">
									{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}
								</span>
							</GameButton>
						);
					}}
					startFromFirstElement
				/>
				{showMoreGames ? (
					<Dropdown
						className="vs--stream-game-nav-more vs--mt-8 vs--mb-8 vs--ml-4 vs--mr-4"
						content={<i className="ic_more" />}
						childrenContainerClassname="vs--stream-dropdown"
					>
						<Fragment>
							{games.map((game) => {
								return (
									<div
										key={game.type}
										className={
											"vs--stream-dropdown-item" +
											(selectedGameType === game.type ? " vs--dropdown-children-active" : "") +
											" vs--flex vs--align-center vs--justify-start"
										}
										onClick={() => handleGameClick(game)}
									>
										<i className={`${GAME_TYPE_ICONS[game.type]} vs--dropdown-children-icon`} />
										<span className="vs--font-smallest vs--font-regular vs--ml-10 vs--dropdown-children-text">
											{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}
										</span>
									</div>
								);
							})}
						</Fragment>
					</Dropdown>
				) : null}
			</footer>
		</>
	);
};

/** StreamPage propTypes
 * PropTypes
 */
StreamPage.propTypes = {
	getStreamConfiguration: PropTypes.func,
	getStreamConfiguration: PropTypes.func,
	getLiveAndUpcomings: PropTypes.func,
	removeFromLiveAndUpcomings: PropTypes.func,
	updateFromLiveAndUpcomings: PropTypes.func,
	addLiveAndUpcomings: PropTypes.func,
	setCurrentGame: PropTypes.func,
	getActiveJackpot: PropTypes.func,
	updateJackpotBonus: PropTypes.func,
	setWonJackpotBonuses: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
	getStreamConfiguration: (id, callback) => {
		dispatch(getStreamConfiguration(id, callback));
	},
	getLiveAndUpcomings: () => {
		dispatch(getLiveAndUpcomings(true));
	},
	removeFromLiveAndUpcomings: (id) => {
		dispatch(removeFromLiveAndUpcomings(id));
	},
	updateFromLiveAndUpcomingsLiveMonitor: (game) => {
		dispatch(updateFromLiveAndUpcomingsLiveMonitor(game));
	},
	addLiveAndUpcomings: (game) => {
		dispatch(addLiveAndUpcomings(game));
	},
	setCurrentGame: (type) => {
		dispatch(setCurrentGame(type));
	},
	getActiveJackpot: (fromStream) => {
		dispatch(getActiveJackpot(fromStream));
	},
	updateJackpotBonus: (data) => {
		dispatch(updateJackpotBonus(data));
	},
	setWonJackpotBonuses: (data) => {
		dispatch(setWonJackpotBonuses(data));
	}
});

export default connect(null, mapDispatchToProps)(StreamPage);
