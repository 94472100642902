import { ODD_STATE } from "constants/market.constants";
import { mergeClassNames, numberWithSpaces } from "utils/common";

const Odd = ({
	odd,
	displayShowName,
	showName,
	allowMarketsHighlights,
	style,
	className,
	textContainerClassName,
	textClassName,
	textClassNameWhenLock,
	showNameClassName,
	lockClassName
}) => {
	const isLock = !odd || !odd.factor || odd.factor < 1.01;
	const renderShowName = displayShowName && !isLock;
	return (
		<div className={mergeClassNames("odd", className)} style={style}>
			<div
				className={mergeClassNames(
					"odd-text-container",
					!isLock &&
						allowMarketsHighlights &&
						odd.oddState === ODD_STATE.WON &&
						"live-monitor-odd-flicker-animation",
					textContainerClassName
				)}
			>
				{renderShowName ? (
					<span className={mergeClassNames("odd-showname", showNameClassName)}>{showName}</span>
				) : null}
				<span className={mergeClassNames("odd-text", textClassName, isLock && textClassNameWhenLock)}>
					{isLock ? <i className={mergeClassNames("ic_lock", lockClassName)} /> : numberWithSpaces(odd.factor)}
				</span>
			</div>
		</div>
	);
};
export default Odd;
