import rouletteBetsRNG from "../roulette-rng";

export function* generateBetOutcomesByGroup(group, rngConfig) {
	const outcomesCollection = rouletteBetsRNG[group](rngConfig);
	for (const outcomes of outcomesCollection) {
		yield ({ group, outcomes });
	}
	return ({ group: null, outcomes: [] });
}

export function* generateBetGroupAndConfigs(config) {
	const configEntries = Object.entries(config)
	for (const [group, rngConfig] of configEntries) {
		yield ({ group, rngConfig });
	}
	return ({ group: null, rngConfig: null });
}