import PropTypes from "prop-types";
import { mergeClassNames } from "utils/common.js";

const EmptyBall = ({ factor, toLeft, id }) => {
	return (
		<div className={mergeClassNames("vs--lucky-six-live-monitor-empty-ball", toLeft ? "to-left" : "")} id={id}>
			<div className="vs--lucky-six-live-monitor-empty-ball-item" />
			{factor && <div className="vs--lucky-six-live-monitor-empty-ball-factor">{factor}x</div>}
		</div>
	);
};

EmptyBall.propTypes = {
	factor: PropTypes.number,
	toLeft: PropTypes.bool,
	id: PropTypes.string.isRequired
};

export default EmptyBall;
