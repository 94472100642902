import { useTranslation } from "react-i18next";
import Timer from "components/pages/luckySix/header/timer";
import PropTypes from "prop-types";
import { buildPathToStaticFolderOfCDN, mergeClassNames } from "utils/common.js";
import { connect, useSelector } from "react-redux";
import sessionType from "types/session.type";
import MonitorJackpotPools from "components/monitors/monitorJackpotPools";
import Pools from "components/jackpotInfo/pools";
import JackpotAnimations from "components/pages/jackpotLiveInfo/animations";

const Header = ({ startTime, eventId, isVisibleHeader }) => {
	const { t } = useTranslation();
	const jackpotInfo = useSelector((state) => state.bonuses.jackpot.data);
	const session = useSelector((state) => state.auth.session);

	return (
		<>
			<div className="vs--lucky-six-live-monitor-header">
				<div
					className={mergeClassNames(
						"vs--lucky-six-live-monitor-header-divider",
						isVisibleHeader ? "is-visible" : ""
					)}
				>
					<svg width="1920" height="92" viewBox="0 0 1920 92" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M911.553 92C906.69 92 901.993 90.2279 898.342 87.0152L854 60H1066L1021.66 87.0152C1018.01 90.2279 1013.31 92 1008.45 92H911.553Z"
							fill="black"
							fillOpacity="0.5"
						/>
						<mask id="path-2-inside-1_5125_26156" fill="white">
							<path d="M0 0H1920V60H0V0Z" />
						</mask>
						<path d="M0 0H1920V60H0V0Z" fill="black" fillOpacity="0.5" />
						<path
							d="M1920 56H0V64H1920V56Z"
							fill="url(#paint0_linear_5125_26156)"
							mask="url(#path-2-inside-1_5125_26156)"
						/>

						<rect x="182" y="17.5" width="1" height="24" rx="0.5" fill="white" fillOpacity="0.2" />

						<rect x="1702" y="17.5" width="1" height="24" rx="0.5" fill="white" fillOpacity="0.2" />
						<defs>
							<linearGradient
								id="paint0_linear_5125_26156"
								x1="0"
								y1="30"
								x2="1920"
								y2="30"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopOpacity="0" />
								<stop offset="0.199542" stopColor="#CCCCCC" />
								<stop offset="0.44476" stopColor="#CCCCCC" stopOpacity="0" />
								<stop offset="0.555532" stopColor="#CCCCCC" stopOpacity="0" />
								<stop offset="0.804026" stopColor="#CCCCCC" />
								<stop offset="1" stopOpacity="0" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<div
					className={mergeClassNames(
						"vs--lucky-six-live-monitor-header-main",
						isVisibleHeader ? "is-visible" : ""
					)}
				>
					<div className="vs--lucky-six-live-monitor-header-main-title">
						<p className="vs--lucky-six-live-monitor-header-main-title-main">{t("common.luckySix")}</p>
						<p className="vs--lucky-six-live-monitor-header-main-title-info">{t("common.betsAreOpen")}</p>
					</div>
					<div className="vs--lucky-six-live-monitor-header-main-timer">
						{startTime ? <Timer startTime={startTime} /> : null}
					</div>

					<div className="vs--lucky-six-live-monitor-header-main-information">
						<span className="vs--lucky-six-live-monitor-header-main-information-label">ID #: </span>
						<span className="vs--lucky-six-live-monitor-header-main-information-value">{eventId}</span>
					</div>
				</div>

				<div className="vs--lucky-six-live-monitor-header-sub-header">
					<img
						className="vs--lucky-six-live-monitor-header-sub-header-logo"
						alt="logo"
						src={
							session.betShopLogoPath
								? `${import.meta.env.SYSTEM_CDN_URL}/${session.betShopLogoPath.toLowerCase()}`
								: buildPathToStaticFolderOfCDN("cashier-images/betshop-default-logo.png")
						}
					/>

					<div className="vs--lucky-six-live-monitor-header-sub-header-jackpot">
						<Pools
							className="vs--lucky-six-live-monitor-header-sub-header-jackpot-pools"
							showLevelIcon={true}
							data={jackpotInfo.levels}
						/>
					</div>
				</div>
			</div>

			<div className="vs--active-jackpot-live-info vs--flex vs--flex-col vs--align-center vs--justify-center">
				<JackpotAnimations
					getMainContainer={() => document.querySelector(".vs--active-jackpot-live-info")}
					disableAnimation={!isVisibleHeader}
				/>
			</div>
		</>
	);
};

Header.propTypes = {
	startTime: PropTypes.string,
	eventId: PropTypes.number,
	session: sessionType,
	isVisibleHeader: PropTypes.bool
};

export default Header;
