import axios from "axios";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { GAME_STATUSES } from "constants/game.constants";

import { isSeasonGame } from "utils/common";

import {
	LIVE_AND_UPCOMINGS_BEFORE,
	LIVE_AND_UPCOMINGS_AFTER,
	SET_LIVE_AND_UPCOMINGS,
	SET_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	REMOVE_LIVE_AND_UPCOMINGS,
	REMOVE_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	UPDATE_LIVE_AND_UPCOMINGS,
	UPDATE_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	ADD_LIVE_AND_UPCOMINGS,
	ADD_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	LAST_RESULTS_BEFORE,
	LAST_RESULTS_AFTER,
	SET_LAST_RESULTS,
	ADD_LAST_RESULT,
	EVENT_BEFORE,
	EVENT_AFTER,
	SET_EVENT,
	SET_CURRENT_EVENT,
	UPDATE_EVENT,
	UPDATE_EVENT_LIVE_MONITOR,
	SET_CURRENT_GAME,
	SET_LUCKY_SIX_STATISTICS,
	SET_SPIN_TO_WIN_STATISTICS
} from "../../actionTypes";
import { MAIN_SCENES } from "constants/liveMonitor.constants";

const setLiveAndUpcomingBefore = () => ({
	type: LIVE_AND_UPCOMINGS_BEFORE
});

const setLiveAndUpcomingAfter = () => ({
	type: LIVE_AND_UPCOMINGS_AFTER
});

const setLiveAndUpcoming = (data) => ({
	type: SET_LIVE_AND_UPCOMINGS,
	payload: { data }
});

const setLiveAndUpcomingLiveMonitor = (data) => ({
	type: SET_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	payload: { data }
});

const setLastResultsBefore = () => ({
	type: LAST_RESULTS_BEFORE
});

const setLastResultsAfter = () => ({
	type: LAST_RESULTS_AFTER
});

const setLastResults = (data) => ({
	type: SET_LAST_RESULTS,
	payload: { data }
});

const setEventBefore = () => ({
	type: EVENT_BEFORE
});

const setEventAfter = () => ({
	type: EVENT_AFTER
});

const setEvent = (data, rtps) => ({
	type: SET_EVENT,
	payload: { data, rtps }
});

export const getLiveAndUpcomings = (fromStream) => {
	return (dispatch, getState) => {
		dispatch(setLiveAndUpcomingBefore());

		const headers = {};
		if (fromStream) {
			headers.stream = true;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LIVE_AND_UPCOMING}`,
			method: Methods.GET,
			params: { type: getState().game.currentGameType },
			headers: headers
		})
			.then(({ data: { value: data } }) => {
				const currentGameType = getState().game.currentGameType;

				if (data.map((e) => e.gameType).includes(currentGameType)) {
					let upcommingData = [];
					if (isSeasonGame(currentGameType)) {
						if (data[0] && data[0].events) {
							upcommingData = [...data[0].events];
						}

						if (data[1] && data[1].events) {
							upcommingData = upcommingData.concat([...data[1].events]);
						}
						upcommingData = upcommingData.filter((e) => e.status !== GAME_STATUSES.FINISHED);
					} else {
						upcommingData = data;
					}
					dispatch(setLiveAndUpcoming(upcommingData));

					const liveEvent = upcommingData.find((ev) => ev.status === GAME_STATUSES.STARTED);

					if (fromStream && liveEvent) {
						setTimeout(() => {
							dispatch(updateLiveEvent(liveEvent.id));
						}, 0);
					} else {
						dispatch(setLiveAndUpcomingAfter());
					}
				} else {
					dispatch(setLiveAndUpcomingAfter());
				}
			})
			.catch((ex) => {
				dispatch(setLiveAndUpcomingAfter());
			});
	};
};

export const getLiveAndUpcomingsLiveMonitor = (fromStream = true) => {
	return (dispatch, getState) => {
		dispatch(setLiveAndUpcomingBefore());
		const currentGameType = getState().game.currentGameType;
		const headers = {};
		if (fromStream) {
			headers.stream = true;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LIVE_AND_UPCOMING}`,
			method: Methods.GET,
			params: { type: currentGameType },
			headers: headers
		})
			.then(({ data: { value: data } }) => {
				if (data.map((e) => e.gameType).includes(currentGameType)) {
					let upcommingData = [];
					if (isSeasonGame(currentGameType)) {
						if (data[0] && data[0].events) {
							upcommingData = [...data[0].events];
						}
						if (data[1] && data[1].events) {
							upcommingData = upcommingData.concat([...data[1].events]);
						}
					} else {
						upcommingData = data;
					}
					dispatch(setLiveAndUpcomingLiveMonitor(upcommingData));

					const liveEvent = upcommingData.find((ev) => ev.status !== GAME_STATUSES.CANCELED);

					if (fromStream && liveEvent) {
						setTimeout(() => {
							dispatch(updateLiveEventLiveMonitor(liveEvent.id));
						}, 0);
					}
				}
			})
			.finally((ex) => {
				dispatch(setLiveAndUpcomingAfter());
			});
	};
};

export const updateLiveEvent = (id) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_EVENT}`,
			method: Methods.GET,
			params: { id },
			headers: { stream: true }
		})
			.then(({ data: { value: data } }) => {
				dispatch(updateFromLiveAndUpcomings(data));
				dispatch(setLiveAndUpcomingAfter());
			})
			.catch((ex) => {
				dispatch(setLiveAndUpcomingAfter());
			});
	};
};

export const updateLiveEventLiveMonitor = (id) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_EVENT}`,
			method: Methods.GET,
			params: { id },
			headers: { stream: true }
		})
			.then(({ data: { value: data } }) => {
				dispatch(updateFromLiveAndUpcomingsLiveMonitor(data));
			})
			.finally((ex) => {
				dispatch(setLiveAndUpcomingAfter());
			});
	};
};

export const removeFromLiveAndUpcomings = (id) => ({
	type: REMOVE_LIVE_AND_UPCOMINGS,
	payload: { id }
});

export const removeFromLiveAndUpcomingsLiveMonitor = (id, lessAndEqual = false) => ({
	type: REMOVE_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	payload: { id, lessAndEqual }
});

export const updateFromLiveAndUpcomings = (game) => ({
	type: UPDATE_LIVE_AND_UPCOMINGS,
	payload: { game }
});

export const updateFromLiveAndUpcomingsLiveMonitor = (game) => ({
	type: UPDATE_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	payload: { game }
});

export const changeEventByScene = (gameType, scene, callback1, callback2) => {
	return (dispatch, getState) => {
		if (!gameType || !scene) {
			return;
		}
		const scenes = MAIN_SCENES[gameType];
		const liveAndUpcomings = getState().game.liveAndUpcomings.data;
		let event = null;
		switch (true) {
			case scene === scenes.RESULTS:
				event = liveAndUpcomings.find((lau) => {
					return lau.status === GAME_STATUSES.FINISHED;
				});
				break;
			case scene === scenes.LIVE_INFO:
				event = liveAndUpcomings.find((lau) => {
					return lau.status === GAME_STATUSES.STARTED;
				});
				break;
			default:
				event = liveAndUpcomings.find((lau) => {
					return ![GAME_STATUSES.STARTED, GAME_STATUSES.FINISHED, GAME_STATUSES.CANCELED].includes(lau.status);
				});
				break;
		}

		if (event) {
			const current = getState().game.current;
			if (current !== event.id) {
				dispatch(setCurrentEvent(event.id));
			}

			if (typeof callback1 === "function") {
				callback1(event);
			}
		} else {
			if (typeof callback2 === "function") {
				callback2();
			}
		}
	};
};

export const addLiveAndUpcomings = (game) => ({
	type: ADD_LIVE_AND_UPCOMINGS,
	payload: { game }
});

export const addLiveAndUpcomingsLiveMonitor = (game) => ({
	type: ADD_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	payload: { game }
});

export const getLastResults = (fromStream) => {
	return (dispatch, getState) => {
		dispatch(setLastResultsBefore());

		const headers = {};
		if (fromStream) {
			headers.stream = true;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LAST_FINISHED_RESULTS}`,
			method: Methods.GET,
			params: { type: getState().game.currentGameType },
			headers: headers
		})
			.then(({ data: { value: data } }) => {
				dispatch(setLastResults(data));
				dispatch(setLastResultsAfter());
			})
			.catch((ex) => {
				dispatch(setLastResultsAfter());
			});
	};
};

export const addLastResult = (result) => ({
	type: ADD_LAST_RESULT,
	payload: { result }
});

export const getEvent = (id, fromStream) => {
	return (dispatch, getState) => {
		dispatch(setEventBefore());

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_EVENT}`,
			method: Methods.GET,
			params: { id },
			headers: fromStream ? { stream: true } : null
		})
			.then(({ data: { value: data } }) => {
				const state = getState();
				const games = state?.auth?.session?.games ?? [];
				const currentGameType = state?.game?.currentGameType ?? 1;
				const currentGame = games.find((g) => g.type === currentGameType);
				dispatch(setEvent(data, currentGame?.rtPs ?? []));
				setTimeout(() => {
					dispatch(setEventAfter());
				}, 100);
			})
			.catch((ex) => {
				dispatch(setEventAfter());
			});
	};
};

export const setCurrentEvent = (id) => ({
	type: SET_CURRENT_EVENT,
	payload: { id }
});

export const updateEvent = (id, data, rtps) => ({
	type: UPDATE_EVENT,
	payload: { id, data, rtps }
});

export const updateEventLiveMonitor = (id, data, rtps, updateMarkets = false) => ({
	type: UPDATE_EVENT_LIVE_MONITOR,
	payload: { id, data, rtps, updateMarkets }
});

export const setCurrentGame = (type) => ({
	type: SET_CURRENT_GAME,
	payload: { type }
});

export const getLuckySixStatistics = (allTime = false) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_LUCKY_SIX_STATISTICS}`,
			method: Methods.GET,
			params: { allTime },
			headers: { stream: true }
		}).then(({ data: { value: data } }) => {
			dispatch(setLuckySixStatistics(data));
		});
	};
};

const setLuckySixStatistics = (data) => ({
	type: SET_LUCKY_SIX_STATISTICS,
	payload: { data }
});

export const getSpinToWinStatistics = (allTime = false, fromStream = false) => {
	return (dispatch) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.GET_SPIN_TO_WIN_STATISTICS}`,
			method: Methods.GET,
			params: { allTime },
			headers: fromStream ? { stream: true } : null
		}).then(({ data: { value: data } }) => {
			const fromHotToColdSorted = [...data.cellStatistics]
				.sort((next, prev) => {
					return (
						prev.electedCount > next.electedCount
							? prev.number - next.number
							: -1
					)
				})
			dispatch(setSpinToWinStatistics({ ...data, fromHotToColdSorted }));
		});
	};
};

const setSpinToWinStatistics = (data) => ({
	type: SET_SPIN_TO_WIN_STATISTICS,
	payload: { data }
});
