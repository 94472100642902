import PropTypes from "prop-types";

const Item = ({ children }) => {
	return children;
};

Item.propTypes = {
	sliderKey: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
};

export default Item;
