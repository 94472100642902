import { Fragment, useContext, useEffect, useRef } from "react"
import ScenesFlowContext from "./scenesFlowContext"
import { mergeClassNames } from "utils/common";

const SceneFlowItem = ({ className, pageKey, children, ...props }) => {
	const providerValue =  useContext(ScenesFlowContext);
	const providerValueRef = useRef(); providerValueRef.current = providerValue

	useEffect(() => {
		providerValueRef.current.addKey(pageKey)
		return () => {
			providerValueRef.current.removeKey(pageKey)
		}
	}, [pageKey])

	const scene = providerValue.scenes.find(scene => scene.key === pageKey);

	if (!scene || !scene.show) {
		return <Fragment />
	}

	return (
		<div
			className={mergeClassNames("vs--scene-flow-item", className)}
			data-scene={pageKey}
			data-is-mounting={pageKey === providerValue.mountingScene}
			{...props}
		>{children}</div>
	)
}
export default SceneFlowItem