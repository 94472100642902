import { useDispatch, useSelector } from "react-redux";
import runMarketUtilsFunction from "utils/markets/run.js";
import { GAME_STATUSES, GAME_TYPE, LUCKY_SIX_BALL_COLORS } from "constants/game.constants.js";
import {
	addLuckySixBalls,
	clearLuckySixBalls,
	doBet,
	removeLuckySixBall
} from "store/actions/betslip/betslip.actions.jsx";

const useLuckySixBetting = ({ currentEvent, currentMarket, currentOdd }) => {
	const bets = useSelector((state) => state.betslip.bets);
	const luckySixBalls = useSelector((state) => state.betslip.luckySixBalls);

	const dispatch = useDispatch();

	const isOddSelected = (id) => {
		return bets.some((b) => b.oddId === id && b.eventId === currentEvent.id);
	};

	const areOddsDisabled = () => {
		return currentEvent.status === GAME_STATUSES.CLOSE_FOR_BETTING;
	};

	const handleOddClick = (isSelectingBallsColumn) => {
		if (areOddsDisabled()) {
			return;
		}

		if (isSelectingBallsColumn) {
			const numbers = [LUCKY_SIX_BALL_COLORS.indexOf(currentOdd?.name.toLowerCase()) + 1];

			for (let i = 0; i < 5; i++) {
				numbers.push(numbers[i] + 8);
			}

			if (numbers.toString() === luckySixBalls.toString()) {
				dispatch(clearLuckySixBalls());
				return;
			}

			dispatch(clearLuckySixBalls());
			dispatch(addLuckySixBalls(numbers));
			return;
		}

		const group = runMarketUtilsFunction(
			"makeGroupTitle",
			[
				{
					group: currentMarket.group,
					argument: currentMarket.argument,
					gameData: currentEvent?.gameData ?? {},
					gameType: GAME_TYPE.LUCKY_SIX
				}
			],
			GAME_TYPE.LUCKY_SIX
		);

		dispatch(doBet(currentOdd, group, currentEvent));
	};

	const handleBallClick = (number) => {
		if (areOddsDisabled()) {
			return;
		}

		if (luckySixBalls.includes(number)) {
			dispatch(removeLuckySixBall(number));
			return;
		}

		if (luckySixBalls.length < 6) {
			dispatch(addLuckySixBalls([number]));
		}
	};

	const handleRandomClick = () => {
		if (areOddsDisabled()) {
			return;
		}

		const randomBalls = [];

		while (randomBalls.length < 6) {
			const randomNumber = Math.floor(Math.random() * 48) + 1;

			if (!randomBalls.includes(randomNumber)) {
				randomBalls.push(randomNumber);
			}
		}

		dispatch(clearLuckySixBalls());
		dispatch(addLuckySixBalls(randomBalls));
	};

	return {
		isOddSelected,
		areOddsDisabled,
		handleOddClick,
		handleBallClick,
		handleRandomClick
	};
};

export default useLuckySixBetting;
