import { buildPathToStaticFolderOfCDN } from "utils/common"
import "./styles.scss"
import { useContext, useEffect, useRef } from "react"
import moment from "moment"
import { useTranslation } from "react-i18next"
import ScenesFlowContext from "components/pages/scenesFlow/scenesFlowContext"
import useEvent from "hooks/useEvent"

const CloseForBetting = ({ event = null, onFinish: argOnFinish, onMount: argOnMount }) => {

	const ref = useRef();
	const { t } = useTranslation();

	const sceneFlowProviderValue = useContext(ScenesFlowContext)
	const onMount = useEvent(() => {
		if (typeof argOnMount === "function") {
			argOnMount(sceneFlowProviderValue)
		}
	});
	const onFinish = useEvent(() => {
		if (typeof argOnFinish === "function") {
			argOnFinish(sceneFlowProviderValue)
		}
	});

	useEffect(() => {

		if (!event) {
			return
		}

		const startTime = event?.startTime ?? undefined;

		if (!startTime) {
			return
		}

		onMount()

		const date = moment.utc(startTime).local().toDate();
		const diff = Math.abs(moment().diff(date, "seconds"));
		let count = diff - 1;
		let prevCount = diff;

		const countdownElement = ref.current.querySelector(".vs--spin-2-win-stream-scene-close-for-betting-countdown-number");
		const titleElement = ref.current.querySelector(".vs--spin-2-win-stream-scene-close-for-betting-title");
		const subTitleElement = ref.current.querySelector(".vs--spin-2-win-stream-scene-close-for-betting-subTitle");
		const shadowImgElement = ref.current.querySelector(".vs--spin-2-win-stream-scene-close-for-betting-shadow");
		let mainTimeoutId = null;
		const timeoutIds = [];

		const startCountdown = () => {
			if (prevCount === count) {
				count--
				return
			} else {
				prevCount = count
			}
			
			if (count < 1) {
				// When countdown ends, swap classes
				titleElement.classList.add("hide");
				subTitleElement.classList.add("show");
				countdownElement.classList.add("hide"); // Hide countdown number
				countdownElement.textContent = "";
				// Apply hide countdown animation after countdown finishes
				countdownElement.style.animation = "none"; // Reset current animation
				// void countdownElement.offsetWidth; // Trigger reflow to restart the animation
				countdownElement.style.animation = "hideCountdownAnim 0.6s linear forwards"; // Apply the hide countdown animation

				return;
			}

			countdownElement.textContent = count;
			countdownElement.style.animation = "none"; // Reset current animation
			void countdownElement.offsetWidth; // Trigger reflow to restart the animation
			countdownElement.style.animation = "countdownAnim 1s ease-in-out";

			count--;
			timeoutIds.push(setTimeout(startCountdown, 1000));
		}

		mainTimeoutId = setTimeout(startCountdown, 600)

		countdownElement.addEventListener("animationend", () => {
			if (count === 1) {
				shadowImgElement.classList.add("hide")
			}
			if (count === 0) {
				titleElement.classList.add("hide");
				countdownElement.classList.add("hide"); // Hide countdown number
			}
		});

		return () => {
			clearTimeout(mainTimeoutId)
			timeoutIds.forEach(timeoutId => {
				clearTimeout(timeoutId)
			})
		}
	}, [event])

	return (
		<div ref={ref} className="vs--spin-2-win-stream-scene vs--spin-2-win-stream-scene-close-for-betting">
			<img
				src={buildPathToStaticFolderOfCDN("cashier-images/stream/spin-to-win/live-process-bg.png")}
				className="vs--spin-2-win-stream-scene-close-for-betting-bg"
				alt="bg"
			/>
			<div className="vs--spin-2-win-stream-scene-close-for-betting-shadow-bg"></div>
			<img
				src={buildPathToStaticFolderOfCDN("cashier-images/stream/spin-to-win/shadow.svg")}
				className="vs--spin-2-win-stream-scene-close-for-betting-shadow"
				alt="bg"
			/>
			<div className='vs--spin-2-win-stream-scene-close-for-betting-start'>
				<span className='vs--spin-2-win-stream-scene-close-for-betting-title'>{t("common.getReady")}</span>
				<span className='vs--spin-2-win-stream-scene-close-for-betting-subTitle'>{t("common.gameIsStarting")}</span>
				<span className='vs--spin-2-win-stream-scene-close-for-betting-countdown-number'></span>
			</div>
		</div>
	)
}
export default CloseForBetting