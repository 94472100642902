import runMarketUtilsFunction from "utils/markets/run.js";
import SelectColor from "components/pages/main/markets/luckySix/selectColor/index.jsx";
import { GAME_TYPE } from "constants/game.constants.js";

const ColorMarkets = ({ group, markets, currentEvent, isSelectingColumn = false }) => {
	const currentMarket = markets?.find((market) => market.group === group);
	const currentMarketTitle = runMarketUtilsFunction("makeGroupTitle", [{ group }], GAME_TYPE.LUCKY_SIX);

	return isSelectingColumn ? (
		<SelectColor currentEvent={currentEvent} currentMarket={currentMarket} isSelectingColumn={isSelectingColumn} />
	) : (
		<div className="vs--lucky-six-container-other-market-block-content vs--flex vs--flex-col vs--align-center vs--justify-start">
			<div className="vs--lucky-six-other-bets-title vs--flex">
				<span className="vs--font-exstrasmall vs--font-medium">{currentMarketTitle.toUpperCase()}</span>
			</div>

			<div className="vs--lucky-six-container-other-market-block-content-colors vs--flex vs--flex-wrap vs--justify-center vs--align-center8">
				<SelectColor currentEvent={currentEvent} currentMarket={currentMarket} />
			</div>
		</div>
	);
};

export default ColorMarkets;
