import { mergeClassNames } from "utils/common";

const BulkSelectionButton = ({
	selectingNumbers,
	text,
	subText,
	handleMarketHover,
	handleOddClick,
	isOddDisabled,
	className = ""
}) => {
	return (
		<button
			className={mergeClassNames(className, isOddDisabled && "vs--bulk-selection-disabled")}
			onMouseOver={!isOddDisabled ? () => handleMarketHover(selectingNumbers) : undefined}
			onMouseLeave={!isOddDisabled ? () => handleMarketHover([]) : undefined}
			onClick={handleOddClick}
			disabled={isOddDisabled}
		>
			{!isOddDisabled ? (
				<>
					{text}
					{subText && <span>{subText}</span>}
				</>
			) : (
				<i className="ic_lock vs--font-bigest" />
			)}
		</button>
	);
};

export default BulkSelectionButton;
