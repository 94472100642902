import { isNullish } from 'utils/common';
import { v4 as uuidV4 } from "uuid";

import {
	BONUS_BEFORE,
	BONUS_AFTER,
	SET_VIEW_BONUS,
	HIDE_VIEWING_BONUS,
	SET_USE_BONUS,
	CLEAN_USE_BONUS,
	JACKPOT_BONUS_BEFORE,
	JACKPOT_BONUS_AFTER,
	SET_JACKPOT_BONUS,
	UPDATE_JACKPOT_BONUS,
	SET_WON_JACKPOT_BONUSES,
	REMOVE_WON_JACKPOT_BONUS
} from '../../actionTypes';

export default (state = {}, { type, payload }) => {
	switch (type) {
		case BONUS_BEFORE:
			return {
				...state,
				standard: {
					...state.standard,
					isBonusLoading: true
				}
			}
		case BONUS_AFTER:
			return {
				...state,
				standard: {
					...state.standard,
					isBonusLoading: false
				}
			}
		case SET_VIEW_BONUS:
			return {
				...state,
				standard: {
					...state.standard,
					showBonus: payload.viewingBonus !== null,
					viewingBonus: payload.viewingBonus
				}
			}
		case HIDE_VIEWING_BONUS:
			return {
				...state,
				standard: {
					...state.standard,
					showBonus: false,
					viewingBonus: null
				}
			}
		case SET_USE_BONUS:
			return {
				...state,
				standard: {
					...state.standard,
					showBonus: false,
					viewingBonus: null,
					useBonus: state.standard.viewingBonus !== null,
					bonus: state.standard.viewingBonus
				}
			}
		case CLEAN_USE_BONUS:
			return {
				...state,
				standard: {
					...state.standard,
					showBonus: false,
					viewingBonus: null,
					useBonus: false,
					bonus: null
				}
			}
		case JACKPOT_BONUS_BEFORE:
			return {
				...state,
				jackpot: {
					...state.jackpot,
					isLoading: true
				}
			}
		case JACKPOT_BONUS_AFTER:
			return {
				...state,
				jackpot: {
					...state.jackpot,
					isLoading: false
				}
			}
		case SET_JACKPOT_BONUS:
			return {
				...state,
				jackpot: {
					...state.jackpot,
					data: payload.jackpotInfo
				}
			}
		case UPDATE_JACKPOT_BONUS:
			return {
				...state,
				jackpot: {
					...state.jackpot,
					data: {
						...state.jackpot.data,
						levels: state.jackpot.data?.levels?.map((level) => {
							if (level.levelType === payload.updatedData.levelType) {
								const { betSlipId, lastWinTime, ...rest } = payload.updatedData;

								return { 
									...level, 
									betSlipId: isNullish(betSlipId) ? level.betSlipId : betSlipId, 
									lastWinTime: isNullish(lastWinTime) ? level.lastWinTime : lastWinTime,
									...rest 
								};
							}

							return level;
						})
					}
				}
			}
		case SET_WON_JACKPOT_BONUSES:
			const newWonJackpots = payload.wonJackpotBonuses.map(wonJackpot => ({...wonJackpot, _id: uuidV4() })).sort((nextWonJackpot, prevWonJackpot) => nextWonJackpot.levelType - prevWonJackpot.prev);
			return {
				...state,
				jackpot: {
					...state.jackpot,
					wonJackpots: [...state.jackpot.wonJackpots, ...newWonJackpots]
				}
			}
		case REMOVE_WON_JACKPOT_BONUS:
			return {
				...state,
				jackpot: {
					...state.jackpot,
					wonJackpots: state.jackpot.wonJackpots.filter(wonJackpot => wonJackpot._id !== payload.jackpot._id)
				}
			}
			break;
		default:
			return state;
	}
}
