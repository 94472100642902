const SvgTextShadow = ({
	text: argText,
	letterSpacing = 1,
	fontSize = 120,
	fontWeight = "bold",
	fontFamily = "Arial, sans-serif"
}) => {
	const text = String(argText);
	const textLength = text.length * 80;

	return (
		<svg
			width={textLength}
			height="210"
			viewBox={`0 0 ${textLength} 210`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<filter id="innerShadow" x="-50%" y="-50%" width="200%" height="200%">
					<feOffset dx="3" dy="3" />
					<feGaussianBlur stdDeviation="4" result="blur" />
					<feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" />
					<feColorMatrix type="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0.75 0" />
					<feBlend mode="multiply" in2="SourceGraphic" result="shadow" />
				</filter>
			</defs>
			<text
				x="5"
				y="160"
				fontSize={fontSize}
				fontWeight={fontWeight}
				fontFamily={fontFamily}
				fill="#DADADA"
				filter="url(#innerShadow)"
				letterSpacing={letterSpacing}
				textLength={textLength - 10}
				lengthAdjust="spacingAndGlyphs"
			>
				{text}
			</text>
		</svg>
	);
};

export default SvgTextShadow;
