import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants.js";
import { mergeClassNames } from "utils/common.js";
import { useSelector } from "react-redux";
import { SPIN_TO_WIN_MARKET_GROUPS } from "constants/market.constants.js";

const generateOutcomeFromNumbers = (numbers) => {
	const numbersClone = [...numbers];

	numbersClone.sort((next, prev) => next - prev);
	return numbersClone.join("-");
};

const findOdd = ({ markets, marketGroup, numbers }) => {
	const foundMarket = markets.find((market) => market.group === marketGroup) ?? {};
	const outcome = generateOutcomeFromNumbers(numbers);

	const foundOdd = foundMarket.odds?.at(0)?.find((odd) => odd.outcome === outcome);

	return foundOdd ?? {};
};

const Spin2WinBoardNumbers = ({ handleMarketHover, markets, handleOddClick, bets, isOddDisabled }) => {
	const hoveredNumbers = useSelector((state) => state.spin2Win.hoveredNumbers);

	const handleInternalOddClick = (marketGroup, numbers) => {
		const foundMarket = markets.find((market) => market.group === marketGroup);
		const foundOdd = findOdd({ markets, marketGroup, numbers });

		handleOddClick(foundMarket, foundOdd);
	};

	const indexToNumberMappingFunction = (index) => 3 * (index + 1) - 2;

	return (
		<>
			<div className="vs--roulete-top-arrows vs--justify-center">
				{Array.from({ length: 11 }, (_, index) => {
					const firstNumberInFirstRow = indexToNumberMappingFunction(index);
					const firstNumberInSecondRow = indexToNumberMappingFunction(index + 1);

					const firstRow = [firstNumberInFirstRow, firstNumberInFirstRow + 1, firstNumberInFirstRow + 2];
					const secondRow = [firstNumberInSecondRow, firstNumberInSecondRow + 1, firstNumberInSecondRow + 2];
					const numbers = [...firstRow, ...secondRow];

					const foundOdd = findOdd({ markets, marketGroup: SPIN_TO_WIN_MARKET_GROUPS.DOUBLE_STREET, numbers });
					const isSelected = bets.some((bet) => bet.oddId === foundOdd.id);

					return (
						<div
							key={index}
							className={mergeClassNames("vs--rulete-row-selection-button", isOddDisabled && "disabled")}
							onMouseOver={!isOddDisabled ? () => handleMarketHover(numbers) : undefined}
							onMouseLeave={!isOddDisabled ? () => handleMarketHover([]) : undefined}
							onClick={
								!isOddDisabled
									? () => handleInternalOddClick(SPIN_TO_WIN_MARKET_GROUPS.DOUBLE_STREET, numbers)
									: undefined
							}
						>
							<svg width="10" height="9" viewBox="0 0 10 9" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.69867 8.59091C5.38815 9.13636 4.61185 9.13636 4.30133 8.59091L0.10929 1.22727C-0.201231 0.681816 0.18692 -8.57887e-07 0.807962 -8.03594e-07L9.19204 -7.06342e-08C9.81308 -1.63409e-08 10.2012 0.681818 9.89071 1.22727L5.69867 8.59091Z" />
							</svg>

							{isSelected && !isOddDisabled && <div className="vs--roulete-number-selected" />}
						</div>
					);
				})}
			</div>

			<div className="vs--roulete-numbers">
				{SPIN_TO_WIN_MARKETS_NUMBERS.NUMBERS.map((number) => {
					const colorClass = SPIN_TO_WIN_MARKETS_NUMBERS.RED_NUMBERS.includes(number) ? "vs--red" : "vs--black"; // Assign red or black

					const isFirstColumn = SPIN_TO_WIN_MARKETS_NUMBERS.FIRST_COLUMN.includes(number);
					const isFirstThree = SPIN_TO_WIN_MARKETS_NUMBERS.FIRST_THREE.includes(number);

					const foundNumberOdd = findOdd({
						markets,
						marketGroup: SPIN_TO_WIN_MARKET_GROUPS.STRAIGHT,
						numbers: [number]
					});
					const isNumberSelected = bets.some((bet) => bet.oddId === foundNumberOdd.id);

					const foundLeftSplitOdd = findOdd({
						markets,
						marketGroup: SPIN_TO_WIN_MARKET_GROUPS.SPLIT,
						numbers: [number, isFirstThree ? 0 : number - 3]
					});
					const isLeftSplitSelected = bets.some((bet) => bet.oddId === foundLeftSplitOdd.id);

					const foundBottomSplitOdd = findOdd({
						markets,
						marketGroup: SPIN_TO_WIN_MARKET_GROUPS.SPLIT,
						numbers: [number, number - 1]
					});
					const isBottomSplitSelected = bets.some((bet) => bet.oddId === foundBottomSplitOdd.id);

					const foundCornerOdd = findOdd({
						markets,
						marketGroup: isFirstThree ? SPIN_TO_WIN_MARKET_GROUPS.TRIO : SPIN_TO_WIN_MARKET_GROUPS.CORNER,
						numbers: isFirstThree ? [0, number, number - 1] : [number, number - 1, number - 3, number - 4]
					});
					const isCornerSelected = bets.some((bet) => bet.oddId === foundCornerOdd.id);

					const isSelected = false;

					return (
						<div
							key={number}
							className={mergeClassNames("vs--roulete-number-wrapper", isOddDisabled && "disabled")}
						>
							<div
								className={mergeClassNames(
									"vs--roulete-number",
									colorClass,
									hoveredNumbers.includes(number) && "hovered"
								)}
								key={number}
								onClick={
									!isOddDisabled
										? () => handleInternalOddClick(SPIN_TO_WIN_MARKET_GROUPS.STRAIGHT, [number])
										: undefined
								}
								onMouseOver={!isOddDisabled ? () => !isSelected && handleMarketHover([number]) : undefined}
								onMouseLeave={!isOddDisabled ? () => !isSelected && handleMarketHover([]) : undefined}
							>
								<span className="vs--roulete-number-text">{number}</span>
								{isNumberSelected && !isOddDisabled && <div className="vs--roulete-number-selected" />}
							</div>

							<div
								className="vs--roulete-number-left-side"
								onMouseOver={
									!isOddDisabled
										? () => !isSelected && handleMarketHover([number, isFirstThree ? 0 : number - 3])
										: undefined
								}
								onMouseLeave={!isOddDisabled ? () => !isSelected && handleMarketHover([]) : undefined}
								onClick={
									!isOddDisabled
										? () =>
												handleInternalOddClick(SPIN_TO_WIN_MARKET_GROUPS.SPLIT, [
													number,
													isFirstThree ? 0 : number - 3
												])
										: undefined
								}
							>
								{isLeftSplitSelected && !isOddDisabled && <div className="vs--roulete-number-selected" />}
							</div>

							{!isFirstColumn && (
								<>
									<div
										className={mergeClassNames(
											"vs--roulete-number-left-corner",
											isFirstThree && "first-three"
										)}
										onMouseOver={
											!isOddDisabled
												? () => {
														const numbers = [];

														if (isFirstThree) {
															numbers.push(0, number, number - 1);
														} else {
															numbers.push(number, number - 1, number - 3, number - 4);
														}

														!isSelected && handleMarketHover(numbers);
													}
												: undefined
										}
										onClick={
											!isOddDisabled
												? () => {
														const group = isFirstThree
															? SPIN_TO_WIN_MARKET_GROUPS.TRIO
															: SPIN_TO_WIN_MARKET_GROUPS.CORNER;
														const numbers = [];

														if (isFirstThree) {
															numbers.push(0, number, number - 1);
														} else {
															numbers.push(number, number - 1, number - 3, number - 4);
														}

														handleInternalOddClick(group, numbers);
													}
												: undefined
										}
										onMouseLeave={!isOddDisabled ? () => !isSelected && handleMarketHover([]) : undefined}
									>
										{isCornerSelected && !isOddDisabled && <div className="vs--roulete-number-selected" />}
									</div>

									<div
										className="vs--roulete-number-bottom"
										onMouseOver={
											!isOddDisabled
												? () => !isSelected && handleMarketHover([number, number - 1])
												: undefined
										}
										onMouseLeave={!isOddDisabled ? () => !isSelected && handleMarketHover([]) : undefined}
										onClick={
											!isOddDisabled
												? () =>
														handleInternalOddClick(SPIN_TO_WIN_MARKET_GROUPS.SPLIT, [number, number - 1])
												: undefined
										}
									>
										{isBottomSplitSelected && !isOddDisabled && (
											<div className="vs--roulete-number-selected" />
										)}
									</div>
								</>
							)}
						</div>
					);
				})}
			</div>

			<div className="vs--roulete-bottom-arrows">
				{Array.from({ length: 12 }, (_, index) => {
					const firstNumberInRow = indexToNumberMappingFunction(index);
					const numbers = [firstNumberInRow, firstNumberInRow + 1, firstNumberInRow + 2];

					const foundOdd = findOdd({ markets, marketGroup: SPIN_TO_WIN_MARKET_GROUPS.STREET, numbers });
					const isSelected = bets.some((bet) => bet.oddId === foundOdd.id);

					return (
						<div
							key={index}
							className={mergeClassNames("vs--rulete-row-selection-button", isOddDisabled && "disabled")}
							onMouseOver={!isOddDisabled ? () => handleMarketHover(numbers) : undefined}
							onMouseLeave={!isOddDisabled ? () => handleMarketHover([]) : undefined}
							onClick={
								!isOddDisabled
									? () => handleInternalOddClick(SPIN_TO_WIN_MARKET_GROUPS.STREET, numbers)
									: undefined
							}
						>
							<svg width="10" height="9" viewBox="0 0 10 9" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.69867 8.59091C5.38815 9.13636 4.61185 9.13636 4.30133 8.59091L0.10929 1.22727C-0.201231 0.681816 0.18692 -8.57887e-07 0.807962 -8.03594e-07L9.19204 -7.06342e-08C9.81308 -1.63409e-08 10.2012 0.681818 9.89071 1.22727L5.69867 8.59091Z" />
							</svg>
							{isSelected && !isOddDisabled && <div className="vs--roulete-number-selected" />}
						</div>
					);
				})}
			</div>
		</>
	);
};

export default Spin2WinBoardNumbers;
