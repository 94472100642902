import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { GAME_TYPE, SPIN_TO_WIN_BOARD_GROUPS, SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants";
import { useTranslation } from "react-i18next";

const RouletteTable = ({ activeGroup }) => {

	const { t } = useTranslation()
	const ref = useRef()

	useEffect(() => {
		if (!activeGroup) {
			return
		}
		
		const trierNums = SPIN_TO_WIN_MARKETS_NUMBERS[SPIN_TO_WIN_BOARD_GROUPS.TRIER];
		const orpehlinsNums = SPIN_TO_WIN_MARKETS_NUMBERS[SPIN_TO_WIN_BOARD_GROUPS.ORPEHLINS];
		const voisinNums = SPIN_TO_WIN_MARKETS_NUMBERS[SPIN_TO_WIN_BOARD_GROUPS.VOISIN];
		const zeroNums =SPIN_TO_WIN_MARKETS_NUMBERS[SPIN_TO_WIN_BOARD_GROUPS.ZERO];

		const groups = [
			{ selector: ".trier", numbers: trierNums },
			{ selector: ".orpehlins", numbers: orpehlinsNums },
			{ selector: ".voisin", numbers: voisinNums },
			{ selector: ".zero", numbers: zeroNums },
		];

		const groupToFill = groups.find(group => group.selector === `.${activeGroup}`)

		if (!groupToFill) {
			return
		}

		const group = ref.current.querySelector(groupToFill.selector);

		if (!group) {
			return
		}

		group.classList.add("active")

		const tags = groupToFill.numbers.map((num) => {
			const numberGroup = ref.current.querySelector(`g[class='num${num}']`);
			if (numberGroup) {
				const path = numberGroup.querySelector("& > path");
				if (path) {
					path.classList.add("coloredPath");
					path.setAttribute("fill", "#009499");
					path.setAttribute("stroke", "#00F6FF");
					return path
				}
			}
			return null
		});

		const filteredTags = tags.filter(Boolean)

		return () => {
			group.classList.remove("active")
			filteredTags.forEach((path) => {
				path.classList.remove("coloredPath");
				path.removeAttribute("fill");
				path.removeAttribute("stroke");
			});
		}
	}, [activeGroup])

	return (
		<div className="vs--spin-2-win-roulette-table-container">
			<div ref={ref} className="vs--spin-2-win-roulette-table">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1034 494" fill="none">
					<g filter="url(#filter0_d_7219_18647)">

						<g className='num1'>
							<g filter="url(#filter1_ii_7219_18647)">
								<path d="M375.571 95.6797H328.564V158.962H375.571V95.6797Z" fill="url(#paint0_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M329.564 96.6797H374.571V157.962H329.564V96.6797Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="346.417" y="137.746">1</tspan></text>
						</g>

						<g className='num33'>
							<g filter="url(#filter2_ii_7219_18647)">
								<path d="M328.563 95.6797H281.556V158.962H328.563V95.6797Z" fill="url(#paint1_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M282.556 96.6797H327.563V157.962H282.556V96.6797Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="292" y="137.746">33</tspan></text>
						</g>

						<g className='num20'>
							<g filter="url(#filter3_ii_7219_18647)">
								<path d="M422.594 95.6797H375.587V158.962H422.594V95.6797Z" fill="url(#paint2_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M376.587 96.6797H421.594V157.962H376.587V96.6797Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="385" y="137.746">20</tspan></text>
						</g>

						<g className='num13'>
							<g filter="url(#filter4_ii_7219_18647)">
								<path d="M328.563 335.488H281.556V398.77H328.563V335.488Z" fill="url(#paint3_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M282.556 336.488H327.563V397.77H282.556V336.488Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="294" y="378.98">13</tspan></text>
						</g>


						<g className='num27'>
							<g filter="url(#filter5_ii_7219_18647)">
								<path d="M375.571 335.488H328.564V398.77H375.571V335.488Z" fill="url(#paint4_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M329.564 336.488H374.571V397.77H329.564V336.488Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="338.89" y="378.98">27</tspan></text>

						</g>


						<g className='num34'>
							<g filter="url(#filter6_ii_7219_18647)">
								<path d="M469.602 335.488H422.595V398.77H469.602V335.488Z" fill="url(#paint5_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M423.595 336.488H468.602V397.77H423.595V336.488Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="433.219" y="378.98">34</tspan></text>
						</g>

						<g className='num36'>
							<g filter="url(#filter7_ii_7219_18647)">
								<path d="M253.67 335.486C247.742 335.486 241.97 334.937 236.355 333.904L224.429 396.171C233.904 397.869 243.691 398.769 253.67 398.769H281.554V335.486H253.67Z" fill="url(#paint6_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M225.602 395.362L237.151 335.062C242.516 335.993 248.023 336.486 253.67 336.486H280.554V397.769H253.67C244.101 397.769 234.709 396.937 225.602 395.362Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="242" y="378.978">36</tspan></text>

						</g>


						<g className='num3'>
							<g filter="url(#filter8_ii_7219_18647)">
								<path d="M921.976 179.564L862.991 208.108C869.093 219.898 872.552 233.171 872.552 247.226C872.552 247.41 872.552 247.576 872.552 247.743L938.63 247.526C938.63 247.426 938.63 247.326 938.63 247.226C938.63 222.896 932.632 199.931 921.993 179.564H921.976Z" fill="url(#paint7_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M873.551 246.739C873.474 233.06 870.158 220.123 864.346 208.563L921.549 180.881C931.74 200.702 937.516 222.963 937.628 246.529L873.551 246.739Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="896.647" y="230.529">3</tspan></text>

						</g>


						<g className='num16'>
							<g filter="url(#filter9_ii_7219_18647)">
								<path d="M253.67 158.962H281.554V95.6797H253.67C243.952 95.6797 234.443 96.529 225.212 98.1444L236.686 160.477C242.196 159.495 247.864 158.962 253.67 158.962Z" fill="url(#paint8_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M280.554 96.6797V157.962H253.67C248.146 157.962 242.748 158.439 237.49 159.323L226.379 98.9586C235.241 97.4641 244.359 96.6797 253.67 96.6797H280.554Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="246" y="137.746">16</tspan></text>

						</g>



						<g className='num24'>
							<g filter="url(#filter10_ii_7219_18647)">
								<path d="M236.687 160.478L225.213 98.1445C204.022 101.842 184.325 109.569 166.976 120.477L202.787 173.7C212.87 167.272 224.344 162.692 236.687 160.478Z" fill="url(#paint9_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M168.383 120.777C185.142 110.391 204.073 102.978 224.409 99.3029L235.522 159.676C223.759 161.914 212.794 166.282 203.075 172.337L168.383 120.777Z" stroke="#232323" strokeWidth="2" />
							<text transform="translate(194.651 121.967)" fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="0" y="25.0566">24</tspan></text>

						</g>

						<g className='num5'>
							<g filter="url(#filter11_ii_7219_18647)">
								<path d="M202.767 173.698L166.956 120.475C149.38 131.549 134.221 145.871 122.452 162.574L176.969 198.328C183.766 188.57 192.563 180.193 202.767 173.698Z" fill="url(#paint10_linear_7219_18647)" />
							</g>
							<path d="M123.868 162.307C135.288 146.333 149.846 132.584 166.669 121.84L201.368 173.41C191.697 179.714 183.308 187.706 176.709 196.962L123.868 162.307Z" stroke="#752121" strokeWidth="2" />
							<text transform="translate(160.421 148.019)" fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="-1" y="26.0566">5</tspan></text>

						</g>

						<g className='num10'>
							<g filter="url(#filter12_ii_7219_18647)">
								<path d="M176.989 198.329L122.471 162.574C110.928 178.961 102.618 197.613 98.481 217.713L163.22 230.436C165.567 218.746 170.33 207.888 176.989 198.329Z" fill="url(#paint11_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M122.734 163.943L175.585 198.604C169.338 207.796 164.809 218.148 162.441 229.264L99.6673 216.927C103.774 197.666 111.743 179.76 122.734 163.943Z" stroke="#232323" strokeWidth="2" />
							<text transform="translate(125.4 185.135)" fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="-3" y="26.0566">10</tspan></text>

						</g>

						<g className='num23'>
							<g filter="url(#filter13_ii_7219_18647)">
								<path d="M161.534 247.224C161.534 241.479 162.125 235.884 163.22 230.438L98.4806 217.715C96.5162 227.257 95.4731 237.133 95.4731 247.224C95.4731 257.533 96.5684 267.575 98.6197 277.3L163.446 265.01C162.212 259.265 161.551 253.319 161.551 247.224H161.534Z" fill="url(#paint12_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M160.534 247.224V248.224H160.557C160.621 253.689 161.209 259.032 162.259 264.217L99.3992 276.135C97.49 266.774 96.4731 257.123 96.4731 247.224C96.4731 237.544 97.4396 228.063 99.2636 218.888L162.047 231.227C161.063 236.423 160.534 241.756 160.534 247.224Z" stroke="#752121" strokeWidth="2" />
							<text transform="translate(116.123 232.988)" fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="-3" y="25.0566">23</tspan></text>

						</g>


						<g className='num12'>
							<g filter="url(#filter14_ii_7219_18647)">
								<path d="M835.749 176.686L875.142 125.877C855.741 111.954 832.898 102.179 808.038 97.9824L796.564 160.332C811.08 162.797 824.431 168.526 835.749 176.686Z" fill="url(#paint13_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M797.73 159.521L808.843 99.1354C832.814 103.332 854.871 112.752 873.713 126.087L835.547 175.314C824.54 167.549 811.679 162.041 797.73 159.521Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="819.122" y="147.025">12</tspan></text>

						</g>

						<g className='num8'>
							<g filter="url(#filter15_ii_7219_18647)">
								<path d="M163.43 265.008L98.604 277.298C102.811 297.282 111.103 315.85 122.647 332.154L177.459 296.749C170.766 287.306 165.916 276.582 163.43 265.008Z" fill="url(#paint14_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M176.045 296.472L122.911 330.792C111.923 315.059 103.968 297.238 99.7963 278.09L162.663 266.171C165.158 277.172 169.767 287.395 176.045 296.472Z" stroke="#232323" strokeWidth="2" />
							<text transform="translate(131.04 278.152)" fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="-3" y="27.0566">8</tspan></text>

						</g>


						<g className='num30'>
							<g filter="url(#filter16_ii_7219_18647)">
								<path d="M177.457 296.735L122.645 332.14C134.362 348.677 149.399 362.882 166.835 373.873L203.012 320.916C192.912 314.538 184.237 306.294 177.457 296.719V296.735Z" fill="url(#paint15_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M177.207 298.087C183.778 307.158 192.044 315.015 201.605 321.203L166.555 372.511C149.872 361.852 135.433 348.221 124.067 332.412L177.207 298.087Z" stroke="#752121" strokeWidth="2" />
							<text transform="translate(154.781 319.902)" fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="-5" y="25.0566">30</tspan></text>

						</g>

						<g className='num11'>
							<g filter="url(#filter17_ii_7219_18647)">
								<path d="M203.014 320.932L166.837 373.889C183.996 384.697 203.484 392.424 224.414 396.171L236.34 333.905C224.223 331.69 212.941 327.21 202.997 320.932H203.014Z" fill="url(#paint16_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M203.293 322.296C212.867 328.201 223.636 332.469 235.169 334.702L223.619 395.01C203.542 391.289 184.82 383.88 168.25 373.593L203.293 322.296Z" stroke="#232323" strokeWidth="2" />
							<text transform="translate(195.625 344.647)" fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="-4" y="25.0566">11</tspan></text>

						</g>


						<g className='num6'>
							<g filter="url(#filter18_ii_7219_18647)">
								<path d="M422.594 335.488H375.587V398.77H422.594V335.488Z" fill="url(#paint17_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M376.587 336.488H421.594V397.77H376.587V336.488Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="390.582" y="378.98">6</tspan></text>

						</g>

						<g className='num17'>
							<g filter="url(#filter19_ii_7219_18647)">
								<path d="M516.606 335.488H469.599V398.77H516.606V335.488Z" fill="url(#paint18_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M470.599 336.488H515.606V397.77H470.599V336.488Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="481.611" y="378.98">17</tspan></text>

						</g>


						<g className='num25'>
							<g filter="url(#filter20_ii_7219_18647)">
								<path d="M563.615 335.488H516.607V398.77H563.615V335.488Z" fill="url(#paint19_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M517.607 336.488H562.615V397.77H517.607V336.488Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="525.548" y="378.98">25</tspan></text>

						</g>


						<g className='num2'>
							<g filter="url(#filter21_ii_7219_18647)">
								<path d="M610.623 335.486H563.616V398.769H610.623V335.486Z" fill="url(#paint20_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M564.616 336.486H609.623V397.769H564.616V336.486Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="578.693" y="378.982">2</tspan></text>

						</g>

						<g className='num21'>
							<g filter="url(#filter22_ii_7219_18647)">
								<path d="M657.63 335.486H610.623V398.769H657.63V335.486Z" fill="url(#paint21_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M611.623 336.486H656.63V397.769H611.623V336.486Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="620.877" y="378.982">21</tspan></text>

						</g>


						<g className='num4'>
							<g filter="url(#filter23_ii_7219_18647)">
								<path d="M704.654 335.488H657.646V398.77H704.654V335.488Z" fill="url(#paint22_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M658.646 336.488H703.654V397.77H658.646V336.488Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="673.021" y="378.98">4</tspan></text>

						</g>


						<g className='num19'>
							<g filter="url(#filter24_ii_7219_18647)">
								<path d="M751.661 335.486H704.654V398.769H751.661V335.486Z" fill="url(#paint23_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M705.654 336.486H750.661V397.769H705.654V336.486Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="715.658" y="378.982">19</tspan></text>

						</g>

						<g className='num15'>
							<g filter="url(#filter25_ii_7219_18647)">
								<path d="M780.417 335.484H751.663V398.766H780.417C790.117 398.766 799.609 397.9 808.823 396.302L796.897 334.068C791.543 335.001 786.049 335.501 780.417 335.501V335.484Z" fill="url(#paint24_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M796.099 335.217L807.648 395.487C798.804 396.967 789.706 397.766 780.417 397.766H752.663V336.484H779.417V336.501H780.417C785.769 336.501 790.997 336.054 796.099 335.217Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="764.143" y="377.434">15</tspan></text>

						</g>

						<g className='num32'>
							<g filter="url(#filter26_ii_7219_18647)">
								<path d="M796.895 334.053L808.82 396.286C833.384 392.006 855.984 382.331 875.193 368.525L835.436 317.982C824.275 325.959 811.15 331.571 796.895 334.053Z" fill="url(#paint25_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M809.617 395.129L798.067 334.858C811.755 332.327 824.39 326.934 835.237 319.347L873.76 368.32C855.11 381.535 833.296 390.857 809.617 395.129Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="816.668" y="369.701">32</tspan></text>

						</g>


						<g className='num26'>
							<g filter="url(#filter27_ii_7219_18647)">
								<path d="M862.574 287.125L921.785 315.285C932.493 294.902 938.561 271.887 938.613 247.523L872.535 247.74C872.448 261.912 868.849 275.284 862.574 287.125Z" fill="url(#paint26_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M937.607 248.527C937.396 272.013 931.558 294.216 921.344 313.968L863.941 286.668C869.847 275.199 873.284 262.342 873.523 248.737L937.607 248.527Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="888.255" y="285.199">26</tspan></text>

						</g>

						<g className='num0'>
							<g filter="url(#filter28_ii_7219_18647)">
								<path d="M835.435 318L875.193 368.542C894.698 354.537 910.726 336.318 921.783 315.285L862.572 287.125C856.105 299.315 846.769 309.873 835.417 317.983L835.435 318Z" fill="url(#paint27_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M875.342 367.481L836.526 318.137C847.474 310.17 856.523 299.927 862.905 288.139L920.722 315.637C909.852 336.045 894.262 353.762 875.342 367.481Z" stroke="#164833" strokeWidth="1.54637" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="870.906" y="336.681">0</tspan></text>

						</g>


						<g className='num35'>
							<g filter="url(#filter29_ii_7219_18647)">
								<path d="M862.99 208.106L921.975 179.562C910.901 158.362 894.786 139.977 875.141 125.889L835.749 176.698C847.205 184.958 856.558 195.716 862.99 208.106Z" fill="url(#paint28_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M875.34 127.265C894.225 140.986 909.774 158.72 920.608 179.113L863.425 206.784C857.097 194.916 848.106 184.569 837.17 176.497L875.34 127.265Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="863.967" y="181.045">35</tspan></text>

						</g>


						<g className='num28'>
							<g filter="url(#filter30_ii_7219_18647)">
								<path d="M780.416 158.962C785.927 158.962 791.316 159.445 796.566 160.344L808.04 97.9945C799.07 96.479 789.839 95.6797 780.416 95.6797H751.663V158.962H780.416Z" fill="url(#paint29_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M806.872 98.8146L795.761 159.197C790.765 158.392 785.645 157.962 780.416 157.962H752.663V96.6797H780.416C789.432 96.6797 798.271 97.4159 806.872 98.8146Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="764.596" y="137.746">28</tspan></text>

						</g>


						<g className='num7'>
							<g filter="url(#filter31_ii_7219_18647)">
								<path d="M751.662 95.6797H704.655V158.962H751.662V95.6797Z" fill="url(#paint30_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M705.655 96.6797H750.662V157.962H705.655V96.6797Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="720.413" y="137.746">7</tspan></text>

						</g>


						<g className='num29'>
							<g filter="url(#filter32_ii_7219_18647)">
								<path d="M704.654 95.6797H657.646V158.962H704.654V95.6797Z" fill="url(#paint31_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M658.646 96.6797H703.654V157.962H658.646V96.6797Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="668.268" y="137.746">29</tspan></text>

						</g>


						<g className='num18'>
							<g filter="url(#filter33_ii_7219_18647)">
								<path d="M657.63 95.6797H610.623V158.962H657.63V95.6797Z" fill="url(#paint32_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M611.623 96.6797H656.63V157.962H611.623V96.6797Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="620.877" y="137.746">18</tspan></text>

						</g>

						<g className='num22'>
							<g filter="url(#filter34_ii_7219_18647)">
								<path d="M610.622 95.6797H563.615V158.962H610.622V95.6797Z" fill="url(#paint33_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M564.615 96.6797H609.622V157.962H564.615V96.6797Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="572.939" y="137.746">22</tspan></text>

						</g>


						<g className='num9'>
							<g filter="url(#filter35_ii_7219_18647)">
								<path d="M563.615 95.6797H516.607V158.962H563.615V95.6797Z" fill="url(#paint34_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M517.607 96.6797H562.615V157.962H517.607V96.6797Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="532.302" y="137.746">9</tspan></text>

						</g>


						<g className='num31'>
							<g filter="url(#filter36_ii_7219_18647)">
								<path d="M516.606 95.6797H469.599V158.962H516.606V95.6797Z" fill="url(#paint35_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M470.599 96.6797H515.606V157.962H470.599V96.6797Z" stroke="#232323" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="481.611" y="137.746">31</tspan></text>

						</g>


						<g className='num14'>
							<g filter="url(#filter37_ii_7219_18647)">
								<path d="M469.602 95.6797H422.595V158.962H469.602V95.6797Z" fill="url(#paint36_linear_7219_18647)" />
							</g>
							<path className='coloredPath' d="M423.595 96.6797H468.602V157.962H423.595V96.6797Z" stroke="#752121" strokeWidth="2" />
							<text fill="white" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="30.9274" fontWeight="bold" letterSpacing="0em"><tspan x="433.219" y="137.746">14</tspan></text>
						</g>

						<g className='orpehlins'>
							<g filter="url(#filter38_ii_7219_18647)">
								<path d="M329.749 159.08L375.137 335.367H516.86L563.595 159.08H445.664H329.749Z" fill="url(#paint37_linear_7219_18647)" />
							</g>
							<path d="M329.749 158.08H328.458L328.78 159.329L374.169 335.616L374.362 336.367H375.137H516.86H517.629L517.826 335.623L564.562 159.336L564.895 158.08H563.595H445.664H329.749Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" />
							<path className='active-color' d="M329.749 159.08L375.137 335.367H516.86L563.595 159.08H445.664H329.749Z" fill="#03F7FF" fillOpacity='0' />
							<text fill="white" fillOpacity="0.8" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="32" fontWeight="bold" letterSpacing="0em">
								<tspan x="396" y="254.784">{t(`markets.${GAME_TYPE.SPIN_TO_WIN}.orpehlins`)}</tspan>
							</text>

						</g>

						<g className='voisin'>
							<g filter="url(#filter39_ii_7219_18647)">
								<path d="M752 219.5L795 158.5L563.251 159.08L516.859 335.367H752V219.5Z" fill="url(#paint38_linear_7219_18647)" />
							</g>
							<path d="M795.817 159.076L796.932 157.495L794.997 157.5L563.248 158.08L562.479 158.082L562.283 158.826L515.892 335.112L515.562 336.367H516.859H752H753V335.367V219.817L795.817 159.076Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" />
							<path className='active-color' d="M795.817 159.076L796.932 157.495L794.997 157.5L563.248 158.08L562.479 158.082L562.283 158.826L515.892 335.112L515.562 336.367H516.859H752H753V335.367V219.817L795.817 159.076Z" fill="#03F7FF" fillOpacity='0' />
							<text fill="white" fillOpacity="0.8" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="32" fontWeight="bold" letterSpacing="0em">
								<tspan x="620" y="254.784">{t(`markets.${GAME_TYPE.SPIN_TO_WIN}.voisin`)}</tspan>
							</text>
						</g>

						<g className='trier'>
							<g>
								<path d="M254.289 159.08C200.626 159.08 161.194 199.351 161.194 247.223C161.194 297.48 203.179 335.367 253.976 335.367H375.157L329.748 159.08H254.306H254.289Z" fill="url(#paint39_linear_7219_18647)" />
							</g>
							<path d="M375.157 336.367H376.448L376.126 335.117L330.717 158.831L330.523 158.08H329.748H254.306H254.289C200.104 158.08 160.194 198.769 160.194 247.223C160.194 298.095 202.691 336.367 253.976 336.367H375.157Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" />
							<path className='active-color' d="M375.157 336.367H376.448L376.126 335.117L330.717 158.831L330.523 158.08H329.748H254.306H254.289C200.104 158.08 160.194 198.769 160.194 247.223C160.194 298.095 202.691 336.367 253.976 336.367H375.157Z" fill="#03F7FF" fillOpacity='0' />

							<text fill="white" fillOpacity="0.8" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="32" fontWeight="bold" letterSpacing="0em">
								<tspan x="240" y="254.784">{t(`markets.${GAME_TYPE.SPIN_TO_WIN}.trier`)}</tspan>
							</text>
						</g>

						<g className='zero'>
							<g filter="url(#filter41_ii_7219_18647)">
								<path d="M796.5 335.349H753.5V220.619L796.5 158.5C796.5 158.5 817 162.831 835.5 176C865 196.999 872.5 226 872.5 248C872.5 271.5 860 300.736 836.668 317.738C818.5 330.977 796.5 335.349 796.5 335.349Z" fill="url(#paint40_linear_7219_18647)" />
							</g>
							<path d="M753.5 336.349H752.5V335.349V220.619V220.307L752.678 220.05L795.678 157.931L796.056 157.384L796.707 157.522L796.5 158.5C796.707 157.522 796.707 157.522 796.707 157.522L796.708 157.522L796.712 157.523L796.725 157.525L796.773 157.536C796.814 157.545 796.875 157.558 796.955 157.576C797.114 157.612 797.348 157.667 797.652 157.74C798.258 157.886 799.142 158.11 800.259 158.419C802.492 159.037 805.659 159.998 809.407 161.373C816.898 164.121 826.735 168.533 836.08 175.185C865.913 196.422 873.5 225.773 873.5 248C873.5 271.795 860.867 301.342 837.257 318.546C828.068 325.243 817.928 329.685 810.104 332.451C806.189 333.836 802.844 334.804 800.476 335.426C799.291 335.738 798.35 335.963 797.703 336.11C797.38 336.184 797.13 336.239 796.96 336.275C796.875 336.293 796.81 336.307 796.765 336.316L796.714 336.326L796.701 336.329L796.697 336.33L796.696 336.33C796.695 336.33 796.695 336.33 796.5 335.349L796.695 336.33L796.598 336.349H796.5H753.5Z" stroke="white" strokeOpacity="0.8" strokeWidth="2" />
							<path className='active-color' d="M753.5 336.349H752.5V335.349V220.619V220.307L752.678 220.05L795.678 157.931L796.056 157.384L796.707 157.522L796.5 158.5C796.707 157.522 796.707 157.522 796.707 157.522L796.708 157.522L796.712 157.523L796.725 157.525L796.773 157.536C796.814 157.545 796.875 157.558 796.955 157.576C797.114 157.612 797.348 157.667 797.652 157.74C798.258 157.886 799.142 158.11 800.259 158.419C802.492 159.037 805.659 159.998 809.407 161.373C816.898 164.121 826.735 168.533 836.08 175.185C865.913 196.422 873.5 225.773 873.5 248C873.5 271.795 860.867 301.342 837.257 318.546C828.068 325.243 817.928 329.685 810.104 332.451C806.189 333.836 802.844 334.804 800.476 335.426C799.291 335.738 798.35 335.963 797.703 336.11C797.38 336.184 797.13 336.239 796.96 336.275C796.875 336.293 796.81 336.307 796.765 336.316L796.714 336.326L796.701 336.329L796.697 336.33L796.696 336.33C796.695 336.33 796.695 336.33 796.5 335.349L796.695 336.33L796.598 336.349H796.5H753.5Z" fill="#03F7FF" fillOpacity='0' />
							<text fill="white" fillOpacity="0.8" xmlSpace="preserve" style={{ whiteSpace: "pre" }} fontFamily="DIN Condensed" fontSize="32" fontWeight="bold" letterSpacing="0em">
								<tspan x="790" y="254.784">{t(`markets.${GAME_TYPE.SPIN_TO_WIN}.zero`)}</tspan>
							</text>
						</g>
					</g>

					<defs>

						<filter id="filter0_d_7219_18647" x="0.629868" y="0.836411" width="1032.84" height="492.777" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
							<feOffset />
							<feGaussianBlur stdDeviation="47.4216" />
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7219_18647" />
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7219_18647" result="shape" />
						</filter>

						<linearGradient id="paint0_linear_7219_18647" x1="329.874" y1="100.953" x2="380.064" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint1_linear_7219_18647" x1="282.866" y1="100.953" x2="333.056" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint2_linear_7219_18647" x1="376.897" y1="100.953" x2="427.087" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint3_linear_7219_18647" x1="282.866" y1="340.762" x2="333.056" y2="345.363" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint4_linear_7219_18647" x1="329.874" y1="340.762" x2="380.064" y2="345.363" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint5_linear_7219_18647" x1="423.905" y1="340.762" x2="474.096" y2="345.363" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint6_linear_7219_18647" x1="226.021" y1="339.31" x2="286.809" y2="345.916" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint7_linear_7219_18647" x1="865.099" y1="185.246" x2="945.04" y2="196.191" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint8_linear_7219_18647" x1="226.782" y1="101.079" x2="286.755" y2="107.515" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint9_linear_7219_18647" x1="168.919" y1="104.441" x2="243.015" y2="112.878" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint10_linear_7219_18647" x1="124.69" y1="126.962" x2="209.785" y2="137.796" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint11_linear_7219_18647" x1="100.669" y1="168.229" x2="183.508" y2="180.057" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint12_linear_7219_18647" x1="97.3677" y1="222.68" x2="169.13" y2="232.783" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint13_linear_7219_18647" x1="798.755" y1="104.541" x2="882.092" y2="114.809" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint14_linear_7219_18647" x1="100.802" y1="270.603" x2="183.957" y2="282.655" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint15_linear_7219_18647" x1="124.885" y1="303.148" x2="210.008" y2="314.091" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint16_linear_7219_18647" x1="168.775" y1="327.202" x2="242.647" y2="335.623" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint17_linear_7219_18647" x1="376.897" y1="340.762" x2="427.087" y2="345.363" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint18_linear_7219_18647" x1="470.909" y1="340.762" x2="521.099" y2="345.363" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint19_linear_7219_18647" x1="517.918" y1="340.762" x2="568.108" y2="345.363" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint20_linear_7219_18647" x1="564.926" y1="340.76" x2="615.116" y2="345.361" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint21_linear_7219_18647" x1="611.933" y1="340.76" x2="662.123" y2="345.361" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint22_linear_7219_18647" x1="658.957" y1="340.762" x2="709.147" y2="345.363" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint23_linear_7219_18647" x1="705.965" y1="340.76" x2="756.155" y2="345.361" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint24_linear_7219_18647" x1="753.256" y1="339.46" x2="814.076" y2="346.091" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint25_linear_7219_18647" x1="799.077" y1="324.508" x2="882.115" y2="334.755" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint26_linear_7219_18647" x1="864.693" y1="253.17" x2="945.022" y2="264.295" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint27_linear_7219_18647" x1="837.825" y1="293.91" x2="929.246" y2="305.878" gradientUnits="userSpaceOnUse">
							<stop stopColor="#277640" />
							<stop offset="1" stopColor="#11402C" />
						</linearGradient>
						<linearGradient id="paint28_linear_7219_18647" x1="838.152" y1="132.74" x2="929.461" y2="144.558" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint29_linear_7219_18647" x1="753.234" y1="101.068" x2="813.24" y2="107.525" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint30_linear_7219_18647" x1="705.965" y1="100.953" x2="756.155" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint31_linear_7219_18647" x1="658.957" y1="100.953" x2="709.147" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint32_linear_7219_18647" x1="611.933" y1="100.953" x2="662.123" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint33_linear_7219_18647" x1="564.925" y1="100.953" x2="615.115" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint34_linear_7219_18647" x1="517.918" y1="100.953" x2="568.108" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint35_linear_7219_18647" x1="470.909" y1="100.953" x2="521.099" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#343434" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint36_linear_7219_18647" x1="423.905" y1="100.953" x2="474.096" y2="105.554" gradientUnits="userSpaceOnUse">
							<stop stopColor="#AF1413" />
							<stop offset="1" stopColor="#601111" />
						</linearGradient>
						<linearGradient id="paint37_linear_7219_18647" x1="337.393" y1="175.07" x2="580.532" y2="214.976" gradientUnits="userSpaceOnUse">
							<stop stopColor="#232323" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint38_linear_7219_18647" x1="525.951" y1="174.543" x2="812.102" y2="230.221" gradientUnits="userSpaceOnUse">
							<stop stopColor="#232323" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint39_linear_7219_18647" x1="168.188" y1="175.07" x2="391.609" y2="208.622" gradientUnits="userSpaceOnUse">
							<stop stopColor="#232323" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
						<linearGradient id="paint40_linear_7219_18647" x1="868.61" y1="174.541" x2="742.423" y2="185.047" gradientUnits="userSpaceOnUse">
							<stop stopColor="#232323" />
							<stop offset="1" stopColor="#161616" />
						</linearGradient>
					</defs>
				</svg>
			</div>
		</div>
	)
}
export default RouletteTable