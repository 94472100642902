import { combineReducers } from "redux";

import CommonReducer from "./common/common.reducer";
import AuthReducer from "./auth/auth.reducer";
import GameReducer from "./game/game.reducer";
import BetslipReducer from "./betslip/betslip.reducer";
import TicketsReducer from "./tickets/tickets.reducer";
import BonusesReducer from "./bonuses/bonuses.reducer";
import ReportReducer from "./report/report.reducer";
import ResultsReducer from "./results/results.reducer";
import QRReducer from "./qr/qr.reducer";
import SeasonReducer from "./season/season.reducer";
import TemplatesReducer from "./templates/templates.reducer";
import Spin2WinReducer from "./spin2Win/spin2Win.reducer";

export default () =>
	combineReducers({
		common: CommonReducer,
		auth: AuthReducer,
		game: GameReducer,
		betslip: BetslipReducer,
		tickets: TicketsReducer,
		bonuses: BonusesReducer,
		report: ReportReducer,
		results: ResultsReducer,
		qr: QRReducer,
		season: SeasonReducer,
		templates: TemplatesReducer,
		spin2Win: Spin2WinReducer
	});
