import Pools from "components/jackpotInfo/pools"
import JackpotAnimations from "components/pages/jackpotLiveInfo/animations";
import { useEffect, useRef } from "react"

const MonitorJackpotPools = ({ jackpotInfo, disableAnimation }) => {
	return (
		<div className="vs--monitor-jackpot-pools-container">
			<Pools
				className="vs--monitor-jackpot-pools"
				showLevelIcon={true}
				data={jackpotInfo.levels}
			/>
			<div className="vs--active-jackpot-live-info vs--flex vs--flex-col vs--align-center vs--justify-center">
				<JackpotAnimations
					isLiveMonitor={true}
					getMainContainer={() => document.querySelector(".vs--active-jackpot-live-info")}
					disableAnimation={disableAnimation}
				/>
			</div>
		</div>
	)
}
export default MonitorJackpotPools