import { useTranslation } from "react-i18next";
import { mergeClassNames } from "utils/common.js";

const StatTicket = ({ title, over, under, even, odd, enableExtraSpace, shortName = false }) => {
	const { t } = useTranslation();
	const hasEvenOdd = even && odd;
	const hasOverUnder = over && under;

	return (
		<div className="vs--lucky-six-live-monitor-stat-ticket">
			<span className="vs--lucky-six-live-monitor-stat-ticket-title">{title}</span>

			<div
				className={mergeClassNames(
					"vs--lucky-six-live-monitor-stat-ticket-group",
					enableExtraSpace ? "extra-space" : ""
				)}
			>
				{hasOverUnder && (
					<div className="vs--lucky-six-live-monitor-stat-ticket-group-container">
						<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content">
							<span className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-name">
								{shortName ? t("common.over") : `${t("common.over")} ${over}`}
							</span>

							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-group">
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${over} fill`}
								></div>
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${100 - over}`}
								></div>
							</div>

							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-percent">
								{over}%
							</div>
						</div>

						<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content">
							<span className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-name">
								{shortName ? t("common.under") : `${t("common.under")} ${under}`}
							</span>

							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-group">
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${under} fill`}
								></div>
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${100 - under}`}
								></div>
							</div>
							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-percent">
								{under}%
							</div>
						</div>
					</div>
				)}

				{hasEvenOdd && (
					<div className="vs--lucky-six-live-monitor-stat-ticket-group-container">
						<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content">
							<span className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-name">
								{shortName ? t("common.even") : `${t("common.even")} ${even}`}
							</span>

							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-group">
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${even} fill`}
								></div>
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${100 - even}`}
								></div>
							</div>

							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-percent">
								{even}%
							</div>
						</div>

						<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content">
							<span className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-name">
								{shortName ? t("common.odd") : `${t("common.odd")} ${odd}`}
							</span>

							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-group">
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${odd} fill`}
								></div>
								<div
									className={`vs--lucky-six-live-monitor-stat-ticket-group-container-content-group-line percent-${100 - odd}`}
								></div>
							</div>
							<div className="vs--lucky-six-live-monitor-stat-ticket-group-container-content-percent">
								{odd}%
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default StatTicket;
