import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BALL_POSITION_TO_STAT_ITEM } from "constants/game.constants.js";
import PropTypes from "prop-types";

const ColorFrequency = ({ ball = BALL_POSITION_TO_STAT_ITEM.FIRST, group, colourStatistics }) => {
	const { t } = useTranslation();

	const drawStats = useMemo(() => {
		return Object.keys(colourStatistics).map((key) => ({ value: colourStatistics[key][ball], color: key }));
	}, [colourStatistics, ball]);

	return (
		<div className="vs--lucky-six-live-monitor-color-frequency">
			<span className="vs--lucky-six-live-monitor-color-frequency-title">
				{t(`common.${ball === BALL_POSITION_TO_STAT_ITEM.FIRST ? "first" : "last"}BallColorFrequency`)}
			</span>

			<div className="vs--lucky-six-live-monitor-color-frequency-container">
				{drawStats.map((stat, index) => (
					<div className="vs--lucky-six-live-monitor-color-frequency-container-group" key={index}>
						<div
							className={`vs--lucky-six-live-monitor-color-frequency-container-group-color color-${stat.color} height-${stat.value}`}
						></div>
						<span className="vs--lucky-six-live-monitor-color-frequency-container-group-name">{stat.value}%</span>
					</div>
				))}
			</div>
		</div>
	);
};

ColorFrequency.propTypes = {
	ball: PropTypes.string,
	group: PropTypes.string,
	colourStatistics: PropTypes.array.isRequired
};

export default ColorFrequency;
