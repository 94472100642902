import { useState, Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import ScrollableNavbar from "components/ui/scrollableNavbar";
import Dropdown from "components/ui/dropdown";
import GameButton from "components/ui/buttons/gameButton";

import { getUrlVars } from "utils/common";
import fullScreenAPI from "utils/fullscreen";

import { GAME_TYPE, GAME_TYPE_TEXT_KEYS, GAME_TYPE_ICONS } from "constants/game.constants";

import { getStreamConfiguration } from "store/actions/auth/auth.actions";

import sessionType from "types/session.type";
import playerType from "types/player.type";
import Paths from "constants/path.constants";

/** Report print view page Component */
const LiveMonitorPage = ({ getStreamConfiguration }) => {
	const { t } = useTranslation();
	const [selectedGame, setSelectedGame] = useState(null);
	const [showMoreGames, setShowMoreGames] = useState(false);
	const selectedGameType = selectedGame?.type ?? null;
	const dataQuery = getUrlVars()["data"];
	const data = useMemo(() => {
		return JSON.parse(decodeURIComponent(atob(dataQuery))); 
	}, [dataQuery]);

	const { games } = data;

	/** Function , fires on game button click
	 * @function
	 * @param {object} game - clicked game
	 * @memberOf LiveMonitorPage
	 */
	const handleGameClick = (game) => {
		if (game.type !== selectedGameType) {
			setSelectedGame(game);
		}
	};

	/** Function to enter Fullscreen
	 * @function
	 * @memberOf LiveMonitorPage
	 */
	const enterFullscreen = () => {
		const element = document.getElementsByClassName("vs--stream-content-iframe")[0];
		fullScreenAPI.toggle(element);
	};

	/** Function to exit Fullscreen
	 * @function
	 * @memberOf LiveMonitorPage
	 */
	const exitFullscreen = () => {
		fullScreenAPI.toggle();
	};

	/** Function fires on fullscreen button click
	 * @function
	 * @memberOf LiveMonitorPage
	 */
	const onFullscreen = () => {
		if (!fullScreenAPI.isFullscreen) {
			enterFullscreen();
		} else {
			exitFullscreen();
		}
	};

	return (
		<>
			<div className="vs--stream vs--flex vs--flex-col">
				<span className="vs--title vs--stream-title vs--font-medium vs--mb-16">{t("cashier.gameLiveMonitor")}</span>
				<div className="vs--stream-content vs--flex-equal" data-game={selectedGameType}>
					{selectedGameType !== null ? (
						<div className="vs--stream-content-iframe">
							<iframe className="vs--stream-content-iframe-element" src={`${Paths.LIVE_MONITOR}/${selectedGameType}?data=${dataQuery}`} allowFullScreen />
							<div className="vs--stream-content-iframe-controls">
								<div className="vs--stream-content-iframe-controls-inner vs--flex vs--flex-row vs--align-center vs--justify-end vs--pl-8 vs--pr-8">
									<div className="vs--flex vs--flex-row vs--align-center">
										<div className="vs--stream-content-iframe-control" onClick={onFullscreen} data-action="fullscreen">
											<i className="ic_video-fullscreen" title={t("common.fullscreen")} />
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="vs--stream-content-empty vs--flex vs--flex-col vs--justify-center vs--align-center">
							<i className="ic_stream" />
							<span className="vs--title vs--font-regular vs--font-smallest vs--mt-16 vs--text-center">{t("cashier.streamText")}</span>
						</div>
					)}
				</div>
			</div>
			<footer className="vs--stream-footer">
				<ScrollableNavbar
					wrapperClassName="vs--stream-games"
					containerClassName="vs--mt-24 vs--mb-24"
					innerClassName="vs--stream-games-tabs vs--flex vs--flex-row"
					elements={games}
					keyFieldName="id"
					isActiveChecking={(game) => selectedGameType === game.type}
					onContentFullyFit={(isContentFullyFit) => {
						setShowMoreGames(!isContentFullyFit);
					}}
					renderFunction={(game) => {
						return (
							<GameButton className="vs--stream-games-tabs-btn" onClick={() => handleGameClick(game)} selected={selectedGameType === game.type}>
								<i className={`${GAME_TYPE_ICONS[game.type] || ""} vs--stream-games-tabs-btn-icon`} />
								<span className="vs--stream-games-tabs-btn-text">{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}</span>
							</GameButton>
						);
					}}
					startFromFirstElement
				/>
				{showMoreGames ? (
					<Dropdown className="vs--stream-game-nav-more vs--mt-8 vs--mb-8 vs--ml-4 vs--mr-4" content={<i className="ic_more" />} childrenContainerClassname="vs--stream-dropdown">
						<Fragment>
							{games.map((game) => {
								return (
									<div key={game.type} className={"vs--stream-dropdown-item" + (selectedGameType === game.type ? " vs--dropdown-children-active" : "") + " vs--flex vs--align-center vs--justify-start"} onClick={() => handleGameClick(game)}>
										<i className={`${GAME_TYPE_ICONS[game.type]} vs--dropdown-children-icon`} />
										<span className="vs--font-smallest vs--font-regular vs--ml-10 vs--dropdown-children-text">{t(`common.${GAME_TYPE_TEXT_KEYS[game.type]}`)}</span>
									</div>
								);
							})}
						</Fragment>
					</Dropdown>
				) : null}
			</footer>
		</>
	);
};

/** LiveMonitorPage propTypes
 * PropTypes
 */
LiveMonitorPage.propTypes = {
	/** Redux state property, the object of stream */
	stream: PropTypes.object,
	/** Redux state property, Games */
	games: PropTypes.array,
	/** Redux action to update stream configuration */
	getStreamConfiguration: PropTypes.func,
	state: PropTypes.any,
	/** Redux state property, current session */
	session: sessionType,
	/** Redux state property, current authenticated user */
	player: playerType
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
	getStreamConfiguration: (id, callback) => {
		dispatch(getStreamConfiguration(id, callback));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveMonitorPage);
