import { useDispatch, useSelector } from "react-redux";
import { setHoveredNumbers } from "store/actions/spin2Win/spin2Win.actions.js";
import Spin2WinBoardNumbers from "components/pages/main/markets/spin2Win/topBoard/numbers.jsx";
import runMarketUtilsFunction from "utils/markets/run.js";
import { doBet } from "store/actions/betslip/betslip.actions.jsx";
import BasketMarkets from "components/pages/main/markets/spin2Win/topBoard/basketMarkets.jsx";
import HighLowMarkets from "components/pages/main/markets/spin2Win/topBoard/highLowMarkets.jsx";
import DozenMarkets from "components/pages/main/markets/spin2Win/topBoard/dozenMarkets.jsx";
import EvenOddAndColorMarkets from "components/pages/main/markets/spin2Win/topBoard/evenOddAndColorMarkets.jsx";
import ColumnMarkets from "components/pages/main/markets/spin2Win/topBoard/columnMarkets.jsx";

const TopBoard = ({ markets, eventInfo, isOddDisabled }) => {
	const dispatch = useDispatch();

	const currentGameType = useSelector((state) => state.game.currentGameType);
	const bets = useSelector((state) => state.betslip.bets);

	const handleMarketHover = (numbers) => {
		dispatch(setHoveredNumbers(numbers));
	};

	const handleOddClick = (market, odd) => {
		const group = runMarketUtilsFunction(
			"makeGroupTitle",
			[
				{
					group: market.group,
					argument: market.argument,
					gameData: eventInfo?.gameData ?? {},
					gameType: currentGameType
				}
			],
			currentGameType
		);

		dispatch(doBet(odd, group, eventInfo));
	};

	return (
		<div className="vs--roulete">
			<BasketMarkets
				markets={markets}
				handleOddClick={handleOddClick}
				handleMarketHover={handleMarketHover}
				bets={bets}
				isOddDisabled={isOddDisabled}
			/>

			<div>
				<HighLowMarkets
					markets={markets}
					handleOddClick={handleOddClick}
					handleMarketHover={handleMarketHover}
					bets={bets}
					isOddDisabled={isOddDisabled}
				/>

				<Spin2WinBoardNumbers
					markets={markets}
					handleOddClick={handleOddClick}
					handleMarketHover={handleMarketHover}
					bets={bets}
					isOddDisabled={isOddDisabled}
				/>

				<DozenMarkets
					markets={markets}
					handleOddClick={handleOddClick}
					handleMarketHover={handleMarketHover}
					bets={bets}
					isOddDisabled={isOddDisabled}
				/>

				<EvenOddAndColorMarkets
					markets={markets}
					handleOddClick={handleOddClick}
					handleMarketHover={handleMarketHover}
					bets={bets}
					isOddDisabled={isOddDisabled}
				/>
			</div>

			<ColumnMarkets
				markets={markets}
				handleOddClick={handleOddClick}
				handleMarketHover={handleMarketHover}
				bets={bets}
				isOddDisabled={isOddDisabled}
			/>
		</div>
	);
};

export default TopBoard;
