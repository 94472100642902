import useEvent from "hooks/useEvent"
import useForceUpdate from "hooks/useForceUpdate"
import useUpdateEffect from "hooks/useUpdateEffect"
import { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { isNullish } from "utils/common"

const defaultProps = {
	defaultItemKey: null
}

const useSceneFlowProcess = ({ defaultItemKey, imperativeHandlerRef } = defaultProps) => {
	const [scenes, setScenes] = useState([])
	const currentMountingRef = useRef(defaultItemKey ?? "none");

	const addKey = useEvent((key) => {
		setScenes(prevScenes => {
			return [...prevScenes, { key, show: !isNullish(defaultItemKey) && defaultItemKey === key }]
		})
	})

	const removeKey = useEvent((key) => {
		setScenes(prevScenes => {
			return prevScenes.filter(scene => scene.key !== key)
		})
	})

	const changeSceneByKey = useEvent((key) => {
		currentMountingRef.current = key;
		setScenes(prevScenes => prevScenes.map(scene => {
			if (scene.key === key) {
				return {
					...scene,
					show: true
				}
			}
			return scene
		}))
	})

	const sceneIsMounted = useEvent((key) => {
		currentMountingRef.current = "none";
		setScenes(prevScenes => prevScenes.map(scene => {
			if (scene.key !== key) {
				return {
					...scene,
					show: false
				}
			}
			return scene
		}))
	})

	const providerValue = useMemo(() => {
		return {
			scenes, addKey, removeKey, changeSceneByKey, sceneIsMounted, mountingScene: currentMountingRef.current
		}
	}, [scenes, addKey, removeKey, changeSceneByKey, sceneIsMounted])

	useImperativeHandle(imperativeHandlerRef, () => {
		return providerValue;
	}, [providerValue]);

	return providerValue
}

export default useSceneFlowProcess