import BulkSelectionButton from "components/pages/main/markets/spin2Win/topBoard/bulkSelectionButton.jsx";
import { SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants.js";
import { SPIN_TO_WIN_MARKET_GROUPS } from "constants/market.constants.js";
import { useTranslation } from "react-i18next";
import { mergeClassNames } from "utils/common";

const EvenOddAndColorMarkets = ({ markets, handleOddClick, handleMarketHover, bets, isOddDisabled }) => {
	const { t } = useTranslation();

	const evenOddMarket = markets.find((market) => market.group === SPIN_TO_WIN_MARKET_GROUPS.EVEN_ODD);
	const evenOddMarketOdds = evenOddMarket?.odds[0] || [];

	const colorMarket = markets.find((market) => market.group === SPIN_TO_WIN_MARKET_GROUPS.RED_BLACK);
	const colorMarketOdds = colorMarket?.odds[0] || [];

	const isOddSelected = bets.some((bet) => bet.oddId === evenOddMarketOdds[0]?.id);
	const isEvenSelected = bets.some((bet) => bet.oddId === evenOddMarketOdds[1]?.id);

	const isRedSelected = bets.some((bet) => bet.oddId === colorMarketOdds[0]?.id);
	const isBlackSelected = bets.some((bet) => bet.oddId === colorMarketOdds[1]?.id);

	return (
		<div className="vs--roulete-select-numbers">
			<BulkSelectionButton
				className={isEvenSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(evenOddMarket, evenOddMarketOdds[1])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.EVEN_NUMBERS}
				text={t("common.even")}
				subText={evenOddMarketOdds[1]?.factor ? `x${evenOddMarketOdds[1].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={mergeClassNames("vs--roulete-black", isBlackSelected && "selected")}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(colorMarket, colorMarketOdds[1])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.BLACK_NUMBERS}
				text={t("common.black")}
				subText={colorMarketOdds[1]?.factor ? `x${colorMarketOdds[1].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={mergeClassNames("vs--roulete-red", isRedSelected && "selected")}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(colorMarket, colorMarketOdds[0])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.RED_NUMBERS}
				text={t("common.red")}
				subText={colorMarketOdds[0]?.factor ? `x${colorMarketOdds[0].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
			<BulkSelectionButton
				className={isOddSelected ? "selected" : ""}
				handleMarketHover={handleMarketHover}
				handleOddClick={() => handleOddClick(evenOddMarket, evenOddMarketOdds[0])}
				selectingNumbers={SPIN_TO_WIN_MARKETS_NUMBERS.ODD_NUMBERS}
				text={t("common.odd")}
				subText={evenOddMarketOdds[0]?.factor ? `x${evenOddMarketOdds[0].factor}` : ""}
				isOddDisabled={isOddDisabled}
			/>
		</div>
	);
};

export default EvenOddAndColorMarkets;
