import PropTypes from "prop-types";
import { mergeClassNames } from "utils/common";
import { useEffect, useMemo, useState } from "react";
import { ProgressBarColor } from "components/progressBar/configs";

import "./styles.scss";

const ProgressBar = ({ progressData }) => {
	const [progressMappedData, setProgressMappedData] = useState(
		progressData.map((item) => ({ ...item, percentage: null }))
	);

	const firstItem = useMemo(() => {
		return progressMappedData.at(0) || null;
	}, [progressMappedData]);

	const lastItem = useMemo(() => {
		return progressMappedData.length > 1 ? progressMappedData.at(-1) : null;
	}, [progressMappedData]);

	const centralItems = useMemo(() => {
		return progressMappedData.slice(1, -1) || null;
	}, [progressMappedData]);

	useEffect(() => {
		setProgressMappedData(progressData.map((item) => ({ ...item, percentage: null })));
	}, [progressData]);

	useEffect(() => {
		setTimeout(() => {
			setProgressMappedData((progressDataPrev) => {
				return progressDataPrev.map((item) => ({
					...item,
					percentage: Math.floor((item.value / item.maxValue) * 100)
				}));
			});
		}, 3000);
	}, []);

	return (
		<div className="vs--live-monitor-progress-bar">
			{firstItem && (
				<div className="vs--live-monitor-progress-bar-group">
					<span className="vs--live-monitor-progress-bar-group-name">{firstItem.name}</span>

					<div
						className={mergeClassNames(
							"vs--live-monitor-progress-bar-group-item",
							"left-to-right",
							"left-border-radius",
							lastItem === null ? "right-border-radius" : "left-border",
							firstItem.color || ""
						)}
					>
						<div className={mergeClassNames(
							"vs--live-monitor-progress-bar-group-item-fill",
							firstItem.percentage ? `fill-${firstItem.percentage}` : "",
						)}></div>
						
						{firstItem.percentage && (
							<span className="vs--live-monitor-progress-bar-group-item-text">{firstItem.percentage}%</span>
						)}
					</div>
				</div>
			)}
			
			{centralItems.map((item, index) => (
				<div key={index} className="vs--live-monitor-progress-bar-group central">
					<span className="vs--live-monitor-progress-bar-group-name center">{item.name}</span>

					<div
						className={mergeClassNames(
							"vs--live-monitor-progress-bar-group-item",
							"central",
							item.color || ""
						)}
					>	

						{item.percentage && (
							<span className="vs--live-monitor-progress-bar-group-item-text">{item.percentage}%</span>
						)}
					</div>
				</div>
			))}

			{lastItem && (
				<div className="vs--live-monitor-progress-bar-group">
					<span className="vs--live-monitor-progress-bar-group-name right">{lastItem.name}</span>
					<div
						className={mergeClassNames(
							"vs--live-monitor-progress-bar-group-item",
							"right-to-left",
							"right-border-radius",
							"right-border",
							lastItem.color || ""
						)}
					>
						<div className={mergeClassNames(
							"vs--live-monitor-progress-bar-group-item-fill",
							lastItem.percentage ? `fill-${lastItem.percentage}` : "",
						)}></div>

						{lastItem.percentage && (
							<span className="vs--live-monitor-progress-bar-group-item-text">{lastItem.percentage}%</span>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

ProgressBar.propTypes = {
	progressData: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			maxValue: PropTypes.number.isRequired,
			color: PropTypes.oneOf(Object.values(ProgressBarColor)),
			name: PropTypes.string.isRequired
		})
	).isRequired
};

export default ProgressBar;
