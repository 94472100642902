import ProgressBarStatisticsBackground from "./progress-bar-statistics-background";
import ProgressBar from "components/progressBar";
import { ProgressBarColor } from "components/progressBar/configs";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { mergeClassNames } from "utils/common";
import { GAME_TYPE, SPIN_TO_WIN_MARKETS_NUMBERS } from "constants/game.constants";
import { useTranslation } from "react-i18next";

const ProgressBarStatistics = () => {
	const {
		lowNumberCount,
		highNumberCount,
		totalElectedCount,
		secondDozenCount,
		thirdDozenCount,
		firstDozenCount,
		redCellCount,
		blackCellCount,
		greenCellCount,
		evenNumberCount,
		oddNumberCount,
		thirdColumnCount,
		secondColumnCount,
		firstColumnCount,
		lastWinNumbers
	} = useSelector((state) => state.game.spinToWinStatistics);

	const { t } = useTranslation()

	const lowToHigh = useMemo(
		() =>
			lowNumberCount && highNumberCount
				? [
						{ value: lowNumberCount, maxValue: totalElectedCount, name: t(`markets.${GAME_TYPE.SPIN_TO_WIN}.low`) },
						{ value: highNumberCount, maxValue: totalElectedCount, name: t(`markets.${GAME_TYPE.SPIN_TO_WIN}.high`) }
					]
				: null,
		[lowNumberCount, highNumberCount, totalElectedCount]
	);

	const firstDozen = useMemo(
		() => (firstDozenCount ? [{ value: firstDozenCount, maxValue: totalElectedCount, name: `1-12 ${t(`markets.${GAME_TYPE.SPIN_TO_WIN}.dozen`)}` }] : null),
		[firstDozenCount, totalElectedCount]
	);

	const secondDozen = useMemo(
		() => (secondDozenCount ? [{ value: secondDozenCount, maxValue: totalElectedCount, name: `13-24 ${t(`markets.${GAME_TYPE.SPIN_TO_WIN}.dozen`)}` }] : null),
		[secondDozenCount, totalElectedCount]
	);

	const thirdDozen = useMemo(
		() => (thirdDozenCount ? [{ value: thirdDozenCount, maxValue: totalElectedCount, name: `25-36 ${t(`markets.${GAME_TYPE.SPIN_TO_WIN}.dozen`)}` }] : null),
		[thirdDozenCount, totalElectedCount]
	);

	const red0Black = useMemo(
		() =>
			redCellCount && greenCellCount && blackCellCount
				? [
						{ value: redCellCount, maxValue: totalElectedCount, color: ProgressBarColor.RED, name: t(`common.red`) },
						{ value: greenCellCount, maxValue: totalElectedCount, color: ProgressBarColor.GREEN, name: "0" },
						{ value: blackCellCount, maxValue: totalElectedCount, color: ProgressBarColor.BLACK, name: t(`common.black`) }
					]
				: null,
		[redCellCount, greenCellCount, blackCellCount, totalElectedCount]
	);

	const evenOdd = useMemo(
		() =>
			oddNumberCount && evenNumberCount
				? [
						{ value: evenNumberCount, maxValue: totalElectedCount, name: t(`common.even`) },
						{ value: oddNumberCount, maxValue: totalElectedCount, name: t(`common.odd`) }
					]
				: null,
		[evenNumberCount, oddNumberCount, totalElectedCount]
	);

	const thirdColumn = useMemo(
		() => (thirdColumnCount ? [{ value: thirdColumnCount, maxValue: totalElectedCount, name: `${t("common.3rd")} ${t("common.column")}` }] : null),
		[thirdColumnCount, totalElectedCount]
	);

	const secondColumn = useMemo(
		() =>
			secondColumnCount ? [{ value: secondColumnCount, maxValue: totalElectedCount, name: `${t("common.2nd")} ${t("common.column")}` }] : null,
		[secondColumnCount, totalElectedCount]
	);

	const firstColumn = useMemo(
		() => (firstColumnCount ? [{ value: firstColumnCount, maxValue: totalElectedCount, name: `${t("common.1st")} ${t("common.column")}` }] : null),
		[firstColumnCount, totalElectedCount]
	);

	return (
		<div className="vs--spin-2-win-stream-progress-bar-statistics-container">
			<div className="vs--spin-2-win-stream-progress-bar-statistics-container-group">
				<ProgressBarStatisticsBackground />

				<div className="vs--spin-2-win-stream-progress-bar-statistics-container-left">
					<div className="vs--spin-2-win-stream-progress-bar-statistics-container-left-group">
						{lowToHigh && <ProgressBar progressData={lowToHigh} />}
						{firstDozen && <ProgressBar progressData={firstDozen} />}
						{secondDozen && <ProgressBar progressData={secondDozen} />}
						{secondDozen && <ProgressBar progressData={thirdDozen} />}

						{lastWinNumbers.length > 0 && (
							<div className="vs--spin-2-win-stream-progress-bar-statistics-container-left-group-item">
								<span className="vs--spin-2-win-stream-progress-bar-statistics-container-left-group-item-name">
									{t("statistics.last{count}Matches").replace("{count}", 10)}
								</span>
								<div className="vs--spin-2-win-stream-progress-bar-statistics-container-left-group-item-elements">
									{lastWinNumbers.map((item, index) => (
										<div
											className={mergeClassNames(
												"vs--spin-2-win-stream-progress-bar-statistics-container-left-group-item-elements-item",
												SPIN_TO_WIN_MARKETS_NUMBERS.RED_NUMBERS.includes(item) ? "red" : "",
												SPIN_TO_WIN_MARKETS_NUMBERS.BLACK_NUMBERS.includes(item) ? "black" : "",
												item === 0 ? "green" : ""
											)}
											key={index}
										>
											{item}
										</div>
									))}
								</div>
							</div>
						)}
					</div>
				</div>

				<div className="vs--spin-2-win-stream-progress-bar-statistics-container-right">
					<div className="vs--spin-2-win-stream-progress-bar-statistics-container-right-group">
						{red0Black && <ProgressBar progressData={red0Black} />}
						{evenOdd && <ProgressBar progressData={evenOdd} />}
						{thirdColumn && <ProgressBar progressData={thirdColumn} />}
						{secondColumn && <ProgressBar progressData={secondColumn} />}
						{firstColumn && <ProgressBar progressData={firstColumn} />}
					</div>
				</div>
			</div>
		</div>
	);
};
export default ProgressBarStatistics;
