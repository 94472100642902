import {
	LIVE_AND_UPCOMINGS_BEFORE,
	LIVE_AND_UPCOMINGS_AFTER,
	SET_LIVE_AND_UPCOMINGS,
	SET_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	REMOVE_LIVE_AND_UPCOMINGS,
	REMOVE_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	UPDATE_LIVE_AND_UPCOMINGS,
	UPDATE_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	ADD_LIVE_AND_UPCOMINGS,
	ADD_LIVE_AND_UPCOMINGS_LIVE_MONITOR,
	LAST_RESULTS_BEFORE,
	LAST_RESULTS_AFTER,
	SET_LAST_RESULTS,
	ADD_LAST_RESULT,
	EVENT_BEFORE,
	EVENT_AFTER,
	SET_EVENT,
	SET_CURRENT_EVENT,
	UPDATE_EVENT,
	UPDATE_EVENT_LIVE_MONITOR,
	SET_CURRENT_GAME,
	SET_LUCKY_SIX_STATISTICS,
	SET_SPIN_TO_WIN_STATISTICS
} from "../../actionTypes";
import { isCupGame, isSimpleCupGame, isLeg2, getOrderNumbersOfCupGameWaitableRounds } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";
import { GAME_STATUSES, GAME_EVENT_TYPE } from "constants/game.constants";
import { isChampionsCupGame } from "../../../utils/common";

const getCurrentRound = (state) => {
	return state.liveAndUpcomings.data.find((round) => round.id === state.current);
};

export default (state = {}, { type, payload }) => {
	switch (type) {
		case LIVE_AND_UPCOMINGS_BEFORE:
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					loading: true
				}
			};
		case LIVE_AND_UPCOMINGS_AFTER:
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					loading: false
				}
			};

		case SET_LIVE_AND_UPCOMINGS:
			let currentMatch = null;
			if (
				!(
					isSimpleCupGame(state.currentGameType) ||
					(isChampionsCupGame(state.currentGameType) && isLeg2(getCurrentRound(state)?.orderNumber ?? null))
				)
			) {
				currentMatch = payload.data.find(
					(d) => ![GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(d.status)
				);
			} else {
				const waitableOrderNumbers = getOrderNumbersOfCupGameWaitableRounds(state.currentGameType);
				currentMatch = payload.data.find(
					(d) =>
						![GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(d.status) ||
						waitableOrderNumbers.includes(d.orderNumber)
				);
			}

			currentMatch = currentMatch ? currentMatch : payload.data[0];

			return {
				...state,
				current: currentMatch ? currentMatch.id : null,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: [...payload.data]
				}
			};
		case SET_LIVE_AND_UPCOMINGS_LIVE_MONITOR:
			let currentMatchLiveMonitor = payload.data.find((ev) => ev.status !== GAME_STATUSES.CANCELED);
			currentMatchLiveMonitor = currentMatchLiveMonitor ? currentMatchLiveMonitor : payload.data[0];

			return {
				...state,
				current: currentMatchLiveMonitor ? currentMatchLiveMonitor.id : null,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: [...payload.data]
				}
			};
		case REMOVE_LIVE_AND_UPCOMINGS:
			const filtered = state.liveAndUpcomings.data.filter((d) => payload.id !== d.id);
			let nextGame = null;
			if (
				!(
					isSimpleCupGame(state.currentGameType) ||
					(isChampionsCupGame(state.currentGameType) && isLeg2(getCurrentRound(state)?.orderNumber ?? null))
				)
			) {
				nextGame = filtered.find(
					(d) => ![GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(d.status)
				);
			} else {
				const waitableOrderNumbers = getOrderNumbersOfCupGameWaitableRounds(state.currentGameType);
				nextGame = filtered.find(
					(d) =>
						![GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(d.status) ||
						waitableOrderNumbers.includes(d.orderNumber)
				);
			}

			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: filtered
				},
				current:
					payload.id === state.current ||
					filtered
						.filter((d) => d.status === GAME_STATUSES.STARTED || d.status === GAME_STATUSES.CLOSE_FOR_BETTING)
						.map((d) => d.id)
						.includes(state.current)
						? nextGame
							? nextGame.id
							: state.current
						: state.current,
				matches: {
					...state.matches,
					data: state.matches.data.filter((m) => m.id !== payload.id)
				}
			};

		case REMOVE_LIVE_AND_UPCOMINGS_LIVE_MONITOR:
			const fn = payload.lessAndEqual ? (d) => payload.id <= d.id : (d) => payload.id < d.id;
			const filteredLiveMonitor = state.liveAndUpcomings.data.filter(fn);
			const nextGameId = filteredLiveMonitor[0]?.id || null;
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: filteredLiveMonitor
				},
				current: nextGameId,
				matches: {
					...state.matches,
					data: state.matches.data.filter((m) => m.id !== payload.id)
				}
			};

		case UPDATE_LIVE_AND_UPCOMINGS:
			const updated = state.liveAndUpcomings.data
				.map((d) => (payload.game.id !== d.id ? d : payload.game))
				.filter((d) => d.id >= payload.game.id);

			let nextCurrent = null;

			if (
				!(
					isSimpleCupGame(state.currentGameType) ||
					(isChampionsCupGame(state.currentGameType) && isLeg2(getCurrentRound(state)?.orderNumber ?? null))
				)
			) {
				nextCurrent = updated.find((d) => ![GAME_STATUSES.STARTED].includes(d.status));
			} else {
				const waitableOrderNumbers = getOrderNumbersOfCupGameWaitableRounds(state.currentGameType);
				nextCurrent = updated.find(
					(d) =>
						![GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING].includes(d.status) ||
						waitableOrderNumbers.includes(d.orderNumber)
				);
			}

			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: updated
				},
				current: nextCurrent ? nextCurrent.id : state.current
			};
		case UPDATE_LIVE_AND_UPCOMINGS_LIVE_MONITOR:
			const updated_liveMonitor = state.liveAndUpcomings.data.map((d) =>
				payload.game.id === d.id ? payload.game : d
			);
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: updated_liveMonitor
				},
				current: state.current ? state.current : updated_liveMonitor[0]?.id || null
			};
		case ADD_LIVE_AND_UPCOMINGS:
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: [...state.liveAndUpcomings.data, payload.game]
				}
			};
		case ADD_LIVE_AND_UPCOMINGS_LIVE_MONITOR:
			return {
				...state,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: [...state.liveAndUpcomings.data, payload.game]
				},
				current: state.current ? state.current : payload.game.id
			};
		case LAST_RESULTS_BEFORE:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					loading: true
				}
			};
		case LAST_RESULTS_AFTER:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					loading: false
				}
			};
		case SET_LAST_RESULTS:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					data: payload.data
				}
			};
		case ADD_LAST_RESULT:
			return {
				...state,
				lastResults: {
					...state.lastResults,
					data: [payload.result, ...state.lastResults.data].slice(0, 5)
				}
			};
		case EVENT_BEFORE:
			return {
				...state,
				matches: {
					...state.matches,
					loading: true
				}
			};
		case EVENT_AFTER:
			return {
				...state,
				matches: {
					...state.matches,
					loading: false
				}
			};
		case SET_EVENT:
			return {
				...state,
				matches: {
					...state.matches,
					data: [
						...state.matches.data,
						{
							id: payload.data.id,
							event: payload.data,
							markets:
								[GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type) &&
								payload.data.markets
									? runMarketUtilsFunction(
											"makeAllMarkets",
											[payload.data.markets, payload.data.gameData, payload.rtps, state.currentGameType],
											state.currentGameType
										)
									: {}
						}
					]
				}
			};
		case SET_CURRENT_EVENT:
			return {
				...state,
				current: payload.id
			};
		case UPDATE_EVENT:
			let newData = [...state.matches.data].map((m) => {
				return m.id !== payload.id
					? m
					: {
							...m,
							event: {
								...m.event,
								status: payload.data.status,
								gameData: payload.data.gameData,
								markets: payload.data.markets
							},
							markets:
								payload.data.type === GAME_EVENT_TYPE.WEEK && isCupGame(payload.data.gameType)
									? runMarketUtilsFunction(
											"makeAllMarkets",
											[payload.data.markets, payload.data.gameData, payload.rtps, state.currentGameType],
											state.currentGameType
										)
									: m.markets
						};
			});

			if (payload.data.type === GAME_EVENT_TYPE.EVENT) {
				newData = newData.map((ev) => {
					if (ev.event && ev.event.events) {
						return {
							...ev,
							event: {
								...ev.event,
								events: ev.event.events.map((e) =>
									e.id === payload.id
										? {
												...payload.data,
												markets: isCupGame(payload.data.gameType) ? payload.data.markets : e.markets,
												marketsCount: isCupGame(payload.data.gameType)
													? payload.data.marketsCount
													: e.marketsCount
											}
										: e
								)
							}
						};
					}
					return ev;
				});
			}
			return {
				...state,
				matches: {
					...state.matches,
					data: newData
				}
			};
		case UPDATE_EVENT_LIVE_MONITOR:
			let newData_liveMonitor = [...state.matches.data].map((m) => {
				return m.id !== payload.id
					? m
					: {
							...m,
							event: {
								...m.event,
								status: payload.data.status,
								gameData: payload.data.gameData,
								markets: payload.data.markets,
								events: payload.updateMarkets ? payload.data.events : m.event.events
							},
							markets:
								payload.data.type === GAME_EVENT_TYPE.WEEK && isCupGame(payload.data.gameType)
									? runMarketUtilsFunction(
											"makeAllMarkets",
											[payload.data.markets, payload.data.gameData, payload.rtps, state.currentGameType],
											state.currentGameType
										)
									: m.markets
						};
			});

			if (payload.data.type === GAME_EVENT_TYPE.EVENT) {
				newData_liveMonitor = newData_liveMonitor.map((ev) => {
					if (ev.event && ev.event.events) {
						return {
							...ev,
							event: {
								...ev.event,
								events: ev.event.events.map((e) =>
									e.id === payload.id
										? {
												...payload.data,
												markets: payload.updateMarkets ? payload.data.markets : e.markets,
												marketsCount: payload.updateMarkets ? payload.data.marketsCount : e.marketsCount
											}
										: e
								)
							}
						};
					}
					return ev;
				});
			}
			return {
				...state,
				matches: {
					...state.matches,
					data: newData_liveMonitor
				}
			};
		case SET_CURRENT_GAME:
			return {
				...state,
				currentGameType: payload.type,
				liveAndUpcomings: {
					...state.liveAndUpcomings,
					data: []
				},
				lastResults: {
					...state.lastResults,
					data: []
				},
				current: null
			};
		case SET_LUCKY_SIX_STATISTICS:
			return {
				...state,
				lucySixStatistics: payload.data
			};
		case SET_SPIN_TO_WIN_STATISTICS:
			return {
				...state,
				spinToWinStatistics: {
					...state.spinToWinStatistics,
					...payload.data
				}
			};
		default:
			return state;
	}
};
