/**
 * @typedef {Object} BetOptions
 * @property {number} [count=1] - Number of bets to generate (if applicable).
 * @property {boolean} [isAmerican=false] - Whether to use American roulette rules.
 */

const rouletteBetsRNG = {
	/**
	 * Generates unique straight-up bets.
	 * @param {BetOptions} [options={}] - Bet options.
	 * @returns {Array<[string]>} - Array of unique straight-up numbers.
	 */
	straight: ({ count = 1, isAmerican = false } = {}) => {
		const numbers = Array.from({ length: 37 }, (_, i) => String(i));

		if (isAmerican) {
			numbers.push("00");
		}

		if (count > numbers.length) {
			throw new Error(`Cannot generate more than ${numbers.length} unique numbers.`);
		}

		return numbers.sort(() => Math.random() - 0.5).slice(0, count).map(num => [num]);
	},

	/**
	 * Generates unique corner bets.
	 * @param {BetOptions} [options={}] - Bet options.
	 * @returns {Array<[string, string, string, string]>} - Array of unique corner bet sets.
	 */
	corner: ({ count = 1 } = {}) => {
		const board = [
			[3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36],
			[2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35],
			[1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34]
		];
		const corners = [];

		for (let i = 0; i < board[0].length - 1; i++) {
			corners.push([
				String(board[2][i]), String(board[2][i + 1]),
				String(board[1][i]), String(board[1][i + 1])
			]);
			corners.push([
				String(board[1][i]), String(board[1][i + 1]),
				String(board[0][i]), String(board[0][i + 1])
			]);
		}

		return corners.sort(() => Math.random() - 0.5).slice(0, count);
	},

	/**
	 * Generates unique split bets.
	 * @param {BetOptions} [options={}] - Bet options.
	 * @returns {Array<[string, string]>} - Array of unique split bet pairs.
	 */
	split: ({ count = 1, isAmerican = false } = {}) => {
		const numbers = Array.from({ length: 36 }, (_, i) => String(i + 1));

		if (isAmerican) {
			numbers.push("00");
			numbers.push("0");
		}

		const validSplits = [];

		numbers.forEach((num) => {
			const n = Number(num);

			if (!isNaN(n)) {
				if (n % 3 !== 0) {
					validSplits.push([num, String(n + 1)]);
				}

				if (n <= 33) {
					validSplits.push([num, String(n + 3)]);
				}
			}
		});

		if (isAmerican) {
			validSplits.push(["00", "2"]);
		}

		return validSplits.sort(() => Math.random() - 0.5).slice(0, count);
	},

	/**
	 * Generates unique trio bets.
	 * @param {BetOptions} [options={}] - Bet options.
	 * @returns {Array<[string, string, string]>} - Array of unique trio bet triplets.
	 */
	trio: ({ count = 1, isAmerican = false } = {}) => {
		let trios;

		if (isAmerican) {
			trios = [["0", "1", "2"], ["0", "2", "3"], ["00", "2", "3"], ["0", "00", "2"]];
		} else {
			trios = [["0", "1", "2"], ["0", "2", "3"]];
		}

		return trios.sort(() => Math.random() - 0.5).slice(0, count);
	},

	/**
	 * Generates unique street bets.
	 * @param {BetOptions} [options={}] - Bet options.
	 * @returns {Array<[string, string, string]>} - Array of unique street bet triplets.
	 */
	street: ({ count = 1, isAmerican = false } = {}) => {
		const streets = [];

		for (let i = 0; i < 12; i++) {
			streets.push([String(i * 3 + 1), String(i * 3 + 2), String(i * 3 + 3)]);
		}

		if (isAmerican) {
			streets.push(["0", "00", "2"]);
			streets.push(["00", "2", "3"]);
		}

		return streets.sort(() => Math.random() - 0.5).slice(0, count);
	},

	/**
	 * Generates unique double street bets.
	 * @param {BetOptions} [options={}] - Bet options.
	 * @returns {Array<[string, string, string, string, string, string]>} - Array of unique double street bet sets.
	 */
	doublestreet: ({ count = 1 } = {}) => {
		const doubleStreets = [];

		for (let i = 0; i < 11; i++) {
			doubleStreets.push([
				String(i * 3 + 1), String(i * 3 + 2), String(i * 3 + 3),
				String(i * 3 + 4), String(i * 3 + 5), String(i * 3 + 6)
			]);
		}

		return doubleStreets.sort(() => Math.random() - 0.5).slice(0, count);
	},

	/**
	 * Returns basket bet numbers.
	 * @param {BetOptions} [options={}] - Bet options.
	 * @returns {Array<[string, string, string, string, string]>} - The basket bet numbers.
	 */
	basket: ({ isAmerican = false } = {}) => {
		if (isAmerican) {
			return [["0", "00", "1", "2", "3"]];
		}

		return [["0", "1", "2", "3"]];
	},

	/**
	 * Returns Trier bet numbers.
	 * @returns {Array<[string[]]>} - The Trier bet numbers.
	 */
	trier: () => {
		return [["5", "8", "10", "11", "13", "16", "23", "24", "27", "30", "33", "36"]];
	},

	/**
	 * Returns Orphelins bet numbers.
	 * @returns {Array<[string[]]>} - The Orphelins bet numbers.
	 */
	orpehlins: () => {
		return [["1", "6", "9", "14", "17", "20", "31", "34"]];
	},

	/**
	 * Returns Voisin bet numbers.
	 * @returns {Array<[string[]]>} - The Voisin bet numbers.
	 */
	voisin: () => {
		return [["0", "2", "3", "4", "7", "12", "15", "18", "19", "22", "25", "26", "28", "29", "32", "35"]];
	},

	/**
	 * Returns Zero bet numbers.
	 * @returns {Array<[string[]]>} - The Zero bet numbers.
	 */
	zero: () => {
		return [["0", "3", "12", "15", "26", "32", "35"]];
	}
};

export default rouletteBetsRNG;
