import PropTypes from "prop-types";
import RaceRects from "components/ui/raceRects";
import { GAME_TYPE, NUMBER_TO_COLOR_MAPPER } from "constants/game.constants";
import { offset, shift, flip } from "@floating-ui/dom";
import { isRacingGame } from "utils/common";

/** Pending Bet Component in Betslip */
const PendingBet = ({ pendingBet, onClose, children }) => {
	return (
		<div
			className={
				"vs--single-bet vs--single-bet-pending vs--mb-2 vs--pt-12 vs--pb-12 vs--pl-11 vs--pr-12 vs--flex vs--flex-col"
			}
		>
			<div className="vs--flex vs--flex-row vs--justify-start vs--flex-equal">
				<div className="vs--bet-remove vs--flex vs--flex-col vs--align-center vs--justify-start">
					<div className="vs--single-bet-remove vs--pt-4 vs--pr-14 vs--pb-4" onClick={onClose}>
						<i className="ic_close" />
					</div>
				</div>
				<div
					className="vs--flex vs--flex-col vs--align-center vs--justify-start vs--flex-equal"
					data-game={pendingBet.gameType}
				>
					<div className="vs--flex vs--flex-row vs--flex-equal vs--justify-start vs--single-bet-text-content">
						<div className="vs--flex vs--flex-equal vs--pr-4">
							{isRacingGame(pendingBet.gameType) &&
							Array.isArray(pendingBet.dataArr) &&
							pendingBet.dataArr.length > 0 ? (
								<RaceRects
									gameType={pendingBet.gameType}
									rectData={pendingBet.dataArr}
									middlewares={[offset({ mainAxis: 6 }), flip(), shift()]}
									overlayClassName="vs--result-block-place-to-bottom"
								/>
							) : pendingBet.gameType === GAME_TYPE.KENO ? (
								<span className="vs--title vs--flex-inline vs--font-regular vs--single-bet-odd vs--single-bet-odd-keno vs--font-exstrasmall">
									{pendingBet.dataArr.map((ball) => (
										<div key={ball} className="vs--flex">
											<div className="vs--result-keno vs--markets-keno-selection-num-sphere vs--flex vs--align-center vs--justify-center vs--mr-4">
												<span className="vs--flex-wrap vs--font-mini">{ball}</span>
											</div>
										</div>
									))}
								</span>
							) : pendingBet.gameType === GAME_TYPE.LUCKY_SIX ? (
								<div className="vs--lucky-six-pending-balls">
									{pendingBet.dataArr.map((number) => {
										const color = NUMBER_TO_COLOR_MAPPER[number % 8];

										return (
											<div
												key={number}
												className={`vs--lucky-six-ball vs--lucky-six-ball-active vs--lucky-six-ball-${color} vs--flex vs--justify-center vs--align-center`}
											>
												<span className="vs--lucky-six-ball-text">{number}</span>
											</div>
										);
									})}
								</div>
							) : (
								""
							)}
						</div>
					</div>
					<div className="vs--flex vs--flex-row vs--flex-equal vs--justify-start vs--single-bet-text-content vs--mt-12">
						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

/** PendingBet propTypes
 * PropTypes
 */
PendingBet.propTypes = {
	/** The pending bet of component */
	pendingBet: PropTypes.object,
	/** React property, handler of clos icon click event */
	onClose: PropTypes.func,
	/** React property, react children */
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default PendingBet;
