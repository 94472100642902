import useLuckySixBetting from "hooks/useLuckySixBetting.js";
import ColorMarkets from "components/pages/main/markets/luckySix/markets/colorMarkets.jsx";
import { LUCKY_SIX_MARKETS } from "constants/game.constants.js";
import Ball from "components/pages/main/markets/luckySix/ball/ball.jsx";

const BoardNumbers = ({ currentEvent, markets }) => {
	const { handleBallClick, areOddsDisabled, handleRandomClick } = useLuckySixBetting({ currentEvent });

	return (
		<div className="vs--lucky-six-container-default-market vs--flex vs--flex-col vs--align-center">
			<div className="vs--lucky-six-container-default-market-block vs--flex vs--flex-col">
				<div className="vs--lucky-six-container-default-market-block-colors vs--flex vs--align-center vs--justify-between">
					<ColorMarkets
						group={LUCKY_SIX_MARKETS.FIRST_BALL_COLOR}
						markets={markets}
						currentEvent={currentEvent}
						isSelectingColumn
					/>
				</div>

				<div className="vs--lucky-six-container-default-market-block-numbers vs--flex vs--flex-wrap">
					{Array.from({ length: 48 }, (_, i) => (
						<Ball key={i} number={i + 1} disabled={areOddsDisabled()} handleBallClick={handleBallClick} />
					))}
				</div>
			</div>

			<div
				onClick={handleRandomClick}
				className="vs--lucky-six-container-default-market-random vs--markets-list-item-content-odd-item vs--flex vs--align-center"
			>
				<div className="vs--markets-list-item-content-odd-item-title">
					<span className="vs--font-small">Random</span>
				</div>
			</div>
		</div>
	);
};

export default BoardNumbers;
