import Pools from "components/jackpotInfo/pools"
import JackpotAnimations from "components/pages/jackpotLiveInfo/animations";
import { useEffect, useRef } from "react"

const STREAM_NORMAL_WIDTH = 1920;
const STREAM_NORMAL_HEIGHT = 1080;

const setupScaleSizes = (tag, w, h) => {
	const scaleXValue = w / STREAM_NORMAL_WIDTH;
	const scaleYValue = h / STREAM_NORMAL_HEIGHT;
	tag.style.transformOrigin = "top left";
	tag.style.transform = `scaleX(${scaleXValue}) scaleY(${scaleYValue})`;
}

const resetScaleSizes = (tag) => {
	tag.style.transform = "";
	tag.style.transformOrigin = "";
}

const StreamJackpotPools = ({ jackpotInfo, disableAnimation }) => {
	const ref = useRef();
	useEffect(() => {
		const poolsContainer = ref.current;
		const overlayContainer = poolsContainer.parentElement;
		resetScaleSizes(poolsContainer);
		setupScaleSizes(poolsContainer, overlayContainer.offsetWidth, overlayContainer.offsetHeight)
		const handler = () => {
			if (!overlayContainer.offsetWidth || !overlayContainer.offsetHeight) {
				resetScaleSizes(poolsContainer);
			} else {
				setupScaleSizes(poolsContainer, overlayContainer.offsetWidth, overlayContainer.offsetHeight)
			}
		}
		
		const observer = new ResizeObserver(handler)
		observer.observe(overlayContainer)
		return () => {
			observer.disconnect()
		}
	}, [])
	return (
		<div ref={ref} className="vs--stream-jackpot-pools-container">
			<Pools
				className="vs--stream-jackpot-pools"
				showLevelIcon={true}
				data={jackpotInfo.levels}
			/>
			<div className="vs--active-jackpot-live-info vs--flex vs--flex-col vs--align-center vs--justify-center">
				<JackpotAnimations
					getMainContainer={() => document.querySelector(".vs--active-jackpot-live-info")}
					disableAnimation={disableAnimation}
				/>
			</div>
		</div>
	)
}
export default StreamJackpotPools