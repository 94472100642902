import axios from "axios";
import i18n from "i18next";
import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { BETSLIP_MODES, BETSLIP_BETS_LIMIT } from "constants/betslip.constants";
import { TICKET_TYPE } from "constants/ticket.constants";
import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { addPending } from "store/actions/tickets/pendings.actions";
import { printTicket } from "store/actions/tickets/ticket.actions";
import { setWonJackpotBonuses } from "store/actions/bonuses/jackpot.actions";
import { isPlaceBetDisabled } from "utils/bets";
import { binaryToFlags, getLineFromMatrix, isNullish } from "utils/common";
import { showSuccess } from "utils/message";
import runMarketUtilsFunction from "utils/markets/run";

import { PAYMENT_TYPE } from "constants/common.constants";

import {
	SET_BETSLIP_MODE,
	ADD_BET,
	REMOVE_BET,
	REMOVE_LAST_BET,
	UPDATE_BET_STAKE,
	CLEAR_BETS,
	SET_STAKE,
	PLACE_BET_BEFORE,
	PLACE_BET_AFTER,
	SET_BETSLIP,
	SET_FORECAST,
	CLEAR_FORECAST,
	SET_KENO_BALLS,
	CLEAR_KENO_BALLS,
	UPDATE_EVENT_BETS,
	SHOW_PAYMENT_METHOD,
	SET_CONFIRMING_TRANSACTION,
	SET_ON_FOCUS_STAKE,
	SET_ON_BLUR_TIMEOUT_ID,
	CLEAR_LUCKY_SIX_BALLS,
	ADD_LUCKY_SIX_BALLS,
	REMOVE_LUCKY_SIX_BALL
} from "../../actionTypes";
import { cleanUseBonus } from "store/actions/bonuses/bonuses.actions";
import { setErrorMessage } from "store/actions/common/common.actions";

const setMode = (mode) => ({
	type: SET_BETSLIP_MODE,
	payload: { mode }
});

export const setBetslipMode = (mode) => {
	return (dispatch, getState) => {
		const state = getState();
		const useBonus = state?.bonuses?.standard?.useBonus;

		if (useBonus) {
			if (mode === BETSLIP_MODES.SINGLE) {
				const bets = state?.betslip?.bets ?? [];
				if (bets.length > 1) {
					return dispatch(setErrorMessage(i18n.t("errors.message.InappropriateQuantity")));
				}
			} else if (mode === BETSLIP_MODES.MULTI) {
				const bonusAmount = state?.bonuses?.standard?.bonus?.amount ?? 0;
				dispatch(setStake(String(bonusAmount)));
			}
		}
		return dispatch(setMode(mode));
	};
};

export const addBet = (bet) => {
	return {
		type: ADD_BET,
		payload: {
			bet: {
				...bet,
				stake: bet.stake || "",
				key: `${Date.now()}_${bet?.id}_${bet?.eventId}_${bet?.oddId}_${bet?.factor}`,
				expired: false
			}
		}
	};
};

export const removeBet = (key = null, oddId, eventId) => ({
	type: REMOVE_BET,
	payload: { key, oddId, eventId }
});

export const doBet = (market, group, eventInfo) => {
	return (dispatch, getState) => {
		const state = getState();
		const current = state.game.current;
		const currentGameType = state.game.currentGameType;
		const currentMatch = state.game.matches.data.find((m) => m.id === current);
		const eventStatus = currentMatch?.event?.status;
		const mode = state.betslip.mode;

		const useBonus = state.bonuses.standard.useBonus;
		const bonus = state.bonuses.standard.bonus;
		const bonusAmount = bonus?.amount ?? 0;
		const bonusId = useBonus ? bonus?.id ?? null : null;
		let limit = useBonus && bonus.betType === BETSLIP_MODES.SINGLE ? 1 : BETSLIP_BETS_LIMIT;

		const bets = state?.betslip?.bets ?? [];
		const betsCount = bets?.length ?? 0;

		const getStakeToBet = () => {
			let stakeToBet = "";

			if (useBonus) {
				stakeToBet = String(bonusAmount);
			} else if (mode === BETSLIP_MODES.SINGLE) {
				stakeToBet = state.betslip.stake;
			}

			return stakeToBet;
		};

		if (useBonus) {
			const gameTypes = binaryToFlags(Object.values(GAME_TYPE), bonus?.gameType ?? 0);
			if (
				(betsCount >= 1 && mode === BETSLIP_MODES.SINGLE) ||
				(gameTypes.length && bets.some((bet) => !gameTypes.includes(bet.gameType)))
			) {
				const someBetOfSameEvent = bets.some((bet) => bet.eventId === eventInfo.id && bet.oddId !== market.id);
				if (someBetOfSameEvent) {
					return dispatch(setErrorMessage(i18n.t("errors.message.InappropriateQuantity")));
				}
			}

			if (limit > 1) {
				dispatch(setStake(String(bonusAmount)));
			} else {
				dispatch(setStake(""));
			}
		}

		if (currentGameType === GAME_TYPE.KENO) {
			if (state.betslip.bets.length < limit) {
				dispatch(
					addBet({
						gameData: eventInfo.gameData,
						type: eventInfo.type,
						orderNumber: eventInfo.orderNumber,
						startTime: eventInfo.startTime,
						eventId: eventInfo.id,
						id: eventInfo.id,
						factor: market.factor,
						showName: market.showName,
						ticketShowName: market.ticketShowName,
						groupTitle: group,
						oddId: market.id,
						gameType: currentGameType,
						stake: getStakeToBet(),
						rectData: market.rectData,
						bonusId
					})
				);
			}
		} else {
			if (
				market.factor &&
				market.factor >= 1.01 &&
				(eventStatus === GAME_STATUSES.NEW ||
					eventStatus === GAME_STATUSES.PREAMBLE_STARTED ||
					([GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.STARTED].includes(eventStatus) &&
						eventInfo.type === GAME_EVENT_TYPE.LEAGUE))
			) {
				const isBetSelected = state.betslip.bets.some((b) => b.oddId === market.id && b.eventId === eventInfo.id);
				if (!isBetSelected) {
					if (state.betslip.bets.length < limit) {
						dispatch(
							addBet({
								gameData: eventInfo.gameData,
								type: eventInfo.type,
								orderNumber: eventInfo.orderNumber,
								startTime: eventInfo.startTime,
								eventId: eventInfo.id,
								weekId: eventInfo.type === GAME_EVENT_TYPE.EVENT ? eventInfo.parentEventId : null,
								seasonId: eventInfo.seasonId,
								id: eventInfo.id,
								factor: market.factor,
								showName: market.showName,
								ticketShowName: market.ticketShowName,
								groupTitle: group,
								oddId: market.id,
								gameType: currentGameType,
								stake: getStakeToBet(),
								rectData: market.rectData,
								bonusId
							})
						);
					}
				} else {
					dispatch(removeBet(null, market.id, eventInfo.id));
				}
			}
		}
	};
};

export const removeLastBet = () => ({
	type: REMOVE_LAST_BET
});

export const updateBetStake = (stake, key) => ({
	type: UPDATE_BET_STAKE,
	payload: { stake, key }
});

export const clearBets = () => ({
	type: CLEAR_BETS
});

export const setStake = (stake) => ({
	type: SET_STAKE,
	payload: { stake }
});

const placeBetBefore = () => ({
	type: PLACE_BET_BEFORE
});

const placeBetAfter = () => ({
	type: PLACE_BET_AFTER
});

export const placeBet = (method) => {
	return (dispatch, getState) => {
		const state = getState();
		const betSlipData = state.betslip;

		if (
			isPlaceBetDisabled(
				betSlipData.bets,
				betSlipData.mode,
				betSlipData.stake,
				betSlipData.loading,
				state.auth.session
			)
		)
			return;

		const useBonus = state.bonuses.standard.useBonus;
		const bonusId = useBonus ? state.bonuses.standard.bonus?.id ?? null : null;

		dispatch(placeBetBefore());
		const data = {
			bonusId,
			type: betSlipData.mode,
			bets: betSlipData.bets
				.filter((b) => !b.expired)
				.map((b) => ({
					eventId: b.eventId,
					oddId: b.oddId,
					amount: betSlipData.mode === BETSLIP_MODES.SINGLE ? b.stake : 0
				})),
			paymentType: method
		};
		if (betSlipData.mode === BETSLIP_MODES.MULTI) {
			data.totalAmount = betSlipData.stake;
		}

		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.PLACE_BET}`,
			method: Methods.POST,
			data: data
		})
			.then(({ data: { value: result } }) => {
				dispatch(setStake(""));
				dispatch(placeBetAfter());
				dispatch(addPending(result));
				dispatch(clearForecast());
				dispatch(clearKenoBalls());
				dispatch(cleanUseBonus());

				/** Print Ticket */
				dispatch(
					printTicket({
						ticketType: TICKET_TYPE.BET,
						id: result.id,
						type: result.type,
						totalAmount: result.totalAmount,
						possibleWin: result.possibleWin,
						bets: betSlipData.bets
							.filter((b) => !b.expired)
							.map((b, ind) => ({
								...b,
								groupTitle: runMarketUtilsFunction(
									"makeGroupTitle",
									[
										{
											group: result.bets.find((r, i) => ind === i).group,
											argument: result.bets.find((r, i) => ind === i).argument,
											gameData: b.gameData,
											gameType: b.gameType,
											isTicket: true
										}
									],
									b.gameType
								),
								showName: b.ticketShowName,
								id: result.bets[ind].id
							})),
						additionalFactor: result.additionalFactor,
						bonusId: result.bonusId,
						bonusType: result.bonusType,
						wonJackpots: result.wonJackpots
					})
				);

				if (method === PAYMENT_TYPE.CREDIT_CARD && (state?.auth?.session?.showBetRejectPopup ?? true)) {
					dispatch(setConfirmingTransaction({ id: result.id, betTime: result.betTime }));
				}

				dispatch(clearBets());

				if (!isNullish(result.wonJackpots)) {
					setWonJackpotBonuses(result.wonJackpots);
				}

				if (useBonus) {
					showSuccess(
						<div>
							<i className="ic_done" />
							<span>{i18n.t("bonus.theFreeBetsHaveSuccessfullyBeenPlaced")}</span>
						</div>
					);
				}
			})
			.catch((ex) => {
				dispatch(placeBetAfter());
			});
	};
};

export const setBetslip = (betslip) => ({
	type: SET_BETSLIP,
	payload: { betslip }
});

export const setForecast = (forecast) => ({
	type: SET_FORECAST,
	payload: { forecast }
});

export const clearForecast = () => ({
	type: CLEAR_FORECAST
});

export const setKenoBalls = (kenoBalls) => ({
	type: SET_KENO_BALLS,
	payload: { kenoBalls }
});

export const clearKenoBalls = () => ({
	type: CLEAR_KENO_BALLS
});

export const addLuckySixBalls = (balls) => ({
	type: ADD_LUCKY_SIX_BALLS,
	payload: { balls }
});

export const removeLuckySixBall = (ball) => ({
	type: REMOVE_LUCKY_SIX_BALL,
	payload: { ball }
});

export const clearLuckySixBalls = () => ({
	type: CLEAR_LUCKY_SIX_BALLS
});

export const updateEventBets = (event) => ({
	type: UPDATE_EVENT_BETS,
	payload: { event }
});

export const showPaymentMethod = (show) => ({
	type: SHOW_PAYMENT_METHOD,
	payload: { show }
});

export const setConfirmingTransaction = (transaction) => ({
	type: SET_CONFIRMING_TRANSACTION,
	payload: { transaction }
});

export const rejectBetslip = (id, betTime) => {
	return (dispatch, getState) => {
		return axios({
			url: `${import.meta.env.SYSTEM_API_URL}${ApiUrls.REJECT_BETSLIP}`,
			method: Methods.POST,
			data: { id, betTime }
		})
			.then(({ data: { value: result } }) => {
				dispatch(setConfirmingTransaction(null));
			})
			.catch((ex) => {
				dispatch(setConfirmingTransaction(null));
			});
	};
};

export const setOnFocusBets = (betIdentifiers) => ({
	type: SET_ON_FOCUS_STAKE,
	payload: { betIdentifiers }
});

export const setOnBlurTimeoutId = (timeoutId) => ({
	type: SET_ON_BLUR_TIMEOUT_ID,
	payload: { timeoutId }
});

export const clearTimeoutId = () => (dispatch, getState) => {
	const state = getState();
	const oldTimeoutId = state?.betslip?.focusStake?.timeoutId ?? null;
	if (oldTimeoutId !== null) {
		clearTimeout(oldTimeoutId);
	}
	return dispatch(setOnBlurTimeoutId(null));
};

export const setOnBlurBets = () => (dispatch) => {
	dispatch(setOnFocusBets(null));
	dispatch(clearTimeoutId());
};

export const setOnBlurBetsWithTimeout =
	(ms = 250) =>
	(dispatch) => {
		return dispatch(
			setOnBlurTimeoutId(
				setTimeout(() => {
					dispatch(setOnBlurBets());
				}, ms)
			)
		);
	};

export const handleSetKenoBalls = (odd, eventInfo, gameType, raceFormatCount, sequencConfig, markets) => {
	return (dispatch, getState) => {
		const prev = getState().betslip.kenoBalls;
		if (prev.balls.includes(odd)) {
			dispatch(
				setKenoBalls({
					eventInfo,
					gameType,
					balls: prev.balls.filter((b) => b !== odd)
				})
			);
			return;
		}

		if (prev.balls.length >= raceFormatCount) {
			return;
		}

		let balls = [];

		if (sequencConfig && sequencConfig.init) {
			const line = getLineFromMatrix(
				markets,
				...(sequencConfig.side === "top" ? [sequencConfig.dataRow, true] : [sequencConfig.dataCol, false])
			);
			const index = line.indexOf(odd);
			const selected = line.concat(line.copyWithin()).slice(index, index + sequencConfig.qty);
			balls = [...prev.balls, ...selected].filter((odd, i, arr) => arr.indexOf(odd) === i);
		} else {
			balls = prev.balls.concat([odd]);
		}

		dispatch(
			setKenoBalls({
				eventInfo,
				gameType,
				balls
			})
		);
	};
};
