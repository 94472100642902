import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LUCKY_SIX_MARKETS } from "constants/game.constants.js";
import { Fragment } from "react";
import eventType from "types/event.type.js";
import BetBall from "components/pages/luckySix/components/betBall";
import { findMarketWinnerByGroup } from "components/pages/luckySix/components/markets/helpers";

const FirstMarket = ({ markets, currentEventScenes, overUnderOutcome, oddEvenOutcome }) => {
	const { t } = useTranslation();

	const overUnder =
		overUnderOutcome || findMarketWinnerByGroup(LUCKY_SIX_MARKETS.FIRST_BALL_OVER_UNDER, markets)?.outcome;
	const oddEven = oddEvenOutcome || findMarketWinnerByGroup(LUCKY_SIX_MARKETS.FIRST_BALL_ODD_EVEN, markets)?.outcome;
	const firstBall = currentEventScenes?.at(0);

	return (
		<>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-name">
				{t("common.1st")}:
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{firstBall?.ballColour && <BetBall number={firstBall.number} color={firstBall.ballColour} />}
			</span>
			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{overUnder ? (
					<>
						<span style={{ color: "#A3A0C0" }}> - </span>
						{overUnder}
					</>
				) : null}
			</span>

			<span className="vs--lucky-six-live-monitor-finished-game-results-container-balls-footer-item-value">
				{oddEven ? (
					<>
						<span style={{ color: "#A3A0C0" }}> - </span>
						{oddEven}
					</>
				) : null}
			</span>
		</>
	);
};

FirstMarket.propTypes = {
	markets: PropTypes.array,
	currentEventScenes: PropTypes.array,
	overUnderOutcome: PropTypes.string,
	oddEvenOutcome: PropTypes.string
};

export default FirstMarket;
