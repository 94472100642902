import React from "react";
import runMarketUtilsFunction from "utils/markets/run.js";
import { connect, useSelector } from "react-redux";
import { GAME_STATUSES } from "constants/game.constants.js";
import { mergeClassNames } from "utils/common.js";

const OddMarkets = ({ group, haveTitle = false, singleSection, currentGameType, emptySpace = false }) => {
	const current = useSelector((state) => state.game.current);
	const currentEvent = useSelector((state) => state.game.matches.data.find((m) => m.id === current) || null);
	const market = currentEvent?.markets?.main?.find((marketItem) => marketItem.group === group);

	const marketTitle = runMarketUtilsFunction("makeGroupTitle", [{ group, currentGameType }], currentGameType);
	const firstOdd = market?.odds?.[0]?.[0];
	const secondOdd = market?.odds?.[0]?.[1];

	return (
		<>
			{haveTitle && <div className="vs--lucky-six-live-monitor-odd-markets-title">{marketTitle}</div>}
			<div className="vs--lucky-six-live-monitor-odd-markets">
				<div
					className={mergeClassNames(
						"vs--lucky-six-live-monitor-odd-markets-items",
						singleSection ? "single-section" : ""
					)}
				>
					{market ? (
						<>
							<div
								className={mergeClassNames(
									"vs--lucky-six-live-monitor-odd-markets-items-element",
									singleSection ? "single-section" : ""
								)}
							>
								<span className="vs--lucky-six-live-monitor-odd-markets-items-element-name">
									{firstOdd?.showName ?? firstOdd?.name}
								</span>
								<span className="vs--lucky-six-live-monitor-odd-markets-items-element-factor">
									{firstOdd.factor}
								</span>
							</div>
							<div
								className={mergeClassNames(
									"vs--lucky-six-live-monitor-odd-markets-items-element",
									singleSection ? "single-section" : ""
								)}
							>
								<span className="vs--lucky-six-live-monitor-odd-markets-items-element-name">
									{secondOdd?.showName ?? secondOdd?.name}
								</span>
								<span className="vs--lucky-six-live-monitor-odd-markets-items-element-factor">
									{secondOdd.factor}
								</span>
							</div>
						</>
					) : (
						"-"
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const currentEvent =
		state.game.liveAndUpcomings.data.find((event) =>
			[GAME_STATUSES.NEW, GAME_STATUSES.PREAMBLE_STARTED].includes(event.status)
		) || state.game.liveAndUpcomings.data[0];
	return {
		currentGameType: state.game.currentGameType,
		matches: state.game.matches.data,
		game: state.game,
		currentEvent
	};
};

export default connect(mapStateToProps)(OddMarkets);
