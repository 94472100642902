import { useTranslation } from "react-i18next";
import moment from "moment";

import AmountNums from "components/jackpotInfo/amountNums";
import { mergeClassNames, toFixed, isNullish, buildPathToStaticFolderOfCDN } from "utils/common";

import { JACKPOT_LEVELS_ICONS } from "constants/bonus.constants";

import { JACKPOT_DATE_TIME_FORMAT } from "constants/date.constants";


const JACKPOT_LEVELS = {
	"level-1": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-1.svg"),
	"level-2": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-2.svg"),
	"level-4": buildPathToStaticFolderOfCDN("cashier-images/jackpot/level-4.svg")
}

const SingleCard = ({ className = "", currencyDecimalCount = 0, currencyCode, currencySymbol, jackpot }) => {
	const { t } = useTranslation();

	const format = "( ddd)" + (currencyDecimalCount > 0 ? ".".padEnd(currencyDecimalCount + 1, "d") : "")
	const possiblyLargeAmount = toFixed(jackpot?.amount || 0, currencyDecimalCount);
	const amount = Number(possiblyLargeAmount) || 0;
	return (
		<div className="vs--single">
			<div className={mergeClassNames(className, "vs--flex vs--flex-col vs--justify-between", `vs--jackpot-content-levels-${jackpot.levelType}`, "vs--jackpot-content-levels-level-card-single")}>
				<div className="vs--jackpot-content-levels-card-content-body vs--flex vs--flex-col vs--justify-center vs--align-center">
					<span className="vs--jackpot-content-levels-card-title">{jackpot.poolName}</span>

					<img className='vs--jackpot-content-levels-card-gif' src={JACKPOT_LEVELS_ICONS[`level-${jackpot.levelType}`]}></img>

					<div className={mergeClassNames("vs--jackpot-content-levels-card-content-body-amount vs--jackpot-content-levels-card-content-body-amount-countdown", "vs--flex vs--align-center")}>
						<AmountNums
							amount={amount}
							format={format}
							currencySymbol={currencySymbol}
							numClassName="vs--jackpot-content-level-card-content-body-amount-num-single-info"
						/>
					</div>

						<div className="vs--jackpot-content-levels-card-min-bet">
							<span className="vs--jackpot-content-levels-card-min-bet-title">{t('cashier.minBet')}:&nbsp;</span>
							<span className="vs--jackpot-content-levels-card-min-bet-value vs--ml-4">{jackpot.minBetAmount}&nbsp;{currencyCode}</span>
						</div>

						<div className="vs--font-big vs--font-medium">
							<span className="vs--jackpot-content-levels-footer-last-winner">{t('cashier.lastWinner')}:&nbsp;</span>
							<span className="vs--jackpot-content-levels-footer-last-winner">{isNullish(jackpot.lastWinTime) || isNullish(jackpot.betSlipId) ? "-" : `${t('common.id')} ${jackpot.betSlipId}`}</span>
						</div>
						

						<div className="vs--font-big vs--font-medium">
							<span className="vs--jackpot-content-levels-footer-date">{jackpot.lastWinTime === null ? "" : moment.utc(jackpot.lastWinTime).local().format(JACKPOT_DATE_TIME_FORMAT)}</span>
						</div>
				</div>
			</div>
		</div>
	);
};

export default SingleCard;
