import RouletteWheel from "../../roulette-wheel"
import ColdStatistics from "./cold-statistics"
import HotStatistics from "./hot-statistics"

const RouletteStatistics = ({ fromHotToColdSorted }) => {
	return (
		<div className="vs--spin-2-win-stream-roulette-statistics-container">
			<ColdStatistics fromHotToColdSorted={fromHotToColdSorted} />
			<HotStatistics fromHotToColdSorted={fromHotToColdSorted} />
			<div className="vs--spin-2-win-stream-roulette-statistics-background"></div>
			<RouletteWheel fromHotToColdSorted={fromHotToColdSorted} />
		</div>
	)
}
export default RouletteStatistics