import { useState } from "react";
import {
	SPIN_TO_WIN_BOARD_GROUPS,
	SPIN_TO_WIN_BOTTOM_MARKETS_OUTCOMES,
	SPIN_TO_WIN_MARKETS_NUMBERS
} from "constants/game.constants.js";
import BoardSvg from "components/pages/main/markets/spin2Win/bottomBoard/boardSvg.jsx";
import { setHoveredNumbers } from "store/actions/spin2Win/spin2Win.actions.js";
import { useDispatch, useSelector } from "react-redux";
import runMarketUtilsFunction from "utils/markets/run.js";
import { doBet } from "store/actions/betslip/betslip.actions.jsx";

const BottomBoard = ({ markets, eventInfo, isOddDisabled }) => {
	const [hoveredGroup, setHoveredGroup] = useState(SPIN_TO_WIN_BOARD_GROUPS.NONE);

	const currentGameType = useSelector((state) => state.game.currentGameType);
	const bets = useSelector((state) => state.betslip.bets);

	const dispatch = useDispatch();

	const mouseOverHandler = (group) => {
		dispatch(setHoveredNumbers(SPIN_TO_WIN_MARKETS_NUMBERS[group]));
	};

	const mouseLeaveHandler = () => {
		setHoveredGroup(SPIN_TO_WIN_BOARD_GROUPS.NONE);
		dispatch(setHoveredNumbers([]));
	};

	const oddClickHandler = (bottomGroup) => {
		const outcomes = SPIN_TO_WIN_BOTTOM_MARKETS_OUTCOMES[bottomGroup];

		markets?.forEach((market) => {
			market?.odds[0]?.forEach((odd) => {
				if (outcomes.includes(odd.outcome)) {
					if (bets.some((bet) => bet.oddId === odd.id)) {
						return;
					}

					const group = runMarketUtilsFunction(
						"makeGroupTitle",
						[
							{
								group: market.group,
								argument: market.argument,
								gameData: eventInfo?.gameData ?? {},
								gameType: currentGameType
							}
						],
						currentGameType
					);

					dispatch(doBet(odd, group, eventInfo));
				}
			});
		});
	};

	return (
		<BoardSvg
			onClick={oddClickHandler}
			onMouseOver={mouseOverHandler}
			onMouseLeave={mouseLeaveHandler}
			hoveredGroup={hoveredGroup}
			isOddDisabled={isOddDisabled}
		/>
	);
};

export default BottomBoard;
