import { useMemo } from "react";

import Num from './num';

import usePrevious from 'hooks/usePrevious';

import { mergeClassNames } from "utils/common";
import JackpotOdometer from "components/jackpotOdometer";

const ANIMATION_CLASSNAME_PREFIX_BY_INDICES = {
	FASTEST: "fastest",
	FAST: "fast",
	COMMON: "common"
}

const getAnimationClassNamePrefix = (arrLength, index) => {
	if (index === arrLength - 1) {
		return ANIMATION_CLASSNAME_PREFIX_BY_INDICES.FASTEST;
	}
	if (arrLength >= 2 && index === arrLength - 2) {
		return ANIMATION_CLASSNAME_PREFIX_BY_INDICES.FAST;
	}

	return ANIMATION_CLASSNAME_PREFIX_BY_INDICES.COMMON;
}

const AmountNums = ({ className = "", numClassName, amount, format, currencySymbol }) => {
	return (
		<div className={mergeClassNames(
			className,
			"vs--jackpot-content-levels-card-amount",
			"vs--flex vs--align-center"
		)}>
			<div className={mergeClassNames("vs--jackpot-content-levels-card-amount-num", numClassName)}>
				<JackpotOdometer value={amount} format={format} />
			</div>
			<div className={mergeClassNames(`vs--jackpot-content-levels-card-amount-symbol`, 'vs--ml-6')}>&nbsp;{currencySymbol?.slice(0, 8)}</div>
		</div>
	)
};

export default AmountNums;