import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { doBet, clearLuckySixBalls } from "store/actions/betslip/betslip.actions";
import Tooltip from "components/ui/tooltip";
import useDocumentListener from "hooks/useDocumentListener";
import KEY_CODE from "constants/keyboard.constants";
import { isSeasonGame } from "utils/common.js";
import { GAME_TYPE } from "constants/game.constants.js";

const LuckySixButton = () => {
	const luckySixBalls = useSelector((state) => state.betslip.luckySixBalls);
	const matches = useSelector((state) => state.game.matches.data);
	const current = useSelector((state) => state.game.current);
	const currentGameType = useSelector((state) => state.game.currentGameType);
	const session = useSelector((state) => state.auth.session);

	const getEventInfo = () => {
		const match = matches.find((m) => m.id === current);

		const parentEvent = match?.event ?? {};

		if (isSeasonGame(currentGameType)) {
			return {
				parentEvent,
				eventInfos: parentEvent?.events ?? []
			};
		}

		return {
			gameData: parentEvent.gameData ?? {},
			id: parentEvent.id ?? null,
			startTime: parentEvent.startTime ?? null,
			status: parentEvent.status ?? null,
			type: parentEvent.type ?? null,
			gameType: parentEvent.gameType ?? null,
			orderNumber: parentEvent.orderNumber ?? null
		};
	};

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleLuckySixBallsButtonClick = () => {
		const game = session.games.find((g) => g.type === GAME_TYPE.LUCKY_SIX);

		if (luckySixBalls?.length === 6) {
			dispatch(
				doBet(
					{
						balls: luckySixBalls,
						id: luckySixBalls.join(":"),
						showName: luckySixBalls.join(", "),
						ticketShowName: luckySixBalls.join(", "),
						factor: game.rtPs?.find((rtP) => rtP.orderNumber === 1).margin
					},
					t("common.ballNumbers"),
					getEventInfo()
				)
			);

			dispatch(clearLuckySixBalls());
		}
	};

	// Handler for space key down event
	// Add listener for key down
	useDocumentListener("keydown", (e) => {
		if (e.keyCode === KEY_CODE.SPACE) {
			handleLuckySixBallsButtonClick();
		}
	});

	return luckySixBalls?.length > 0 ? (
		<Tooltip>
			<button
				type="button"
				className={`vs--button vs--button-forecast vs--pr-8 vs--pl-8 vs--pt-12 vs--pb-12 ${
					luckySixBalls.length < 6 ? "vs--button-disabled" : ""
				}`}
				onClick={handleLuckySixBallsButtonClick}
				disabled={luckySixBalls.length < 6}
				>
				{t("bet.addToBetslip")}
			</button>
	 </Tooltip>
	) : (
		<Fragment />
	);
};

export default LuckySixButton;
