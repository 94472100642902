import { buildPathToStaticFolderOfCDN } from "utils/common"
import Timer from "../timer"
import { GAME_STATUSES } from "constants/game.constants";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import JackpotAnimations from "components/pages/jackpotLiveInfo/animations";
import Pools from "components/jackpotInfo/pools";

const StreamHeader = ({ event, logoPath }) => {

	const { t } = useTranslation();
	const jackpotInfo = useSelector((state) => state.bonuses.jackpot.data);
	const isEventExists = Boolean(event);
	const isBetsAreOpen = isEventExists && [GAME_STATUSES.NEW, GAME_STATUSES.PREAMBLE_STARTED].includes(event.status);

	return (
		<div className="vs--spin-2-win-stream-header">
			<div className="vs--spin-2-win-stream-header-top">
				<div className="vs--spin-2-win-stream-header-section vs--spin-2-win-stream-section-gap vs--flex vs--flex-row vs--justify-start vs--align-center vs--flex-equal vs--pl-20">
					<span className="vs--spin-2-win-stream-name">
						{t("common.spin2Win")}
					</span>
					{
						isBetsAreOpen
							? (
								<Fragment>
									<span className="vs--spin-2-win-stream-text-separator"></span>
									<span className="vs--spin-2-win-stream-status">
										{t("common.betsAreOpen")}
									</span>
								</Fragment>
							)
							: null
					}
				</div>
				<div className="vs--spin-2-win-stream-header-section">
					<Timer startTime={event?.startTime ?? null} />
				</div>
				<div className="vs--spin-2-win-stream-header-section vs--spin-2-win-stream-section-gap vs--flex vs--flex-row vs--justify-end vs--align-center vs--flex-equal vs--pr-20">
					{
						isEventExists
							? (
								<Fragment>
									<span className="vs--spin-2-win-stream-text-separator"></span>
									<span className="vs--spin-2-win-stream-name">
										{t("common.id")}&nbsp;#:
									</span>
									<span className="vs--spin-2-win-stream-name">
										{event.id}
									</span>
								</Fragment>
							)
							: null
					}
				</div>
			</div>
			<div className="vs--spin-2-win-stream-header-bottom ">
				<div className="vs--spin-2-win-stream-header-section vs--flex-equal">
					<img className="vs--spin-2-win-stream-logo" src={logoPath ? `${import.meta.env.SYSTEM_CDN_URL}/${logoPath.toLowerCase()}` : buildPathToStaticFolderOfCDN("cashier-images/betshop-default-logo.png")} alt="logo" />
				</div>
				<div className="vs--spin-2-win-stream-header-section vs--flex-equal vs--flex vs--flex-row vs--justify-end vs--align-center">
					<div className="vs--spin-2-win-stream-jackpot">
						<Pools
							className="vs--spin-2-win-stream-jackpot-pools"
							showLevelIcon={true}
							data={jackpotInfo.levels}
						/>
						<div className="vs--active-jackpot-live-info vs--flex vs--flex-col vs--align-center vs--justify-center">
							<JackpotAnimations
								getMainContainer={() => document.querySelector(".vs--active-jackpot-live-info")}
								disableAnimation={!isBetsAreOpen}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default StreamHeader