import { useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import StartedGameFlowMain from "components/pages/luckySix/startedGameFlow/components/startedGameFlowMain";
import { GAME_STATUSES } from "constants/game.constants.js";
import Countdown from "components/countdown";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const StartedGameFlow = ({ onStart, onEnd }) => {
	const { t } = useTranslation();
	const timeoutId = useRef(null);
	const liveMatch = useSelector(
		(state) => state.game.liveAndUpcomings.data.find((m) => m.status === GAME_STATUSES.STARTED) || null
	);

	const [showCountdown, setShowCountdown] = useState(false);
	const [countDownSec, setCountDownSec] = useState(null);

	const onFinish = useCallback(() => {
		onStart();
		setShowCountdown(false);
	}, [onStart]);

	useEffect(() => {
		if (liveMatch === null) {
			timeoutId.current = setTimeout(() => {
				setShowCountdown(true);
			}, 2000);
		} else {
			onStart();
		}

		return () => clearTimeout(timeoutId.current);
	}, [liveMatch, onStart]);

	useEffect(() => {
		let sec = moment().second();
		setCountDownSec(60 - sec - 3); // -3 for animation duration
	}, []);

	return (
		<div className="vs--lucky-six-live-monitor-container-started-game-flow-container">
			<StartedGameFlowMain scenes={liveMatch?.gameData?.scenes} onEnd={onEnd} liveMatch={liveMatch} />

			{showCountdown && countDownSec && (
				<Countdown start={countDownSec} endValue={1} closingText={t("common.start")} finished={onFinish} />
			)}
		</div>
	);
};

StartedGameFlow.propTypes = {
	onStart: PropTypes.func.isRequired,
	onEnd: PropTypes.func.isRequired
};

export default StartedGameFlow;
