import { useTranslation } from "react-i18next";
import moment from "moment";

import AmountNums from 'components/jackpotInfo/amountNums';
import {  mergeClassNames, toFixed, isNullish } from "utils/common";

import { JACKPOT_LEVEL, JACKPOT_LEVELS_ICONS, MAX_HIT_AMOUNT_NUM_LENGTH } from "constants/bonus.constants";

import { JACKPOT_DATE_TIME_FORMAT } from "constants/date.constants";

const LEVELS_HIERARCHY = {
	[JACKPOT_LEVEL.BRONZE]: [JACKPOT_LEVEL.BRONZE],
	[JACKPOT_LEVEL.SILVER]: [JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.BRONZE],
	[JACKPOT_LEVEL.GOLD]: [JACKPOT_LEVEL.GOLD, JACKPOT_LEVEL.SILVER, JACKPOT_LEVEL.BRONZE]
}

const Card = ({
	className = "",
	currencyDecimalCount = 0,
	currencyCode,
	currencySymbol,
	jackpot
}) => {
	const { t } = useTranslation();

	const maxNums = Array.from({ length: MAX_HIT_AMOUNT_NUM_LENGTH + currencyDecimalCount });
	const possiblyLargeAmount = toFixed(jackpot?.amount || 0, currencyDecimalCount);
	const amount = Number(possiblyLargeAmount) || 0;
	const format = "( ddd)" + (currencyDecimalCount > 0 ? ".".padEnd(currencyDecimalCount + 1, "d") : "")

	return (
		<div className="vs--jackpot-content-levels vs--flex vs--flex-col vs--align-center">
		<div className={mergeClassNames(
			className,
			"vs--flex vs--flex-col vs--justify-between",
			"vs--jackpot-content-levels-card",
			`vs--jackpot-content-levels-card-${jackpot.levelType}`
		)}>
				<span className="vs--jackpot-content-levels-card-title">{jackpot.poolName}</span>

				<img className='vs--jackpot-content-levels-card-gif' src={JACKPOT_LEVELS_ICONS[`level-${jackpot.levelType}`]}></img>

				<div className={mergeClassNames(
					"vs--jackpot-content-levels-card-amount vs--jackpot-content-levels-card-content-body-amount-countdown",
					"vs--flex vs--align-center"
				)}>
					<AmountNums
						amount={amount}
						format={format}
						currencySymbol={currencySymbol}
					/>
				</div>

				<div className="vs--jackpot-content-levels-card-min-bet">
					<span className="vs--jackpot-content-levels-card-min-bet-title">{t('cashier.minBet')}:&nbsp;</span>
					<span className="vs--jackpot-content-levels-card-min-bet-value vs--ml-4">{jackpot.minBetAmount}&nbsp;{currencyCode}</span>
				</div>

				<div className="vs--jackpot-content-levels-card-level vs--flex vs--mt-8">
					{LEVELS_HIERARCHY[jackpot.levelType].map((level) => (
						<img key={level} className="vs--jackpot-content-levels-card-level-icon" src={JACKPOT_LEVELS_ICONS[`png-level-${level}`]}></img>
					))}
				</div>
		</div>

				<div className="vs--jackpot-content-levels-footer vs--flex vs--flex-col vs--align-center vs--justify-between vs--pl-16 vs--pr-16">
					<div className="vs--font-big vs--font-medium">
						<span className="vs--jackpot-content-levels-footer-last-winner">{t('cashier.lastWinner')}:&nbsp;</span>
						<span className="vs--jackpot-content-levels-footer-last-winner">{isNullish(jackpot.lastWinTime) || isNullish(jackpot.betSlipId) ? "-" : `${t('common.id')} ${jackpot.betSlipId}`}</span>
					</div>
					<div className="vs--font-big vs--font-medium">
						<span className="vs--jackpot-content-levels-footer-date">{jackpot.lastWinTime === null ? "-" : moment.utc(jackpot.lastWinTime).local().format(JACKPOT_DATE_TIME_FORMAT)}</span>
					</div>
				</div>

		</div>
	)
};

export default Card;