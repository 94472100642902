import SideBets from "components/pages/main/markets/luckySix/sideBets/index.jsx";
import BoardNumbers from "components/pages/main/markets/luckySix/boardNumbers/index.jsx";

const LuckySixMarkets = ({ markets, eventInfo }) => {
	return (
		<div className="vs--lucky-six vs--flex vs--align-center vs--justify-center">
			<div className="vs--lucky-six-container vs--flex vs--justify-between vs--align-center">
				<BoardNumbers markets={markets} currentEvent={eventInfo} />

				<SideBets markets={markets} currentEvent={eventInfo} />
			</div>
		</div>
	);
};

export default LuckySixMarkets;
